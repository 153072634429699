import React, { useContext, useEffect, useState } from 'react';

import cx from 'classnames';
import moment from 'moment';
import Chart from 'react-apexcharts';

import CustomersApi from '../../../api/CustomersApi';
import IconButton from '../../../components/IconButton';
import TableSkeleton from '../../../components/skeletons/TableSkeleton';
import ClientContext from '../../../Dashboard/ClientContext';
import mapChartData from '../../../utility/mapChartData';

const Headcount = () => {
  const { selected } = useContext(ClientContext);
  const [chartData, setChartData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [selectedDate, setSelectedDate] = useState(moment().format('YYYY-MM-DD'));

  useEffect(() => {
    if (selected) {
      setLoadingData(true);
      const param = selected.customerUrl.replace('https://', '').split('.')[0];
      CustomersApi.getHeadcountData(moment(selectedDate).format('YYYY'), param)
        .then((res) => {
          const formated = mapChartData(res.data.data);
          setChartData([{ name: 'Employees', data: formated }]);
          setLoadingData(false);
        })
        .catch(() => {
          setLoadingData(false);
        });
    }
  }, [selected, selectedDate]);

  const data = {
    options: {
      grid: {
        borderColor: '#e9edef',
        strokeDashArray: 3,
        xaxis: {
          lines: {
            show: false,
          },
        },
        padding: {
          top: 30,
          right: 32,
          left: 12,
        },
        row: {
          colors: undefined,
          opacity: 1,
        },
      },
      chart: {
        type: 'area',
        foreColor: '#93a5b1',
        animations: {
          enabled: false,
        },
        toolbar: {
          show: true,
        },
        zoom: {
          enabled: true,
        },
      },
      colors: ['#1A938A'],
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      xaxis: {
        labels: {
          show: true,
          trim: true,
          showDuplicates: false,
          minHeight: undefined,
          maxHeight: 120,
          style: {
            colors: [],
            fontSize: '12px',
          },
          datetimeFormatter: {
            month: 'MMM',
          },
        },
        type: 'datetime',
        tickAmount: 12,
        axisBorder: {
          show: true,
          color: '#e9edef',
          height: 1,
        },
        axisTicks: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      stroke: {
        curve: 'smooth',
        width: 2,
      },
      fill: {
        type: 'gradient',
      },
      yaxis: {
        decimalsInFloat: 0,
        tooltip: {
          enabled: false,
          offsetX: 0,
        },
        labels: {
          show: true,
          align: 'left',
          offsetX: 0,
        },
        forceNiceScale: true,
      },
      tooltip: {
        x: {
          show: true,
          format: 'MMM dd',
        },
        marker: {
          show: false,
        },
      },
    },
  };

  const prevYear = () => {
    setSelectedDate((prevState) => moment(prevState).subtract(1, 'year'));
  };

  const nextYear = () => {
    setSelectedDate((prevState) => moment(prevState).add(1, 'year'));
  };

  return (
    <div>
      <div className='d-flex justify-content-end mt-3 me-5 head-cont-top'>
        <IconButton
          icon='icon-chevron-left'
          size='sm'
          isDisabled={moment().subtract(1, 'year').isSame(selectedDate, 'year')}
          className={cx('d-flex', 'justify-content-center', 'align-items-center')}
          onClick={() => {
            prevYear();
          }}
        />
        <span className='mx-2 fw-bold'>{moment(selectedDate).format('YYYY')}</span>
        <IconButton
          icon='icon-chevron-right'
          size='sm'
          isDisabled={moment().isSame(selectedDate, 'year')}
          onClick={() => {
            nextYear();
          }}
          className={cx('d-flex', 'justify-content-center', 'align-items-center')}
        />
      </div>
      <div className='me-5 mt-5'>
        {loadingData ? (
          <TableSkeleton lineHight={300} num={1} />
        ) : (
          <Chart options={data.options} series={chartData} type='area' height='283px' />
        )}
      </div>
    </div>
  );
};

export default Headcount;
