import React, { useContext, useEffect, useRef } from 'react';

import _ from 'lodash';
import PropTypes from 'prop-types';

import FormContext from './FormContext';

const FieldWrapper = ({ children, name, ...rest }) => {
  const fieldRef = useRef(null);
  const { formik } = useContext(FormContext);
  const prevSubmitCountRef = React.useRef(formik?.submitCount);
  const prevErrorStateRef = React.useRef(!_.isEmpty(formik?.errors));
  const firstErrorKey = Object.keys(formik?.errors || {})[0];

  useEffect(() => {
    if (formik) {
      if (
        (prevSubmitCountRef.current !== formik.submitCount ||
          prevErrorStateRef.current !== !_.isEmpty(formik?.errors)) &&
        !formik.isValid
      ) {
        if (fieldRef.current && firstErrorKey === name)
          // eslint-disable-next-line no-unused-expressions
          fieldRef.current?.scrollIntoView && fieldRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      prevSubmitCountRef.current = formik.submitCount;
    }
  }, [formik?.submitCount, formik?.isValid, firstErrorKey]);

  return (
    <div {...rest} ref={fieldRef}>
      {children}
    </div>
  );
};

FieldWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default FieldWrapper;
