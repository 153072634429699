import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './EditMenu.module.scss';

const link = cx(styles.link, 'd-flex', 'align-items-center', 'px-3', 'py-2');

const EditMenu = ({ onEdit, onDelete }) => (
  <ul className={cx(styles.editMenu, 'radius-4', 'bg-white', 'py-2', 'px-0')}>
    <li className={styles.listItem} onClick={onEdit}>
      <div className={link}>
        <i className='icon-pencil-create me-2' />
        Edit
      </div>
    </li>
    <li className={styles.listItem} onClick={onDelete}>
      <div className={link} style={{ color: '#F45147' }}>
        <i className='icon-trash me-2' style={{ color: '#F45147' }} />
        Delete
      </div>
    </li>
  </ul>
);

EditMenu.propTypes = {
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default EditMenu;
