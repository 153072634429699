import React, { useEffect, useMemo } from 'react';

import cx from 'classnames';
import styles from './ImplementationTableRow.module.scss';
import Personas from 'components/Personas/Personas';
import Tooltip from 'components/Tooltip/Tooltip';

const DisplayAssignees = ({ setSelectedAssignee, assignees, users }) => {
  const selectedAssignee = useMemo(() => {
    if (!assignees) {
      return [];
    }
    const userData = assignees?.map((item) => {
      const userInfo = users?.find((element) => element.id === Number(item));
      return userInfo;
    });
    return userData?.map((data) => ({
      ...data,
      label: data?.fullName,
      value: data?.id.toString(),
    }));
  }, [assignees, users]);

  useEffect(() => {
    setSelectedAssignee(selectedAssignee);
  }, [assignees]);

  const rednderAssignes = (selectedOptions) => {
    const additionalUsers = selectedOptions?.length > 3 ? selectedOptions?.length - 3 : null;
    return (
      <>
        {selectedOptions?.map(
          (el, index) =>
            index < 3 && (
              <Tooltip label={el.fullName} position={'top'} key={el.id} portalElementId='tooltip-portal'>
                <Personas name={el.fullName} size={35} />
              </Tooltip>
            )
        )}
        {additionalUsers && additionalUsers > 0 && (
          <div className={cx(styles.moreUsers, 'd-flex', 'align-items-center', 'justify-content-center')}>
            <Tooltip
              portalElementId='tooltip-portal'
              label={selectedOptions?.slice(3).map((obj) => (
                <div key={obj.id}>{obj.fullName}</div>
              ))}
              position={'top'}
            >
              {`+${additionalUsers}`}
            </Tooltip>
          </div>
        )}
      </>
    );
  };
  return <>{assignees?.length > 0 ? rednderAssignes(selectedAssignee) : '--'}</>;
};

export default DisplayAssignees;
