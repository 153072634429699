import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './SpinnerRing.module.scss';

const SpinnerRing = ({ width, height, positionFixed }) => (
  <div
    className={cx(
      styles.sendingOverlay,
      'd-flex',
      'justify-content-center',
      'align-items-center',
      positionFixed && 'position-fixed'
    )}
    style={{ width, height }}
  >
    <div className={styles.content}>
      <div className={cx(styles.box, 'd-flex', 'justify-content-center', 'align-items-center')}>
        <div className={styles.circle} />
      </div>
    </div>
  </div>
);

SpinnerRing.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  positionFixed: PropTypes.bool,
};

SpinnerRing.defaultProps = {
  width: '100%',
  height: '100%',
  positionFixed: false,
};

export default SpinnerRing;
