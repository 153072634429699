import React from 'react';

import { useNavigate } from 'react-router-dom';

import PageHeader from 'components/PageHeader/PageHeader';
import { PrivateRoutes } from 'Router/routes';

const SubscriptionsHeader = () => {
  const navigate = useNavigate();

  const navigateBack = () => {
    navigate(PrivateRoutes.insights);
  };

  return (
    <PageHeader
      title='Renewal and Collections'
      subtitle='List of all subscriptions'
      isShowBackButton
      onClickGoBack={navigateBack}
    />
  );
};

export default SubscriptionsHeader;
