import { useQueryHook, useMutationHook } from 'utility/reactQueryHooks';
import ClientsApi from 'api/ClientsApi';

export const queryKeys = {
  TenantSubscriptions: (id, params) => ['TenantSubscriptions', id, JSON.stringify(params)],
  activateSubscription: ['activateSubscription'],
  deactivateSubscription: ['deactivateSubscription'],
  updateSubscription: ['updateSubscription'],
};

export const useQueryGetTenantSubscriptions = (id, params) =>
  useQueryHook(queryKeys.TenantSubscriptions(id, params), () => ClientsApi.getTenantSubscriptions(id, params));

export const useMutationActivateSubscription = () =>
  useMutationHook(queryKeys.activateSubscription, ClientsApi.ActivateTenantSubscription);

export const useMutationDeactivateSubscription = () =>
  useMutationHook(queryKeys.deactivateSubscription, ClientsApi.DeactivateTenantSubscription);

export const useMutationUpdateSubscription = () =>
  useMutationHook(queryKeys.updateSubscription, ClientsApi.updateTenantSubscription);
