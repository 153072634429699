import React, { useEffect, useState, useContext } from 'react';

import cx from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import Scrollbars from 'react-custom-scrollbars-2';
import Modal from 'react-modal';

import styles from './BiometricDetails.module.scss';
import BiometricDveicesApi from '../../../../api/BiometricDevicesApi';
import { AuthContext } from '../../../../Auth/AuthContext';
import ConfirmModal from '../../../../components/ConfirmModal/ConfirmModal';
import IconButton from '../../../../components/IconButton';
import modalStyles from '../../../../components/modalStyles';
import SpinnerRing from '../../../../components/SpinnerRing';
import Tooltip from '../../../../components/Tooltip';

const addModalStyle = _.cloneDeep(modalStyles);
addModalStyle.content.width = '480px';
addModalStyle.content.marginTop = '100px';

const BiometricDetails = ({ id, onClose, tenant, onEditNote, onAddNote }) => {
  const [details, setDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [notes, setNotes] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedNote, setSelectedNote] = useState(undefined);
  const { authState } = useContext(AuthContext);

  useEffect(() => {
    setLoading(true);
    BiometricDveicesApi.getDeviceById(id)
      .then((res) => {
        setDetails(res.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    BiometricDveicesApi.getDeviceNotes(id)
      .then((res) => {
        setNotes(res.data.items);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const deleteNote = () =>
    BiometricDveicesApi.deleteNote(selectedNote.id).then(() => {
      const filterRemoved = notes.filter((item) => item.id !== selectedNote.id);
      setNotes(filterRemoved);
      setShowDeleteModal(false);
    });

  const onConfirm = () => {
    setShowDeleteModal(false);
    deleteNote();
  };

  return (
    <>
      <div className='pt-4 ps-5'>
        {loading && <SpinnerRing />}
        <div>
          <Scrollbars
            className='scroll-bar'
            autoHide
            renderView={({ style, ...props }) => <div {...props} style={{ ...style, overflowX: 'hidden' }} />}
            style={{ height: '720px' }}
          >
            <div>
              <div className='pe-5 pt-5'>
                <p className='fw-bold mt-1 mb-3'>Device info</p>
                <div className='row'>
                  <div className={cx(styles.inputHeight, 'form-group col-6')}>
                    <p className='label-title'>Serial number</p>
                    <p className='mb-3 text-steal'>
                      {details.serialNumber?.length > 25 ? (
                        <div className='tooltip-box'>
                          {details.serialNumber.substring(0, 25)}...
                          <Tooltip label={details.serialNumber} />
                        </div>
                      ) : (
                        details.serialNumber
                      )}
                    </p>
                  </div>
                  <div className={cx(styles.inputHeight, 'form-group col-6')}>
                    <p htmlFor='workEmail' className='label-title'>
                      Device protocol
                    </p>
                    <p className='mb-3 text-steal'>{details.protocol}</p>
                  </div>
                  <div className={cx(styles.inputHeight, 'form-group col-6')}>
                    <p className='label-title'>Device Time Zone</p>
                    <p className='mb-3 text-steal'>{details.timeZone}</p>
                  </div>
                  <div className={cx(styles.inputHeight, 'form-group col-6')}>
                    <p className='label-title'>Added to Unbilled Charges</p>
                    <p className='mb-3 text-steal'>{details.isAddedToUnbilledCharges ? 'YES' : 'NO'}</p>
                  </div>
                  <div className={cx(styles.inputHeight, 'form-group col-6')}>
                    <p className='label-title'>Device token</p>
                    <p className='mb-3 text-steal'>
                      {details.token?.length > 30 ? (
                        <div className='tooltip-box'>
                          {details.token.substring(0, 30)}...
                          <Tooltip label={details.token} />
                        </div>
                      ) : (
                        details.token
                      )}
                    </p>
                  </div>
                  <div className={cx(styles.inputHeight, 'form-group col-6')}>
                    <p className='label-title'>Last sync</p>
                    <p className='mb-3 text-steal'>
                      {details.lastImportDate ? moment(details.lastImportDate).format('MMM DD, YYYY (HH:mm)') : '- -'}
                    </p>
                  </div>
                </div>
                <div className={styles.line} />
                <p className='fw-bold mt-5 mb-3'>Cost</p>
                <div className='row'>
                  <div className={cx(styles.inputHeight, 'form-group col-6')}>
                    <p className='label-title'>Licence cost:</p>
                    <p className='mb-3 text-steal'>SAR 240.00 / 1 Year</p>
                  </div>
                  <div className={cx(styles.inputHeight, 'form-group col-6')}>
                    <p className='label-title'>Integraton cost:</p>
                    <p className='mb-3 text-steal'>{details?.protocol === 'CAMS' ? 'SAR 490.00' : 'SAR 790.00'}</p>
                  </div>
                </div>
                <div className={styles.line} />
                <p className='fw-bold mt-5 mb-3'>Configured by</p>
                <div className='row'>
                  <div className={cx(styles.inputHeight, 'form-group col-6')}>
                    <p className='label-title'>Name</p>
                    <p className='mb-3 text-steal'>{details.configuredBy?.fullName}</p>
                  </div>
                  <div className={cx(styles.inputHeight, 'form-group col-6')}>
                    <p className='label-title'>Cofigured at</p>
                    <p className='mb-3 text-steal'>
                      {details.configuredAt ? moment(details.configuredAt).format('MMM DD, YYYY (HH:mm)') : '- -'}
                    </p>
                  </div>
                </div>
                <div className={styles.line} />
                <div className='d-flex align-items-center justify-content-between'>
                  <p className='fw-bold mt-5 mb-3'>Notes</p>
                  <button
                    type='button'
                    onClick={() => {
                      onAddNote(id);
                    }}
                    className='btn btn-primary text-white'
                  >
                    <div className='d-flex align-items-center justify-content-between'>
                      <i className='icon-plus me-2' />
                      <span> Add Note</span>
                    </div>
                  </button>
                </div>
                <div className='row'>
                  {notes.length > 0 ? (
                    notes.map((item) => (
                      <div
                        key={item.id}
                        className={cx(
                          styles.inputHeight,
                          styles.notes,
                          'd-flex align-items-start justify-content-between col-12'
                        )}
                      >
                        <div className=''>
                          <p className='label-title'>{`${item.user?.fullName} (${moment(item.createdAt).format(
                            'MMM DD, YYYY HH:mm'
                          )})`}</p>
                          <p className='mb-3 text-steal'>{item.content}</p>
                        </div>
                        {authState.user.fullName === item.user?.fullName && (
                          <div className='d-flex justify-content-end'>
                            <button
                              type='button'
                              onClick={() => {
                                onEditNote(item.id);
                              }}
                              title='Edit'
                              className='btn btn-light ms-1'
                            >
                              <i className='icon-pencil-create' />
                            </button>
                            <button
                              type='button'
                              onClick={() => {
                                setSelectedNote(item);
                                setShowDeleteModal(true);
                              }}
                              title='Delete'
                              className='btn btn-light ms-1'
                            >
                              <i className='icon-trash' />
                            </button>
                          </div>
                        )}
                      </div>
                    ))
                  ) : (
                    <div className={cx(styles.emptyList, 'm-auto')}>
                      <div
                        className={cx(
                          styles.iconHistory,
                          'd-flex',
                          'justify-content-center',
                          'align-items-center',
                          'm-auto'
                        )}
                      >
                        <i className={cx(styles.icon, 'icon-housing')} />
                      </div>
                      <p className='mt-4 text-center text-capitalize'>No notes for this device</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Scrollbars>
          <div className='d-flex justify-content-end border-t py-4 mt-1 me-5'>
            <button className='btn btn-primary text-white' onClick={onClose} type='button'>
              Close
            </button>
          </div>
        </div>
      </div>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Modal
          isOpen={showDeleteModal}
          ariaHideApp={false}
          closeTimeoutMS={300}
          style={addModalStyle}
          onRequestClose={() => {
            setShowDeleteModal(false);
          }}
        >
          <ConfirmModal
            onClose={() => {
              setShowDeleteModal(false);
            }}
            onSubmit={onConfirm}
            modalName='Remove Note'
            confirmName='Delete'
            confirmText='Are you sure you want to remove this note?'
          />
        </Modal>
      </div>
    </>
  );
};

export default BiometricDetails;
