import React from 'react';
import Modal from 'components/Modal/Modal';
import DirtyForm from 'components/DirtyForm/DirtyForm';

const SaveTasksModal = ({ isOpen, onClose, onHide, onConfirm }) => (
  <Modal
    isOpen={isOpen}
    showHeader
    shouldCloseOnOverlayClick
    styles={{ width: '450px', height:'400px',padding: '0' }}
    ariaHideApp={false}
    onRequestClose={() => {
      onHide();
    }}
  >
    <DirtyForm
      onClose={onClose}
      onConfirm={onConfirm}
      text={'Do you want to save your changes before ?'}
      cancelText={'No'}
      confirmText={'Save'}
      hideCancelButton={false}
    />
  </Modal>
);

export default SaveTasksModal;
