import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './ContentHeader.module.scss';

const ContentHeader = ({ className, children }) => (
  <div data-testid='contentHeader' className={cx(styles.header, className, 'py-2a border-btm')}>
    {children}
  </div>
);

ContentHeader.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  className: PropTypes.string,
};

ContentHeader.defaultProps = {
  children: '',
  className: '',
};

export default ContentHeader;
