import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './EditMenu.module.scss';

const link = cx(styles.link, 'd-flex', 'align-items-center', 'px-3', 'py-2');

const EditMenu = ({ onEdit, onPreview, onMakeBillable, onDelete, onAddNote, isAddedToUnbilledCharges }) => (
  <ul
    className={cx(styles.editMenu, 'radius-4', 'bg-white', 'py-2', 'px-0')}
    onClick={(e) => {
      e.stopPropagation();
    }}
  >
    <li className={styles.listItem} onClick={onEdit}>
      <div className={link}>
        <i className='icon-pencil-create me-2' />
        Edit
      </div>
    </li>
    <li className={styles.listItem} onClick={onPreview}>
      <div className={link}>
        <i className='icon-glasses me-2' />
        Preview
      </div>
    </li>
    {isAddedToUnbilledCharges && (
      <li className={styles.listItem} onClick={onMakeBillable}>
        <div className={link}>
          <i className='icon-expense-claim me-2' />
          Make billable
        </div>
      </li>
    )}
    <li className={styles.listItem} onClick={onDelete}>
      <div className={link}>
        <i className='icon-trash me-2' />
        Remove device
      </div>
    </li>
    <li className={styles.listItem} onClick={onAddNote}>
      <div className={link}>
        <i className='icon-file-text me-2' />
        Add note
      </div>
    </li>
  </ul>
);

EditMenu.propTypes = {
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onPreview: PropTypes.func.isRequired,
  onMakeBillable: PropTypes.func.isRequired,
  onAddNote: PropTypes.func.isRequired,
};

export default EditMenu;
