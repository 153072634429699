import React from 'react';
import cx from 'classnames';
import styles from './CustomerAccountTypeInput.module.scss';
import Radio from 'components/Radio';

const CustomerTypeRadio = ({ formik, option, title, subtitle }) => (
    <div className={'col-12 col-lg-4 mb-5 px-2a'} onClick={() => formik.setFieldValue('accountType', option)}>
      <div
        className={cx(
          styles.content,
          'py-4 px-5 d-flex flex-column justify-flex-between',
          formik.values.accountType === option ? styles.selected : ''
        )}
      >
        <div>
          <div className='d-flex justify-content-between align-items-center border-btm pb-3 mb-4'>
            <h6 className='font-weight-bold mb-0 text-truncate' style={{ width: '700px' }}>
              {title}
            </h6>
            <Radio
              name='accountType'
              value={option}
              checked={formik.values.accountType === option}
              onChange={formik.handleChange}
            />
          </div>

          <div className={styles.info}>
            <div className='d-flex justify-content-start align-items-center'>
              <i className='icon-info font-size-20 me-2' />
              <p>{subtitle}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

export default CustomerTypeRadio;
