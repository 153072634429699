import React from 'react';
import InputsSectionContainer 
  from '../InputsSectionContainer/InputsSectionContainer';
import CustomerSubscriptionDetailsInputs 
  from './CustomerSubscriptionDetailsInputs/CustomerSubscriptionDetailsInputs';
import CustomerSelectSubscriptionAddOnsInputs 
  from './CustomerSelectSubscriptionAddOnsInputs/CustomerSelectSubscriptionAddOnsInputs';
import { useQueryGetPlansList } 
  from '../../../hooks/useCustomers';

const CustomerAddSubscription = (formik) => {
  const { isLoading, data } = useQueryGetPlansList(formik.values.country);

  return (
    <section className='w-100'>
      <h4 className='subtitle font-size-20 mt-7 mb-5'>Lastly, let&apos;s add a subscription</h4>
      <InputsSectionContainer
        className='my-2'
        title='Subscription Details'
        subTitle={'Define the period, number of users, price for the subscription and any add-ons'}
      >
        <CustomerSubscriptionDetailsInputs formik={formik} isLoading={isLoading} data={data?.data?.items || []} />
      </InputsSectionContainer>
      <InputsSectionContainer
        className='my-2'
        title='Supscription Add-Ons'
        subTitle={'Select add-ons you want to be part of the subscription plan and define their prices'}
      >
        <CustomerSelectSubscriptionAddOnsInputs formik={formik} isLoading={isLoading} data={data?.data?.items || []} />
      </InputsSectionContainer>
    </section>
  );
};

export default CustomerAddSubscription;
