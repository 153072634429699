import TemplatesApi from '../../../api/TemplatesApi';
import { useMutationHook, useQueryHook } from '../../../utility/reactQueryHooks';

export const queryKeys = {
  ImplementationTemplates: (params) => ['ImplementationTemplates', 'list', params],
  ImplementationTemplateDetails: (id) => ['ImplementationTemplateDetails', id],
  ImplementationTemplatesAdd: ['ImplementationTemplatesAdd'],
  ImplementationTemplateEdit: ['ImplementationTemplateEdit'],

  ImplementationTasks: (params) => ['ImplementationTasks', 'list', params],
  ImplementationTaskDetails: (id) => ['ImplementationTaskDetails', id],
  ImplementationTaskAdd: ['ImplementationTaskAdd'],
  ImplementationTaskEdit: ['ImplementationTaskEdit'],
  ImplementationTaskDelete: (id) => ['ImplementationTaskDelete', id],
};

// Templates
export const useImplementationTemplatesList = (params) =>
  useQueryHook(queryKeys.ImplementationTemplates(params), () => TemplatesApi.listImplementationTemplates(params));
export const useImplementationTemplateDetailsQuery = (id) =>
  useQueryHook(queryKeys.ImplementationTemplateDetails(id), () => TemplatesApi.getImplementationTemplateDetails(id));
export const useImplementationTemplatesAdd = () =>
  useMutationHook(queryKeys.ImplementationTemplatesAdd, TemplatesApi.postImplementationTemplate);
export const useImplementationTemplateEdit = () =>
  useMutationHook(queryKeys.ImplementationTemplateEdit, TemplatesApi.updateImplementationTemplate);

// Tasks
export const useImplementationTasksList = (params) =>
  useQueryHook(queryKeys.ImplementationTasks(params), () => TemplatesApi.listImplementationTemplatesTasks(params));
export const useImplementationTaskDetailsQuery = (id) =>
  useQueryHook(queryKeys.ImplementationTaskDetails(id), () => TemplatesApi.getImplementationTaskDetails(id));
export const useImplementationTaskAdd = () =>
  useMutationHook(queryKeys.ImplementationTaskAdd, TemplatesApi.postImplementationTemplatesTask);
export const useImplementationTaskEdit = () =>
  useMutationHook(queryKeys.ImplementationTaskEdit, TemplatesApi.updateImplementationTemplatesTask);

export const useImplementationTaskDelete = (id) =>
  useMutationHook(queryKeys.ImplementationTaskDetails(id), TemplatesApi.deleteImplementationTemplatesTask);
