import React from 'react';

import cx from 'classnames';

import styles from './EditMenu.module.scss';

const link = cx(styles.link, 'd-flex', 'align-items-center', 'px-3', 'py-2');

const EditMenu = ({ items, onAction}) => (
  <ul className={cx(styles.editMenu, 'radius-4', 'bg-white', 'py-2', 'px-0')}>
    {items.map((item) => (
      <li
        key={item.action}
        className={styles.listItem}
        onClick={() => {
          onAction(item.action);
        }}
      >
        <div className={cx(link, item?.iconColor ? item?.iconColor : 'text-muted')}>
          <i className={cx(item.icon, 'me-2', item?.iconColor ? item?.iconColor : 'text-muted')} />
          {item.title}
        </div>
      </li>
    ))}
  </ul>
);

export default EditMenu;
