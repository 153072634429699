import React, { useState } from 'react';

import cx from 'classnames';
import { curry } from 'lodash';

import IconButton from 'components/IconButton';
import PageHeader from 'components/PageHeader/PageHeader';
import RoundedButton from 'components/RoundedButton/RoundedButton';
import SideTransitionContainer from 'components/SideTransitionContainer/SideTransitionContainer';
import FilterSection from 'Insights/Subscriptions/components/SubscriptionFilter/FilterSection';
import {
  accountStatusOptions,
  FILTER_KEYS,
  initialFilterValues,
  paymentConfirmationOptions,
  RENEWAL_STATUSES,
  renewalStatusOptions,
} from 'Insights/Subscriptions/constants';

import styles from './SubscriptionFilter.module.scss';

const SubscriptionFilter = ({ isOpen, onClose, onFilter }) => {
  const [selectedFilterValues, setSelectedFilterValues] = useState(initialFilterValues);

  const nonEmptyFilterValues = (filters) =>
    Object.entries(filters)
      .filter(([, value]) => value !== '')
      .reduce((obj, [key, value]) => {
        obj[key] = value;
        return obj;
      }, {});

  const onSelectFilterValue = curry((key, value) => {
    setSelectedFilterValues((prevState) => ({ ...prevState, [key]: value }));
  });

  const renderCloseButton = () => <IconButton icon='icon-cross-x' size='lg' color='primary' onClick={onClose} />;

  const handleOnFilter = () => {
    const filters = nonEmptyFilterValues(selectedFilterValues);
    if (filters.renewal_status === RENEWAL_STATUSES.NO_ACTION) {
      delete filters.renewal_status;
    }
    onFilter(filters);
    onClose();
  };

  const onClearAll = () => {
    setSelectedFilterValues(initialFilterValues);
    onFilter({});
    onClose();
  };

  return (
    <SideTransitionContainer isIn={isOpen} onClose={onClose} zIndex='999'>
      <section className={cx(styles.SubscriptionFilter, 'bg-white height-100vh d-flex flex-column')}>
        <PageHeader title='Filters' renderSideActionComponent={renderCloseButton} />
        <section className='d-flex flex-column gap-5 mt-4'>
          <FilterSection
            title='Account Status'
            selectedOption={selectedFilterValues[FILTER_KEYS.ACCOUNT_STATUS]}
            onSelect={(e) => onSelectFilterValue(FILTER_KEYS.ACCOUNT_STATUS, e.target.value)}
            options={accountStatusOptions}
          />
          <FilterSection
            title='Renewal Status'
            selectedOption={selectedFilterValues[FILTER_KEYS.RENEWAL_STATUS]}
            onSelect={(e) => onSelectFilterValue(FILTER_KEYS.RENEWAL_STATUS, e.target.value)}
            options={renewalStatusOptions}
          />
          <FilterSection
            title='Payment Confirmation'
            selectedOption={selectedFilterValues[FILTER_KEYS.PAYMENT_CONFIRMATION]}
            onSelect={(e) => {
              onSelectFilterValue(FILTER_KEYS.PAYMENT_CONFIRMATION, e.target.value);
            }}
            options={paymentConfirmationOptions}
          />
        </section>

        <section className='mt-auto d-flex flex-row-reverse gap-3 p-5 border-top'>
          <RoundedButton text='Apply Filter' onClick={handleOnFilter} btnStyle='contained' color='primary' />
          <RoundedButton text='Clear All' onClick={onClearAll} btnStyle='outlined' color='primary' />
        </section>
      </section>
    </SideTransitionContainer>
  );
};

export default SubscriptionFilter;
