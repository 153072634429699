import React from 'react';

import { PHRMenu } from '@palmhr/palmhr-ui-lib';
import PropTypes from 'prop-types';

const Menu = ({
  data,
  onAction,
  icon,
  iconSize,
  iconColor,
  className,
  style,
  showBorder,
  types,
  tooltipText,
  isDisabled,
  renderCustomButton,
  renderCustomItem,
  openMenuClassName,
  itemKey,
}) => (
  <PHRMenu
    data={data}
    onAction={onAction}
    icon={icon}
    iconSize={iconSize}
    iconColor={iconColor}
    className={className}
    style={style}
    showBorder={showBorder}
    types={types}
    tooltipText={tooltipText}
    isDisabled={isDisabled}
    renderCustomButton={renderCustomButton}
    renderCustomItem={renderCustomItem}
    openMenuClassName={openMenuClassName}
    itemKey={itemKey}
  />
);

export default Menu;

Menu.propTypes = {
  style: PropTypes.object,
  onAction: PropTypes.func,
  data: PropTypes.array,
  icon: PropTypes.string,
  iconSize: PropTypes.string,
  iconColor: PropTypes.string,
  itemKey: PropTypes.string,
  showBorder: PropTypes.bool,
  types: PropTypes.array,
  tooltipText: PropTypes.string,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  openMenuClassName: PropTypes.string,
  renderCustomButton: PropTypes.func,
  renderCustomItem: PropTypes.func,
};

Menu.defaultProps = {
  onAction: undefined,
  data: [],
  icon: 'icon-more-horizontal',
  iconSize: 'sm',
  iconColor: 'gray',
  style: {},
  className: '',
  itemKey: '',
  openMenuClassName: '',
  showBorder: false,
  isDisabled: false,
  renderCustomButton: null,
  renderCustomItem: null,
  types: [],
  tooltipText: '',
};
