import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './EditMenu.module.scss';

const link = cx(styles.link, 'd-flex', 'align-items-center', 'px-3', 'py-2');

const EditMenu = ({ onEdit }) => (
  <ul
    className={cx(styles.editMenu, 'radius-4', 'bg-white', 'py-2', 'px-0')}
    onClick={(e) => {
      e.stopPropagation();
    }}
  >
    <li className={styles.listItem} onClick={onEdit}>
      <div className={link}>
        <i className='icon-pencil-create me-2' />
        Change status
      </div>
    </li>
  </ul>
);

EditMenu.propTypes = {
  onEdit: PropTypes.func.isRequired,
};

export default EditMenu;
