import React, { useState } from 'react';

import { PHRPersonas } from '@palmhr/palmhr-ui-lib';
import cx from 'classnames';
import _ from 'lodash';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import CSSTransition from 'react-transition-group/CSSTransition';

import ClientsApi from '../../../api/ClientsApi';
import DeleteLoader from '../../../Clients/Customers-old/DeleteLoader';
import EditMenu from '../../../Clients/Customers-old/EditMenu';
import styles from '../../../Clients/DemoClients/DemoTableRow.module.scss';
import ConfirmModal from '../../../components/ConfirmModal/ConfirmModal';
import modalStyles from '../../../components/modalStyles';
import OutsideClickHandler from '../../../components/OutsideClickHandler';
import Tooltip from '../../../components/Tooltip';

const addModalStyle = _.cloneDeep(modalStyles);
addModalStyle.content.width = '540px';
addModalStyle.content.padding = '0';
addModalStyle.content.overflow = 'visible';

const CustomerInsightRow = ({
  id,
  company,
  contactEmail,
  plan,
  customerData,
  paymentMethod,
  onSelectRow,
  updateList,
  trialEnd,
  planStatus,
  planAmount,
  currency,
  planBillingPeriod,
  startedAt,
}) => {
  const data = {
    customerData,
    planStatus,
    company,
    id,
    trialEnd,
    contactEmail,
    plan,
    planAmount,
    paymentMethod,
    currency,
    planBillingPeriod,
    startedAt,
  };
  const [menuOpened, setMenuOpened] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [sent, setSent] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [editType, setEditType] = useState('');
  const [error, setError] = useState('');
  const canExtend = false;
  const canSuspend = true;
  const canCancel = true;
  const canReactivate = false;
  const canResume = false;
  const canRetry = false;
  const canDelete = false;
  const canAddAdmin = false;
  const canSyncSubscription = false;
  const canArchive = true;
  const canConfirmBilling = false;

  const canUpgrade = false;

  const clickHandler = (type) => {
    setEditType(type);
    setOpenEdit(true);
    setMenuOpened(false);
  };
  const closeModal = () => {
    setOpenEdit(false);
  };

  const onComputeStatus = () => {
    setIsSending(true);
    ClientsApi.computeStatus(id)
      .then(() => {
        setIsSending(false);
        setSent(true);
        setTimeout(() => {
          setSent(false);
          setOpenEdit(false);
          updateList();
        }, 3000);
      })
      .catch(() => {
        setIsSending(false);
      });
  };

  const onSuspendCustomer = () => {
    setIsDeleting(true);
    ClientsApi.suspendCustomer(id)
      .then(() => {
        setIsDeleting(false);
        setSent(true);
        setTimeout(() => {
          setSent(false);
          setOpenEdit(false);
          updateList();
        }, 3000);
      })
      .catch(() => {
        setIsDeleting(false);
      });
  };

  const onCancelCustomer = () => {
    setIsDeleting(true);
    ClientsApi.cancelSubscription(id)
      .then(() => {
        setIsDeleting(false);
        setSent(true);
        setTimeout(() => {
          setSent(false);
          setOpenEdit(false);
          updateList();
        }, 3000);
      })
      .catch(() => {
        setIsDeleting(false);
      });
  };

  const onArchive = () => {
    setIsDeleting(true);
    ClientsApi.archiveClient(id)
      .then(() => {
        setIsDeleting(false);
        setSent(true);
        setTimeout(() => {
          setSent(false);
          setOpenEdit(false);
          updateList();
        }, 3000);
      })
      .catch((err) => {
        setError(err?.response?.detail);
        setIsDeleting(false);
      });
  };

  const renderModal = () => {
    let el = null;
    switch (editType) {
      case 'suspend':
        el = (
          <div className='p-5'>
            <ConfirmModal
              onClose={closeModal}
              onSubmit={onSuspendCustomer}
              isSending={isDeleting}
              sent={sent}
              customLoader={<DeleteLoader />}
              modalName={`Suspend ${company}`}
              confirmName='Suspend'
              confirmText={`Are you sure that you want to suspend ${company}?`}
            />
          </div>
        );
        break;
      case 'cancel':
        el = (
          <div className='p-5'>
            <ConfirmModal
              onClose={closeModal}
              onSubmit={onCancelCustomer}
              isSending={isDeleting}
              sent={sent}
              customLoader={<DeleteLoader />}
              modalName={`Cancel ${company}`}
              confirmName='Cancel'
              confirmText={`Are you sure that you want to cancel ${company}?`}
            />
          </div>
        );
        break;
      case 'archive':
        el = (
          <div className='p-5'>
            <ConfirmModal
              onClose={closeModal}
              onSubmit={onArchive}
              isSending={isDeleting}
              sent={sent}
              customLoader={<DeleteLoader />}
              modalName={`Archive ${company}`}
              confirmName='Archive'
              confirmText={`Are you sure that you want to archive ${company}?
          All data will be deleted including subcsriptions, invoices and palmhr workspace.`}
            />
          </div>
        );
        break;
      case 'compute-status':
        el = (
          <div className='p-5'>
            <ConfirmModal
              onClose={closeModal}
              onSubmit={onComputeStatus}
              isSending={isSending}
              sent={sent}
              error={error}
              modalName='Compute status'
              confirmName='Confirm'
              confirmText={`Are you sure that you want to recompute status for ${company}?`}
            />
          </div>
        );
        break;
      default:
        el = null;
    }
    return el;
  };

  return (
    <tr onClick={() => onSelectRow(data)} className={cx(styles.wrapper)}>
      <td className='d-flex align-items-center ps-3 py-3'>
        <div className='ms-3 d-flex gap-2a align-items-center'>
          <PHRPersonas name={company} />
          <div className='tooltip-box d-inline'>
            <p className={styles.companyName}>{company}</p>
            <p className={styles.mail}>{contactEmail}</p>
            <Tooltip label={company} />
          </div>
        </div>
      </td>
      <td className={cx(styles.values, 'py-3 pe-3')}>
        <div>
          <p className='font-size-14 text-gray-new'>{plan}</p>
        </div>
      </td>
      <td width={160}>
        <span className={cx(styles.status, styles[_.camelCase(customerData?.renewalStatus)])}>
          {_.upperCase(customerData?.renewalStatus)}
        </span>
      </td>
      <td className={cx(styles.values, 'py-3 pe-3')}>
        <span>
          {customerData?.primarySubscription?.expires
            ? moment(customerData?.primarySubscription?.expires).format('MMM DD, YYYY')
            : ''}
        </span>
      </td>
      <td className={cx(styles.values, 'py-3 pe-3')}>
        <span>
          {customerData?.primarySubscription?.confirmedRenewal !== null
            ? customerData?.primarySubscription?.confirmedRenewal
              ? 'Confirmed'
              : customerData?.primarySubscription?.status === 'CANCELLED'
              ? 'Cancelled'
              : 'No action'
            : 'No action'}
        </span>
      </td>
      <td className={cx(styles.values, 'py-3 pe-3')}>
        <span>{customerData?.paymentConfirmedOn ? 'Confirmed' : 'No action'}</span>
      </td>
      <td className={cx(styles.values, 'py-3 pe-3')}>
        <span>
          {customerData?.latestLoginOnTenant ? moment(customerData?.latestLoginOnTenant).format('MMM DD, YYYY') : '--'}
        </span>
      </td>

      <td className={cx(styles.values, 'py-3 pe-3')}>
        <div className='d-flex align-items-center justify-content-between'>
          <div className='position-relative'>
            <div className={cx(styles.moreBox, 'w-100', 'd-flex', 'align-items-center', 'justify-content-end')}>
              <i
                className={cx(styles.moreBtn, 'icon-more-vertical')}
                onClick={(e) => {
                  e.stopPropagation();
                  setMenuOpened(true);
                }}
              />
            </div>
            <CSSTransition
              mountOnEnter
              unmountOnExit
              in={menuOpened}
              timeout={200}
              classNames={{
                enter: styles.enter,
                enterActive: styles.enterActive,
                exit: styles.exit,
                exitActive: styles.exitActive,
              }}
            >
              <OutsideClickHandler
                clickHandler={() => {
                  setMenuOpened(false);
                }}
              >
                <EditMenu
                  onClick={clickHandler}
                  canUpgrade={canUpgrade}
                  canExtend={canExtend}
                  canAddAdmin={canAddAdmin}
                  canDelete={canDelete}
                  canConfirmBilling={canConfirmBilling}
                  canSuspend={canSuspend}
                  canCancel={canCancel}
                  canReactivate={canReactivate}
                  canResume={canResume}
                  canRetry={canRetry}
                  canSyncSubscription={canSyncSubscription}
                  canArchive={canArchive}
                />
              </OutsideClickHandler>
            </CSSTransition>
          </div>
        </div>
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Modal
            isOpen={openEdit}
            contentLabel='Upgrade Client'
            ariaHideApp={false}
            closeTimeoutMS={300}
            style={addModalStyle}
            onRequestClose={() => {
              setOpenEdit(false);
            }}
          >
            {renderModal()}
          </Modal>
        </div>
      </td>
    </tr>
  );
};

CustomerInsightRow.propTypes = {
  customerData: PropTypes.object.isRequired,
  onSelectRow: PropTypes.func.isRequired,
  updateList: PropTypes.func.isRequired,
};

export default CustomerInsightRow;
