import React, { useState } from 'react';

import cx from 'classnames';
import _ from 'lodash';
import Modal from 'react-modal';
import { useQueryClient } from '@tanstack/react-query';

import styles from './MobReleasePage.scss';
import {
  useQueryGetAnnouncements,
  useDeleteAnnouncement,
  usePublishAnnouncement,
  useMutationUpdateAnnouncement,
  useCreateAnnouncement,
  queryKeys,
} from '../hooks/useAnnouncements';
import AnnouncementsApi from '../../api/AnnouncementsApi';
import modalStyle from '../../components/modalStyles';
import AnnouncementModal from '../components/AnnouncementModal';
import PreviewModalMob from '../components/PreviewModalMob';
import AnnouncementTable from 'Announcements/components/AnnouncementTable';
import { PHRPagination } from '@palmhr/palmhr-ui-lib';

const editModalStyle = _.cloneDeep(modalStyle);
editModalStyle.content.width = '640px';
editModalStyle.content.padding = '0';

const previewModalMobStyle = _.cloneDeep(modalStyle);
previewModalMobStyle.content.width = '345px';
previewModalMobStyle.content.padding = '0';
previewModalMobStyle.content.borderRadius = '15px';
previewModalMobStyle.content.overflowX = 'hidden';

const MobReleasePage = () => {
  const queryClient = useQueryClient();
  const [announcementModal, setAnnouncementModal] = useState(false);
  const [previewModalMob, setPreviewModalMob] = useState(false);
  const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [params, setParams] = useState({
    currentPage: 1,
    perPage: 10,
  });

  const { isLoading, data: announcements } = useQueryGetAnnouncements('mob', params);
  const { mutate: deleteAnnouncementMutate } = useDeleteAnnouncement();
  const { mutate: publishAnnouncementMutate } = usePublishAnnouncement();
  const { mutate: updateAnnouncementMutate } = useMutationUpdateAnnouncement();
  const { mutate: createAnnouncementMutate } = useCreateAnnouncement();

  const updatePaginationParams = ({ currentPage, perPage }) => {
    setParams((prevState) => ({ ...prevState, currentPage, perPage }));
  };

  const handleSuccess = async () => {
    await queryClient.invalidateQueries(queryKeys.announcements(params), { exact: true });
  };

  const onPublish = (id) => {
    publishAnnouncementMutate(id, {
      onSuccess: async () => {
        handleSuccess();
        setPreviewModalMob(null);
        setSelectedAnnouncement(null);
      },
    });
  };

  const onDelete = (id) => {
    deleteAnnouncementMutate(id);
  };

  const onEdit = (id) => {
    AnnouncementsApi.getAnnouncementById(id).then((res) => {
      setSelectedAnnouncement(res.data);
      setAnnouncementModal(true);
    });
  };

  const onPreview = (data) => {
    AnnouncementsApi.getAnnouncementById(data.id).then((res) => {
      setSelectedAnnouncement(res.data);
      setPreviewModalMob(true);
    });
  };

  const onSave = (isEdit, data) => {
    setSaving(true);
    if (isEdit) {
      updateAnnouncementMutate(
        { id: selectedAnnouncement.id, data },
        {
          onSuccess: async () => {
            setSaving(false);
            setSaved(true);
            setTimeout(() => {
              setSaved(false);
              setAnnouncementModal(false);
              setSelectedAnnouncement(null);
              handleSuccess();
            }, 1000);
          },
          onError: (err) => {
            setSaving(false);
            if (err?.response?.data?.message) {
              setErrorMessage(err.response.data.message);
            } else if (err?.response?.data?.detail) {
              setErrorMessage(err.response.data.detail);
            } else {
              setErrorMessage('Error! Something went wrong!');
            }
          },
        }
      );
    } else {
      createAnnouncementMutate(data, {
        onSuccess: async () => {
          setSaving(false);
          handleSuccess();
          setSaved(true);
          setAnnouncementModal(false);
          setSelectedAnnouncement(null);
        },
        onError: (err) => {
          setSaving(false);
          let msg = '';
          if (err?.response?.data?.message) {
            msg = err.response.data.message;
          } else if (err?.response?.data?.detail) {
            msg = err.response.data.detail;
          } else {
            msg = 'Error! Something went wrong!';
          }

          if (msg.trim() === 'Bad Request') {
            msg = 'Announcement already exists in predefined period';
          }

          setErrorMessage(msg);
        },
      });
    }
  };

  return (
    <>
      <div className={cx(styles.content, 'bg-white p-5')}>
        <div style={{ height: 'calc(100vh - 240px)' }}>
          <AnnouncementTable
            announcements={announcements?.data?.items}
            onPublish={onPublish}
            onDelete={onDelete}
            onEdit={onEdit}
            onPreview={onPreview}
            isLoading={isLoading}
          />
          {announcements?.data?.pagination?.total > 0 && (
            <PHRPagination onPaginationAction={updatePaginationParams} pagination={announcements?.data?.pagination} />
          )}
        </div>
      </div>

      <Modal
        isOpen={announcementModal}
        contentLabel='Add / Edit Announcement'
        ariaHideApp={false}
        closeTimeoutMS={300}
        style={editModalStyle}
        onRequestClose={() => {
          setAnnouncementModal(false);

          setTimeout(() => {
            setSelectedAnnouncement(null);
          }, 500);
        }}
      >
        <AnnouncementModal
          editData={selectedAnnouncement}
          onSave={onSave}
          onClose={() => {
            setAnnouncementModal(false);

            setTimeout(() => {
              setSelectedAnnouncement(null);
            }, 500);
          }}
          saving={saving}
          saved={saved}
          errorMessageOnSave={errorMessage}
        />
      </Modal>

      {/* Preview Modal Mob */}
      <Modal
        isOpen={previewModalMob}
        contentLabel='Preview Announcement'
        ariaHideApp={false}
        closeTimeoutMS={300}
        style={previewModalMobStyle}
        onRequestClose={() => {
          setPreviewModalMob(false);

          setTimeout(() => {
            setSelectedAnnouncement(null);
          }, 500);
        }}
      >
        <PreviewModalMob
          data={selectedAnnouncement}
          onPublish={onPublish}
          onClose={() => {
            setPreviewModalMob(false);

            setTimeout(() => {
              setSelectedAnnouncement(null);
            }, 500);
          }}
        />
      </Modal>
    </>
  );
};

export default MobReleasePage;
