import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './InvoiceEditMenu.module.scss';

const link = cx(styles.link, 'd-flex', 'align-items-center', 'px-3', 'py-2');

const InvoiceEditMenu = ({ onDownload, onDelete, onPaid }) => (
  <ul
    className={cx(styles.wrapper, 'radius-4', 'bg-white', 'py-2', 'px-0')}
    onClick={(e) => {
      e.stopPropagation();
    }}
  >
    <li className={styles.listItem} onClick={onDownload}>
      <div className={link}>
        <i className='icon-download me-2' />
        Download
      </div>
    </li>
    <li className={styles.listItem} onClick={onPaid}>
      <div className={link}>
        <i className='icon-check-mark me-2' />
        Mark as paid
      </div>
    </li>
    <li className={styles.listItem} onClick={onDelete}>
      <div className={link}>
        <i className='icon-trash me-2' />
        Delete
      </div>
    </li>
  </ul>
);

InvoiceEditMenu.propTypes = {
  onDelete: PropTypes.func.isRequired,
  onPaid: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired,
};

export default InvoiceEditMenu;
