import { useQueryHook, useMutationHook } from 'utility/reactQueryHooks';
import { useQueryClient } from '@tanstack/react-query';

import NotesApi from 'api/NotesApi';

export const queryKeys = {
  notes: (params) => ['notes', JSON.stringify(params)],
  updateNote: ['updateNote'],
  addNote: ['addNote'],
  deleteNote: ['deleteNote'],
};

export const useQueryNotes = (client, params) =>
  useQueryHook(queryKeys.notes(params), () => NotesApi.getNotes(client, params));

export const useUpdateNote = () => useMutationHook(queryKeys.updateNote, NotesApi.updateNoteById);

export const useMutationAddNote = () => useMutationHook(queryKeys.addNote, NotesApi.createNote);

export const useDeleteNote = () => {
  const queryClient = useQueryClient();
  return useMutationHook(queryKeys.deleteNote, NotesApi.deleteNote, {
    onSuccess: async () => {
      await queryClient.refetchQueries(['notes'], { exact: true });
    },
  });
};
