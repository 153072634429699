import React from 'react';

import noCustomers from 'assets/img/no_customers.png';
import RoundedButton from 'components/RoundedButton/RoundedButton';

const EmptyCustomersState = ({ onClick }) => (
  <div className='radius-8 bg-light p-6 mt-5'>
    <div className='m-auto' style={{ width: '160px', height: 'auto' }}>
      <img className='w-100' src={noCustomers} alt='empty-customers' />
    </div>

    <h5 className='fw-bold mx-2 text-center text-steal'>No Customers</h5>

    <p style={{ maxWidth: '264px' }} className='text-gray fs-12 text-center mx-auto'>
      Create your first customer by clicking on the &quot;Add New&quot; buttons.
    </p>
    <div className='d-flex justify-content-center mt-4'>
      <RoundedButton text='Add New' icon='icon-plus' iconPosition='left' color='primary' onClick={onClick} />
    </div>
  </div>
);

export default EmptyCustomersState;
