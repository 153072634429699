import React, { useState } from 'react';

import cx from 'classnames';
import { useFormik } from 'formik';
import { Scrollbars } from 'react-custom-scrollbars-2';

import styles from './AddDevice.module.scss';
import IconButton from '../../../../components/IconButton';
import SpinnerRing from '../../../../components/SpinnerRing';
import SuccessCheckmark from '../../../../components/SuccessCheckmark';

const AddNote = ({ onClose, onSaveNote, saved, selectedDevice, selectedNote }) => {
  const [isSending, setIsSending] = useState(false);

  const validate = (values) => {
    const errors = {};

    if (!values.content) {
      errors.content = 'Note is required';
    }
    return errors;
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      content: selectedNote?.content || '',
    },
    validate,
    onSubmit: (values) => {
      setIsSending(true);
      const sentData = { ...values };

      onSaveNote(!!selectedNote, sentData);
    },
  });

  return (
    <div className={styles.overfowX}>
      {isSending && <SpinnerRing />}
      {saved && <SuccessCheckmark />}
      <div>
        <div className='modal-content py-4 ps-5 position-relative'>
          <form noValidate onSubmit={formik.handleSubmit}>
            <Scrollbars
              className='scroll-bar'
              autoHide
              renderView={({ style, ...props }) => <div {...props} style={{ ...style, overflowX: 'hidden' }} />}
              style={{ height: '32vh' }}
            >
              <div className='pe-5'>
                <div className='row pb-7'>
                  <div className={cx(styles.inputHeight, 'form-group col-12  pt-5')}>
                    <label htmlFor='content' className='label-title'>
                      Note content
                      <sup>*</sup>
                    </label>
                    <textarea
                      rows='4'
                      id='content'
                      name='content'
                      value={formik.values.content}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      required
                      placeholder='Content'
                      className={`form-control ${formik.errors.content && formik.touched.content ? 'is-invalid' : ''}`}
                    />
                    <div className='invalid-feedback'>{formik.errors.content}</div>
                  </div>
                </div>
              </div>
            </Scrollbars>
            <div className='d-flex justify-content-end border-t pt-4 mt-1 me-5'>
              <button type='button' className='btn btn-secondary me-5' onClick={onClose} disabled={isSending}>
                Cancel
              </button>
              <button type='submit' className='btn btn-primary text-white'>
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddNote;
