import React from 'react';

import { PHRList } from '@palmhr/palmhr-ui-lib';
import PropTypes from 'prop-types';

const List = ({ items, renderItem, ItemClassName, className }) => (
  <PHRList items={items} renderItem={renderItem} ItemClassName={ItemClassName} className={className} />
);

export default List;

List.propTypes = {
  items: PropTypes.array.isRequired,
  renderItem: PropTypes.func.isRequired,
  className: PropTypes.string,
  ItemClassName: PropTypes.string,
};

List.defaultProps = {
  className: '',
  ItemClassName: '',
};
