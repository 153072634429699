import React from 'react';

import ActionLoading from 'components/ActionLoading/ActionLoading';
import IconButton from 'components/IconButton';
import Modal from 'components/Modal/Modal';
import PageHeader from 'components/PageHeader/PageHeader';
import PhrWizardWithoutSideMenu from 'components/PHRWizardWithoutSideMenu/PHRWizardWithoutSideMenu';

const WizardModal = ({
  onClose,
  isOpen,
  initialValues,
  onSubmit,
  title,
  isLoading,
  responseStatus,
  generateWizardStepsWithValidation,
  submitButtonText,
  updateKey,
}) => {
  const renderHeaderActions = () => (
    <section className='d-flex gap-3'>
      <IconButton icon='icon-cross-x' size='md' onClick={onClose} />
    </section>
  );

  return (
    <Modal
      styles={{
        width: '90%',
        padding: 0,
        maxHeight: '782px',
        height: '90vh',
      }}
      isOpen={isOpen}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={false}
      showHeader={false}
    >
      <ActionLoading isLoading={isLoading} responseStatus={responseStatus} />

      <div className='d-flex flex-column h-100'>
        <section className='flex-1'>
          <PhrWizardWithoutSideMenu
            renderHeaderComponent={() => (
              <PageHeader
                title={title}
                renderSideActionComponent={renderHeaderActions}
              />
            )}
            className='h-100'
            initialValues={initialValues}
            onSubmit={onSubmit}
            generateWizardSteps={generateWizardStepsWithValidation}
            submitButtonText={submitButtonText}
            updateKey={updateKey}
          />
        </section>
      </div>
    </Modal>
  );
};

export default WizardModal;
