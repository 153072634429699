import React, { useEffect, useState } from 'react';

import cx from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import Scrollbars from 'react-custom-scrollbars-2';

import styles from './DetailsModal.module.scss';
import CareersApi from '../../api/CareersApi';
import IconButton from '../../components/IconButton';
import SpinnerRing from '../../components/SpinnerRing';
import Tooltip from '../../components/Tooltip';

function DetailsModal({ id, onClose }) {
  const [details, setDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [clickedOnCopy, setClickedOnCopy] = useState(false);
  const name = `${details.firstName} ${details.lastName}`;

  useEffect(() => {
    setLoading(true);
    CareersApi.careersDetails(id)
      .then((res) => {
        setDetails(res.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const copyToClipboard = (str) => {
    const el = document.createElement('textarea');
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    setClickedOnCopy(true);

    setTimeout(() => {
      setClickedOnCopy(false);
    }, 1000);
  };

  const handleDownload = (file, action = 'download', state = '') => {
    CareersApi.filePreview(file.id, action)
      .then((res) => {
        if (action === 'preview') {
          const blob = new Blob([res.data], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          window.open(url);
        } else {
          const url = window.URL.createObjectURL(new Blob([res.data]), {
            type: 'application/pdf',
          });
          const link = document.createElement('a');
          link.href = url;
          if (state === 'cv') {
            link.setAttribute('download', `cv_${name}.pdf`);
          } else {
            link.setAttribute('download', `cover_letter_${name}.pdf`);
          }
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch(() => {});
  };

  return (
    <div className='pt-4 ps-5'>
      {loading && <SpinnerRing />}
      <div className='d-flex justify-content-between border-btm pt-0 px-0 pb-3 me-5'>
        <h5 className='ps-0 pe-3 modal-title fw-bold'>
          {name.length > 35 ? (
            <div className='tooltip-box'>
              {name.substring(0, 35)}...
              <Tooltip label={name} />
            </div>
          ) : (
            name
          )}
        </h5>
        <div className='p-0 d-flex justify-content-end'>
          <IconButton icon='icon-cross-x' onClick={onClose} />
        </div>
      </div>
      <div>
        <Scrollbars
          className='scroll-bar'
          autoHide
          renderView={({ style, ...props }) => <div {...props} style={{ ...style, overflowX: 'hidden' }} />}
          style={{ height: '500px' }}
        >
          <div>
            <div className='pe-5 pt-5'>
              <div className='row'>
                <div className={cx(styles.inputHeight, 'form-group col-12 mb-3')}>
                  <p htmlFor='workEmail' className='label-title'>
                    Job Title
                  </p>
                  <div className='text-steal d-flex align-items-start'>{details.jobTitle}</div>
                </div>
              </div>
              <div className={styles.line} />
              <div className='row'>
                <div className={cx(styles.inputHeight, 'form-group col-12 mt-3 mb-3')}>
                  <p htmlFor='workEmail' className='label-title'>
                    Contact email
                  </p>
                  <div className='text-steal d-flex align-items-start'>
                    <a href={`mailto:${details.email}`}>{details.email}</a>
                    {details.email && (
                      <div className={cx('ms-3', 'text-gray')}>
                        <div className='tooltip-box'>
                          <i
                            className='icon-copy'
                            onClick={(e) => {
                              e.stopPropagation();
                              copyToClipboard(details.email);
                            }}
                          />
                          <Tooltip label={clickedOnCopy ? 'Copied' : 'Click to copy'} />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className={styles.line} />
              <div className='row'>
                <div className={cx(styles.inputHeight, 'form-group col-12 mt-3 mb-3')}>
                  <p className='label-title'>CV</p>
                  <div className='justify-content-start mb-3 text-steal d-flex'>
                    <div className='d-flex align-items-start pe-2'>{details?.cv?.name}</div>
                    <div className='d-flex align-items-center justify-content-between'>
                      <div className='tooltip-box'>
                        <IconButton
                          className='ms-3'
                          icon='icon-download'
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDownload(details?.cv, 'download', 'cv');
                          }}
                        />
                        <Tooltip label='Download document' />
                      </div>

                      <div className='tooltip-box'>
                        <IconButton
                          className='ms-3'
                          icon='icon-eye'
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDownload(details?.cv, 'preview', '');
                          }}
                        />
                        <Tooltip label='Preview document' />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={cx(styles.inputHeight, 'form-group col-12 mt-3 mb-3')}>
                  <p className='label-title'>Cover Letter</p>
                  <div className='justify-content-start mb-3 text-steal d-flex'>
                    <div className='d-flex align-items-start pe-2'>
                      {details?.coverLetter?.name ? details?.coverLetter?.name : '- -'}
                    </div>
                    {details?.coverLetter?.name && (
                      <div className='d-flex align-items-center justify-content-between'>
                        <div className='tooltip-box'>
                          <IconButton
                            className='ms-3'
                            icon='icon-download'
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDownload(details?.coverLetter, 'download', '');
                            }}
                          />
                          <Tooltip label='Download document' />
                        </div>

                        <div className='tooltip-box'>
                          <IconButton
                            className='ms-3'
                            icon='icon-eye'
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDownload(details?.coverLetter, 'preview', '');
                            }}
                          />
                          <Tooltip label='Preview document' />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className={cx(styles.inputHeight, 'form-group col-12')}>
                  <p className='label-title'>Portfolio link</p>
                  <div className='text-steal d-flex align-items-start'>
                    {details.portfolioLink ? (
                      <a href={details.portfolioLink} target='_blank' rel='noreferrer'>
                        {details.portfolioLink}
                      </a>
                    ) : (
                      '- -'
                    )}

                    {details.portfolioLink && (
                      <div className={cx('ms-3', 'text-gray')}>
                        <div className='tooltip-box'>
                          <i
                            className='icon-copy'
                            onClick={(e) => {
                              e.stopPropagation();
                              copyToClipboard(details.portfolioLink);
                            }}
                          />
                          <Tooltip label={clickedOnCopy ? 'Copied' : 'Click to copy'} />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className={styles.line} />
              <div className='d-flex justify-content-between mt-3'>
                <div className={cx(styles.inputHeight, 'form-group')}>
                  <p className='label-title'>Created date</p>
                  <p className='mb-3 text-steal'>
                    {details.createdAt ? moment(details.createdAt).format('MMMM DD, YYYY') : '- -'}
                  </p>
                </div>
                <div className={cx(styles.inputHeight, 'form-group')}>
                  <p className='label-title'>Referral</p>
                  <p className='mb-3 text-steal'>{details.referral}</p>
                </div>
              </div>
            </div>
          </div>
        </Scrollbars>
        <div className='d-flex justify-content-end border-t py-4 mt-1 me-5'>
          <button className='btn btn-primary text-white' onClick={onClose} type='button'>
            Close
          </button>
        </div>
      </div>
    </div>
  );
}

DetailsModal.propTypes = {
  id: PropTypes.number,
  onClose: PropTypes.func.isRequired,
};

DetailsModal.defaultProps = {
  id: undefined,
};

export default DetailsModal;
