import React, { useState } from 'react';
import moment from 'moment';
import CSSTransition from 'react-transition-group/CSSTransition';

import { useQueryClient } from '@tanstack/react-query';
import { getSubscriptionMenu } from 'Customers/utility';
import {
  useMutationActivateSubscription,
  useMutationDeactivateSubscription,
} from 'Customers/Subscriptions/hooks/useSubscriptions';
import StatusChip from 'Customers/components/StatusChip/StatusChip';
import fadeAnimation from 'components/animations/fadeAnimation.module.scss';
import OutsideClickHandler from 'components/OutsideClickHandler';
import { useVisibility } from 'Shared/useVisibility';

import EditMenu from '../EditMenu/EditMenu';
import EditSubscriptionModal from '../EditSubscriptionModal/EditSubscriptionModal';
import { PACKAGE_NAMES } from '../../../constants';

const TenantSubscriptionsTableRow = ({
  plan,
  isPrimary,
  status,
  expires,
  startDate,
  mrr,
  id,
  price,
  implementationUser,
  numberOfUnits,
  pricingModel,
  currency,
  requiresUser,
}) => {
  const queryClient = useQueryClient();
  const [editModal, setEditModal] = useState(false);
  const { isOpen: isMenuOpen, open: handleMenuOpen, close: handleCloseMenu } = useVisibility();
  const { mutate: activateMutate } = useMutationActivateSubscription();
  const { mutate: deactivateMutate } = useMutationDeactivateSubscription();
  const data = { price, implementationUser, numberOfUnits };
  const selected = { price, implementationUser, numberOfUnits, id, plan, pricingModel, requiresUser, currency };

  const handleSuccess = async () => {
    await queryClient.invalidateQueries(
      {
        queryKey: ['TenantSubscriptions'],
      },
      { exact: true }
    );
  };

  const handleAction = (action) => {
    switch (action) {
      case 'Delete':
        handleCloseMenu();
        deactivateMutate(
          { id, data },
          {
            onSuccess: () => {
              handleSuccess();
            },
          }
        );
        break;
      case 'Activate':
        handleCloseMenu();
        activateMutate(
          { id, data },
          {
            onSuccess: () => {
              handleSuccess();
            },
          }
        );
        break;
      case 'Edit':
        handleCloseMenu();
        setEditModal(true);
        break;
      default:
        break;
    }
  };
  return (
    <>
      <tr>
        <td className='d-flex align-items-center gap-2 py-3 px-3'>
          <p>{PACKAGE_NAMES[plan] || plan}</p>
          {isPrimary && <i className='icon-verified1-fill text-green me-2' />}
        </td>
        <td>
          <StatusChip status={status} />
        </td>
        <td>
          {currency} {mrr}
        </td>
        <td>{startDate ? moment(startDate).format('MMM DD, YYYY') : '--'}</td>
        <td>{expires ? moment(expires).format('MMM DD, YYYY') : '--'}</td>
        <td>
          <div className='position-relative'>
            <div className='d-flex align-items-center justify-content-start'>
              <i
                className='icon-more-horizontal text-gray'
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  handleMenuOpen();
                }}
              />
            </div>
            <CSSTransition
              mountOnEnter
              unmountOnExit
              in={isMenuOpen}
              timeout={200}
              classNames={{
                enter: fadeAnimation.enter,
                enterActive: fadeAnimation.enterActive,
                exit: fadeAnimation.exit,
                exitActive: fadeAnimation.exitActive,
              }}
            >
              <OutsideClickHandler
                clickHandler={() => {
                  handleCloseMenu();
                }}
              >
                <EditMenu items={getSubscriptionMenu(status, isPrimary)} onAction={handleAction} />
              </OutsideClickHandler>
            </CSSTransition>
          </div>
        </td>
      </tr>
      <EditSubscriptionModal
        isOpen={editModal}
        selected={selected}
        onClose={() => {
          setEditModal(false);
        }}
      />
    </>
  );
};

export default TenantSubscriptionsTableRow;
