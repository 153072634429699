import React, { useEffect, useState } from 'react';

import FullAreaContainer from 'components/FullAreaContainer/FullAreaContainer';
import GroupedList from 'components/GroupedList/GroupedList';
import { useVisibility } from 'Shared/useVisibility';

import { IMPLEMENTATION_TASKS_KEY } from '../../constants';
import { useImplementationTemplatesList } from '../../hooks/useTemplates';
import AddTemplateModal from '../../modals/AddTemplateModal/AddTemplateModal';
import ImplementationTasksDetails from '../ImplementationTasksDetails/ImplementationTasksDetails';
import ImplementationTemplatesDetails from '../ImplementationTemplatesDetails/ImplementationTemplatesDetails';

const TemplatesList = () => {
  const { isOpen, open, close } = useVisibility();
  const { data: templates, isLoading: isTemplatesLoading } = useImplementationTemplatesList();
  const [activeItem, setActiveItem] = useState(null);

  const groups = [
    {
      title: 'templates',
      showAddBtn: true,
      addButtonTooltip: 'Add a new template',
      addButtonText: 'Add Template',
      handleOnClickAdd: open,
      items: (templates?.data?.items || []).map((item) => ({
        key: item.id,
        label: item.name,
        data: item,
      })),
    },
    {
      title: 'Tasks',
      showAddBtn: false,
      addButtonTooltip: '',
      addButtonText: '',
      handleOnClickAdd: () => {},
      items: [
        {
          key: 'Implementation Tasks',
          label: 'Implementation Tasks',
        },
      ],
    },
  ];

  const renderDetails = (item) =>
    item?.key === IMPLEMENTATION_TASKS_KEY ? (
      <ImplementationTasksDetails />
    ) : (
      item && (
        <ImplementationTemplatesDetails
          templateId={item?.data.id}
          template={item?.data}
          isTemplateLoading={isTemplatesLoading}
        />
      )
    );

  useEffect(() => {
    if (!isTemplatesLoading && templates) {
      const selectedTemplate = groups[0].items.find((item) => item.key === activeItem?.key);
      setActiveItem(selectedTemplate || groups[0].items[0]);
    }
  }, [isTemplatesLoading, templates]);
  return (
    <>
      <FullAreaContainer className='row  bg-white rounded'>
        <GroupedList
          className='col-12 col-lg-4 ps-0 pe-0 h-100'
          groups={groups}
          onClickItem={setActiveItem}
          activeItemKey={activeItem?.key}
          isLoading={isTemplatesLoading}
          height={'100%'}
        />
        <div className='col-lg-8 ps-0 pe-0'>{renderDetails(activeItem)}</div>
      </FullAreaContainer>
      <AddTemplateModal isOpen={isOpen} onClose={close} />
    </>
  );
};

export default TemplatesList;
