import React from 'react';
import cx from 'classnames';
import { useFormik } from 'formik';
import * as yup from 'yup';

import RoundedButton from 'components/RoundedButton/RoundedButton';
import EmployeeSelectProfile from 'components/employeeSelect/EmployeeSelectProfile';

const AddAdminForm = ({ onClose, onSubmit, isLoading, initialValues, users }) => {
  const formik = useFormik({
    initialValues,
    validationSchema: yup.object({
      employee: yup.string().required('select employee is required'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      const submissionValues = {
        email: values.employee,
      };
      onSubmit(submissionValues).finally(() => setSubmitting(false));
    },
  });

  const onChange = (v) => {
    formik.setFieldValue('employee', v.user.username);
  };

  return (
    <form noValidate onSubmit={formik.handleSubmit}>
      <div className='m-5'>
        <h6 className='font-weight-bold'>Select an employee to assign as an admin</h6>
        <div className='row' style={{ minHeight: '250px' }}>
          <div className='col-md-12 mt-3'>
            <EmployeeSelectProfile
              name='employee'
              label='Employee'
              options={users}
              value={formik.values.employee}
              onChange={onChange}
              onBlur={formik.handleBlur}
              error={formik.errors.employee}
              hideClear
              isRequired
            />
          </div>
        </div>
      </div>
      <div className={cx('d-flex', 'justify-content-end gap-2', 'p-3', 'border-t')}>
        <RoundedButton text='cancel' btnStyle='outlined' size='md' onClick={onClose} />
        <RoundedButton loading={isLoading} type='submit' text='Save' btnStyle='contained' color='primary' size='md' />
      </div>
    </form>
  );
};

export default AddAdminForm;
