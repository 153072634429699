import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './NavTab.module.scss';

const NavTab = ({ text, onClick, active, className, total }) => (
  <button
    onClick={() => {
      onClick(text);
    }}
    type='button'
    className={cx(
      styles.tab,
      className,
      'rounded-pill d-flex align-items-center py-1 px-2a me-3',
      active && styles.active
    )}
  >
    <span>{text}</span>
    {(total || total === 0) && <span className={styles.total}>{`(${total})`}</span>}
  </button>
);

NavTab.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool,
  total: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

NavTab.defaultProps = {
  className: '',
  active: false,
  total: '',
};

export default NavTab;
