export const userRoleOptions = [
  {
    id: 'ROLE_TRANSLATIONS_MANAGER',
    name: 'Translations manager',
    option: 'Translations manager',
    value: 'ROLE_TRANSLATIONS_MANAGER',
  },
  {
    id: 'ROLE_CLIENTS_MANAGER',
    name: 'Clients manager',
    option: 'Clients manager',
    value: 'ROLE_CLIENTS_MANAGER',
  },
  {
    id: 'ROLE_ADMIN',
    name: 'Admin',
    option: 'Admin',
    value: 'ROLE_ADMIN',
  },
];

export const getRoleNameById = (id) => {
  const role = userRoleOptions.find(option => option.id === id);
  return role ? role.name : null; 
};