import React, { useContext, useEffect, useState } from 'react';

import cx from 'classnames';
import _ from 'lodash';
import Scrollbars from 'react-custom-scrollbars-2';
import Modal from 'react-modal';
import { PHRPagination } from '@palmhr/palmhr-ui-lib';

import InvoiceItem from './InvoiceItem';
import styles from './Invoices.module.scss';
import CustomersApi from '../../../../api/CustomersApi';
import ConfirmModal from '../../../../components/ConfirmModal/ConfirmModal';
import modalStyles from '../../../../components/modalStyles';
import TableSkeleton from '../../../../components/skeletons/TableSkeleton';
import TableHead from '../../../../components/table/TableHead';
import ClientContext from '../ClientContext';

const addModalStyle = _.cloneDeep(modalStyles);
addModalStyle.content.width = '640px';
addModalStyle.content.marginTop = '100px';

const Invoices = () => {
  const { selected } = useContext(ClientContext);
  const [data, setData] = useState([]);
  const [invoicesPagination, setInvoicesPagination] = useState([]);
  const [confirmModal, setConfirmModal] = useState(false);
  const [actionType, setActionType] = useState('');
  const [selectedId, setSelectedId] = useState('');
  const [loadingData, setLoadingData] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [sent, setSent] = useState(false);
  const [params, setParams] = useState({
    currentPage: 1,
    perPage: 5,
  });

  const headerCells = [
    { title: 'Date' },
    { title: 'Plan' },
    { title: 'Period' },
    { title: 'Status' },
    { title: 'Amount', colSpan: 2 },
  ];

  useEffect(() => {
    if (selected) {
      setLoadingData(true);
      CustomersApi.getCustomerInvoices(selected.id, params)
        .then((res) => {
          setData(res.data.items || []);
          setInvoicesPagination(res.data.pagination || []);
          setLoadingData(false);
        })
        .catch(() => {
          setLoadingData(false);
        });
    }
  }, [selected, params]);

  const updatePaginationParams = ({ currentPage, perPage }) =>
    setParams((prevState) => ({ ...prevState, currentPage, perPage }));

  const onDownload = (invoice, action) => {
    const param = selected.id;
    CustomersApi.downloadInvoice(param, invoice, action)
      .then((response) => {
        if (action === 'preview') {
          window.open(response.data?.download?.download_url);
        } else {
          const link = document.createElement('a');
          link.href = response.data?.download?.download_url;
          link.setAttribute('download', 'invoice.pdf');
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch(() => {});
  };

  const onDelete = () => {
    setIsSending(true);
    const param = selected.id;
    CustomersApi.deleteInvoice(param, selectedId)
      .then(() => {
        let state = [...data];
        state = state.filter((el) => el.id !== selectedId);
        setData(state);
        setIsSending(false);
        setSent(true);
        setTimeout(() => {
          setSent(false);
          setConfirmModal(false);
        }, 1000);
      })
      .catch(() => {
        setIsSending(false);
      });
  };

  const onMarkAsPaid = () => {
    setIsSending(true);
    const param = selected.id;
    CustomersApi.markAsPaid(param, selectedId)
      .then(() => {
        const state = [...data];
        const index = _.findIndex(state, ['id', selectedId]);
        state[index].status = 'paid';
        setData(state);
        setIsSending(false);
        setSent(true);
        setTimeout(() => {
          setSent(false);
          setConfirmModal(false);
        }, 1000);
      })
      .catch(() => {
        setIsSending(false);
      });
  };

  const actionHandler = (action, id) => {
    setActionType(action);
    setSelectedId(id);
    setConfirmModal(true);
  };

  if (loadingData) {
    return (
      <div className='me-5'>
        <TableSkeleton num={5} lineHight={40} mb='mb-2' />
      </div>
    );
  }

  return (
    <div>
      {data.length > 0 ? (
        <div className='me-5 mt-3' style={{ height: 'calc(100vh - 265px)' }}>
          <Scrollbars autoHide className='scroll-bar h-100'>
            <div>
              <table className='w-100'>
                <TableHead headerCells={headerCells} className='sticky-top' />
                <tbody>
                  {data.map((item) => (
                    <InvoiceItem
                      key={item.id}
                      data={item}
                      onDownload={() => {
                        onDownload(item.id, 'download');
                      }}
                      onPreview={() => {
                        onDownload(item.id, 'preview');
                      }}
                      onDelete={() => {
                        actionHandler('delete', item.id);
                      }}
                      onMarkAsPaid={() => {
                        actionHandler('paid', item.id);
                      }}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </Scrollbars>
          {invoicesPagination?.total > 0 && (
            <PHRPagination onPaginationAction={updatePaginationParams} pagination={invoicesPagination} />
          )}
        </div>
      ) : (
        <div className='pt-8 me-5'>
          <div className={cx(styles.emptyList, 'm-auto')}>
            <div className={cx(styles.iconHistory, 'd-flex', 'justify-content-center', 'align-items-center', 'm-auto')}>
              <i className={cx(styles.icon, 'icon-file-text')} />
            </div>
            <p className='mt-4 text-center text-capitalize'>No invoices</p>
          </div>
        </div>
      )}
      <Modal
        isOpen={confirmModal}
        contentLabel='Add Client'
        ariaHideApp={false}
        closeTimeoutMS={300}
        style={addModalStyle}
        onRequestClose={() => {
          setConfirmModal(false);
        }}
      >
        <ConfirmModal
          isSending={isSending}
          sent={sent}
          onClose={() => {
            setConfirmModal(false);
          }}
          onSubmit={actionType === 'paid' ? onMarkAsPaid : onDelete}
          modalName={actionType === 'paid' ? 'Mark invoice paid' : 'Delete Invoice'}
          confirmName={actionType === 'paid' ? 'Confirm' : 'Delete'}
          confirmText={
            actionType === 'paid'
              ? 'Are you sure you want to mark this as paid?'
              : 'Are you sure you want to delete this invoice?'
          }
        />
      </Modal>
    </div>
  );
};

export default Invoices;
