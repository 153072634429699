import React from 'react';

import { PHRCalenderPicker } from '@palmhr/palmhr-ui-lib';
import PropTypes from 'prop-types';

const CalendarPicker = ({ value, onSelectDate, min, max, maxDetail, icon, hideLabel, disabled, className }) => (
  <PHRCalenderPicker
    value={value}
    onSelectDate={onSelectDate}
    min={min}
    max={max}
    maxDetail={maxDetail}
    icon={icon}
    hideLabel={hideLabel}
    disabled={disabled}
    className={className}
  />
);

CalendarPicker.propTypes = {
  value: PropTypes.object.isRequired,
  onSelectDate: PropTypes.func.isRequired,
  min: PropTypes.object,
  max: PropTypes.object,
  icon: PropTypes.string,
  hideLabel: PropTypes.bool,
  disabled: PropTypes.bool,
};

CalendarPicker.defaultProps = {
  min: null,
  max: null,
  icon: 'icon-calendar',
  hideLabel: false,
  disabled: false,
};

export default CalendarPicker;
