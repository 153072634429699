import React from 'react';

import styles from '../ImplementationTableRow.module.scss';
import cx from 'classnames';
import _ from 'lodash';

import IconButton from 'components/IconButton';
import OutsideClickHandler from 'components/OutsideClickHandler';
import StatusMenu from '../StatusMenu/StatusMenu';
import StatusChip from '../StatusMenu/StatusChip';
import { useVisibility } from 'Shared/useVisibility';

const StatusCell = ({ status, onSelect, items, label, isSelected }) => {
  const { isOpen: isStatusMenuOpen, open: handleOpenStatusMenu, close: handleCloseStatusMenu } = useVisibility();
  const { isOpen: isMouseHovered, open: handleMouseHover, close: handleRemoveHover } = useVisibility();
  return (
    <td
      className={cx(styles.tableData, styles.fixedWidth, styles.hoverbg)}
      onMouseEnter={() => handleMouseHover()}
      onMouseLeave={() => handleRemoveHover()}
      onClick={
        isSelected
          ? () => {
              handleOpenStatusMenu();
            }
          : null
      }
    >
      <div className='d-flex justify-content-between px-3'>
        {status ? <StatusChip status={status} /> : 'N/A'}
        {isMouseHovered && isSelected && (
          <IconButton
            icon='icon-pencil-create'
            onClick={() => {
              handleOpenStatusMenu();
            }}
          />
        )}
      </div>
      <div className='position-relative'>
        {isStatusMenuOpen && (
          <OutsideClickHandler
            clickHandler={() => {
              handleCloseStatusMenu();
            }}
          >
            <StatusMenu items={items} onSelect={onSelect} label={label} />
          </OutsideClickHandler>
        )}
      </div>
    </td>
  );
};

export default StatusCell;
