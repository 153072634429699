import React from 'react';

import { useQueryClient } from '@tanstack/react-query';
import cx from 'classnames';

import IconButton from 'components/IconButton';
import RoundedButton from 'components/RoundedButton/RoundedButton';
import SpinnerRing from 'components/SpinnerRing';
import { convertToTitleCase } from 'Customers/components/detailsModal/CustomerFeatureFlags/helpers';
import {
  queryKeys,
  useMutationDeleteFeatureFlagPerTenant,
  useQueryDefaultFeatureFlagConfigurations,
} from 'Customers/components/detailsModal/CustomerFeatureFlags/hooks/useFeatureFlag';
import styles from 'Customers/CustomersPage/Customers.module.scss';
import AddFeatureFlagModal from 'FeatureFlags/components/AddFeatureFlagModal/AddFeatureFlagModal';
import { useVisibility } from 'Shared/useVisibility';

const FeatureFlags = () => {
  const queryClient = useQueryClient();
  const { isFetching, data } = useQueryDefaultFeatureFlagConfigurations();
  const { isPending: isDeleting, mutate: deleteFeatureFlag } = useMutationDeleteFeatureFlagPerTenant();
  const { isOpen, open, close } = useVisibility();

  const featureFlagConfigurations = data?.data || {};
  const sortedModules = featureFlagConfigurations?.modules?.sort((a, b) => a.name.localeCompare(b.name)) || [];

  const handleDeleteFeatureFlagModule = (module) => {
    deleteFeatureFlag(
      { configId: featureFlagConfigurations.id, moduleId: module.id },
      {
        onSuccess: () => queryClient.invalidateQueries({ queryKey: queryKeys.getDefaultFeatureFlags }),
      }
    );
  };

  return (
    <>
      <div className='mt-3 mx-3'>
        <div className={cx(styles.wrapper, 'panel p-0')}>
          <div className='px-5 py-4 border-btm d-flex align-items-center justify-content-between'>
            <div>
              <h2>Feature Flags</h2>
              <p className='tab-subtitle'>List of all system feature flags</p>
            </div>
            <RoundedButton icon='icon-plus' text='Add New' color='primary' size='sm' onClick={open} />
          </div>

          {isFetching || isDeleting ? (
            <SpinnerRing />
          ) : (
            <section className=' d-flex flex-column mx-5 mt-5'>
              {sortedModules.map((module) => (
                <section
                  key={module.name}
                  className='d-flex align-items-center w-100 justify-content-between border-btm'
                >
                  <h6 className='my-3'>{convertToTitleCase(module.name)}</h6>
                  <IconButton color='red' onClick={() => handleDeleteFeatureFlagModule(module)} icon='icon-trash' />
                </section>
              ))}
            </section>
          )}
        </div>
      </div>

      <AddFeatureFlagModal isOpen={isOpen} close={close} featureFlagConfigurationsId={featureFlagConfigurations.id} />
    </>
  );
};
export default FeatureFlags;
