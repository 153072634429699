export const periodOptions = [
  {
    value: "DAY'S",
    label: 'Day(s)',
    id: 'DAY',
    option: "DAY'S",
  },
  {
    value: 'WEEK',
    label: 'Week(s)',
    id: 'WEEK',
    option: "WEEK'S",
  },
];

export const IMPLEMENTATION_TASKS_KEY = 'Implementation Tasks';

export const TaskOptions = [
  { action: 'Edit', title: 'Edit', icon: 'icon-pencil-create' },
  { action: 'Delete', title: 'Delete', icon: 'icon-trash' },
];
