import React from 'react';

import { useQueryClient } from '@tanstack/react-query';

import Modal from 'components/Modal/Modal';
import SpinnerRing from 'components/SpinnerRing';
import { convertToSnakeCase } from 'Customers/components/detailsModal/CustomerFeatureFlags/helpers';
import {
  queryKeys,
  useMutationPostFeatureFlagModulePerTenant,
} from 'Customers/components/detailsModal/CustomerFeatureFlags/hooks/useFeatureFlag';
import FeatureFlagForm from 'FeatureFlags/components/FeatureFlagForm/FeatureFlagForm';

const AddFeatureFlagModal = ({ isOpen, close, featureFlagConfigurationsId }) => {
  const queryClient = useQueryClient();
  const { isPending: isCreating, mutate: createFeatureFlag } = useMutationPostFeatureFlagModulePerTenant();

  const handleAddNewFeatureFlagConfigurations = (values) => {
    values.name = convertToSnakeCase(values.name);
    createFeatureFlag(
      { configId: featureFlagConfigurationsId, data: values },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: queryKeys.getDefaultFeatureFlags });
          close();
        },
      }
    );
  };

  return (
    <Modal
      styles={{
        minHeight: 300,
      }}
      title='Add New Feature Flag'
      isOpen={isOpen}
      onRequestClose={close}
      shouldCloseOnOverlayClick
    >
      {isCreating ? (
        <SpinnerRing />
      ) : (
        <FeatureFlagForm
          className='mt-3'
          onSubmit={handleAddNewFeatureFlagConfigurations}
          initialValues={{ name: '', enabled: false, features: [] }}
          onCancel={close}
        />
      )}
    </Modal>
  );
};

export default AddFeatureFlagModal;
