import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import BrandImage from 'components/BrandImage';
import RoundedButton from './RoundedButton/RoundedButton';

const EmptyState = ({
  icon,
  title,
  subtitle,
  onClick,
  btnText,
  showBtn,
  showBtnIcon,
  iconPosition,
  brandImage,
  btnIcon,
  loading,
  btnHref,
  className,
}) => (
  <div className={cx(className, 'radius-8 bg-light p-6 mb-7')}>
    <div className='m-auto' style={{ width: '160px', height: 'auto' }}>
      {brandImage ? (
        <BrandImage name={brandImage} alt='empty-screen' />
      ) : (
        <img className='w-100' src={icon} alt='search-icon' />
      )}
    </div>

    <h5 className='fw-bold mx-2 text-center text-steal'>{title}</h5>

    {subtitle && (
      <p style={{ maxWidth: '264px' }} className='text-gray fs-12 text-center mx-auto'>
        {subtitle}
      </p>
    )}

    {showBtn && (
      <div className='d-flex justify-content-center mt-4'>
        <RoundedButton
          text={btnText}
          icon={showBtnIcon ? btnIcon : null}
          iconPosition={iconPosition}
          color='primary'
          onClick={onClick}
          href={btnHref}
          loading={loading}
        />
      </div>
    )}
  </div>
);

EmptyState.propTypes = {
  icon: PropTypes.string,
  brandImage: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  onClick: PropTypes.func,
  showBtn: PropTypes.bool,
  btnText: PropTypes.string,
  btnIcon: PropTypes.string,
  iconPosition: PropTypes.string,
  showBtnIcon: PropTypes.bool,
  loading: PropTypes.bool,
  btnHref: PropTypes.string,
  className: PropTypes.string,
};

EmptyState.defaultProps = {
  icon: '',
  brandImage: '',
  btnText: '',
  showBtn: true,
  onClick: undefined,
  subtitle: '',
  iconPosition: 'left',
  btnIcon: 'icon-plus',
  showBtnIcon: true,
  loading: false,
  btnHref: '',
  className: '',
};

export default React.memo(EmptyState);
