import React, { useState, useEffect } from 'react';

import cx from 'classnames';
import _ from 'lodash';

import ClientContext from './ClientContext';
import styles from './CustomersHeadcount.module.scss';
import HeadCountBarItem from './HeadCountBarItem';
import CustomersApi from '../api/CustomersApi';
import modalStyle from '../components/modalStyles';
import Line from '../components/skeletons/Line';

const CustomersHeadcount = ({ setShowCompaniesModal }) => {
  const [topCompanies, setTopCompanies] = useState({
    items: [],
    totalUsers: 0,
  });
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState(undefined);
  const [disabeDirBtn, setDisabeDirBtn] = useState({
    next: false,
    prev: false,
  });

  const style = { height: 512, width: '100%', borderRadius: 8, opacity: 0.7 };
  const addModalStyle = _.cloneDeep(modalStyle);
  addModalStyle.content.width = '540px';

  useEffect(() => {
    setLoading(true);
    CustomersApi.getCustomersHeadCountTopFive()
      .then((res) => {
        setLoading(false);
        setTopCompanies(res.data);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const openModal = () => {
    setShowCompaniesModal(true);
  };

  useEffect(() => {
    if (selected) {
      const psIndex = _.findIndex(topCompanies.items, ['company', selected.company]);
      const btnState = { ...disabeDirBtn };
      btnState.prev = psIndex <= 0;
      btnState.next = psIndex >= topCompanies.items.length - 1;
      setDisabeDirBtn(btnState);
    }
  }, [selected]);

  const colorsMap = {
    'pro-40-yearly': '#62D26F',
    'pro-40-monthly': '#62D26F',
    'basic-10_24-yearly': '#4EB1F9',
    'basic-10_24-monthly': '#4EB1F9',
    'basic-25_39-yearly': '#7A6FF0',
    'basic-25_39-monthly': '#7A6FF0',
    'basic-1_9-yearly': '#ffa150',
    'basic-1_9-monthy': '#ffa150',
  };

  if (loading) {
    return (
      <div className='col-6'>
        <Line style={style} gradient={styles.gradient} />
      </div>
    );
  }

  return (
    <ClientContext.Provider value={{ selected }}>
      <div className='col-6'>
        <div className={cx(styles.wrapper, 'h-100')}>
          <div className='d-flex justify-content-between border-btm mb-3 pb-2'>
            <h6 className={cx(styles.title, 'text-gray fw-bold')}>Top 5 Customers</h6>
            <span className={cx(styles.see, 'text-gray fw-bold')} onClick={openModal}>
              See All
            </span>
          </div>
          {topCompanies?.items.length > 0 &&
            topCompanies.items.map((company) => (
              <HeadCountBarItem
                key={company.company}
                title={company.company}
                value={company.numberOfEmployees}
                plan={company.subscriptionPlan}
                percent={(company.numberOfEmployees / topCompanies.totalUsers) * 100}
                total={topCompanies.totalUsers}
                color={colorsMap[company.subscriptionPlan]}
              />
            ))}
        </div>
      </div>
      <button
        type='button'
        className={cx(styles.backdrop, {
          [styles.active]: !!selected,
        })}
        onClick={() => {
          setSelected();
        }}
      />
    </ClientContext.Provider>
  );
};

export default CustomersHeadcount;
