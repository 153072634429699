import React from 'react';

import { PHRListLoader } from '@palmhr/palmhr-ui-lib';
import PropTypes from 'prop-types';

const ListLoader = ({ rowsCount, rowHeight, className }) => (
  <PHRListLoader rowHeight={rowHeight} rowsCount={rowsCount} className={className} />
);

export default ListLoader;

ListLoader.propTypes = {
  rowsCount: PropTypes.number,
  rowHeight: PropTypes.number,
  className: PropTypes.string,
};

ListLoader.defaultProps = {
  rowsCount: 5,
  rowHeight: 50,
  className: '',
};
