import React from 'react';

import { PHRIconButton } from '@palmhr/palmhr-ui-lib';
import PropTypes from 'prop-types';

const IconButton = ({
  icon,
  onClick,
  isDisabled,
  color,
  size,
  className,
  style,
  showNotification,
  active,
  loading,
  showBorder,
  tooltipText,
  tooltipPosition,
  ariaLabel,
  text,
  activeTextBtn,
}) => (
  <PHRIconButton
    icon={icon}
    onClick={onClick}
    isDisabled={isDisabled}
    color={color}
    size={size}
    className={className}
    style={style}
    showNotification={showNotification}
    active={active}
    loading={loading}
    showBorder={showBorder}
    tooltipText={tooltipText}
    tooltipPosition={tooltipPosition}
    ariaLabel={ariaLabel}
    text={text}
    activeTextBtn={activeTextBtn}
  />
);

IconButton.propTypes = {
  icon: PropTypes.string,
  onClick: PropTypes.func,
  isDisabled: PropTypes.bool,
  color: PropTypes.string,
  size: PropTypes.string,
  ariaLabel: PropTypes.string,
  className: PropTypes.string,
  tooltipPosition: PropTypes.string,
  showNotification: PropTypes.bool,
  loading: PropTypes.bool,
  active: PropTypes.bool,
  showBorder: PropTypes.bool,
  text: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  activeTextBtn: PropTypes.bool,
};

IconButton.defaultProps = {
  icon: '',
  onClick: undefined,
  isDisabled: false,
  color: 'gray',
  size: 'sm',
  className: '',
  ariaLabel: '',
  showNotification: false,
  loading: false,
  active: false,
  showBorder: false,
  text: '',
  tooltipPosition: 'top',
  activeTextBtn: false,
};

export default IconButton;
