import React from 'react';

import cx from 'classnames';
import _ from 'lodash';
import { formatStatusClass, formatStatusDisplay } from 'utility/statusChipFormatting';
import styles from './StatusChip.module.scss';

const StatusChip = ({ status }) => (
  <span className={cx(styles.statusChip, styles[formatStatusClass(status)], 'text-truncate')}>
    {formatStatusDisplay(status)}
  </span>
);

export default StatusChip;
