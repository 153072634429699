import React, { useState, useEffect, useContext } from 'react';

import axios from 'axios';
import cx from 'classnames';
import { useFormik } from 'formik';
import * as JWT from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

import styles from './ResetPasword.module.scss';
import AuthApi from '../../api/AuthApi';
import AuthStatic from '../../Shared/Auth/AuthStatic';
import { AuthContext } from '../AuthContext';

const ResetPassword = () => {
  const { dispatch } = useContext(AuthContext);
  const navigate = useNavigate();
  const [formHasErrors, setFormHasErrors] = useState(false);
  const [formIsValid, setFormIsValid] = useState(false);
  const [token, setToken] = useState(window.location.search ? window.location.search.replace('?token=', '') : null);
  const [username, setUsername] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState({
    minLengthValid: false,
    specialCharValid: false,
    upperLowerLetterValid: false,
    includeNumbersValid: false,
  });

  useEffect(() => {
    if (!token) {
      alert('NO TOKEN');
      navigate('/login');
    }

    if (token.includes('&username=')) {
      const splitedToken = token.split('&');
      splitedToken[1] = splitedToken[1].replace('username=', '');

      setToken(splitedToken[0]); // token is frist element in row;
      setUsername(splitedToken[1]); // username is second element in row;
    }
  }, []);

  const validate = (values) => {
    const errors = {};
    const passwordStrengthError = 'Passowrd must meet strength criteria';

    /* Password strength validation */
    const customErrors = { ...passwordStrength };
    if (values.password.length >= 8) {
      customErrors.minLengthValid = true;
    } else {
      errors.password = passwordStrengthError;
      customErrors.minLengthValid = false;
    }

    if (/[~`!#@$%^&*+=\-[\]\\';,/{}|\\":<>?]/g.test(values.password)) {
      customErrors.specialCharValid = true;
    } else {
      errors.password = passwordStrengthError;
      customErrors.specialCharValid = false;
    }

    if (/[a-z]/g.test(values.password) && /[A-Z]/g.test(values.password)) {
      customErrors.upperLowerLetterValid = true;
    } else {
      errors.password = passwordStrengthError;
      customErrors.upperLowerLetterValid = false;
    }

    if (/\d/g.test(values.password) && /[a-zA-Z]/g.test(values.password)) {
      customErrors.includeNumbersValid = true;
    } else {
      errors.password = passwordStrengthError;
      customErrors.includeNumbersValid = false;
    }

    setPasswordStrength(customErrors);
    /* END PASSWORD STRENGTH */

    if (!values.password) {
      errors.password = 'Password is required';
    }

    if (!values.rePassword) {
      errors.rePassword = 'Repeated password is required';
    }

    if (values.password && values.rePassword && values.password !== values.rePassword) {
      errors.rePassword = 'The password and repeated password do not match.';
    }

    if (Object.keys(errors).length === 0) {
      setFormHasErrors(false);
      setFormIsValid(false);

      if (Object.keys(values).length > 0) {
        setFormIsValid(true);

        Object.keys(values).forEach((key) => {
          if (values[key] === null || values[key] === undefined) {
            setFormIsValid(false);
          }
        });
      }
    } else {
      setFormHasErrors(true);
      setFormIsValid(false);
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      password: '',
      rePassword: '',
    },
    validate,
    onSubmit: (values) => {
      setIsLoading(true);

      const data = {
        ...values,
        token,
      };

      if (username) {
        data.username = username;
        data.language = 'en';

        // Need to check why won't work on AuthApi
        axios.post('https://app.palmhr.club/api/v1/force-change-password', data).then(
          (res) => {
            dispatch({
              type: 'set',
              payload: {
                user: JWT(res.data.token),
                token: res.data.token,
                refreshToken: res.data.refreshToken,
              },
            });
            navigate('/dashboard');
          },
          () => {
            setIsLoading(false);
            setFormHasErrors(true);
            setFormIsValid(false);
          }
        );
      } else {
        AuthApi.resetPasword(data).then(
          () => {
            navigate('/login');
          },
          () => {
            setIsLoading(false);
            setFormHasErrors(true);
            setFormIsValid(false);
          }
        );
      }
    },
  });

  return (
    <AuthStatic>
      <p className={`lead mb-4 ${formHasErrors ? 'error-red' : formIsValid ? 'success-green' : ''}`}>
        {formik.errors.password
          ? formik.errors.password
          : formik.errors.rePassword
          ? formik.errors.rePassword
          : 'Enter new password'}
      </p>

      <form className='form-signin' onSubmit={formik.handleSubmit}>
        <div className='form-group phr-from-group'>
          <label className='sr-only' htmlFor='inputPassword'>
            password
          </label>

          <div className='input-group mb-3'>
            <div className='input-group-prepend'>
              <span className={`input-group-text phr-password-icon ${formik.errors.password ? 'error-red' : null}`} />
            </div>

            <input
              type='password'
              name='password'
              id='inputPassword'
              className='form-control'
              placeholder='password'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
            />
          </div>
        </div>

        <div className='form-group phr-from-group'>
          <label className='sr-only' htmlFor='inputRePassword'>
            Repeat password
          </label>

          <div className='input-group mb-3'>
            <div className='input-group-prepend'>
              <span className={`input-group-text phr-password-icon ${formik.errors.rePassword ? 'error-red' : null}`} />
            </div>

            <input
              type='password'
              name='rePassword'
              id='inputRePassword'
              className='form-control'
              placeholder='repeat password'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.rePassword}
            />
          </div>
        </div>

        <div className='col-12 px-2a mb-3'>
          <div
            className={cx(
              styles.passwordStrength,
              'd-flex',
              'justify-content-start',
              'align-items-center',
              passwordStrength.minLengthValid && styles.active
            )}
          >
            <i className='icon-check-mark me-2' />
            <span>At least 8 characters long</span>
          </div>

          <div
            className={cx(
              styles.passwordStrength,
              'd-flex',
              'justify-content-start',
              'align-items-center',
              passwordStrength.upperLowerLetterValid && styles.active
            )}
          >
            <i className='icon-check-mark me-2' />
            <span>A mixture of both uppercase and lowercase letters</span>
          </div>

          <div
            className={cx(
              styles.passwordStrength,
              'd-flex',
              'justify-content-start',
              'align-items-center',
              passwordStrength.includeNumbersValid && styles.active
            )}
          >
            <i className='icon-check-mark me-2' />
            <span>A mixture of letters and numbers</span>
          </div>

          <div
            className={cx(
              styles.passwordStrength,
              'd-flex',
              'justify-content-start',
              'align-items-center',
              passwordStrength.specialCharValid && styles.active
            )}
          >
            <i className='icon-check-mark me-2' />
            <span>At least one special character, eg., ! @ # ? ]</span>
          </div>
        </div>

        {isLoading ? (
          <button
            className='btn btn-primary text-white btn btn-md btn-primary btn-block text-light loadingButton'
            type='button'
            disabled
          >
            <div className='d-flex align-items-center justify-content-center'>
              <span className='spinner-grow spinner-grow-sm' role='status' aria-hidden='true' />
              <span className='ms-2'>Save</span>
            </div>
          </button>
        ) : (
          <button
            className='btn btn-md btn-primary btn-block text-light'
            type='submit'
            disabled={formHasErrors || !formik.values.password || !formik.values.rePassword}
          >
            Save
          </button>
        )}

        <div className='text-center mt-2'>
          <a href='/login'>
            <p className='text'>Back to login</p>
          </a>
        </div>
      </form>
    </AuthStatic>
  );
};

export default ResetPassword;
