import React, { useState } from 'react';
import ClientsApi from 'api/ClientsApi';
import TextInput from 'components/forms/TextInput/TextInput';

const CustomerSubdomainInput = ({ formik }) => {
  const [isDomainAvailable, setIsDomainAvailable] = useState(null);

  const checkIsDomainAvailable = (domain) => {
    if (!domain) return;

    ClientsApi.checkIsDomainAvalable(domain)
      .then((res) => {
        if (res.data.inUse) {
          formik.errors.subdomain = `Subdomain ${domain}.palm.hr already in use`;
          setIsDomainAvailable(false);
        } else {
          setIsDomainAvailable(true);
        }
      })
      .catch(() => {});
  };

  const handleChange = (e) => {
    formik.handleChange(e);
    setIsDomainAvailable(null);
  };

  return (
    <div className='d-flex align-items-center gap-4'>
      <TextInput
        name='subdomain'
        label='subdomain'
        value={formik.values.subdomain}
        onChange={handleChange}
        onBlur={formik.handleBlur}
        isRequired
        error={formik.touched.subdomain && formik.errors.subdomain}
        className='flex-grow-1'
      />
      <button
        className={`btn btn-sm ml-2 ${isDomainAvailable === false ? 'btn-outline-danger' : 'btn-outline-success'}`}
        title='Check is domain available'
        onClick={() => {
          checkIsDomainAvailable(formik.values.subdomain);
        }}
        type='button'
      >
        {isDomainAvailable === null ? 'Test' : isDomainAvailable ? 'OK' : 'Failed'}
      </button>
    </div>
  );
};

export default CustomerSubdomainInput;
