const PERMISSION_GROUP_ADMIN = 'ADMIN';
const PERMISSION_GROUP_SECURITY_ADMIN = 'SECURITY_ADMIN';
const PERMISSION_GROUP_HR = 'HR';
const PERMISSION_GROUP_PUBLIC_RELATIONS = 'PUBLIC_RELATIONS';
const PERMISSION_GROUP_ACCOUNTANT = 'ACCOUNTANT';
const PERMISSION_GROUP_EMPLOYEE = 'EMPLOYEE';

export const permissionGroupConstants = {
  PERMISSION_GROUP_ADMIN,
  PERMISSION_GROUP_SECURITY_ADMIN,
  PERMISSION_GROUP_HR,
  PERMISSION_GROUP_PUBLIC_RELATIONS,
  PERMISSION_GROUP_ACCOUNTANT,
  PERMISSION_GROUP_EMPLOYEE,
};
