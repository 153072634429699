import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './AsideNavItem.module.scss';

const AsideNavItem = ({ text, onClick, isActive, icon }) => (
  <li
    className={cx(
      styles.listItem,
      { [styles.active]: isActive },
      'p-3 d-flex align-items-center justify-content-between'
    )}
    onClick={onClick}
  >
    <div className='d-flex align-items-center'>
      <i className={cx(icon, 'me-2')} />
      <p>{text}</p>
    </div>
  </li>
);

AsideNavItem.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  icon: PropTypes.string.isRequired,
};

export default AsideNavItem;
