import React, { useState, useEffect } from 'react';

import { Chart, registerables } from 'chart.js';
import cx from 'classnames';
import { Bar } from 'react-chartjs-2';

import styles from './SubscriptionChart.module.scss';
import DashboardApi from '../../api/DashboardApi';
import Line from '../../components/skeletons/Line';

Chart.register(...registerables);

export default function SubscriptionChart() {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  const style = { height: 291, width: '100%', borderRadius: 8, opacity: 0.7 };

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    cornerRadius: 4,
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 15,
        bottom: 0,
      },
    },
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        ticks: {
          display: false,
        },
        border: {
          display: false,
        },
        grid: {
          display: false,
          drawOnChartArea: false,
          drawTicks: false,
        },
      },
      x: {
        border: {
          display: false,
        },
        ticks: {
          color: '#93a5b1',
          font: {
            size: 10,
          },
        },
        grid: {
          display: false,
          drawOnChartArea: false,
          drawTicks: false,
        },
      },
    },
    tooltips: {
      mode: 'index',
      intersect: false,
    },
    hover: {
      mode: 'index',
      intersect: false,
    },
    bezierCurve: false,
    elements: {
      point: {
        radius: 0,
      },
    },
  };

  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: 'monthly',
        data: [],
        fill: false,
        backgroundColor: '#ffa150',
        lineTension: 0,
        borderWidth: 2,
        borderColor: '#ffa150',
        barThickness: 25,
        barPercentage: 0.4,
        datalabels: {
          color: '#5D728C',
        },
      },
    ],
  });

  useEffect(() => {
    setLoading(true);
    DashboardApi.getSubscriptionPlan()
        .then((res) => {
          setLoading(false);
          const newData = {};
          chartData.datasets[0].data = [];
          chartData.labels = [];

          res.data.map((item) => {
            newData[item.plan] = parseFloat(item.numberOfSubscriptions);
            chartData.datasets[0].data.push(item.numberOfSubscriptions);
            chartData.labels.push(item.plan);
            return item;
          });
          setChartData(chartData);
        })
        .catch(() => {
          setLoading(false);
        });
  }, []);

  if (loading) {
    return (
      <div className='col-4'>
        <Line style={style} gradient={styles.gradient} />
      </div>
    );
  }

  return (
    <div className='col-4'>
      <div className={cx(styles.wrapper, 'h-100')}>
        <h6 className={cx(styles.title, 'border-btm pb-3 text-gray fw-bold')}>Active Subscriptions plans</h6>
        <div className={cx(styles.subPlanHeight, 'd-flex align-items-end')}>
          <Bar data={chartData} options={options} />
        </div>
      </div>
    </div>
  );
}
