export const taskStatusOptions = [
  { id: 1, status: 'TO_DO', value: 'TODO' },
  { id: 2, status: 'IN_PROGRESS', value: 'IN_PROGRESS' },
  { id: 3, status: 'COMPLETED', value: 'COMPLETED' },
];

export const emailStatusOptions = [
  { id: 1, status: 'VIEWED', value: 'VIEWED' },
  { id: 2, status: 'BOOKED', value: 'BOOKED' },
  { id: 3, status: 'DONE', value: 'DONE' },
];
