import React from 'react';

import cx from 'classnames';
import moment from 'moment';
import TableSkeleton from 'components/skeletons/TableSkeleton';
import Personas from 'components/Personas/Personas';

import styles from 'Clients/DemoClients/DemoTableRow.module.scss';
import TableHead from '../../../../../components/table/TableHead';
import Tooltip from '../../../../../components/Tooltip';

const SubscriptionDetailsTable = ({ customer, loading }) => {
  const headerCells = [{ title: 'Name' }, { title: 'Group' }, { title: 'Last Active' }];

  return (
    <div className='mt-5'>
      <table className='w-100 client-list'>
        <TableHead headerCells={headerCells} />
        {loading && (
          <tbody>
            <tr>
              <td colSpan='6'>
                <TableSkeleton lineHight={45} mb='mb-2' num={5} />
              </td>
            </tr>
          </tbody>
        )}
        {!loading && customer && customer.length > 0 ? (
          <tbody>
            {customer.map((person) => (
              <tr className={cx(styles.wrapper)} key={person.email}>
                <td className='d-flex align-items-center ps-3 py-3'>
                  <div className='ms-3 d-flex gap-2a align-items-center'>
                    <Personas name={person.name} />
                    <div className='tooltip-box d-inline'>
                      <p className={styles.companyName}>{person.name}</p>
                      <p className={styles.mail}>{person.email}</p>
                      <Tooltip label={person.name} />
                    </div>
                  </div>
                </td>
                <td>{person.permissionGroup}</td>
                <td>{person.lastActive ? moment(person.lastActive).fromNow() : '- -'}</td>
              </tr>
            ))}
          </tbody>
        ) : (
          <tbody />
        )}
      </table>
    </div>
  );
};

export default SubscriptionDetailsTable;
