import React from 'react';

import cx from 'classnames';

import styles from './QuickPanel.module.scss';

const QuickPanel = () => (
  <div className='container mt-5 '>
    <div className={cx(styles.wrapper, 'panel clients-page p-0')}>
      <div className={cx(styles.header, 'clients-page-header px-5 py-4')}>
        <div className='d-flex flex-column'>
          <h2>Quick panel</h2>
          <p className='tab-subtitle'>Common actions to manage the clients</p>
        </div>
      </div>
    </div>
  </div>
);

export default QuickPanel;
