import React from 'react';

import cx from 'classnames';

import styles from './InputsSectionContainer.module.scss';

const InputsSectionContainer = ({
  className,
  children,
  title,
  subTitle,
}) => (
  <section className={cx(className, styles.InputsSectionContainer, 'px-5 pt-5 full-border rounded')}>
    <section className='d-flex justify-content-between align-items-center'>
      <section>
        <h6 className='subtitle font-size-16 mb-2'>{title}</h6>
        <p className='mb-4 color-secondary font-size-14 multiline'>{subTitle}</p>
      </section>
    </section>
    {children}
  </section>
);

export default InputsSectionContainer;
