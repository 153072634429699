import http from './Api';

class AnnouncementsApi {
  static getAnnouncements(platform, params) {
    return http.get(`/release-notes/platform/${platform}`, { params });
  }

  static createAnnouncement(data) {
    return http.post('/release-notes', data);
  }

  static getAnnouncementById(id) {
    return http.get(`release-notes/${id}`);
  }

  static updateAnnouncement({ id, data }) {
    return http.put(`/release-notes/${id}`, data);
  }

  static deleteAnnouncement(id) {
    return http.delete(`/release-notes/${id}`);
  }

  static publishAnnouncement(id) {
    return http.put(`/release-notes/${id}/publish`);
  }
}

export default AnnouncementsApi;
