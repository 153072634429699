import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './GridItem.module.scss';

const GridItem = ({ item, onSelect }) => (
  <div
    className='col-3 mb-4 px-2a'
    onClick={() => {
      onSelect(item.selectValue);
    }}
  >
    <div className={cx(styles.wrapper, 'px-4', 'pt-4', 'pb-6')}>
      <div
        className={cx(styles.iconBox, 'd-flex', 'align-items-center', 'justify-content-center')}
        style={{ backgroundColor: item.color }}
      >
        <i className={item.icon} />
      </div>
      <p className='fw-bold mb-3 mt-4'>{item.title}</p>
      <p className={styles.description}>{item.desc}</p>
    </div>
  </div>
);

GridItem.propTypes = {
  item: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default GridItem;
