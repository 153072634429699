import React from 'react';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { AuthContextProvider } from 'Auth/AuthContext';
import { AppRouter } from 'Router/Router';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      networkMode: 'offlineFirst',
    },
  },
});

const App = () => (
  <AuthContextProvider>
    <QueryClientProvider client={queryClient}>
      <AppRouter />
    </QueryClientProvider>
  </AuthContextProvider>
);

export default App;
