import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './HeadCountBarItem.module.scss';

const HeadCountBarItem = ({ title, value, percent, color, plan, total }) => (
  <div className='mb-3'>
    <div className='d-flex align-items-center justify-content-between mb-1'>
      <div>
        <p className={cx(styles.text, 'd-flex align-items-center')}>
          <span className='text-steal'>
            <span>{title}</span>
          </span>
        </p>
        <span className={cx(styles.math, 'gray-500 ms-2')}>{`${value} / ${total} (${Math.round(percent)}%) `}</span>
      </div>
      <div>
        <div className='d-flex align-items-center justify-content-end'>
          <i className='icon-user-circle text-gray me-2' />
          <span className={cx(styles.numb, 'fw-bold', 'text-steal')}>{value}</span>
        </div>
        <div
          className={cx(styles.plan, 'text-right')}
          style={{
            color,
          }}
        >
          {plan
            .replace('-yearly', '')
            .replace('-monthly', '')
            .replace('basic-25_39', 'Basic 25-39')
            .replace('basic-10_24', 'Basic 10-24')
            .replace('basic-1_9', 'Basic 1-9')
            .replace('pro-40', 'Pro 40')}
        </div>
      </div>
    </div>
    <div className='progress' style={{ height: '8px', borderRadius: '3px' }}>
      <div
        className='progress-bar'
        role='progressbar'
        style={{
          width: `${percent}%`,
          borderRadius: '3px',
          backgroundColor: color,
        }}
        aria-valuenow={value}
        aria-valuemin='0'
        aria-valuemax={100}
      />
    </div>
  </div>
);
HeadCountBarItem.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  percent: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
};
export default HeadCountBarItem;
