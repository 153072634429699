import React, { useEffect } from 'react';

import cx from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import DatePicker from 'react-date-picker';

import styles from './DateInput.module.scss';
import 'react-date-picker/dist/DatePicker.css';

const DateInput = ({
  name,
  value,
  label,
  isRequired,
  isDisabled,
  min,
  max,
  className,
  isInvalid,
  change,
  blur,
  error,
  isFormik,
  maxDetail,
}) => {
  const getNextSibling = (elem, selector) => {
    // Get the next sibling element
    let sibling = elem.nextElementSibling;

    // If there's no selector, return the first sibling
    if (!selector) return sibling;

    // If the sibling matches our selector, use it
    // If not, jump to the next sibling and continue the loop
    while (sibling) {
      if (sibling.matches(selector)) return sibling;
      sibling = sibling.nextElementSibling;
    }
    return undefined;
  };
  const removeComaFromPlaceholder = () => {
    setTimeout(() => {
      const select = document.getElementsByClassName('react-date-picker__inputGroup__month');

      if (maxDetail !== 'year') {
        Array.from(select).forEach((el) => {
          const day = getNextSibling(el, '.react-date-picker__inputGroup__day');
          if (el.children[0].innerHTML === 'Select') {
            day.nextElementSibling.innerHTML = '';
          } else {
            day.nextElementSibling.innerHTML = ', ';
          }
        });
      }
    }, 10);
  }; // Hack for removing , in palceholder

  const onChange = (val) => {
    const date = val ? moment(val).format('YYYY-MM-DD') : '';
    if (isFormik) {
      change(name)(date);
    } else {
      change(date);
    }
  };

  useEffect(() => {
    removeComaFromPlaceholder();
  }, [value]);
  return (
    <div className={cx(styles.wrapper, className, 'position-relative pt-2')}>
      <div className='d-flex'>
        {label ? (
          <label className='label-title' htmlFor={name}>
            {label}
            {isRequired && <sup>*</sup>}
          </label>
        ) : null}
      </div>
      <DatePicker
        className={cx('form-control', (error || isInvalid) && 'is-invalid')}
        value={typeof value === 'string' ? (value !== '' ? new Date(value) : '') : value}
        onChange={(val) => {
          onChange(val);
        }}
        onBlur={blur}
        disabled={isDisabled}
        disableCalendar={isDisabled}
        name={name}
        format={maxDetail === 'year' ? 'MMMy' : 'MMM dd, yyyy'}
        maxDate={typeof max === 'string' ? new Date(max) : max}
        minDetail='decade'
        maxDetail={maxDetail}
        minDate={typeof min === 'string' ? new Date(min) : min}
        required={isRequired}
        calendarIcon={<i className='icon-calendar-dates' />}
        clearIcon={<i className='icon-cross-x' />}
        yearPlaceholder=''
        monthPlaceholder='Select'
        dayPlaceholder=''
      />
      <div className='invalid-feedback mt-1 text-left'>
        <span>{error}</span>
      </div>
    </div>
  );
};

DateInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  change: PropTypes.func,
  blur: PropTypes.func,
  label: PropTypes.string,
  className: PropTypes.string,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isInvalid: PropTypes.bool,
  min: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  max: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  error: PropTypes.string,
  isFormik: PropTypes.bool,
};

DateInput.defaultProps = {
  value: new Date(),
  label: '',
  change: undefined,
  blur: undefined,
  isRequired: false,
  isDisabled: false,
  className: '',
  min: new Date('1900-01-01'),
  max: new Date('3000-12-31'),
  isInvalid: false,
  error: '',
  isFormik: true,
};

export default DateInput;
