import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './BarItem.module.scss';

const BarItem = ({ title, value, percent, color }) => (
  <div className='mb-3'>
    <div className='d-flex align-items-center justify-content-between mb-1'>
      <p className={cx(styles.text, 'd-flex align-items-center')}>
        <span className='text-steal'>{title}</span>
        <span className='gray-300 ms-2'>{`${Math.round(percent)}%`}</span>
      </p>
      <p className={cx(styles.text, 'fw-bold', 'text-steal')}>{value}</p>
    </div>
    <div className='progress' style={{ height: '8px', borderRadius: '3px' }}>
      <div
        className='progress-bar'
        role='progressbar'
        style={{
          width: `${percent}%`,
          borderRadius: '3px',
          backgroundColor: color,
        }}
        aria-valuenow={value}
        aria-valuemin='0'
        aria-valuemax={100}
      />
    </div>
  </div>
);
BarItem.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  percent: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
};
export default BarItem;
