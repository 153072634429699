import React from 'react';

import { useQueryClient } from '@tanstack/react-query';

import Modal from '../../../../components/Modal/Modal';
import AddTemplateForm from '../../forms/AddTemplateForm/AddTemplateForm';
import { useImplementationTemplateEdit, queryKeys } from '../../hooks/useTemplates';

const EditTemplateModal = ({ isOpen, onClose, template, id }) => {
  const initialValues = {
    name: template.name,
    nameLocal: template.nameLocal,
    tasks: template.tasks,
    description: template.description,
  };
  const { mutate, isLoading } = useImplementationTemplateEdit();
  const queryClient = useQueryClient();
  const onHandleSubmit = (data) => {
    mutate(
      {
        id,
        template: data,
      },
      {
        onSuccess: async () => {
          onClose();
          await queryClient.invalidateQueries({ queryKey: queryKeys.ImplementationTemplateDetails(id) });
          await queryClient.invalidateQueries({ queryKey: queryKeys.ImplementationTemplates() });
        },
      }
    );
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} title={'Edit Template'} styles={{ width: '720px' }}>
      <AddTemplateForm
        tasks={template.tasks}
        initialValues={initialValues}
        onSubmit={onHandleSubmit}
        isLoading={isLoading}
      />
    </Modal>
  );
};

export default EditTemplateModal;
