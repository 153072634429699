import React from 'react';

import { PHRList } from '@palmhr/palmhr-ui-lib';

import CheckBox from 'components/forms/Checkbox/Checkbox';
import { PACKAGE_NAMES } from 'Customers/constants';
import { getSubscriptionPricingModels } from 'utility/pricingUtils';

const SubscriptionAddOnsList = ({ type, title, items, addonSelected, onSelectAddon }) => {
  const renderItem = (item) => (
    <div className='d-flex justify-content-between px-2' key={item.id}>
      <div className='d-flex mb-3 gap-2' style={{ width: '100%' }}>
        <CheckBox
          value={item.id}
          onChange={(checked) => onSelectAddon(checked, item)}
          checked={addonSelected.includes(item.identifier)}
          name='addonSubscriptions'
          label={PACKAGE_NAMES[item.name] || item.name}
        />
      </div>
      <div className='d-flex flex-column justify-content-start align-items-end' style={{ width: '120px' }}>
        <div className='d-flex justify-content-end align-items-center w-100'>
          <div className='d-flex align-items-center'>
            <p className='font-size-16 mb-0'>{item.defaultUnitPrice}</p>
            <p className='font-size-16 px-2'>{item.currency}</p>
          </div>
        </div>
        <h6 className='font-size-12 text-gray mb-3 text-end'>
          {getSubscriptionPricingModels(
            item.isSingleCharge,
            item.pricingUnit,
            item.pricingModel,
            item.defaultBillingPeriod
          )}
        </h6>
      </div>
    </div>
  );

  return (
    <div>
      <div className='px-4 py-2 mb-5' style={{ backgroundColor: type === 'primary' ? '#1a938a26' : '#D2A15326' }}>
        <h5
          className='mb-0'
          style={{ fontSize: '14px', fontWeight: 'bold', color: type === 'primary' ? '#1A938A' : '#D2A153' }}
        >
          {title}
        </h5>
      </div>
      <PHRList items={items} renderItem={renderItem} className='list-unstyled' />
    </div>
  );
};

export default SubscriptionAddOnsList;
