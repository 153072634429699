import React from 'react';

import cx from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import CSSTransition from 'react-transition-group/CSSTransition';

import styles from './NotesTableRow.module.scss';
import EditMenu from './EditMenu/EditMenu';
import fadeAnimation from 'components/animations/fadeAnimation.module.scss';
import OutsideClickHandler from 'components/OutsideClickHandler';
import TableDataCell from 'components/table/TableDataCell';
import Tooltip from 'components/Tooltip';
import IconButton from 'components/IconButton';
import { useVisibility } from 'Shared/useVisibility';
import DeleteNoteModal from '../modals/DeleteNoteModal';

const NotesTableRow = ({ name, description, createdAt, id, onEdit }) => {
  const { isOpen: isShowContent, toggle: toggleShow } = useVisibility();
  const { isOpen: isConfirmationOpen, open: handleConfirmationOpen, close: handleCloseConfirmation } = useVisibility();
  const { isOpen: isMenuOpen, open: handleMenuOpen, close: handleCloseMenu } = useVisibility();

  return (
    <>
      <tr
        className={cx(styles.row, isShowContent ? '' : 't-row border-btm light')}
        onClick={() => {
          toggleShow();
        }}
      >
        <TableDataCell className='ps-3'>
          <>
            {name.length > 15 ? (
              <div className='tooltip-box'>
                {name.substring(0, 15)}...
                <Tooltip label={name} />
              </div>
            ) : (
              name
            )}
          </>
        </TableDataCell>
        <TableDataCell className='d-flex align-items-center'>
          <IconButton icon={'icon-calendar-dates'} className='me-2' isDisabled size='md' />
          {createdAt ? moment(createdAt).format('MMM DD, YYYY') : '- -'}
        </TableDataCell>
        <TableDataCell className=''>
          <>
            <div className='d-flex justify-content-end align-items-center'>
              <div className='position-relative'>
                <div className='w-100 d-flex align-items-center justify-content-end'>
                  <i
                    className={cx(styles.moreBtn, 'icon-more-horizontal')}
                    onClick={() => {
                      handleMenuOpen();
                    }}
                  />
                </div>
                <CSSTransition
                  mountOnEnter
                  unmountOnExit
                  in={isMenuOpen}
                  timeout={200}
                  classNames={{
                    enter: fadeAnimation.enter,
                    enterActive: fadeAnimation.enterActive,
                    exit: fadeAnimation.exit,
                    exitActive: fadeAnimation.exitActive,
                  }}
                >
                  <OutsideClickHandler
                    clickHandler={() => {
                      handleCloseMenu();
                    }}
                  >
                    <EditMenu
                      onEdit={() => {
                        onEdit(id);
                      }}
                      onDelete={() => {
                        handleConfirmationOpen();
                      }}
                    />
                  </OutsideClickHandler>
                </CSSTransition>
              </div>
            </div>
          </>
        </TableDataCell>
      </tr>
      <CSSTransition
        mountOnEnter
        unmountOnExit
        in={isShowContent}
        timeout={200}
        classNames={{
          enter: fadeAnimation.enter,
          enterActive: fadeAnimation.enterActive,
          exit: fadeAnimation.exit,
          exitActive: fadeAnimation.exitActive,
        }}
      >
        <tr
          className={cx(styles.row, 't-row border-btm light')}
          onClick={() => {
            toggleShow();
          }}
        >
          <td colSpan={12} className={cx(styles.data, 'ps-2')}>
            <div className='d-flex gap-1'>
              <IconButton icon='icon-chevron-right' isDisabled color={'#93A5B1'} size='md' />
              <div className='my-1' style={{ whiteSpace: 'pre-line' }}>
                {description || 'No description to show'}
              </div>
            </div>
          </td>
        </tr>
      </CSSTransition>
      <DeleteNoteModal
        name={name}
        id={id}
        handleCloseConfirmation={handleCloseConfirmation}
        isConfirmationOpen={isConfirmationOpen}
      />
    </>
  );
};

export default NotesTableRow;
