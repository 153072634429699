import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import Personas from 'components/Personas/Personas';

import styles from './Options.module.scss';

const value = cx(styles.value, 'flex-grow-2');
const name = cx(styles.name, 'mb-0');

const Options = (props) => {
  const { data } = props;
  
  return (
    <div className='d-flex align-items-center'>
      {data.user && <Personas name={data.user.fullName} size={30} />}
      <span className={value}>
        <p className={name}>{data.label}</p>
      </span>
    </div>
  );
};

Options.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Options;
