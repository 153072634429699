const modalStyle = {
  overlay: {
    backgroundColor: 'rgba(4, 51, 68, 0.4)',
    zIndex: '1060',
    backdropFilter: 'blur(2px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    inset: 'initial',
    width: '720px',
    height: 'auto',
    maxHeight: 'calc(100vh - 4rem)',
    transition: 'all 0.5s ease-in-out',
    position: 'relative',
    padding: '24px 32px',
    border: 'none',
    borderRadius: '8px',
    boxShadow: `0 8px 10px 1px rgba(93,114,140,0.14),0 3px 14px 2px
        rgba(93,114,140,0.12),0 5px 5px -3px rgba(93,114,140,0.20)`,
  },
};

export default modalStyle;
