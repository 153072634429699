import React from 'react';

import cx from 'classnames';
import { useFormik } from 'formik';
import * as yup from 'yup';

import SelectInput from 'components/forms/SelectInput/SelectInput';
import Textarea from 'components/forms/Textarea/Textarea';
import TextInput from 'components/forms/TextInput/TextInput';
import RoundedButton from 'components/RoundedButton/RoundedButton';

import { periodOptions } from '../../constants';
import { useImplementationTasksList } from '../../hooks/useTemplates';

const validationSchema = yup.object({
  name: yup.string().required('Name is required'),
  nameLocal: yup.string(),
  periodAmount: yup
    .number()
    .typeError('Amount must be a number')
    .positive('Amount must be positive')
    .integer('Amount must be an integer')
    .required('Amount is required'),
  periodUnit: yup.string().required('Period is required'),
  description: yup.string().required('Description is required'),
});

const AddTaskForm = ({ onSubmit, isLoading, initialValues }) => {
  const { data: tasks } = useImplementationTasksList();
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      const submissionValues = {
        name: values.name,
        nameLocal: values.nameLocal,
        periodAmount: parseInt(values.periodAmount, 10) || 0,
        periodUnit: values.periodUnit,
        description: values.description,
        order: tasks.data.items.length + 1,
      };
      onSubmit(submissionValues).finally(() => setSubmitting(false));
    },
  });

  return (
    <form noValidate onSubmit={formik.handleSubmit}>
      <div className='d-flex flex-column pt-5'>
        <TextInput
          name='name'
          suffix={'en'}
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.name && formik.errors.name}
          label='name'
          isRequired
        />

        <TextInput
          name='nameLocal'
          suffix={'ar'}
          value={formik.values.nameLocal}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.nameLocal && formik.errors.nameLocal}
          direction='rtl'
          isRequired
        />
      </div>
      <div>
        <div className='d-flex justify-content-between'>
          <div className='col-6 pe-2'>
            <TextInput
              label={'amount'}
              name={'periodAmount'}
              value={formik.values.periodAmount}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.periodAmount && formik.errors.periodAmount}
            />
          </div>
          <div className='col-6 ps-2 '>
            <SelectInput
              label={'period'}
              name={'periodUnit'}
              placeholder={'Select'}
              options={periodOptions}
              value={formik.values.periodUnit}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isCustom
              error={formik.touched.periodUnit && formik.errors.periodUnit}
            />
          </div>
        </div>
      </div>
      <Textarea
        customClass={'mb-4 mt-2'}
        label={'Description'}
        name={'description'}
        placeholder={'Type your message here.'}
        rows={'6'}
        value={formik.values.description}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.description && formik.errors.description}
      />
      <div className={cx('d-flex', 'justify-content-end', 'py-4', 'pe-4', 'border-t')}>
        <RoundedButton loading={isLoading} type='submit' text='Save' btnStyle='contained' color='primary' size='md' />
      </div>
    </form>
  );
};

export default AddTaskForm;
