import { useState, useEffect } from 'react';

const initialState = {
  resolved: false,
  msg: '',
  callback: undefined,
  isFailed: false,
};

const useResolveHandler = (delay) => {
  const [responseStatus, setResponseStatus] = useState(initialState);

  useEffect(() => {
    if (responseStatus.resolved) {
      let sec = delay || 2000;
      if (!delay && responseStatus.isFailed) {
        sec = 5000;
      }
      const handler = setTimeout(() => {
        if (responseStatus?.callback) {
          responseStatus.callback();
        }
        setResponseStatus({ ...responseStatus, resolved: false });
      }, sec);
      return () => {
        clearTimeout(handler);
      };
    }
    return () => {};
  }, [responseStatus.resolved]);

  const setStatus = (status, callback, messageResponse, details) => {
    let message = '';
    if (messageResponse) {
      message = messageResponse;
    }

    if (!messageResponse) {
      message = status ? 'Changes saved successfully' : 'Error! Something went wrong!';
    }

    if (details) {
      const pattern = {};

      Object.keys(details).forEach((key) => {
        pattern[key] = details[key];
      });
    }

    setResponseStatus({ resolved: true, msg: message, callback, isFailed: !status });
  };

  return {
    responseStatus,
    setStatus,
  };
};

export default useResolveHandler;
