import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from '../../../components/EditMenu.module.scss';

const link = cx(styles.link, 'd-flex', 'align-items-center', 'px-3', 'py-2');

const UserEditMenu = ({ onEdit, onDeactivate, status, onActivate }) => (
  <ul
    className={cx(styles.editMenu, 'radius-4', 'bg-white', 'py-2', 'px-0')}
    onClick={(e) => {
      e.stopPropagation();
    }}
  >
    <li className={styles.listItem} onClick={onEdit}>
      <div className={link}>
        <i className='icon-pencil-create me-2' />
        Edit
      </div>
    </li>
    {status ? (
      <li className={styles.listItem} onClick={onDeactivate}>
        <div className={cx(link, 'text-danger')}>
          <i className='icon-cross-x me-2 text-danger' />
          Deactivate
        </div>
      </li>
    ) : (
      <li className={styles.listItem} onClick={onActivate}>
        <div className={cx(link, 'text-primary')}>
          <i className='icon-rotate-ccw me-2 text-primary' />
          Activate
        </div>
      </li>
    )}
  </ul>
);

UserEditMenu.propTypes = {
  onEdit: PropTypes.func.isRequired,
};

export default UserEditMenu;
