import React from 'react';
import EmptyPageList from 'components/EmptyPageList/EmptyPageList';
import RoundedButton from 'components/RoundedButton/RoundedButton';
import Filter from './Filter/Filter';
import OutsideClickHandler from 'components/OutsideClickHandler';
import { useVisibility } from 'Shared/useVisibility';

const ImplementationEmptyState = ({ onFilter, templates }) => {
  const {
    isOpen: importTemplateOpened,
    open: handleImportTemplateOpen,
    close: handleHideImportTemplate,
  } = useVisibility();
  return (
    <>
      <div className='h-100'>
        <div style={{ backgroundColor: '#F5F9FA', height: '420px' }}>
          <EmptyPageList
            title='No tasks'
            leadText='Create tasks for implementation by clicking on the 
        “Create Task” button or import a template with “Import Template”.'
          />
          <div className='d-flex justify-content-center align-Items-center py-5 mt-4'>
            <div className='position-relative'>
              <RoundedButton
                className='mx-auto'
                icon='icon-Import'
                text={'Import Template'}
                btnStyle='contained'
                color='primary'
                size='md'
                onClick={() => {
                  handleImportTemplateOpen();
                }}
              />
              {importTemplateOpened && (
                <OutsideClickHandler
                  clickHandler={() => {
                    handleHideImportTemplate();
                  }}
                >
                  <Filter onFilter={onFilter} closeImportTemplate={handleHideImportTemplate} templates={templates} />
                </OutsideClickHandler>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ImplementationEmptyState;
