import React from 'react';

import cx from 'classnames';
import { useNavigate } from 'react-router-dom';

import { PrivateRoutes } from 'Router/routes';

import styles from './Headcount.module.scss';
import IconButton from '../../components/IconButton';

const HeadCountHeader = ({ title }) => {
  const navigate = useNavigate();

  const navigateBack = () => {
    navigate(PrivateRoutes.insights);
  };

  return (
    <div className={cx(styles.header, 'd-flex align-items-center justify-content-between py-3 px-5')}>
      <div className='d-flex align-items-center'>
        <IconButton icon='icon-arrow-left' onClick={navigateBack} />
        <h2 className='ps-2'>{title}</h2>
      </div>
    </div>
  );
};

export default HeadCountHeader;
