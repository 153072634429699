import _ from 'lodash';

import CustomerAddSubscription from './CustomersPage/components/CustomerAddSubscription/CustomerAddSubscription';
import CustomerSetupAccount from './CustomersPage/components/CustomerSetupAccount/CustomerSetupAccount';
import EditCustomerBasicInfo from './CustomersPage/components/EditCustomerBasicInfo/EditCustomerBasicInfo';
import NewCustomerBasicInfo from './CustomersPage/components/NewCustomerBasicInfo/NewCustomerBasicInfo';
import {
  NewCustomerBasicInfoValidationSchema,
  NewCustomerSetupAccountValidationSchema,
  NewCustomerSubscriptionSchema,
} from './validations/NewCustomerValidationSchema';

export const isInsert = (data) => !data || (_.isObject(data) && _.isEmpty(data));

export const shouldLoadFromAPI = (data) => !(isInsert(data) || _.isObject(data));

export const getAccountOptions = (status, accountType) => {
  const accountOptions = [];
  const statusLower = status.toLowerCase();
  const isLiveSite = accountType === 'LIVE_INSTANCE';
  const isDemoOrTrialSite = accountType === 'DEMO' || accountType === 'TRIAL';

  const conditions = {
    canSyncSubscription: isLiveSite && statusLower !== 'failed',
    canAddAdmin: statusLower !== 'failed' && statusLower !== 'archived',
    canCancel: ['active', 'pending_suspension'].includes(statusLower) && isLiveSite,
    canRetry: statusLower === 'failed',
    canActivate: statusLower === 'suspended',
    canSuspend: ['active', 'pending_suspension'].includes(statusLower),
    canArchive: statusLower !== 'archived' && statusLower !== 'failed',
    canExtend: ['active', 'suspended'].includes(statusLower) && isDemoOrTrialSite,
  };

  const actions = [
    {
      condition: conditions.canSyncSubscription,
      action: 'SyncSubscription',
      title: 'Sync Subscription',
      icon: 'icon-refresh-ccw',
    },
    { condition: conditions.canAddAdmin, action: 'AddAdmin', title: 'Add Admin', icon: 'icon-user-add' },
    { condition: conditions.canSuspend, action: 'Suspend', title: 'Suspend', icon: 'icon-user-remove' },
    { condition: conditions.canCancel, action: 'Delete', title: 'Cancel Subscription', icon: 'icon-cross-x' },
    { condition: conditions.canActivate, action: 'Activate', title: 'Unsuspend', icon: 'icon-user-remove' },
    { condition: conditions.canArchive, action: 'Archive', title: 'Archive', icon: 'icon-case' },
    { condition: conditions.canExtend, action: 'ExtendDemo', title: 'Extend Demo', icon: 'icon-calendar-dates' },
    { condition: conditions.canRetry, action: 'Retry', title: 'Retry', icon: 'icon-rotate-ccw' },
  ];

  actions.forEach(({ condition, action, title, icon }) => {
    if (condition) {
      accountOptions.push({ action, title, icon });
    }
  });

  return accountOptions;
};

export const getSubscriptionMenu = (status, isPrimary) =>
  status !== 'ACTIVE'
    ? [
        { action: 'Edit', title: 'Edit', icon: 'icon-pencil-create' },
        { action: 'Activate', title: 'Activate', icon: 'icon-circle-checked-fill', iconColor: 'text-success' },
      ]
    : [
        { action: 'Edit', title: 'Edit', icon: 'icon-pencil-create' },
        { action: 'Delete', title: isPrimary ? 'Cancel' : 'Opt_Out', icon: 'icon-cross-x', iconColor: 'text-danger' },
      ];

export const getCustomerData = (data, wizardIsInsert) => {
  const commonData = {
    companyName: data.companyName,
    firstName: data.firstName,
    lastName: data.lastName,
    workEmail: data.workEmail,
    jobTitle: data.jobTitle,
    phoneNumber: data.phoneNumber,
    department: data.department,
    companySize: '',
  };

  if (wizardIsInsert) {
    return {
      customer: {
        ...commonData,
        country: data.country,
      },
      account: {
        subdomain: data.subdomain,
        accountType: data.accountType,
        subscription:
          data.accountType === 'LIVE_INSTANCE'
            ? {
                plan: 'plg-palmhr-core',
                numberOfUsers: data.numberOfUsers,
                unitPrice: data.unitPrice,
                type: 'SUBSCRIPTION',
                country: data.country,
                periodUnit: data.periodUnit,
                addonSubscriptions: data?.addonSubscriptions?.map((item) => ({
                  ...item,
                  country: data.country,
                  periodUnit: data.periodUnit,
                })),
              }
            : {},
      },
    };
  }
  return {
    ...commonData,
  };
};

export const getAccountType = new Map([
  ['LIVE_INSTANCE', 'Live Site'],
  ['TEST_SITE', 'Test Site'],
  ['DEMO', 'Demo'],
  ['TRIAL', 'Trial'],
]);

const defaultCustomersProperties = {
  firstName: '',
  lastName: '',
  companyName: '',
  country: '',
  workEmail: '',
  phoneNumber: '',
  jobTitle: '',
  department: '',
  subdomain: '',
  accountType: 'LIVE_INSTANCE',
  periodUnit: '',
  numberOfUsers: '',
  plan: '',
  unitPrice: '',
  unitPriceAfter: null,
  discount: '',
  includeFullCompensation: '',
  addonSubscriptions: [],
};

export const initializeAccountValues = (country) => ({
  country: country || '',
  subdomain: '',
  accountType: 'LIVE_INSTANCE',
  periodUnit: '',
  numberOfUsers: '',
  plan: '',
  unitPrice: '',
  unitPriceAfter: null,
  discount: '',
  includeFullCompensation: '',
  addonSubscriptions: [],
});

export const initializeValues = (wizardIsInsert, data) => (wizardIsInsert ? defaultCustomersProperties : data);

export const wizardSteps = (initialValues) => {
  if (initialValues.accountType === 'LIVE_INSTANCE') {
    return [
      {
        id: 1,
        component: NewCustomerBasicInfo,
        validationSchema: NewCustomerBasicInfoValidationSchema,
        isValid: NewCustomerBasicInfoValidationSchema.isValidSync(initialValues),
      },
      {
        id: 2,
        component: CustomerSetupAccount,
        validationSchema: NewCustomerSetupAccountValidationSchema,
        isValid: NewCustomerSetupAccountValidationSchema.isValidSync(initialValues),
      },
      {
        id: 3,
        component: CustomerAddSubscription,
        validationSchema: NewCustomerSubscriptionSchema,
        isValid: NewCustomerSubscriptionSchema.isValidSync(initialValues),
      },
    ];
  }
  return [
    {
      id: 1,
      component: NewCustomerBasicInfo,
      validationSchema: NewCustomerBasicInfoValidationSchema,
      isValid: NewCustomerBasicInfoValidationSchema.isValidSync(initialValues),
    },
    {
      id: 2,
      component: CustomerSetupAccount,
      validationSchema: NewCustomerSetupAccountValidationSchema,
      isValid: NewCustomerSetupAccountValidationSchema.isValidSync(initialValues),
    },
  ];
};

export const newAccountWizardSteps = (initialValues) => {
  if (initialValues.accountType === 'LIVE_INSTANCE') {
    return [
      {
        id: 1,
        component: CustomerSetupAccount,
        validationSchema: NewCustomerSetupAccountValidationSchema,
        isValid: NewCustomerSetupAccountValidationSchema.isValidSync(initialValues),
      },
      {
        id: 2,
        component: CustomerAddSubscription,
        validationSchema: NewCustomerSubscriptionSchema,
        isValid: NewCustomerSubscriptionSchema.isValidSync(initialValues),
      },
    ];
  }
  return [
    {
      id: 1,
      component: CustomerSetupAccount,
      validationSchema: NewCustomerSetupAccountValidationSchema,
      isValid: NewCustomerSetupAccountValidationSchema.isValidSync(initialValues),
    },
  ];
};

export const editWizardSteps = (initialValues) => [
  {
    id: 1,
    component: EditCustomerBasicInfo,
    validationSchema: NewCustomerBasicInfoValidationSchema,
    isValid: NewCustomerBasicInfoValidationSchema.isValidSync(initialValues),
  },
];
