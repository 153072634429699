import React from 'react';

import { PHRCustomRoute } from '@palmhr/palmhr-ui-lib';
import PropTypes from 'prop-types';

const CustomRoute = ({ component: Component, checkCanAccess, ...rest }) => (
  <PHRCustomRoute component={Component} checkCanAccess={checkCanAccess} {...rest} />
);

export default CustomRoute;

CustomRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  checkCanAccess: PropTypes.func,
  hidePage: PropTypes.bool,
};

CustomRoute.defaultProps = {
  checkCanAccess: null,
  hidePage: false,
};
