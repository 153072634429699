import React from 'react';

import { PHRPersonas } from '@palmhr/palmhr-ui-lib';

const Personas = ({ name, avatar, jobTitle, size, className, showDetails, iconStatus, customText }) => (
  <PHRPersonas
    name={name}
    avatar={avatar}
    size={size}
    jobTitle={jobTitle}
    className={className}
    showDetails={showDetails}
    iconStatus={iconStatus}
    customText={customText}
  />
);
export default Personas;
