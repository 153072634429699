import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './SwitchInput.module.scss';

const SwitchInput = ({ name, checked, label, onChange, onBlur, size, isDisabled }) => {
  const onBlurField = () => {
    if (onBlur) {
      onBlur(name);
    }
  };
  return (
    <div
      className={cx(styles.wrapper, { [styles.disabled]: isDisabled }, 'd-flex align-items-end')}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <label className={cx(styles.switch, styles[size], 'mb-0')}>
        <input
          disabled={isDisabled}
          name={name}
          type='checkbox'
          value={checked}
          checked={checked ? 'checked' : ''}
          onChange={(event) => {
            onChange(event.target.checked, event.target.name);
          }}
          onBlur={onBlurField}
        />
        <span data-testid={`${name}-input`} className={cx(styles.slider, styles.round)} />
      </label>
      {label && <p className={cx(styles.label, 'ms-2 m-0', { [styles.checked]: checked })}>{label}</p>}
    </div>
  );
};

SwitchInput.propTypes = {
  checked: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  size: PropTypes.string,
  isDisabled: PropTypes.bool,
};

SwitchInput.defaultProps = {
  checked: false,
  label: '',
  onChange: undefined,
  onBlur: undefined,
  size: 'md',
  isDisabled: false,
};

export default SwitchInput;
