import React, { useState, useEffect } from 'react';

import cx from 'classnames';

import styles from './ClientsCount.module.scss';
import DashboardApi from '../../api/DashboardApi';
import Line from '../../components/skeletons/Line';
import countPercent from '../../utility/countPercent';

const ClientsCount = () => {
  const [data, setData] = useState({});
  const [demo, setDemo] = useState({});
  const [loading, setLoading] = useState(true);

  const style = { height: 228, width: '100%', borderRadius: 8, opacity: 0.7 };

  useEffect(() => {
    setLoading(true);
    DashboardApi.getDemoClients()
      .then((res) => {
        setLoading(false);
        setDemo(res.data);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    DashboardApi.getCustomerStatus()
      .then((res) => {
        setLoading(false);
        setData(res.data);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <div className='col-4'>
        <Line style={style} gradient={styles.gradient} />
      </div>
    );
  }

  return (
    <div className='col-4'>
      <div className={cx(styles.wrapper, 'pt-2 pe-3 ps-3 pb-4 radius-4 h-100')}>
        <div className={cx(styles.date)} />
        <h6 className='title border-btm pb-3 text-gray fw-bold'>Active Clients</h6>
        <div className='d-flex pt-3  align-items-center'>
          <div className='border-r w-50'>
            <div className='d-flex justify-content-center mt-3'>
              <i className={cx(styles.icon, 'icon-user text-warning')} />
            </div>
            <p className={cx(styles.number, 'mt-2 mb-1')}>{demo.active}</p>
            <p className={styles.text}>{`${countPercent(demo.active, demo.active + data.active)}% ${'Demo'}`}</p>
          </div>
          <div className='w-50 position-relative'>
            <div className={styles.vs}>VS</div>
            <div className='d-flex justify-content-center mt-3'>
              <i className={cx(styles.icon, 'icon-user text-success')} />
            </div>
            <p className={cx(styles.number, 'mt-2 mb-1')}>{data.active}</p>
            <p className={cx(styles.text, 'ms-2')}>
              {`${countPercent(data.active, data.active + demo.active)}% ${'Customer'}`}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientsCount;
