import React, { useState } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Link } from 'react-router-dom';

import Personas from 'components/Personas/Personas';
import IconButton from 'components/IconButton';
import Tooltip from 'components/Tooltip/Tooltip';

import AddCustomerModal from '../../../Modal/AddCustomerModal/AddCustomerModal';
import styles from './CustomerCard.module.scss';

const CustomerCard = ({ data }) => {
  const [addCustomerModal, setAddCustomerModal] = useState(false);
  const [firstName, lastName] = _.split(data.representative, ' ', 2);
  const selected = { ...data, workEmail: data.contactEmail, lastName, firstName };

  return (
    <div className={cx(styles.wrapper, 'px-3 pt-3 pb-4 h-100')}>
      <div
        className='d-flex justify-content-between mb-3'
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className='d-flex justify-content-start'>
          {data.actionList.length > 0 && (
            <Tooltip label={'Actions required'}>
              <i className='icon-notification font-size-20' style={{ color: '#ffa150' }} />
            </Tooltip>
          )}
        </div>
        <div className='d-flex justify-content-end'>
          <IconButton
            icon='icon-pencil-create'
            onClick={() => {
              setAddCustomerModal(true);
            }}
            size='sm'
          />
        </div>
      </div>
      <div>
        <Link to={`/customers/${data.id}/accounts`}>
          <>
            <div className={styles.logoWrapper}>
              <div>
                {data?.logo ? (
                  <img src={data?.logo} alt='company-logo' />
                ) : (
                  <Personas name={data?.companyName} size={80} />
                )}
              </div>
            </div>
            <p className={cx(styles.name, 'fw-bold mb-2 text-center mt-3')}>{data?.companyName}</p>
            <p className='text-center text-steal fs-14 mb-1'>{data?.representative}</p>
            <p className='text-center text-steal fs-14 mb-8'>{data?.contactEmail}</p>
            <div className='border-t pt-3 d-flex justify-content-center align-items-center text-gray'>
              <div className='mx-2 d-flex justify-content-center align-items-center'>
                <i className='icon-user-circle me-1' />
                <span>{data?.totalAccounts}</span>
              </div>
              <div className='mx-2 d-flex justify-content-center align-items-center'>
                <i className='icon-loan me-1' />
                <span>{data?.totalSubscriptions}</span>
              </div>
              <div className='mx-2 d-flex justify-content-center align-items-center'>
                <i className='icon-icon-6 me-1' />
                <span>{data?.numberOfUsers}</span>
              </div>
            </div>
          </>
        </Link>
      </div>
      <AddCustomerModal
        isOpen={addCustomerModal}
        data={selected}
        onClose={() => {
          setAddCustomerModal(false);
        }}
      />
    </div>
  );
};

CustomerCard.propTypes = {
  data: PropTypes.object.isRequired,
};

export default CustomerCard;
