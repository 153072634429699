import React, { useState, useMemo } from 'react';

import cx from 'classnames';
import moment from 'moment';
import styles from './ImplementationTableRow.module.scss';
import IconButton from 'components/IconButton';
import OutsideClickHandler from 'components/OutsideClickHandler';
import Tooltip from 'components/Tooltip/Tooltip';
import StatusChip from './StatusMenu/StatusChip';
import AssigneeList from './AssigneesList/AssigneeList';
import { useVisibility } from 'Shared/useVisibility';
import ShowRowContent from './ShowRowContent/ShowRowContent';
import DeleteClientTask from './DeleteClientTask';
import StatusCell from './StatusCell/StatusCell';
import { taskStatusOptions, emailStatusOptions } from '../constants';
import DisplayAssignees from './DisplayAssignees';
import { useQueryGetUsers } from '../hooks/useImplementation';

const ImplementationTableRow = ({
  dragRef,
  innerRef,
  id,
  emailStatus,
  dueDate,
  name,
  isSystem,
  description,
  assignees,
  status,
  isSelected,
  updateTask,
  showDrag,
  handleConfirmationOpen,
  openSave,
}) => {
  const { isOpen: isMouseHovered, open: handleMouseHover, close: handleRemoveHover } = useVisibility();
  const { isOpen: isShowContent, close: handleHideContent, toggle: handleShowContent } = useVisibility();
  const { data: users, isLoading } = useQueryGetUsers();
  const [assigneeOpened, setAssigneeOpened] = useState(false);
  const [selectedAssignee, setSelectedAssignee] = useState([]);
  const handleChange = (label, value) => {
    const updatedTask = { [label]: value };
    updateTask(id, updatedTask);
  };

  const handleAssigneeChange = (assigneeValue) => {
    if (assigneeValue) {
      const assigneesId = assigneeValue.map((option) => option.user?.id || option.id);
      handleChange('assignees', assigneesId);
    }
  };

  const onSelect = (label, value) => {
    handleChange(label, value);
  };

  const handleRowClick = () => {
    if (!isSelected) {
      handleConfirmationOpen();
    }
  };

  return (
    <>
      <tr
        ref={innerRef}
        className={cx(styles.row, isShowContent ? '' : 't-row border-btm light')}
        key={id}
        onClick={handleRowClick}
      >
        <td className={cx(styles.tableData)}>
          <div className='d-flex justify-content-start align-items-center gap-2 ms-3'>
            {showDrag && <i ref={dragRef} className='icon-Reorder' />}
            {name.length > 25 ? (
              <div className='tooltip-box'>
                {name.substring(0, 15)}...
                <Tooltip label={name} portalElementId='tooltip-portal' />
              </div>
            ) : (
              name
            )}
          </div>
        </td>
        <td
          className={cx(styles.tableData, styles.hoverbg, styles.assignees)}
          onMouseEnter={() => handleMouseHover()}
          onMouseLeave={() => handleRemoveHover()}
          onClick={
            isSelected
              ? () => {
                  setAssigneeOpened(true);
                }
              : null
          }
        >
          <div className='d-flex justify-content-between align-items-center px-3'>
            <div className='d-flex justify-content-start align-items-center gap-2'>
              {isSystem ? (
                <StatusChip status={'system'} />
              ) : (
                !isLoading && (
                  <DisplayAssignees
                    setSelectedAssignee={setSelectedAssignee}
                    assignees={assignees}
                    users={users?.data?.items}
                  />
                )
              )}
            </div>
            {isMouseHovered && isSelected && !isSystem && (
              <IconButton
                icon='icon-pencil-create'
                onClick={() => {
                  setAssigneeOpened(true);
                }}
              />
            )}
          </div>
          <div className='position-relative'>
            {assigneeOpened && !isLoading && !isSystem && (
              <OutsideClickHandler
                clickHandler={() => {
                  setAssigneeOpened(false);
                }}
              >
                <AssigneeList
                  assignee={selectedAssignee}
                  users={users?.data?.items}
                  onAssigneeChange={handleAssigneeChange}
                />
              </OutsideClickHandler>
            )}
          </div>
        </td>
        <td className={cx(styles.tableData, 'd-flex align-items-center')}>
          <IconButton icon={'icon-calendar-dates'} isDisabled size='sm' />
          {dueDate ? moment(dueDate).format('MMM DD, YYYY') : '- -'}
        </td>
        <StatusCell
          status={status}
          onSelect={onSelect}
          label={'status'}
          items={taskStatusOptions}
          isSelected={isSelected}
        />
        <StatusCell
          status={emailStatus}
          onSelect={onSelect}
          label={'emailStatus'}
          items={emailStatusOptions}
          isSelected={isSelected}
        />
        <td className={cx(styles.tableData, styles.icons)}>
          <div className='d-flex justify-content-end align-items-center'>
            <div className='position-relative'>
              <div className='w-100 d-flex align-items-center justify-content-end'>
                <i
                  className={cx(styles.moreBtn, isShowContent ? 'icon-chevron-top' : 'icon-chevron-bottom')}
                  onClick={() => {
                    handleShowContent();
                  }}
                />
                <DeleteClientTask id={id} name={name} openSave={openSave} />
              </div>
            </div>
          </div>
        </td>
      </tr>
      <ShowRowContent
        text={description}
        handleHideContent={handleHideContent}
        isShowContent={isShowContent}
        colSpan={6}
        icon='icon-info'
      />
    </>
  );
};

export default ImplementationTableRow;
