import React, { useState } from 'react';

import cx from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import CSSTransition from 'react-transition-group/CSSTransition';

import styles from './CareersTableRow.module.scss';
import EditMenu from './EditMenu';
import CareersApi from '../../api/CareersApi';
import fadeAnimation from '../../components/animations/fadeAnimation.module.scss';
import IconButton from '../../components/IconButton';
import modalStyles from '../../components/modalStyles';
import OutsideClickHandler from '../../components/OutsideClickHandler';
import Tooltip from '../../components/Tooltip';

const editModalStyle = _.cloneDeep(modalStyles);
editModalStyle.content.width = '480px';
editModalStyle.content.padding = '0';
editModalStyle.content.overflow = 'visible';

const CareersTableRow = ({ data, onSelect, onEdit }) => {
  const [editOpened, setEditOpened] = useState(false);
  const name = `${data.firstName} ${data.lastName}`;

  const handleDownload = (file, action = 'download') => {
    CareersApi.filePreview(file.id, action)
      .then((res) => {
        if (action === 'preview') {
          const blob = new Blob([res.data], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          window.open(url);
        } else {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'cv');
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch(() => {});
  };

  return (
    <tr className={cx(styles.wrapper)} onClick={onSelect}>
      <td className={cx(styles.values, 'd-flex align-items-center ps-3 py-3')}>
        <div className='ms-3'>
          <div>
            {name.length > 25 ? (
              <div className='tooltip-box'>
                {name.substring(0, 25)}...
                <Tooltip label={name} />
              </div>
            ) : (
              name
            )}
          </div>
          <div className={cx(styles.mail)}>
            {data.email.length > 30 ? (
              <div className='tooltip-box'>
                {data.email.substring(0, 25)}...
                <Tooltip label={data.email} />
              </div>
            ) : (
              data.email
            )}
          </div>
        </div>
      </td>
      <td className={cx(styles.values, 'py-3 pe-3')}>
        <span>{data.jobTitle}</span>
      </td>
      <td className={cx(styles.values, 'py-3 pe-3')}>
        <span>{data.createdAt ? moment(data.createdAt).format('MMM DD, YYYY') : '- -'}</span>
      </td>
      <td className={cx(styles.values, 'py-3 pe-3')}>
        <div className='d-flex align-items-center justify-content-start'>
          <div className='d-flex align-items-baseline pe-2'>
            {data.cv.name.length > 15 ? (
              <div className='tooltip-box'>
                {data.cv.name.substring(0, 15)}...
                <Tooltip label={data.cv.name} />
              </div>
            ) : (
              data.cv.name
            )}{' '}
          </div>
          <div className={('d-flex', 'align-items-center', 'justify-content-start')} />
          <div className='tooltip-box'>
            <IconButton
              icon='icon-eye'
              onClick={(e) => {
                e.stopPropagation();
                handleDownload(data.cv, 'preview');
              }}
            />
            <Tooltip label='Preview document' />
          </div>
        </div>
      </td>
      <td className={cx(styles.values, 'py-3 pe-3')}>
        <div className='d-flex align-items-center justify-content-between'>
          <div className={cx(styles.status, styles[_.camelCase(data.status)], 'd-flex align-items-baseline')}>
            {data.status}
          </div>
          <div className='position-relative'>
            <div className={('w-100', 'd-flex', 'align-items-center', 'justify-content-end')}>
              <IconButton
                icon='icon-more-vertical'
                onClick={(e) => {
                  e.stopPropagation();
                  setEditOpened(true);
                }}
              />
            </div>
            <CSSTransition
              mountOnEnter
              unmountOnExit
              in={editOpened}
              timeout={200}
              classNames={{
                enter: fadeAnimation.enter,
                enterActive: fadeAnimation.enterActive,
                exit: fadeAnimation.exit,
                exitActive: fadeAnimation.exitActive,
              }}
            >
              <OutsideClickHandler
                clickHandler={() => {
                  setEditOpened(false);
                }}
              >
                <EditMenu
                  announcementId={data.id}
                  onEdit={() => {
                    onEdit(data.id);
                  }}
                />
              </OutsideClickHandler>
            </CSSTransition>
          </div>
        </div>
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      </td>
    </tr>
  );
};

CareersTableRow.propTypes = {
  data: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default CareersTableRow;
