import React, { useEffect, useState } from 'react';

import DraggableList from 'components/DraggableList/DraggableList';
import Checkbox from 'components/forms/Checkbox/Checkbox';

import reorderListItems from '../../../../utility/reorderListItems';
import { useImplementationTasksList } from '../../hooks/useTemplates';

const TasksList = ({ tasks, updateFormTasks }) => {
  const { data: defaultTasks } = useImplementationTasksList();
  const [localTasks, setLocalTasks] = useState([]);
  const [selectedTaskIds, setSelectedTaskIds] = useState([]);

  useEffect(() => {
    if (!defaultTasks || !defaultTasks.data || !defaultTasks.data.items) return;

    const updatedDefaultTasks = defaultTasks.data.items.map((defaultTask) => {
      const isTaskSelected = tasks.some((task) => task.originalTask === defaultTask.id);

      if (isTaskSelected) {
        setSelectedTaskIds((prevSelectedTaskIds) => [...prevSelectedTaskIds, parseInt(defaultTask.id, 10)]);
      }

      return defaultTask;
    });

    const reorderedLocalTasks = reorderListItems(updatedDefaultTasks, tasks);

    setLocalTasks(reorderedLocalTasks);
  }, [defaultTasks, tasks]);

  const moveItem = (dragIndex, hoverIndex) => {
    const updatedTasks = [...localTasks];
    const dragItem = updatedTasks[dragIndex];
    updatedTasks.splice(dragIndex, 1);
    updatedTasks.splice(hoverIndex, 0, dragItem);

    const reorderedTasks = updatedTasks.map((task, index) => ({
      ...task,
      order: index + 1,
    }));

    const selectedTasks = reorderedTasks.filter((task) => selectedTaskIds.includes(task.id));
    const updatedSelectedTasks = selectedTasks.map((task) => ({
      ...task,
      originalTask: task.id,
    }));

    setLocalTasks(reorderedTasks);
    updateFormTasks(updatedSelectedTasks);
  };

  const handleTasksSelection = (taskId) => {
    const updatedSelectedTaskIds = selectedTaskIds.includes(taskId)
      ? selectedTaskIds.filter((id) => id !== taskId)
      : [...selectedTaskIds, taskId];

    setSelectedTaskIds(updatedSelectedTaskIds);
    const selectedTasks = localTasks.filter((task) => updatedSelectedTaskIds.includes(task.id));

    const updatedSelectedTasks = selectedTasks.map((task) => ({
      ...task,
      originalTask: task.id,
    }));
    updateFormTasks(updatedSelectedTasks);
  };

  const renderTaskContent = (task) => (
    <div className='d-flex w-100 align-items-center border-btm pb-3 pt-3'>
      <Checkbox
        ariaLabel={`Task Item ${task.id}`}
        disableAutoScroll
        id={`task-${task.id}`}
        name={`task-item-${task.id}`}
        value={task.name}
        checked={selectedTaskIds.includes(task.id)}
        onChange={() => handleTasksSelection(task.id)}
      />
      <span className='ms-2'>{task.name}</span>
    </div>
  );
  return (
    <DraggableList
      items={localTasks.map((task) => ({ id: task.id, value: renderTaskContent(task) }))}
      onMoveItem={moveItem}
    />
  );
};

export default TasksList;
