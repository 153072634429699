import React, { useState, useEffect } from 'react';

import EmptyState from 'components/EmptyState';
import CheckBox from 'components/forms/Checkbox/Checkbox';
import CardsSkeleton from 'components/skeletons/CardsSkeleton';

import SubscriptionAddOnsList from './SubscriptionAddOnsList';

const CustomerSelectSubscriptionAddOnsInputs = ({ formik, data, isLoading }) => {
  const serviceItems = data.filter((item) => item?.isService && !item?.isPrimary);
  const productItems = data.filter((item) => !item?.isService && !item?.isPrimary);

  const [isAllChecked, setIsAllChecked] = useState(false);

  useEffect(() => {
    const allItems = [...serviceItems, ...productItems];
    const allSelected = allItems.every((item) =>
      formik.values.addonSubscriptions.some((subscription) => subscription.plan === item.identifier)
    );
    setIsAllChecked(allSelected);
  }, [formik.values.addonSubscriptions, serviceItems, productItems]);

  const handleSelectAddon = (checked, component) => {
    const currentComponents = formik.values.addonSubscriptions ?? [];

    if (checked) {
      formik.setFieldValue('addonSubscriptions', [
        ...currentComponents,
        {
          plan: component.identifier,
          type: component.isSingleCharge ? 'ONE_TIME_CHARGE' : 'SUBSCRIPTION',
          numberOfUsers:
            component.pricingModel === 'PER_UNIT' && component.pricingUnit !== 'INTEGRATION'
              ? formik.values.numberOfUsers
              : 1,
          unitPrice: component.defaultUnitPrice,
        },
      ]);
    } else {
      const remainingComponents = currentComponents.filter(
        (formikComponent) => formikComponent?.plan !== component.identifier
      );

      formik.setFieldValue('addonSubscriptions', remainingComponents);
    }
  };

  const checkAllAddons = (checked) => {
    setIsAllChecked(checked);

    if (checked) {
      const allAddons = [...serviceItems, ...productItems].map((item) => ({
        plan: item.identifier,
        type: item.isSingleCharge ? 'ONE_TIME_CHARGE' : 'SUBSCRIPTION',
        numberOfUsers:
          item.pricingModel === 'PER_UNIT' && item.pricingUnit !== 'INTEGRATION' ? formik.values.numberOfUsers : 1,
        unitPrice: item.defaultUnitPrice,
      }));
      formik.setFieldValue('addonSubscriptions', allAddons);
    } else {
      formik.setFieldValue('addonSubscriptions', []);
    }
  };

  return (
    <>
      {isLoading && <CardsSkeleton col={12} height={289} num={1} />}
      {!isLoading && data.length > 0 && (
        <>
          <CheckBox
            value='includeFullCompensation'
            name='includeFullCompensation'
            label='All'
            className='mb-3 ms-2'
            checked={isAllChecked}
            onChange={(checked) => checkAllAddons(checked)}
          />
          <SubscriptionAddOnsList
            type='primary'
            title={'PRODUCT'}
            items={productItems}
            onSelectAddon={handleSelectAddon}
            addonSelected={formik.values.addonSubscriptions.map((addon) => addon.plan)}
          />
          <SubscriptionAddOnsList
            type='secondary'
            title={'SERVICE'}
            items={serviceItems}
            onSelectAddon={handleSelectAddon}
            addonSelected={formik.values.addonSubscriptions.map((addon) => addon.plan)}
          />
        </>
      )}
      {!isLoading && !data.length && (
        <EmptyState brandImage='empty-dolar.png' title='No Addons to show' showBtn={false} />
      )}
    </>
  );
};

export default CustomerSelectSubscriptionAddOnsInputs;
