import React from 'react';

import { PHRSelectInput } from '@palmhr/palmhr-ui-lib';
import PropTypes from 'prop-types';

const SelectInput = ({
  refSelect,
  className,
  name,
  label,
  options,
  autoFocus,
  size,
  isDisabled,
  isRequired,
  styles,
  onChange,
  onBlur,
  onAddNew,
  showAddBtn,
  value,
  isFormik = true,
  onInputChange,
  error,
  hideClear,
  scrollMore,
  placeholder,
  isGroupedOption,
  hideGroupedIcon,
  showError,
  optionUppercase,
  tooltip,
  menuPlacement,
  isShown,
  tooltipPortalElementId,
  direction,
}) => (
  <PHRSelectInput
    refSelect={refSelect}
    className={className}
    name={name}
    label={label}
    options={options}
    autoFocus={autoFocus}
    size={size}
    isDisabled={isDisabled}
    isRequired={isRequired}
    styles={styles}
    onChange={onChange}
    onBlur={onBlur}
    onAddNew={onAddNew}
    showAddBtn={showAddBtn}
    value={value}
    isFormik={isFormik}
    onInputChange={onInputChange}
    error={error}
    hideClear={hideClear}
    scrollMore={scrollMore}
    placeholder={placeholder}
    isGroupedOption={isGroupedOption}
    hideGroupedIcon={hideGroupedIcon}
    showError={showError}
    optionUppercase={optionUppercase}
    tooltip={tooltip}
    menuPlacement={menuPlacement}
    isShown={isShown}
    tooltipPortalElementId={tooltipPortalElementId}
    direction={direction}
  />
);

export default SelectInput;

SelectInput.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onAddNew: PropTypes.func,
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  className: PropTypes.string,
  styles: PropTypes.object,
  showAddBtn: PropTypes.bool,
  isFormik: PropTypes.bool,
  hideClear: PropTypes.bool,
  onInputChange: PropTypes.func,
  placeholder: PropTypes.string,
  isGroupedOption: PropTypes.bool,
  hideGroupedIcon: PropTypes.bool,
  autoFocus: PropTypes.bool,
  showError: PropTypes.bool,
  size: PropTypes.string,
  tooltipPortalElementId: PropTypes.string,
  error: PropTypes.string,
  scrollMore: PropTypes.func,
  tooltip: PropTypes.shape({
    text: PropTypes.string.isRequired,
    icon: PropTypes.string,
    position: PropTypes.string,
  }),
  optionUppercase: PropTypes.bool,
  isShown: PropTypes.bool,
  direction: PropTypes.oneOf(['ltr', 'rtl']),
};

SelectInput.defaultProps = {
  value: '',
  label: '',
  onChange: undefined,
  onBlur: undefined,
  isDisabled: false,
  isRequired: false,
  className: '',
  tooltipPortalElementId: '',
  styles: undefined,
  options: [],
  onAddNew: undefined,
  showAddBtn: false,
  isFormik: true,
  onInputChange: undefined,
  hideClear: false,
  placeholder: '',
  isGroupedOption: false,
  autoFocus: false,
  hideGroupedIcon: false,
  showError: true,
  size: '',
  error: '',
  scrollMore: undefined,
  direction: 'ltr',
  tooltip: undefined,
  optionUppercase: false,
  isShown: false,
};
