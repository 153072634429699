import React, { useState } from 'react';

import _ from 'lodash';
import PropTypes from 'prop-types';

import IconButton from 'components/IconButton';
import CountryFlag from 'components/CountryFlag/CountryFlag';
import CustomerInfo from './CustomerInfo';

import styles from './Header.module.scss';
import AddCustomerModal from '../../Modal/AddCustomerModal/AddCustomerModal';
import Personas from 'components/Personas/Personas';
import { useQueryGetCountries } from 'Customers/hooks/useCustomers';

const Header = ({ details }) => {
  const [addCustomerModal, setAddCustomerModal] = useState(false);
  const { data } = useQueryGetCountries();
  const countryDetails = data?.data?.items?.find((item) => item.alphaThreeCode === details.country) || [];
  const [firstName, lastName] = _.split(details?.representative, ' ', 2);
  const selected = { ...details, workEmail: details?.contactEmail, lastName, firstName };

  return (
    <div className='bg-light radius-8 p-3 my-4'>
      <div className='row'>
        <div className='col-6'>
          <div className='d-flex align-items-start ms-2 mt-2'>
            <div className={styles.logoBox}>
              <div className={styles.imgHolder}>
                {details?.logo ? (
                  <img src={details?.logo} alt='company-logo' />
                ) : (
                  <Personas name={details?.companyName} size={80} />
                )}
              </div>
              {countryDetails && (
                <div className={styles.flagBox}>
                  <div className='w-100 h-100 d-flex justify-content-center align-items-center'>
                    <CountryFlag data={countryDetails} />
                  </div>
                </div>
              )}
            </div>

            <div className='ms-4'>
              <div className='mb-4'>
                <p className={styles.name}>{details?.companyName}</p>
                <p className='label p-0'>COMPANY</p>
              </div>

              {details.actionList && details.actionList.length !== 0 && (
                <div className='d-flex align-items-center mb-2 gap-2'>
                  <i className='icon-notification font-size-20' style={{ color: '#ffa150' }} />
                  <p className='fs-14'> {details?.actionList.length} action required</p>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className='col-5 mt-2'>
          <CustomerInfo icon='icon-user-circle' type={'Representative Name'} name={details?.representative} />
          <CustomerInfo icon='icon-mail' type={'Work email'} name={details?.contactEmail || '- -'} />
          <CustomerInfo icon='icon-call-phone' type={'Phone'} name={details?.phoneNumber || '- -'} />
          <CustomerInfo icon='icon-people' type={'Job Title'} name={details?.jobTitle || '- -'} />
          <CustomerInfo icon='icon-users' type={'Department'} name={details?.department || '- -'} />
        </div>

        <div className='col-1'>
          <div className='d-flex justify-content-end'>
            <IconButton
              icon='icon-pencil-create'
              size='sm'
              color='gray'
              onClick={() => {
                setAddCustomerModal(true);
              }}
            />
          </div>
        </div>
      </div>
      <AddCustomerModal
        isOpen={addCustomerModal}
        data={selected}
        onClose={() => {
          setAddCustomerModal(false);
        }}
      />
    </div>
  );
};
Header.propTypes = {
  details: PropTypes.object.isRequired,
};

export default Header;
