import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';

import Personas from 'components/Personas/Personas';
import IconButton from 'components/IconButton';
import Tooltip from 'components/Tooltip/Tooltip';
import AddCustomerModal from '../../../Modal/AddCustomerModal/AddCustomerModal';

const CustomersTableRow = ({
  id,
  totalAccounts,
  totalSubscriptions,
  numberOfUsers,
  companyName,
  representative,
  contactEmail,
  phoneNumber,
  actionList,
  country,
  department,
  jobTitle,
}) => {
  const navigate = useNavigate();
  const [addCustomerModal, setAddCustomerModal] = useState(false);
  const [firstName, lastName] = _.split(representative, ' ', 2);
  const data = {
    id,
    companyName,
    workEmail: contactEmail,
    lastName,
    firstName,
    phoneNumber,
    country,
    department,
    jobTitle,
  };
  const onGoToAccounts = () => {
    navigate(`/customers/${id}/accounts`);
  };

  return (
    <>
      <tr onClick={onGoToAccounts}>
        <td className='d-flex align-items-center py-3'>
          <div className='ms-3 d-flex gap-2a align-items-center'>
            <Personas name={companyName} />
            <p>{companyName}</p>
            {actionList.length > 0 && (
              <Tooltip label={'Actions required'}>
                <i className='icon-notification font-size-16 ' style={{ color: '#ffa150' }} />
              </Tooltip>
            )}
          </div>
        </td>
        <td>
          <div className='d-inline'>
            <p>{representative}</p>
            <p className='text-gray-500 font-size-12'>{contactEmail}</p>
          </div>
        </td>
        <td>
          <p>{totalAccounts}</p>
        </td>
        <td>
          <p>{totalSubscriptions}</p>
        </td>
        <td>
          <p>{numberOfUsers}</p>
        </td>
        <td>
          <IconButton
            icon='icon-pencil-create'
            onClick={() => {
              setAddCustomerModal(true);
            }}
            size='sm'
          />
        </td>
      </tr>
      <AddCustomerModal
        isOpen={addCustomerModal}
        data={data}
        onClose={() => {
          setAddCustomerModal(false);
        }}
      />
    </>
  );
};

export default CustomersTableRow;
