import React, { useContext, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';

import Modal from 'components/Modal/Modal';
import SaveTemplateForm from '../forms/SaveTemplateForm';
import { useSaveNewAsTemplate, queryKeys } from '../hooks/useImplementation';
import ClientContext from '../../ClientContext';
import SuccessScreen from 'components/SuccessScreen/SuccessScreen';

const SaveTemplateModal = ({ isOpen, onClose }) => {
  const { mutate, isLoading } = useSaveNewAsTemplate();
  const [showSuccess, setShowSuccess] = useState(false);
  const queryClient = useQueryClient();
  const { selected } = useContext(ClientContext);

  const onHandleSubmit = (templateData) => {
    mutate(
      { client: selected.id, data: templateData },
      {
        onSuccess: async () => {
          onClose();
          setShowSuccess(true);
        },
      }
    );
  }

  const closeSuccess = async () => {
    await queryClient.refetchQueries(queryKeys.clientTasks({ currentPage: 1, perPage: 10000 }), {
      exact: true,
    });
    setShowSuccess(false);
  }
  return (
    <>
      <Modal
        isOpen={isOpen}
        showHeader
        shouldCloseOnOverlayClick
        contentLabel={'Save Template'}
        title={'Save Template'}
        ariaHideApp={false}
        styles={{ width: '560px', padding: '0' }}
        onRequestClose={() => {
          onClose();
        }}
      >
        <SaveTemplateForm onSubmit={onHandleSubmit} isLoading={isLoading} />
      </Modal>
      <Modal
        isOpen={showSuccess}
        showHeader
        ariaHideApp={false}
        styles={{ width: '450px', minHeight: '400px', padding: '0' }}
        onRequestClose={() => {
          closeSuccess();
        }}
      >
        <SuccessScreen
          title={'Success!'}
          text={'New Template has been saved successfully!'}
          onClose={() => {
            closeSuccess();
          }}
          btnColor={'success'}
          textColor={'dark'}
        />
      </Modal>
    </>
  );
};

export default SaveTemplateModal;
