import React from 'react';

import AnnouncementTableRow from './AnnouncementTableRow';
import DataTable from 'components/DataTable/DataTable';
import EmptyState from 'components/EmptyState/EmptyState';

const headerCells = [
  { title: 'title' },
  { title: 'video' },
  { title: 'release date' },
  { title: 'period' },
  { title: 'visibility', colSpan: 2 },
];

const AnnouncementTable = ({
  announcements,
  onPagination,
  isLoading,
  isPagination,
  pagination,
  onPublish,
  onDelete,
  onPreview,
  onEdit,
}) => (
  <DataTable
    onPaginationAction={onPagination}
    pagination={pagination}
    isPagination={isPagination}
    isScroll
    paginationDivCss='mt-auto'
    data={announcements}
    isLoading={isLoading}
    columns={headerCells}
    TableRowComponent={AnnouncementTableRow}
    rowAdditionalProps={{ onPublish, onDelete, onPreview, onEdit }}
    EmptySearchComponent={() => <EmptyState message={'Nothing to show'} />}
  />
);

export default AnnouncementTable;
