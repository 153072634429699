import React, { useRef } from 'react';

import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Scrollbars from 'react-custom-scrollbars';

import IconButton from 'components/IconButton';
import RoundedButton from 'components/RoundedButton/RoundedButton';
import ContentHeader from 'components/ContentHeader/ContentHeader';

import FilterMenuGroup from './FilterMenuGroup';
import styles from './SearchFilterMenu.module.scss';

const SearchFilterMenu = ({ filterMapArray, selectedFilter, onApplyFilter, onSelectField, onClose }) => {
  const filterRef = useRef();

  const onCheckboxClick = (val, field, id, groupTitle, label) => {
    onSelectField(val, field, id, groupTitle, label);
  };

  const onClickApply = () => {
    onApplyFilter(selectedFilter, true);
  };

  const onClickClearAll = () => {
    onApplyFilter([], true);
  };

  return (
    <div className={cx(styles.wrapper, 'd-flex flex-column justify-content-between')} ref={filterRef}>
      <ContentHeader className='mx-5'>
        <div className='d-flex justify-content-between'>
          <h5>Filters</h5>
          <IconButton className={styles.closeButton} icon='icon-cross-x' onClick={onClose} size='lg' color='gray' />
        </div>
      </ContentHeader>

      <Scrollbars
        className='scrollbarsWrapper'
        autoHide
        autoHideTimeout={1000}
        autoHideDuration={200}
        style={{ flex: 1, height: 'auto' }}
      >
        <div className='px-5 py-4'>
          {_.sortBy(filterMapArray, [(f) => f.title]).map((group) => (
            <div key={group.title}>
              <FilterMenuGroup group={group} onCheckboxClick={onCheckboxClick} />
            </div>
          ))}
        </div>
      </Scrollbars>

      <div className={cx(styles.footer, 'd-flex justify-content-end align-items-center border-t')}>
        <RoundedButton
          className='me-3'
          type='button'
          text='Clear all'
          btnStyle='text'
          color='primary'
          size='md'
          onClick={onClickClearAll}
        />
        <RoundedButton
          type='button'
          text='Apply filters'
          btnStyle='contained'
          color='primary'
          size='md'
          onClick={onClickApply}
        />
      </div>
    </div>
  );
};

SearchFilterMenu.propTypes = {
  filterMapArray: PropTypes.array.isRequired,
  selectedFilter: PropTypes.array.isRequired,
  onApplyFilter: PropTypes.func.isRequired,
  onSelectField: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SearchFilterMenu;
