import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

const Line = ({ style, gradient, className }) => <div className={cx(gradient, className)} style={{ ...style }} />;

Line.propTypes = {
  gradient: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
};

Line.defaultProps = {
  className: '',
  style: {
    height: 8,
    width: '100%',
    borderRadius: 2,
    opacity: 0.7,
  },
};

export default Line;
