import React from 'react';
import RoundedButton from 'components/RoundedButton/RoundedButton';
import SelectField from 'components/SelectField';
import TextInput from 'components/forms/TextInput/TextInput';
import { periodUnitOptions, PACKAGE_NAMES } from 'Customers/constants';
import CardsSkeleton from 'components/skeletons/CardsSkeleton';

const CustomerSubscriptionDetailsInputs = ({ formik, isLoading, data }) => {
  const primaryPlan = data.find((item) => item?.isPrimary);

  const handleDiscount = () => {
    const discountDecimal = formik.values.discount / 100;
    const discountPrice = formik.values.unitPrice - formik.values.unitPrice * discountDecimal;
    formik.setFieldValue('unitPrice', discountPrice);
    formik.setFieldValue('unitPriceAfter', discountPrice);
  };
  return (
    <>
      {isLoading && <CardsSkeleton col={12} height={289} num={1} />}
      {!isLoading && (
        <div className='row'>
          <div className='col-md-6 mb-3'>
            <TextInput
              name='plan'
              label='package'
              className='font-size-16'
              value={PACKAGE_NAMES[primaryPlan?.name] || primaryPlan?.name}
              readOnly
            />
          </div>
          <div className='col-md-6 mb-3'>
            <SelectField
              isCustom
              label='Period'
              name='periodUnit'
              placeholder='Select'
              options={periodUnitOptions}
              value={formik.values.periodUnit ?? ''}
              onChange={formik.handleChange}
              onBlur={formik.onBlur}
              error={formik.touched.periodUnit && formik.errors.periodUnit}
              useTranslate
              isRequired
            />
          </div>
          <div className='col-md-6 mb-3'>
            <TextInput
              name='numberOfUsers'
              label='number of users'
              type='number'
              value={formik.values.numberOfUsers}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isRequired
              error={formik.touched.numberOfUsers && formik.errors.numberOfUsers}
            />
          </div>
          <div className='col-md-6 mb-3'>
            <TextInput
              name='unitPrice'
              label='unit Price'
              type='number'
              value={formik.values.unitPrice}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isRequired
              suffix={primaryPlan?.currency}
              error={formik.touched.unitPrice && formik.errors.unitPrice}
            />
          </div>
          <div className='col-md-6 mb-3'>
            <div className='d-flex justify-content-start align-items-center gap-4'>
              <TextInput
                name='discount'
                label='discount'
                type='number'
                value={formik.values.discount}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.discount && formik.errors.discount}
                isDisabled={formik.values.unitPrice === formik.values.unitPriceAfter}
                suffix={'%'}
              />
              {formik.values.unitPrice === formik.values.unitPriceAfter ? (
                <p className='text-primary ms-5 d-flex align-items-center'>
                  <i className='icon-check-mark me-1' />
                  <span>Applied</span>
                </p>
              ) : (
                <RoundedButton text={'Apply'} btnStyle='contained' color='primary' size='sm' onClick={handleDiscount} />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CustomerSubscriptionDetailsInputs;
