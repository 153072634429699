import React from 'react';

import PropTypes from 'prop-types';

import styles from './TableHead.module.scss';
import TableHeadCell from './TableHeadCell';

const TableHead = ({ onSort, headerCells, className }) => (
  <thead className={className}>
    <tr className={styles.tr}>
      {headerCells.map((cell) => (
        <TableHeadCell
          key={cell.title}
          title={cell.title}
          textRight={cell.textRight}
          onSort={onSort}
          sortable={cell.sortable}
          colSpan={cell.colSpan}
          sortValue={cell.sortValue}
        />
      ))}
    </tr>
  </thead>
);

TableHead.propTypes = {
  headerCells: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSort: PropTypes.func,
  className: PropTypes.string,
};

TableHead.defaultProps = {
  onSort: undefined,
  className: '',
};

export default TableHead;
