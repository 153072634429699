import React from 'react';

import { PHRRoundedButton } from '@palmhr/palmhr-ui-lib';
import PropTypes from 'prop-types';

const RoundedButton = ({
  id,
  icon,
  innerRef,
  iconPosition,
  onClick,
  isDisabled,
  className,
  name,
  color,
  text,
  renderCustomContent,
  type,
  href,
  loading,
  btnStyle,
  size,
  ariaLabel,
}) => (
  <PHRRoundedButton
    id={id}
    icon={icon}
    innerRef={innerRef}
    iconPosition={iconPosition}
    onClick={onClick}
    isDisabled={isDisabled}
    className={className}
    name={name}
    color={color}
    text={text}
    renderCustomContent={renderCustomContent}
    type={type}
    href={href}
    loading={loading}
    btnStyle={btnStyle}
    size={size}
    ariaLabel={ariaLabel}
  />
);

RoundedButton.propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['button', 'submit', 'link', 'label']),
  icon: PropTypes.string,
  iconPosition: PropTypes.string,
  onClick: PropTypes.func,
  renderCustomContent: PropTypes.func,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  color: PropTypes.oneOf([
    'white',
    'primary',
    'gray',
    'secondary',
    'dark',
    'warning',
    'danger',
    'info',
    'success',
    'green',
    'red',
  ]),
  href: PropTypes.string,
  loading: PropTypes.bool,
  btnStyle: PropTypes.oneOf(['contained', 'outlined', 'text']),
  size: PropTypes.oneOf(['lg', 'sm', 'md']),
};

RoundedButton.defaultProps = {
  onClick: undefined,
  icon: '',
  iconPosition: 'left',
  isDisabled: false,
  color: 'gray',
  className: '',
  type: 'button',
  href: '',
  loading: false,
  renderCustomContent: undefined,
  btnStyle: 'contained',
  size: 'md',
};

export default RoundedButton;
