import React from 'react';
import { Navigate, Routes, Route, useResolvedPath } from 'react-router-dom';

import ContentHeader from 'components/ContentHeader/ContentHeader';
import NavTab from 'components/NavTab/NavTab';
import Panel from 'components/Panel/Panel';
import CustomRoute from 'components/Navigation/CustomRoute/CustomRoute';
import Templates from 'Implementation/Templates/Templates';
import Overview from './Overview/Overview';

const Implementation = () => {
  const path = useResolvedPath('').pathname;
  return (
    <div className='m-3'>
      <div className='panel p-0' style={{ height: '100vh' }}>
        <Panel
          className={'h-100 position-relative'}
          id={'implementation-panel'}
          title={
            <div className='row px-5 py-4 border-btm'>
              <div className='d-flex justify-content-between flex-column col-4'>
                <h2>Implementation</h2>
                <p className='tab-subtitle'>List of all implementations and their details</p>
              </div>
              <div className='d-flex align-items-center justify-content-center col-4'>
                <NavTab text='overview' href={`${path}/overview`} type='link' />
                <NavTab text='templates' href={`${path}/templates`} type='link' />
              </div>
            </div>
          }
        >
          <Routes>
            <Route path='overview' element={<CustomRoute path={`${path}/overview`} component={Overview} />} />
            <Route path='templates' element={<CustomRoute path={`${path}/templates`} component={Templates} />} />
            <Route path='' element={<Navigate to='overview' replace />} />
          </Routes>
        </Panel>
      </div>
    </div>
  );
};

export default Implementation;
