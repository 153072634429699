import React, { useEffect, useState, useContext } from 'react';

import { Scrollbars } from 'react-custom-scrollbars-2';
import ClientContext from 'Customers/components/detailsModal/ClientContext';
import Search from 'components/Search';

import SubscriptionDetailsTable from './Header/SubscriptionDetailsTable';
import ClientsApi from '../../../../api/ClientsApi';

const LastLoginDetails = () => {
  const { selected } = useContext(ClientContext);
  const [customer, setCustomer] = useState(selected);
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [filteredPeople, setFilteredPeople] = useState([]);

  const getCustomer = (customerId) => {
    ClientsApi.getCustomerInsightDetails(customerId)
      .then((res) => {
        setCustomer(res.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    getCustomer(selected.id);
  }, []);

  useEffect(() => {
    if (searchValue) {
      let state = [...customer.people];
      state = state.filter((person) => {
        const checkName = person.name.toLowerCase().includes(searchValue.toLowerCase());
        return checkName;
      });
      setFilteredPeople(state);
    } else {
      setFilteredPeople(customer.people);
    }
  }, [searchValue, customer]);

  return (
    <Scrollbars>
      <div className='me-5'>
        <div className='d-flex my-5'>
          {customer?.people?.length && (
            <Search
              onChange={(e) => {
                setSearchValue(e.target.value.replace(/^\s+/g, ''));
              }}
              placeholder='Search People'
              value={searchValue}
              onClear={() => {
                setSearchValue('');
              }}
            />
          )}
        </div>
        <SubscriptionDetailsTable customer={filteredPeople} loading={loading} />
      </div>
    </Scrollbars>
  );
};

export default LastLoginDetails;
