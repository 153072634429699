import React, { useState, useEffect } from 'react';

import cx from 'classnames';
import _ from 'lodash';
import { Scrollbars } from 'react-custom-scrollbars-2';
import Modal from 'react-modal';
import { PHRPagination } from '@palmhr/palmhr-ui-lib';

import styles from './Careers.module.scss';
import EditStatus from './components/CareersEditStatus';
import CareersTableRow from './components/CareersTableRow';
import DetailsModal from './components/DetailsModal';
import CareersApi from '../api/CareersApi';
import modalStyle from '../components/modalStyles';
import Search from '../components/Search';
import TableSkeleton from '../components/skeletons/TableSkeleton';
import TableHead from '../components/table/TableHead';

const detailsModalStyle = _.cloneDeep(modalStyle);
detailsModalStyle.content.width = '480px';
detailsModalStyle.content.padding = '0';
detailsModalStyle.content.overflow = 'visible';

const editModalStyle = _.cloneDeep(modalStyle);
editModalStyle.content.width = '480px';
editModalStyle.content.padding = '0';
editModalStyle.content.overflow = 'visible';

const Careers = () => {
  const [careers, setCareers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filteredCareers, setFilteredCareers] = useState([]);
  const [selectedCareers, setSelectedCareers] = useState(undefined);
  const [searchTerm, setSearchTerm] = useState('');
  const [detailsModal, setDetailsModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editStatus, setEditStatus] = useState(undefined);
  const [careersPagination, setCareersPagination] = useState([]);
  const [params, setParams] = useState({
    currentPage: 1,
    perPage: 5,
  });

  const headerCells = [
    { title: 'Applicant', sortable: true, sortValue: 'firstName' },
    { title: 'Job title', sortable: true, sortValue: 'jobTitle' },
    { title: 'Created', sortable: true, sortValue: 'createdAt' },
    { title: 'CV' },
    { title: 'Status', colSpan: 2 },
  ];

  const getCareers = () => {
    setLoading(true);
    CareersApi.getCareers(params)
      .then((res) => {
        setCareers(res.data.items);
        setCareersPagination(res.data.pagination || []);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getCareers();
  }, [params]);

  const selectCareers = (car) => {
    setSelectedCareers(car);
    setDetailsModal(true);
  };

  const onEditStatus = (car) => {
    setEditModal(true);
    setEditStatus(car);
  };

  const changeStatus = (id, data) =>
    CareersApi.editCareersStatus(id, data).then(() => {
      getCareers();
    });

  const updatePaginationParams = ({ currentPage, perPage }) =>
    setParams((prevState) => ({ ...prevState, currentPage, perPage }));

  useEffect(() => {
    if (searchTerm) {
      let state = [...careers];
      state = state.filter((data) => {
        const checkFirstName = data.firstName.toLowerCase().includes(searchTerm.toLowerCase());
        const checkLastName = data.lastName.toLowerCase().includes(searchTerm.toLowerCase());
        const checkJob = data.jobTitle.toLowerCase().includes(searchTerm.toLowerCase());
        return checkJob || checkFirstName || checkLastName;
      });
      setFilteredCareers(state);
    } else {
      setFilteredCareers(careers);
    }
  }, [searchTerm, careers]);

  const onSort = (option, isAsc) => {
    const dir = isAsc ? 'asc' : 'desc';
    let state = [...careers];
    state = _.orderBy(state, [option], [dir]);
    setFilteredCareers(state);
  };

  return (
    <div className='m-3'>
      <div className={cx(styles.wrapper, 'panel clients-page p-0')}>
        <div className='px-5 py-4 border-btm d-flex align-items-center justify-content-between'>
          <div>
            <h2>Careers</h2>
            <p className='tab-subtitle'>List of submited job aplications</p>
          </div>
        </div>
        <div>
          <div className='row px-6'>
            <div className='col-4 d-flex align-items-center position-relative p-0' style={{ height: '55px' }}>
              <Search
                onChange={(e) => {
                  setSearchTerm(e.target.value.replace(/^\s+/g, ''));
                }}
                placeholder='Search job aplication'
                value={searchTerm}
                onClear={() => {
                  setSearchTerm('');
                }}
              />
            </div>
          </div>
          {filteredCareers.length === 0 && !loading ? (
            <div className='pt-8 m-auto '>
              <div className={cx(styles.emptyList, 'm-auto')}>
                <div
                  className={cx(styles.iconHistory, 'd-flex', 'justify-content-center', 'align-items-center', 'm-auto')}
                >
                  <i className={cx(styles.icon, 'icon-housing')} />
                </div>
                <p className='mt-4 text-center text-capitalize'>No submited job aplications</p>
              </div>
            </div>
          ) : (
            <div
              className='d-flex justify-content-start flex-column gap-2 mx-5'
              style={{ height: 'calc(100vh - 180px)' }}
            >
              <Scrollbars autoHide className='scroll-bar h-100'>
                <div className='pb-7 mb-7'>
                  <table className='w-100'>
                    <TableHead headerCells={headerCells} onSort={onSort} />
                    <tbody>
                      {filteredCareers.length > 0 &&
                        !loading &&
                        filteredCareers.map((item) => (
                          <CareersTableRow
                            key={item.id}
                            data={item}
                            onSelect={() => {
                              selectCareers(item);
                              setDetailsModal(true);
                            }}
                            onEdit={() => {
                              onEditStatus(item);
                            }}
                          />
                        ))}
                      {loading && (
                        <tr className='w-100 client-list'>
                          <td colSpan='6'>
                            <TableSkeleton lineHight={45} mb='mb-2' num={5} />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </Scrollbars>
              {careersPagination?.total > 0 && (
                <PHRPagination onPaginationAction={updatePaginationParams} pagination={careersPagination} />
              )}
            </div>
          )}
        </div>
      </div>
      <Modal
        isOpen={detailsModal}
        contentLabel='Applicant details'
        ariaHideApp={false}
        closeTimeoutMS={300}
        style={detailsModalStyle}
        onRequestClose={() => {
          setDetailsModal(false);
        }}
      >
        <DetailsModal
          id={selectedCareers?.id}
          onClose={() => {
            setDetailsModal(false);
          }}
        />
      </Modal>
      <Modal
        isOpen={editModal}
        contentLabel='Edit Status'
        ariaHideApp={false}
        closeTimeoutMS={300}
        style={editModalStyle}
        onRequestClose={() => {
          setEditModal(false);
        }}
      >
        <EditStatus
          editData={{
            status: editStatus?.status,
            id: editStatus?.id,
          }}
          onClose={() => {
            setEditModal(false);
          }}
          onSave={changeStatus}
        />
      </Modal>
    </div>
  );
};

export default Careers;
