export function convertToTitleCase(str) {
  return str
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter
    .join(' ');
}

export function convertToSnakeCase(str) {
  return str.toLowerCase().replace(/\s+/g, '_');
}
