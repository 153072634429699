import React from 'react';

import cx from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';

import styles from './UnbilledChargeItem.module.scss';
import IconButton from '../../../../components/IconButton';
import TableDataCell from '../../../../components/table/TableDataCell';
import formatCurrency from '../../../../utility/formatCurrency';

const UnbilledChargeItem = ({ data, onDelete }) => (
  <tr className={cx(styles.row, 't-row border-btm light')}>
    <TableDataCell className='ps-3'>
      {data.dateFrom ? moment(data.dateFrom).format('MMM DD, YYYY') : '- -'}
    </TableDataCell>
    <TableDataCell>{data.dateTo ? moment(data.dateTo).format('MMM DD, YYYY') : '- -'}</TableDataCell>
    <TableDataCell>{data.description}</TableDataCell>
    <TableDataCell>
      <div>
        <div className='text-gray small'>{data.currency}</div>
        <div>{data.unitAmount ? formatCurrency(data.unitAmount) : '- -'}</div>
      </div>
    </TableDataCell>
    <TableDataCell>{data.quantity}</TableDataCell>
    <TableDataCell>
      <div>
        <div className='text-gray small'>{data.currency}</div>
        <div>{data.amount ? `${formatCurrency(data.amount)}` : '- -'}</div>
      </div>
    </TableDataCell>
    <TableDataCell>
      <div
        className='d-flex justify-content-end'
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        <div className='d-flex'>
          <IconButton icon='icon-trash' onClick={onDelete} />
        </div>
      </div>
    </TableDataCell>
  </tr>
);

UnbilledChargeItem.propTypes = {
  data: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default UnbilledChargeItem;
