const selectStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#1A938A' : 'white',
    '&:hover': {
      backgroundColor: state.isSelected ? '#1A938A' : '#e9edef',
    },
    '&:active': {
      backgroundColor: '#f4f6f7',
    },
    borderTop: '0 !important',
  }),
  control: (provided, state) => ({
    ...provided,
    boxShadow: 'none',
    borderRadius: '50px',
    color: '#93a5b1',
    flexDirection: 'row-reverse',
    backgroundColor: 'white',
    minHeight: '40px',
    left: '15px',
    width: '170px',
    '&:hover': {
      backgroundColor: '#f4f6f7',
    },
    border: state.isFocused ? '1px solid #f4f6f7 !important' : '0 !important',
  }),
  clearIndicator: (provided) => ({
    ...provided,
    position: 'absolute',
    display: 'none',
    right: 0,
  }),
  menu: (provided) => ({
    ...provided,
    display: 'none',
  }),
  menuList: (provided) => ({
    ...provided,
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: state.isDisabled ? '#A7B5BF' : '#043344',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    visibility: 'hidden',
    margin: 0,
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '2px 8px',
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  multiValue: (base, state) => (state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base),
  multiValueLabel: (base, state) => (state.data.isFixed ? { ...base, paddingRight: 6 } : base),
  multiValueRemove: (base, state) => (state.data.isFixed ? { ...base, display: 'none' } : base),
  indicatorsContainer: (base) => ({ ...base, height: '32px' }),
};

export default selectStyles;
