import React, { useState } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './TableHead.module.scss';
import IconButton from '../IconButton';

const TableHeadCell = ({ onSort, title, colSpan, sortable, sortValue }) => {
  const [isAsc, setIsAsc] = useState(false);
  const onClick = () => {
    onSort(sortValue, isAsc);
    setIsAsc(!isAsc);
  };

  return (
    <th className={styles.th} colSpan={colSpan}>
      <div className={cx('d-flex align-items-center')}>
        <div className='d-flex align-items-center'>
          {title}
          {sortable && <IconButton icon='icon-chevron-sort ms-2' onClick={onClick} size='sm' />}
        </div>
      </div>
    </th>
  );
};

TableHeadCell.propTypes = {
  title: PropTypes.string.isRequired,
  onSort: PropTypes.func,
  colSpan: PropTypes.number,
  sortable: PropTypes.bool,
  sortValue: PropTypes.string,
};

TableHeadCell.defaultProps = {
  onSort: undefined,
  colSpan: 1,
  sortable: false,
  sortValue: '',
};

export default TableHeadCell;
