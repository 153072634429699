import React, { useState } from 'react';

import cx from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import Scrollbars from 'react-custom-scrollbars-2';
import ReactPlayer from 'react-player';

import styles from './PreviewModal.module.scss';
import image from '../../assets/img/whats-new.png';
import IconButton from '../../components/IconButton';
import NavTab from '../../components/NavTab';

const PreviewModal = ({ onClose, onPublish, data }) => {
  const [isArabic, setIsArabic] = useState(false);

  return (
    <div className={cx(isArabic && styles.arStyle, 'position-relative')}>
      <div className={cx(styles.header, 'mb-5 position-relative')}>
        {data?.videoUrl ? (
          <>
            <ReactPlayer width='720px' height='240px' url={data.videoUrl} />
          </>
        ) : (
          <img src={image} alt='video-placeholder' />
        )}

        <IconButton className={styles.closeButton} icon='icon-cross-x' onClick={onClose} color='white' />
      </div>

      <div className={styles.subHeader}>
        <div className='row align-items-center'>
          <div className='col-4'>
            <div className={styles.title}>
              <i className='icon-rocket' />

              <span>{isArabic ? data?.titleArabic : data?.title}</span>
            </div>
          </div>

          <div className='col-4'>
            <div className={cx(styles.navigation, 'd-flex align-items-center justify-content-between')}>
              <NavTab
                text='English'
                active={!isArabic}
                onClick={() => {
                  setIsArabic(false);
                }}
              />
              <NavTab
                text='Arabic'
                active={!!isArabic}
                onClick={() => {
                  setIsArabic(true);
                }}
              />
            </div>
          </div>

          <div className='col-4'>
            <div className={styles.releaseDate}>
              <div>{moment(data?.releaseDate).format('MMM DD, YYYY')}</div>

              <p>Release Date</p>
            </div>
          </div>
        </div>
      </div>

      <div className='ps-5'>
        <Scrollbars
          className={cx(styles.arScrollbar, 'scroll-bar')}
          autoHide
          renderView={({ style, ...props }) => <div {...props} style={{ ...style, overflowX: 'hidden' }} />}
          style={{ height: '256px' }}
        >
          <div className='mt-6 pe-5 mb-5'>
            {data?.featureItems && data.featureItems.length > 0 && (
              <div className={styles.infoSection}>
                <i className={cx(styles.icon, 'icon-star')} />

                <h5 className={styles.sectionTitle}>What is New?</h5>
                <ul className={styles.ul}>
                  {data.featureItems.map((el) => (
                    <li key={el.id} className='ps-4 d-flex align-items-start'>
                      <span className={cx(styles.itemText, 'col-10 p-0')}>{isArabic ? el.titleArabic : el.title}</span>
                      {el.videoUrl && (
                        <div className='col-1'>
                          <IconButton
                            icon='icon-video'
                            onClick={() => {
                              window.open(el.videoUrl, '_target');
                            }}
                          />
                        </div>
                      )}
                      {el.documentUrl && (
                        <div className='col-1'>
                          <IconButton
                            icon='icon-google-docs'
                            onClick={() => {
                              window.open(el.documentUrl, '_target');
                            }}
                          />
                        </div>
                      )}
                    </li>
                  ))}

                  {data.featureItems.length === 0 && (
                    <li className={styles.noInfo}>
                      <div className={cx(styles.emptyList)}>
                        <div
                          className={cx(
                            styles.iconHistory,
                            'd-flex',
                            'justify-content-center',
                            'align-items-center',
                            'm-auto'
                          )}
                        >
                          <i className={cx(styles.icon, 'icon-housing')} />
                        </div>
                        <p className='mt-4 text-center text-capitalize'>No Features</p>
                      </div>
                    </li>
                  )}
                </ul>
              </div>
            )}

            {data?.improvementItems && data.improvementItems.length > 0 && (
              <div className={styles.infoSection}>
                <i className={cx(styles.icon, 'icon-list')} />

                <h5 className={styles.sectionTitle}>What is Improved?</h5>
                <ul className={styles.ul}>
                  {data.improvementItems.map((el) => (
                    <li key={el.id} className='ps-4 d-flex align-items-start'>
                      <span className={cx(styles.itemText, 'col-10 p-0')}>{isArabic ? el.titleArabic : el.title}</span>
                      {el.videoUrl && (
                        <div className='col-1'>
                          <IconButton
                            icon='icon-video'
                            onClick={() => {
                              window.open(el.videoUrl, '_target');
                            }}
                          />
                        </div>
                      )}
                      {el.documentUrl && (
                        <div className='col-1'>
                          <IconButton
                            icon='icon-google-docs'
                            onClick={() => {
                              window.open(el.documentUrl, '_target');
                            }}
                          />
                        </div>
                      )}
                    </li>
                  ))}

                  {data.improvementItems.length === 0 && (
                    <li className={styles.noInfo}>
                      <div className={cx(styles.emptyList)}>
                        <div
                          className={cx(
                            styles.iconHistory,
                            'd-flex',
                            'justify-content-center',
                            'align-items-center',
                            'm-auto'
                          )}
                        >
                          <i className={cx(styles.icon, 'icon-housing')} />
                        </div>
                        <p className='mt-4 text-center text-capitalize'>No Improvements</p>
                      </div>
                    </li>
                  )}
                </ul>
              </div>
            )}
          </div>
        </Scrollbars>
      </div>

      <div className='p-5 d-flex justify-content-end border-t'>
        <button type='button' className='btn btn-secondary' onClick={onClose}>
          Cancel
        </button>

        {data?.visibility && data.visibility === 'PRIVATE' && (
          <button
            type='button'
            onClick={() => {
              onPublish(data.id);
            }}
            className={cx(styles.publishBtn, 'btn btn-primary text-white ms-5')}
          >
            Publish
          </button>
        )}
      </div>
    </div>
  );
};

PreviewModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  data: PropTypes.object,
  onPublish: PropTypes.func,
};

PreviewModal.defaultProps = {
  onPublish: undefined,
  data: {},
};

export default PreviewModal;
