import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import OutsideClickHandler from './OutsideClickHandler';
import styles from './SelectMenu.module.scss';

const SelectMenu = ({ onSelect, items, onClose, style, icons, active }) => (
  <OutsideClickHandler clickHandler={onClose}>
    <ul
      className={cx(styles.selectMenu, 'radius-4', 'bg-white', 'py-2')}
      style={style}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {items.map((item, i) => (
        <li
          key={item}
          className={cx(
            styles.listItem,
            { [styles.active]: active === item },
            'd-flex',
            'align-items-baseline',
            'py-2',
            'px-3'
          )}
          onClick={() => {
            onSelect(item);
          }}
        >
          {icons?.length > 0 && <i className={cx(icons[i], 'me-2')} />}
          <span>{item}</span>
        </li>
      ))}
    </ul>
  </OutsideClickHandler>
);

SelectMenu.propTypes = {
  onSelect: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  style: PropTypes.object,
};

SelectMenu.defaultProps = {
  style: undefined,
};

export default SelectMenu;
