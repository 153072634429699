import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import emptyIcon from 'assets/img/empty_list_icon.png';

import styles from './EmptyPageList.module.scss';

const EmptyPageList = ({ icon, title, leadText }) => (
  <div className={cx('m-auto', 'pt-8')}>
    <div className={cx(styles.emptyList, 'm-auto')}>
      <div className={cx(styles.iconWrapper, 'd-flex', 'justify-content-center', 'align-items-center', 'm-auto')}>
        {icon ? <i className={icon} /> : <img src={emptyIcon} alt='empty list icon' />}
      </div>

      <h5 className={cx(styles.title, 'text-center m-0 mt-2')}>{title}</h5>

      <p className={cx(styles.lead, 'text-center mt-2')}>{leadText}</p>
    </div>
  </div>
);

EmptyPageList.propTypes = {
  icon: PropTypes.string,
  leadText: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

EmptyPageList.defaultProps = {
  icon: '',
};

export default EmptyPageList;
