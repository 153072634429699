import React, { useContext } from 'react';

import { useQueryClient } from '@tanstack/react-query';

import Modal from 'components/Modal/Modal';
import AddTaskForm from 'Implementation/Templates/forms/AddTaskForm/AddTaskForm';
import { queryKeys, useAddClientTask } from '../hooks/useImplementation';
import ClientContext from '../../ClientContext';
import ImplementationApi from 'api/ImplementationApi';

const AddTaskModal = ({ isOpen, onClose, tasks, selectedTemplateId, setSelectedTemplateId }) => {
  const { selected } = useContext(ClientContext);
  const queryClient = useQueryClient();

  const { isLoading, mutateAsync: addMutate } = useAddClientTask();

  const initialValues = {
    name: '',
    nameLocal: '',
    periodAmount: null,
    periodUnit: null,
    description: '',
    order: null,
  };

  const handleAdd = async (data) => {
    await addMutate(
      { client: selected.id, data },
      {
        onSuccess: async () => {
          setSelectedTemplateId(null);
          await queryClient.refetchQueries(queryKeys.clientTasks({ currentPage: 1, perPage: 10000 }), {
            exact: true,
          });
          onClose();
        },
      }
    );
  };

  const handleSubmit = async (data) => {
    if (selectedTemplateId !== null && tasks.length === 0) {
      ImplementationApi.createClientTasks({ client: selected.id, data: { template: selectedTemplateId } })
        .then(() => {
          handleAdd(data);
        })
        .catch((error) => {
          console.error('Error creating template:', error);
        });
    } else {
      handleAdd(data);
    }
  };
  return (
    <Modal
      isOpen={isOpen}
      showHeader
      shouldCloseOnOverlayClick
      contentLabel={'Add Task'}
      title={'Add Task'}
      ariaHideApp={false}
      styles={{ width: '560px' }}
      onRequestClose={() => {
        onClose();
      }}
    >
      <AddTaskForm onClose={onClose} onSubmit={handleSubmit} isLoading={isLoading} initialValues={initialValues} />
    </Modal>
  );
};

export default AddTaskModal;
