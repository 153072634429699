import React, { useContext } from 'react';

import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import { useDeleteNote } from '../hooks/useNotes';
import ClientContext from '../../ClientContext';

const DeleteNoteModal = ({ isConfirmationOpen, handleCloseConfirmation, name, id }) => {
  const { mutate } = useDeleteNote();
  const { selected } = useContext(ClientContext);

  const onConfirm = () => {
    mutate(
      { client: selected.id, id },
      {
        onSuccess: () => {
          handleCloseConfirmation();
        },
      }
    );
  };
  return (
    <ConfirmationModal
      isOpen={isConfirmationOpen}
      onAction={onConfirm}
      onClose={() => {
        handleCloseConfirmation();
      }}
      description={`Do you really want to Delete '${name}'? This process can not be undone.`}
      title={'Delete'}
      type='danger'
      actionText='Delete'
    />
  );
};

export default DeleteNoteModal;
