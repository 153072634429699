import { useQueryClient } from '@tanstack/react-query';
import { useQueryHook, useMutationHook } from 'utility/reactQueryHooks';
import ImplementationApi from 'api/ImplementationApi';
import UsersApi from 'api/UsersApi';

export const queryKeys = {
  listTemplates: (params) => ['templates', JSON.stringify(params)],
  listTasks: (params) => ['listTask', JSON.stringify(params)],
  templateById: (id) => ['templateById', id],
  clientTasks: (params) => ['clientTasks', JSON.stringify(params)],
  addClientTasks: () => ['addClientTasks'],
  updateClientTasks: () => ['updateClientTasks'],
  addTaskClient: () => ['addTaskClient'],
  ImplementationTasks: (params) => ['ImplementationTasks', 'list', params],
  updateTemplateFromClient: () => ['updateTemplateFromClient'],
  saveAsTemplate: () => ['saveAsTemplate'],
  users: ['users'],
  deleteClientTask: ['deleteClientTask'],
};

export const useQueryGetUsers = () => useQueryHook(queryKeys.users, () => UsersApi.getUsers());

export const useQueryTemplates = (params) =>
  useQueryHook(queryKeys.listTemplates(params), () =>
    ImplementationApi.getTemplates(params || { currentPage: 1, perPage: 10000 })
  );

export const useQueryTasks = (params) =>
  useQueryHook(queryKeys.listTasks(params), () =>
    ImplementationApi.getTasks(params || { currentPage: 1, perPage: 10000 })
  );

export const useQueryGetTemplate = (id) =>
  useQueryHook(queryKeys.templateById(id), () => ImplementationApi.getTemplateById(id), {
    enabled: id !== null,
  });

export const useQueryClientTasks = (client, params) =>
  useQueryHook(queryKeys.clientTasks(params), () => ImplementationApi.getClientTasks(client, params));

export const useCreateClientTasks = () =>
  useMutationHook(queryKeys.addClientTasks, ImplementationApi.createClientTasks);

export const useUpdateClientTasks = () =>
  useMutationHook(queryKeys.updateClientTasks, ImplementationApi.EditClientTasks);

export const useAddClientTask = () => useMutationHook(queryKeys.addTaskClient, ImplementationApi.addClientTask);

export const useImplementationTasksList = (params) =>
  useQueryHook(queryKeys.ImplementationTasks(params), () =>
    ImplementationApi.listImplementationTemplatesTasks(params || { currentPage: 1, perPage: 10000 })
  );

export const useDeleteClientTask = () => {
  const queryClient = useQueryClient();
  return useMutationHook(queryKeys.deleteClientTask, ImplementationApi.deleteClientTask, {
    onSuccess: async () => {
      await queryClient.refetchQueries(['notes'], { exact: true });
    },
  });
};

export const useUpdateTemplateFromClient = () =>
  useMutationHook(queryKeys.updateTemplateFromClient, ImplementationApi.updateTemplateFromClientTasks);

export const useSaveNewAsTemplate = () =>
  useMutationHook(queryKeys.saveAsTemplate, ImplementationApi.saveAsNewTemplate);
