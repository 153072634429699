const readName = (language, engName, arabName) => {
  let result = '';

  if (language === 'ar' && arabName) {
    result = arabName;
  } else if (engName) {
    result = engName;
  }

  return result;
};

export default readName;
