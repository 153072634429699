import React from 'react';

import PropTypes from 'prop-types';

import Line from './Line';
import styles from './TableSkeleton.module.scss';

const CardsSkeleton = ({ col, height, num, mb }) => {
  const renderItems = () => {
    const items = [];
    for (let index = 0; index < num; index += 1) {
      items.push(
        <div className={`col-${col} px-2a`} key={Math.random()}>
          <Line
            style={{ height, width: '100%' }}
            gradient={styles.gradient}
            className={`radius-8 ${index === num - 1 ? '' : mb}`}
          />
        </div>
      );
    }
    return items;
  };
  return (
    <div>
      <div className='row row-small'>{renderItems()}</div>
    </div>
  );
};

CardsSkeleton.propTypes = {
  mb: PropTypes.string,
};

CardsSkeleton.defaultProps = {
  mb: 'mb-4',
};

export default CardsSkeleton;
