import React, { useState, useRef, useEffect } from 'react';

import { PHRSearchInput } from '@palmhr/palmhr-ui-lib';
import PropTypes from 'prop-types';

const Search = ({
  onChange,
  onClear,
  placeholder,
  className,
  value,
  minimized,
  autofocus,
  direction,
  iconSize,
  iconColor,
  disabled,
  readOnly,
}) => (
  <PHRSearchInput
    onChange={onChange}
    onClear={onClear}
    placeholder={placeholder}
    className={className}
    value={value}
    minimized={minimized}
    autofocus={autofocus}
    direction={direction}
    iconSize={iconSize}
    iconColor={iconColor}
    disabled={disabled}
    readOnly={readOnly}
  />
);

Search.propTypes = {
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.string,
  autofocus: PropTypes.bool,
  iconSize: PropTypes.string,
  iconColor: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
};

Search.defaultProps = {
  placeholder: 'Type text...',
  className: '',
  value: '',
  autofocus: false,
  disabled: false,
  readOnly: false,
  iconSize: 'md',
  iconColor: 'gray',
};

export default Search;
