import React, { useState, useEffect } from 'react';

import cx from 'classnames';
import _ from 'lodash';

import styles from './InvoicesChart.module.scss';
import DashboardApi from '../../api/DashboardApi';
import BarItem from '../../components/BarItem';
import Line from '../../components/skeletons/Line';

function Invoices() {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const style = { height: 512, width: '100%', borderRadius: 8, opacity: 0.7 };

  const colorsMap = {
    paid: '#62D26F',
    posted: '#11BBD1',
    payment_due: '#ffa150',
    not_paid: '#f45147',
    voided: '93a5b1',
    pending: '#FFC43D',
  };

  useEffect(() => {
    setLoading(true);
    DashboardApi.getInvoiceStatus()
      .then((res) => {
        setLoading(false);
        setData(res.data);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <div className='col-6'>
        <Line style={style} gradient={styles.gradient} />
      </div>
    );
  }
  return (
    <div className='col-6'>
      <div className={cx(styles.wrapper, 'h-100')}>
        <h6 className={cx(styles.title, 'border-btm pb-3 text-gray fw-bold')}>Invoices</h6>
        <div className='mt-6'>
          {Object.keys(data).map((title) => {
            if (title !== 'total') {
              return (
                <BarItem
                  key={title}
                  title={_.startCase(title)}
                  value={data[title]}
                  percent={(data[title] / data.total) * 100}
                  color={colorsMap[title]}
                />
              );
            }
            return null;
          })}
        </div>
      </div>
    </div>
  );
}

export default Invoices;
