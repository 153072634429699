import React from 'react';
import cx from 'classnames';
import { useFormik } from 'formik';
import * as yup from 'yup';
import moment from 'moment';

import RoundedButton from 'components/RoundedButton/RoundedButton';
import DateInput from 'components/forms/DateInput';

const ExtenDemoForm = ({ onClose, onSubmit, isLoading, initialValues, trialEnd }) => {
  const formik = useFormik({
    initialValues,
    validationSchema: yup.object({
      expireDate: yup.string().required('Period is required'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      const dateWithTime = moment(values.expireDate).set({
        hour: moment().hour(),
        minute: moment().minute(),
        second: moment().second(),
        millisecond: moment().millisecond(),
      });
      const submissionValues = {
        expireDate: dateWithTime,
      };
      onSubmit(submissionValues).finally(() => setSubmitting(false));
    },
  });

  return (
    <form noValidate onSubmit={formik.handleSubmit}>
      <div className='m-5'>
        <h6 className='font-weight-bold'>Select an expire Date</h6>
        <div className='row' style={{ minHeight: '250px' }}>
          <div className='col-12'>
            <DateInput
              label='Expire Date'
              name='expireDate'
              min={trialEnd || new Date()}
              isRequired
              value={formik.values.expireDate}
              change={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.expireDate && formik.errors.expireDate}
            />
          </div>
        </div>
      </div>
      <div className={cx('d-flex', 'justify-content-end gap-2', 'p-3', 'border-t')}>
        <RoundedButton text='cancel' btnStyle='contained' color='white' size='md' onClick={onClose} />
        <RoundedButton loading={isLoading} type='submit' text='Save' btnStyle='contained' color='primary' size='md' />
      </div>
    </form>
  );
};

export default ExtenDemoForm;
