import React from 'react';

import DataTable from 'components/DataTable/DataTable';
import OverviewTableRow from '../OverviewTableRow/OverviewTableRow';
import EmptyState from 'components/EmptyState';

const headerCells = [
  { title: 'Customer Name', sortable: true, sortValue: 'customerName' },
  { title: 'plan', sortable: true, sortValue: 'plan' },
  { title: 'MMR', sortable: true, sortValue: 'mmr' },
  { title: 'Current Implementation Task', sortable: true, sortValue: 'currentTask' },
  { title: 'Task Status', sortable: true, sortValue: 'taskStatus' },
  { title: 'Last Updated', sortable: true, sortValue: 'lastUpdated' },
];

const OverviewTable = ({ data, isLoading, onSort }) => (
  <DataTable
    isScroll
    data={data}
    isLoading={isLoading}
    columns={headerCells}
    headerAdditionalProps={{
      onSort,
    }}
    TableRowComponent={OverviewTableRow}
    EmptySearchComponent={() => <EmptyState brandImage='no_customers.png' title='Nothing to show' showBtn={false} />}
  />
);

export default OverviewTable