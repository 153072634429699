import React from 'react';

import cx from 'classnames';

import styles from './TranslationsTableRow.module.scss';
import TableDataCell from '../components/table/TableDataCell';

const TranslationsTableRow = ({ item, approveTranslation, setShowDeleteModal, setShowEditModal, selectRow }) => (
  <tr key={item.id} className={styles.tr}>
    <TableDataCell>
      <div className='text-truncate'>{item.translationKey}</div>
    </TableDataCell>
    <TableDataCell>{item.english}</TableDataCell>
    <TableDataCell className={cx(styles.arabic, 'pe-0 ps-3 text-right')}>{item.arabic}</TableDataCell>
    <TableDataCell>
      <div className='d-flex justify-content-end'>
        {item.review && (
          <button type='button' title='Approve' onClick={() => approveTranslation(item.id)} className='btn btn-light'>
            <i className='icon-check-mark' />
          </button>
        )}
        <button
          type='button'
          onClick={() => {
            selectRow(item);
            setShowEditModal(true);
          }}
          title='Edit'
          className='btn btn-light ms-1'
        >
          <i className='icon-pencil-create' />
        </button>
        <button
          type='button'
          onClick={() => {
            selectRow(item);
            setShowDeleteModal(true);
          }}
          title='Delete'
          className='btn btn-light ms-1'
        >
          <i className='icon-trash' />
        </button>
      </div>
    </TableDataCell>
  </tr>
);

export default TranslationsTableRow;
