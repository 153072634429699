/* eslint-disable comma-dangle */
import React, { useState } from 'react';

import cx from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import CSSTransition from 'react-transition-group/CSSTransition';

import styles from './AnnouncementTableRow.module.scss';
import EditMenu from './EditMenu';
import fadeAnimation from '../../components/animations/fadeAnimation.module.scss';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import IconButton from '../../components/IconButton';
import OutsideClickHandler from '../../components/OutsideClickHandler';

const AnnouncementTableRow = ({
  id,
  visibility,
  title,
  videoUrl,
  releaseDate,
  startDate,
  endDate,
  onPublish,
  onDelete,
  onEdit,
  onPreview,
}) => {
  const [editOpened, setEditOpened] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [action, setAction] = useState('');
  const data = { id, visibility, title, videoUrl, releaseDate, startDate, endDate };

  const onConfirm = () => {
    setOpenConfirm(false);
    switch (action) {
      case 'publish':
        onPublish(id);
        break;
      default:
        onDelete(id);
        break;
    }
  };

  const onOpenConfirm = (ationType) => {
    setOpenConfirm(true);
    setEditOpened(false);
    setAction(ationType);
  };

  const modalContent = {
    publish: {
      modalName: 'Publish Announcement',
      confirmName: 'Publish',
      confirmText: 'Are you sure you want to publish this announcement?',
    },
    delete: {
      modalName: 'Delete Announcement',
      confirmName: 'Delete',
      confirmText: 'Are you sure you want to delete this announcement?',
    },
  };

  return (
    <tr
      className={cx(styles.wrapper)}
      onClick={() => {
        onPreview(data);
      }}
    >
      <td className='d-flex align-items-center ps-3 py-3'>
        <div className='ms-3'>
          <div>{title}</div>
        </div>
      </td>

      <td className={cx(styles.values, 'pe-3 py-3')}>
        {videoUrl ? (
          <IconButton
            className='me-3'
            icon='icon-video'
            onClick={() => {
              window.open(videoUrl, '_target');
            }}
          />
        ) : (
          '- -'
        )}
      </td>

      <td className={cx(styles.values, 'py-3 pe-3')}>
        {releaseDate ? moment(releaseDate).format('MMM DD, YYYY') : '- -'}
      </td>

      <td className={cx(styles.values, 'py-3 pe-3')}>
        {startDate ? moment(startDate).format('MMM DD, YYYY') : '- -'} {'-'}{' '}
        {endDate ? moment(endDate).format('MMM DD, YYYY') : '- -'}
      </td>

      <td className={cx(styles.values, 'py-3 pe-3')}>
        <span className={cx(styles.status, styles[_.camelCase(visibility)])}>{_.upperCase(visibility)}</span>
      </td>

      <td className={cx(styles.values, 'py-3')}>
        <div className='d-flex justify-content-end align-items-center'>
          <div className='position-relative'>
            <div className='w-100 d-flex align-items-center justify-content-center'>
              <i
                className={cx(styles.moreBtn, 'icon-more-horizontal')}
                onClick={(e) => {
                  e.stopPropagation();
                  setEditOpened(true);
                }}
              />
            </div>
            <CSSTransition
              mountOnEnter
              unmountOnExit
              in={editOpened}
              timeout={200}
              classNames={{
                enter: fadeAnimation.enter,
                enterActive: fadeAnimation.enterActive,
                exit: fadeAnimation.exit,
                exitActive: fadeAnimation.exitActive,
              }}
            >
              <OutsideClickHandler
                clickHandler={() => {
                  setEditOpened(false);
                }}
              >
                <EditMenu
                  isPrivate={visibility === 'PRIVATE'}
                  announcementId={id}
                  onEdit={() => {
                    onEdit(id);
                  }}
                  onPreview={() => {
                    onPreview(data);
                  }}
                  onDelete={() => {
                    onOpenConfirm('delete');
                  }}
                  onPublish={() => {
                    onOpenConfirm('publish');
                  }}
                />
              </OutsideClickHandler>
            </CSSTransition>
          </div>
        </div>
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <ConfirmationModal
            isOpen={openConfirm}
            onAction={onConfirm}
            onClose={() => {
              setOpenConfirm(false);
            }}
            description={modalContent[action]?.confirmText}
            title={modalContent[action]?.modalName}
            type={action === 'delete' ? 'danger' : 'warning'}
            actionText={modalContent[action]?.confirmName}
          />
        </div>
      </td>
    </tr>
  );
};

AnnouncementTableRow.propTypes = {
  onPublish: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onPreview: PropTypes.func.isRequired,
};

export default AnnouncementTableRow;
