import stringUtility from './stringUtility';
import { permissionGroupConstants } from '../constants/permissionGroupConstants';

const permissionGroups = () => Object.values(permissionGroupConstants);

const permissionGroupLabel = (permissionGroup) => stringUtility.capitalize(permissionGroup.replaceAll('_', ' '));

const permissionGroupOptions = () => {
  const options = {};

  permissionGroups().forEach((option) => {
    options[option] = permissionGroupLabel(option);
  });

  return options;
};

export default {
  permissionGroups,
  permissionGroupLabel,
  permissionGroupOptions,
};
