import React, { useContext } from 'react';
import RoundedButton from 'components/RoundedButton/RoundedButton';
import { useUpdateTemplateFromClient, queryKeys } from '../../hooks/useImplementation';
import ClientContext from '../../../ClientContext';
import { useVisibility } from 'Shared/useVisibility';
import { useQueryClient } from '@tanstack/react-query';
import SaveTemplateModal from '../../modals/SaveTemplateModal';

const TemplateActions = () => {
  const { isOpen, open, close } = useVisibility();
  const { selected } = useContext(ClientContext);
  const queryClient = useQueryClient();
  const params = {
    currentPage: 1,
    perPage: 10000,
  };

  const { mutate: mutateUpdateTemplate, isLoading: isLoadingUpdating } = useUpdateTemplateFromClient();

  const handleUpdateTemplate = () => {
    mutateUpdateTemplate(selected.id, {
      onSuccess: async () => {
        await queryClient.invalidateQueries(queryKeys.clientTasks(params), { exact: true });
      },
    });
  };

  return (
    <>
      <div className='d-flex justify-content-end pb-3 gap-2'>
        <RoundedButton
          type='button'
          text={'Update Template'}
          btnStyle='outlined'
          color='primary'
          size='sm'
          loading={isLoadingUpdating}
          onClick={handleUpdateTemplate}
        />
        <RoundedButton
          type='button'
          text={'Save as New'}
          btnStyle='contained'
          color='primary'
          size='sm'
          onClick={open}
        />
      </div>
      <SaveTemplateModal isOpen={isOpen} onClose={close} />
    </>
  );
};

export default TemplateActions;
