import React from 'react';

import { useQueryTasks, useDeleteClientTask } from '../hooks/useImplementation';
import IconButton from 'components/IconButton';
import { useVisibility } from 'Shared/useVisibility';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';

const DeleteClientTask = ({ id, name, openSave }) => {
  const { isOpen: isConfirmationOpen, open: handleConfirmationOpen, close: handleCloseConfirmation } = useVisibility();

  const { data } = useQueryTasks();
  const { mutate, isLoading } = useDeleteClientTask({
    currentPage: 1,
    perPage: 10000,
  });

  const handleDeleteTask = () => {
    mutate(id);
  };
  return (
    <>
      {!data?.data.items.some((task) => task.name === name) && (
        <IconButton
          icon='icon-trash'
          onClick={() => {
            if(!openSave())
            {
              handleConfirmationOpen();
              return;
            }
            openSave();
          }}
          loading={isLoading}
        />
      )}
      <ConfirmationModal
        isOpen={isConfirmationOpen}
        onAction={handleDeleteTask}
        onClose={() => {
          handleCloseConfirmation();
        }}
        description={`Do you really want to Delete '${name}'? This process can not be undone.`}
        title={'Delete'}
        type='danger'
        actionText='Delete'
      />
    </>
  );
};

export default DeleteClientTask;
