import React from 'react';

import TenantSubscriptionsTableRow from '../TenantSubscriptionsTableRow/TenantSubscriptionsTableRow';
import DataTable from 'components/DataTable/DataTable';
import EmptyState from 'components/EmptyState/EmptyState';

const headerCells = [
  { title: 'plan', sortable: true, sortValue: 'plan' },
  { title: 'status', sortable: true, sortValue: 'status' },
  { title: 'mrr', sortable: true, sortValue: 'mrr' },
  { title: 'start date', sortable: true, sortValue: 'startDate' },
  { title: 'renewal date', sortable: true, sortValue: 'renewalDate' },
  { title: '' },
];

const TenantSubscriptionsTable = ({ subscriptions, isLoading }) => (
  <DataTable
    isScroll
    data={subscriptions}
    isLoading={isLoading}
    columns={headerCells}
    TableRowComponent={TenantSubscriptionsTableRow}
    EmptySearchComponent={() => <EmptyState message={'No Subscription'} />}
  />
);

export default TenantSubscriptionsTable;
