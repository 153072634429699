export default function translateOptionsCountries(options) {
  const result = [];
  options.forEach((item) => {
    result.push({
      value: `${item.id}`,
      label: `${item.name}`,
      isDisabled: item.isDisabled || false,
    });
  });

  return result;
}
