import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars-2';

import Grid from './Grid';

const TableOfContents = ({ items, onSelect }) => (
  <div className='h-100 p-0'>
    <div className={cx(' px-5 py-4 border-btm d-flex align-items-center justify-content-between')}>
      <h2>Insights</h2>
    </div>
    <Scrollbars className='scrollbarsWrapper' autoHide autoHideTimeout={1000} autoHideDuration={200}>
      <div className='px-5'>
        <Grid items={items} onSelect={onSelect} />
      </div>
    </Scrollbars>
  </div>
);

TableOfContents.propTypes = {
  onSelect: PropTypes.func.isRequired,
  items: PropTypes.array,
};
TableOfContents.defaultProps = {
  items: [],
};

export default TableOfContents;
