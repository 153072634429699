import React from 'react';

import { PHRModal } from '@palmhr/palmhr-ui-lib';
import PropTypes from 'prop-types';

const Modal = ({
  children,
  styles,
  onRequestClose,
  isOpen,
  title,
  showHeader,
  showDirtyForm,
  contentLabel,
  shouldCloseOnOverlayClick,
  direction,
}) => (
  <PHRModal
    onRequestClose={onRequestClose}
    isOpen={isOpen}
    title={title}
    showHeader={showHeader}
    showDirtyForm={showDirtyForm}
    contentLabel={contentLabel}
    shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
    direction={direction}
    styles={styles}
  >
    {children}
  </PHRModal>
);

export default Modal;

Modal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.elementType]).isRequired,
  styles: PropTypes.object,
  onRequestClose: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string,
  showHeader: PropTypes.bool,
  showDirtyForm: PropTypes.bool,
  contentLabel: PropTypes.string,
  shouldCloseOnOverlayClick: PropTypes.bool,
  direction: PropTypes.oneOf(['ltr', 'rtl']),
};

Modal.defaultProps = {
  styles: {},
  onRequestClose: () => {},
  title: '',
  showHeader: true,
  showDirtyForm: false,
  contentLabel: '',
  shouldCloseOnOverlayClick: true,
  direction: 'ltr',
};
