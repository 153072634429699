import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './TableDataCell.module.scss';

const TableDataCell = ({ children, className }) => <td className={cx(styles.data, className)}>{children}</td>;
TableDataCell.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]),
  className: PropTypes.string,
};
TableDataCell.defaultProps = {
  className: '',
  children: '',
};
export default TableDataCell;
