/* eslint-disable */

import React from 'react';
import {propTypes, defaultProps, containerClasses, contentClasses, onContentScroll} from './utility';
import Scrollbars from 'react-custom-scrollbars';

const Panel = ({ id, title, children, className, contentClassName, style, onScroll, footer}) =>
{

    return (
        <div
            id={id}
            style={style}
            className={containerClasses(className)}
        >
            {title && title}

            <div
                id={`${id}-content`}
                className={contentClasses(contentClassName)}
            >
                <Scrollbars
                    hideTracksWhenNotNeeded autoHide
                    onScrollFrame={onContentScroll(onScroll)}
                >
                    {children}
                </Scrollbars>
            </div>

            {footer && footer}

        </div>
    );
};

Panel.propTypes = propTypes();
Panel.defaultProps = defaultProps();

export default Panel;