import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';
import Scrollbars from 'react-custom-scrollbars-2';

import styles from './PreviewModalMob.module.scss';
import IconButton from '../../components/IconButton';

const PreviewModalMob = ({ onClose, data }) => (
  <div className={cx(styles, 'position-relative')}>
    <div className={cx(styles.header, 'mb-5 position-relative')}>
      <IconButton className={styles.closeButton} icon='icon-cross-x' onClick={onClose} />

      <div className='row align-items-center'>
        <div className='col-6'>
          <div className={styles.title}>
            <span>
              Release notes
              <br />
              {data?.title}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div className=''>
      <Scrollbars
        className={cx(styles.Scrollbar, 'scroll-bar')}
        autoHide
        renderView={({ style, ...props }) => <div {...props} style={{ ...style, overflowX: 'hidden' }} />}
        style={{ height: '233px' }}
      >
        <div className='pe-5 mb-5'>
          {data?.featureItems && data.featureItems.length > 0 && (
            <div className={styles.infoSection}>
              <ul className={styles.ul}>
                {data.featureItems.map((el) => (
                  <li key={el.id} className='d-flex align-items-start'>
                    <span className={cx(styles.itemText, 'col-11 px-0')}>{el.title}</span>
                    {el.videoUrl && (
                      <div className='col-1'>
                        <IconButton
                          icon='icon-video'
                          onClick={() => {
                            window.open(el.videoUrl, '_target');
                          }}
                        />
                      </div>
                    )}
                  </li>
                ))}

                {data.featureItems.length === 0 && (
                  <li className={styles.noInfo}>
                    <div className={cx(styles.emptyList)}>
                      <div
                        className={cx(
                          styles.iconHistory,
                          'd-flex',
                          'justify-content-center',
                          'align-items-center',
                          'm-auto'
                        )}
                      >
                        <i className={cx(styles.icon, 'icon-housing')} />
                      </div>
                      <p className='mt-4 text-center text-capitalize'>No Features</p>
                    </div>
                  </li>
                )}
              </ul>
            </div>
          )}
          {data?.improvementItems && data.improvementItems.length > 0 && (
            <div className={styles.infoSection}>
              <ul className={styles.ul}>
                {data.improvementItems.map((el) => (
                  <li key={el.id} className='d-flex align-items-start'>
                    <span className={cx(styles.itemText, 'col-11 p-0')}>{el.title}</span>
                    {el.videoUrl && (
                      <div className='col-1'>
                        <IconButton
                          icon='icon-video'
                          onClick={() => {
                            window.open(el.videoUrl, '_target');
                          }}
                        />
                      </div>
                    )}
                  </li>
                ))}

                {data.improvementItems.length === 0 && (
                  <li className={styles.noInfo}>
                    <div className={cx(styles.emptyList)}>
                      <div
                        className={cx(
                          styles.iconHistory,
                          'd-flex',
                          'justify-content-center',
                          'align-items-center',
                          'm-auto'
                        )}
                      >
                        <i className={cx(styles.icon, 'icon-housing')} />
                      </div>
                      <p className='mt-4 text-center text-capitalize'>No Improvements</p>
                    </div>
                  </li>
                )}
              </ul>
            </div>
          )}
        </div>
      </Scrollbars>
    </div>
  </div>
);

PreviewModalMob.propTypes = {
  onClose: PropTypes.func.isRequired,
  data: PropTypes.object,
  onPublish: PropTypes.func,
};

PreviewModalMob.defaultProps = {
  onPublish: undefined,
  data: {},
};

export default PreviewModalMob;
