import http from './Api';

class TemplatesApi {
  static listImplementationTemplates(params) {
    return http.get('templates', { params: params || { perPage: 9999 } });
  }

  static getImplementationTemplateDetails(id) {
    return http.get(`templates/${id}`);
  }

  static getImplementationTaskDetails(id) {
    return http.get(`templates/task/${id}`);
  }

  static listImplementationTemplatesTasks(params) {
    return http.get('templates/tasks', { params: params || { perPage: 9999 } });
  }

  static postImplementationTemplate(template) {
    return http.post('templates', template);
  }

  static postImplementationTemplatesTask(task) {
    return http.post('templates/task', task);
  }

  static updateImplementationTemplate(data) {
    return http.put(`templates/${data.id}`, data.template);
  }

  static updateImplementationTemplatesTask(data) {
    return http.put(`templates/task/${data.id}`, data.task);
  }

  static deleteImplementationTemplatesTask(id) {
    return http.delete(`templates/task/${id}`);
  }
}

export default TemplatesApi;
