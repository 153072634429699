import _ from 'lodash';
import Localize from 'react-intl-universal';

function formatKey(prefix, value, patern) {
  let data = Localize.get(prefix);
  if (value) {
    data = Localize.get(`${prefix}.${_.snakeCase(value).toUpperCase()}`, patern);
  }
  return data || `${prefix}.${_.snakeCase(value).toUpperCase()}`;
}

export default formatKey;
