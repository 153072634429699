import React from 'react';

import { useQueryClient } from '@tanstack/react-query';
import cloneDeep from 'lodash/cloneDeep';

import SwitchInput from 'components/forms/SwitchInput/SwitchInput';
import SpinnerRing from 'components/SpinnerRing';
import { convertToTitleCase } from 'Customers/components/detailsModal/CustomerFeatureFlags/helpers';
import {
  queryKeys,
  useMutationPostFeatureFlagConfigPerTenant,
  useMutationUpdateFeatureFlagPerTenant,
  useQueryFeatureFlagsPerTenant,
} from 'Customers/components/detailsModal/CustomerFeatureFlags/hooks/useFeatureFlag';

const CustomerFeatureFlags = ({ details }) => {
  const tenant = details.subdomain;
  const queryClient = useQueryClient();

  const { isFetching, data } = useQueryFeatureFlagsPerTenant(tenant);
  const { isPending: isUpdating, mutate: updateFeatureFlagConfig } = useMutationUpdateFeatureFlagPerTenant(tenant);
  const { isPending: isCreating, mutate: createFeatureFlagConfig } = useMutationPostFeatureFlagConfigPerTenant(tenant);

  const featureFlagConfigurations = data?.data || {};

  if (isFetching || isUpdating || isCreating) {
    return <SpinnerRing />;
  }

  const handleChangeFeatureFlag = (checked, name) => {
    const clonedFeatureConfigModules = cloneDeep(featureFlagConfigurations.modules);
    clonedFeatureConfigModules.find((currentModule) => currentModule.name === name).enabled = checked;

    if (featureFlagConfigurations.type === 'DEFAULT') {
      createFeatureFlagConfig(
        {
          data: {
            name: tenant,
            modules: clonedFeatureConfigModules,
            type: 'TENANT',
            parent: featureFlagConfigurations.id,
          },
        },
        {
          onSuccess: () => queryClient.invalidateQueries({ queryKey: queryKeys.getFeatureFlagsPerTenant(tenant) }),
        }
      );
    } else {
      updateFeatureFlagConfig(
        {
          id: featureFlagConfigurations.id,
          data: {
            moduleName: name,
            enabled: checked,
          },
        },
        {
          onSuccess: () => queryClient.invalidateQueries({ queryKey: queryKeys.getFeatureFlagsPerTenant(tenant) }),
        }
      );
    }
  };

  const sortedModules = featureFlagConfigurations.modules.sort((a, b) => a.name.localeCompare(b.name));

  return (
    <section className='mt-5 me-5'>
      <h3 className='font-size-16 font-weight-bold mb-5'>Module Names:</h3>
      <section className='d-flex flex-column gap-3'>
        {sortedModules.map((module) => (
          <section key={module.name} className='d-flex justify-content-between'>
            <section className='d-flex gap-1'>
              <h6>{convertToTitleCase(module.name)}</h6>
            </section>
            <SwitchInput name={module.name} onChange={handleChangeFeatureFlag} checked={module.enabled} />
          </section>
        ))}
      </section>
    </section>
  );
};

export default CustomerFeatureFlags;
