import { useState } from 'react';

export const useVisibility = () => {
  const [isOpen, setIsOpen] = useState(false);

  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);
  const toggle = () => setIsOpen((prevModalStatus) => !prevModalStatus);

  return { isOpen, open, close, toggle };
};
