import UsersApi from '../../api/UsersApi';
import { useQueryHook, useMutationHook } from 'utility/reactQueryHooks';

export const queryKeys = {
  users: (params) => ['users', JSON.stringify(params)],
  updateUser: ['updateUser'],
  addUser: ['addUser'],
  deactivateUser: ['deactivateUser'],
  activateUser: ['activateUser'],
};

export const useQueryGetUsers = (params) =>
  useQueryHook(queryKeys.users(params), () => UsersApi.getUsers(params));

export const useUpdateUser = () => useMutationHook(queryKeys.updateUser, UsersApi.updateUser);

export const useMutationAddUser = () => useMutationHook(queryKeys.addUser, UsersApi.createUser);

export const useMutationDeactivateUser = () => useMutationHook(queryKeys.deactivateUser, UsersApi.deactivateUser);

export const useMutationActivateUser = () => useMutationHook(queryKeys.activateUser, UsersApi.activateUser);
