import React from 'react';

import { useQueryClient } from '@tanstack/react-query';

import Modal from '../../../../components/Modal/Modal';
import AddTemplateForm from '../../forms/AddTemplateForm/AddTemplateForm';
import { useImplementationTemplatesAdd, queryKeys } from '../../hooks/useTemplates';

const AddTemplateModal = ({ isOpen, onClose }) => {
  const initialValues = {
    name: '',
    nameLocal: '',
    tasks: '',
    description: '',
  };

  const { mutate, isLoading } = useImplementationTemplatesAdd();
  const queryClient = useQueryClient();

  const onHandleSubmit = (templateData) => {
    mutate(templateData, {
      onSuccess: async () => {
        onClose();
        await queryClient.invalidateQueries({ queryKey: queryKeys.ImplementationTemplates() });
      },
    });
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} title={'Add Template'} styles={{ width: '720px' }}>
      <AddTemplateForm tasks={[]} initialValues={initialValues} onSubmit={onHandleSubmit} isLoading={isLoading} />
    </Modal>
  );
};

export default AddTemplateModal;
