import React from 'react';

import { PHRPageHeader } from '@palmhr/palmhr-ui-lib';

const PageHeader = ({
  title,
  subtitle,
  isShowBackButton,
  onClickGoBack,
  className,
  isLoading,
  navTabs,
  renderSideActionComponent,
  renderTitleActionComponent,
}) => (
  <PHRPageHeader
    title={title}
    subtitle={subtitle}
    isShowBackButton={isShowBackButton}
    onClickGoBack={onClickGoBack}
    className={className}
    isLoading={isLoading}
    navTabs={navTabs}
    renderSideActionComponent={renderSideActionComponent}
    renderTitleActionComponent={renderTitleActionComponent}
  />
);

export default PageHeader;
