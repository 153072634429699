import React from 'react';

import PropTypes from 'prop-types';

import styles from './AnchorTableRow.module.scss';
import TableDataCell from '../components/table/TableDataCell';

const AnchorsTableRow = ({ data, onEditModal, onDeleteModal }) => (
  <tr className={styles.tr}>
    <TableDataCell className='ps-5 pe-5'>{data.anchorKey}</TableDataCell>
    <TableDataCell>
      <>
        <div className='mb-1'>
          <b>Web:</b> {data.web || '--'}
        </div>
        <div className='mb-1'>
          <b>iOS:</b> {data.ios || '--'}
        </div>
        <div className='mb-1'>
          <b>Android:</b> {data.android || '--'}
        </div>
      </>
    </TableDataCell>
    <TableDataCell>{data.createdBy?.fullName}</TableDataCell>
    <TableDataCell className='action-cell'>
      <div className='d-flex justify-content-end'>
        <button type='button' onClick={onEditModal} title='Edit' className='btn btn-light ms-1'>
          <i className='icon-pencil-create' />
        </button>
        <button type='button' onClick={onDeleteModal} title='Delete' className='btn btn-light ms-1'>
          <i className='icon-trash' />
        </button>
      </div>
    </TableDataCell>
  </tr>
);

AnchorsTableRow.propTypes = {
  data: PropTypes.object.isRequired,
  onEditModal: PropTypes.func.isRequired,
  onDeleteModal: PropTypes.func.isRequired,
};

export default AnchorsTableRow;
