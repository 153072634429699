import React, { useState, useEffect } from 'react';

import isEqual from 'lodash/isEqual';
import { useQueryTemplates, useQueryGetTemplate } from './hooks/useImplementation';
import IconButton from 'components/IconButton';
import Filter from './components/Filter/Filter';
import OutsideClickHandler from 'components/OutsideClickHandler';
import ImplementationTable from './components/ImplementationTable';
import AddTaskModal from './modals/AddTaskModal';
import { useVisibility } from 'Shared/useVisibility';
import SaveTasks from './components/SaveTasks';
import TemplateHeader from './components/TemplateHeader';

const Implementation = ({ clientTasks, onSort }) => {
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const [tasks, setTasks] = useState(clientTasks || []);
  const [isAddTaskModalOpen, setIsAddTaskModalOpen] = useState(false);
  const { isOpen: isConfirmationOpen, open: handleConfirmationOpen, close: handleCloseConfirmation } = useVisibility();
  const {
    isOpen: importTemplateOpened,
    open: handleImportTemplateOpen,
    close: handleHideImportTemplate,
  } = useVisibility();

  const { isOpen: isNotSavedModalOpened, open: handleSaveModalOpen, close: handleHideSaveModal } = useVisibility();

  const {
    isOpen: isTemplatesActionsShown,
    open: handleShowTemplateActions,
    close: handleHideTemplateActions,
  } = useVisibility();

  const { data: templates } = useQueryTemplates({
    currentPage: 1,
    perPage: 10000,
  });

  const { isLoading: isLoadingSelectedTemplate, data: selectedTemplate } = useQueryGetTemplate(selectedTemplateId);
  const { isLoading: isLoadingTasksTemplate, data: tasksTemplate } = useQueryGetTemplate(tasks[0]?.templateId || null);

  useEffect(() => {
    if (clientTasks && clientTasks.length > 0) {
      setTasks(clientTasks);
    }
  }, [clientTasks]);

  useEffect(() => {
    const isMatch = () => {
      if (tasks?.length !== tasksTemplate?.data.tasks.length) {
        return false;
      }
      return tasks?.every((obj1) => tasksTemplate.data.tasks?.some((obj2) => obj1.name === obj2.name));
    };

    if (tasks.length > 0 && !isMatch() && !isLoadingTasksTemplate) {
      handleShowTemplateActions();
      return;
    }

    handleHideTemplateActions();
  }, [tasks, tasksTemplate]);

  const moveItem = (dragIndex, hoverIndex) => {
    const dragItem = tasks[dragIndex];
    const updatedItems = [...tasks];
    updatedItems.splice(dragIndex, 1);
    updatedItems.splice(hoverIndex, 0, dragItem);
    setTasks(updatedItems);
  };

  const closeSaveModal = () => {
    setTasks(clientTasks);
    handleHideSaveModal();
  };
  const updateTask = (taskId, updatedTask) => {
    const updatedTasks = tasks.map((task) => (task.id === taskId ? { ...task, ...updatedTask } : task));
    setTasks(updatedTasks);
  };

  const onFilter = (id) => {
    setSelectedTemplateId(id);
    handleHideImportTemplate();
  };
  return (
    <OutsideClickHandler
      clickHandler={() => {
        if (!isEqual(clientTasks, tasks)) {
          handleSaveModalOpen();
        }
      }}
    >
      <div className='d-flex flex-column justify-content-between h-100 me-5'>
        <div className='d-flex flex-column justify-content-start gap-2'>
          <div className='d-flex justify-content-between align-items-center mt-5'>
            <div className='d-flex'>
              <h6>
                <strong>Implementation tasks</strong>
              </h6>
            </div>
            <div className='d-flex flex-end'>
              {((tasks && tasks.length !== 0) || selectedTemplateId !== null) && (
                <div>
                  <IconButton
                    icon={'icon-plus'}
                    color='primary'
                    size='md'
                    tooltipText={'Add Task'}
                    onClick={() => {
                      if (!isEqual(clientTasks, tasks)) {
                        handleSaveModalOpen();
                        return;
                      }
                      setIsAddTaskModalOpen(true);
                    }}
                  />
                </div>
              )}
              {tasks.length === 0 && (
                <div className='position-relative'>
                  <IconButton
                    icon={'icon-Import'}
                    color='primary'
                    size='md'
                    tooltipText={'Import Template'}
                    onClick={() => {
                      handleImportTemplateOpen();
                    }}
                  />
                  {importTemplateOpened && (
                    <OutsideClickHandler
                      clickHandler={() => {
                        handleHideImportTemplate();
                      }}
                    >
                      <Filter
                        onFilter={onFilter}
                        className='end-50'
                        closeImportTemplate={handleHideImportTemplate}
                        templates={templates?.data?.items}
                      />
                    </OutsideClickHandler>
                  )}
                </div>
              )}
            </div>
          </div>
          {tasks.length !== 0 && (
            <TemplateHeader name={tasksTemplate?.data.name} isTemplatesActionsShown={isTemplatesActionsShown} />
          )}
          {selectedTemplateId !== null && <TemplateHeader name={selectedTemplate?.data.name} />}
          <div className='d-flex justify-content-start'>
            {tasks && tasks.length > 0 && (
              <ImplementationTable
                tasks={tasks}
                onSort={onSort}
                isLoading={isLoadingTasksTemplate}
                onMoveItem={moveItem}
                onFilter={onFilter}
                updateTask={updateTask}
                handleConfirmationOpen={handleConfirmationOpen}
                isDraggable
                isScroll
                showDrag={isTemplatesActionsShown}
                isSelected
                openSave={() => {
                  const shouldOpenModal = !isEqual(clientTasks, tasks);
                  if (shouldOpenModal) {
                    handleSaveModalOpen();
                  }
                  return shouldOpenModal;
                }}
              />
            )}
          </div>
          {tasks.length === 0 && (
            <ImplementationTable
              tasks={selectedTemplate?.data.tasks}
              isLoading={isLoadingSelectedTemplate}
              onFilter={onFilter}
              handleConfirmationOpen={handleConfirmationOpen}
              templates={templates?.data.items}
              isScroll
            />
          )}
        </div>
        {((tasks && tasks.length !== 0) || selectedTemplateId !== null) && (
          <SaveTasks
            tasks={tasks || []}
            selectedTemplateId={selectedTemplateId}
            setSelectedTemplateId={setSelectedTemplateId}
            handleConfirmationOpen={handleConfirmationOpen}
            selectedTemplate={selectedTemplate}
            isConfirmationOpen={isConfirmationOpen}
            handleCloseConfirmation={handleCloseConfirmation}
            onClose={closeSaveModal}
            isNotSavedModalOpened={isNotSavedModalOpened}
            handleHideSaveModal={handleHideSaveModal}
          />
        )}
        <AddTaskModal
          isOpen={isAddTaskModalOpen}
          setSelectedTemplateId={setSelectedTemplateId}
          onClose={() => {
            setIsAddTaskModalOpen(false);
          }}
          tasks={tasks}
          selectedTemplateId={selectedTemplateId}
        />
      </div>
    </OutsideClickHandler>
  );
};

export default Implementation;
