import React from 'react';

import cx from 'classnames';

import styles from './CustomersList.module.scss';
import logo from '../../../assets/img/logo-primary.svg';
import TableSkeleton from '../../../components/skeletons/TableSkeleton';

const CustomersList = ({ filteredCustomers, loading, onSelect, selected }) => {
  const plansMap = {
    'pro-40-yearly': 'Pro 40',
    'pro-40-monthly': 'Pro 40',
    'basic-10_24-yearly': 'Basic 10-24',
    'basic-10_24-monthly': 'Basic 10-24',
    'basic-25_39-yearly': 'Basic 25-39',
    'basic-25_39-monthly': 'Basic 25-39',
    'basic-1_9-yearly': 'Basic 1-9',
    'basic-1_9-monthy': 'Basic 1-9',
  };

  return (
    <>
      <div className={cx(styles.employeesListWrapper, 'h-100')}>
        {filteredCustomers.length === 0 && !loading ? (
          <div className='pt-8 m-auto'>
            <div className={cx(styles.emptyList, 'm-auto')}>
              <div
                className={cx(styles.iconHistory, 'd-flex', 'justify-content-center', 'align-items-center', 'm-auto')}
              >
                <i className={cx(styles.icon, 'icon-housing')} />
              </div>
              <p className='mt-2 text-center text-capitalize'>No customers</p>
            </div>
          </div>
        ) : (
          <div className={cx(styles.listDiv, 'pb-6')}>
            {loading ? (
              <div className={styles.skeletonList}>
                <TableSkeleton lineHight={48} num={5} />
              </div>
            ) : (
              <>
                {filteredCustomers &&
                  filteredCustomers.map((item) => (
                    <div
                      key={item.company}
                      className={cx(
                        styles.list,
                        { [styles.active]: item.company === selected.company },
                        'd-flex',
                        'align-items-center',
                        'justify-content-between'
                      )}
                      onClick={() => {
                        onSelect(item);
                      }}
                    >
                      <div className='d-flex align-items-center'>
                        <div className={cx(styles.profileImage, 'me-3')}>
                          <img className={styles.img} src={item?.companyLogo || logo} alt={item.company} />
                        </div>
                        <div className={cx(styles.company, 'text-left')}>
                          {item.company}
                          <p className={styles.text}>{plansMap[item.subscriptionPlan]}</p>
                        </div>
                      </div>

                      <div className='d-flex align-items-center justify-content-end'>
                        <i className='icon-user-circle text-gray me-2' />
                        <span className={cx(styles.numb, 'fw-bold', 'text-steal')}>{item.numberOfEmployees}</span>
                      </div>
                    </div>
                  ))}
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default CustomersList;
