import http from './Api';

class NotesApi {
  static getNotes(client, params) {
    return http.get(`tenant/${client}/notes`, { params });
  }

  static getNoteById(client, id) {
    return http.get(`tenant/${client}/notes/${id}`);
  }

  static createNote({client, data}) {
    return http.post(`tenant/${client}/notes`, data);
  }

  static updateNoteById({client, id, data}) {
    return http.put(`tenant/${client}/notes/${id}`, data);
  }

  static deleteNote({client, id}) {
    return http.delete(`tenant/${client}/notes/${id}`);
  }
}

export default NotesApi;
