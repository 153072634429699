import React, { useEffect, useState } from 'react';

import cx from 'classnames';
import { useLocation } from 'react-router-dom';

import SideBar from 'Navigation/SideBar';

import styles from './Header.module.scss';
import OutsideClickHandler from './OutsideClickHandler';

const Header = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const location = useLocation();
  const initialView = location.pathname.split('/')[1] || 'dashboard';
  const [currentView, setCurrentView] = useState(initialView);

  useEffect(() => {
    const path = location.pathname.split('/')[1];
    setCurrentView(path || 'dashboard');
  }, [location]);

  const handleOpen = () => {
    setIsSidebarOpen(true);
  };

  const handleClose = () => {
    setIsSidebarOpen(false);
  };
  return (
    <div className={cx(styles.wrapper, isSidebarOpen ? styles.backdrop : styles.minimized)}>
      <OutsideClickHandler clickHandler={handleClose}>
        <SideBar
          close={handleClose}
          show={isSidebarOpen ? '' : 'minimized'}
          open={handleOpen}
          currentView={currentView}
          setCurrentView={setCurrentView}
        />
      </OutsideClickHandler>
    </div>
  );
};
export default Header;
