import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import styles from './Filter.module.scss';

const link = cx(styles.link, 'd-flex', 'align-items-center', 'px-3', 'py-2');

const Filter = ({ onFilter, className, closeImportTemplate, templates }) => (
  <ul className={cx(styles.filterMenu, className, 'radius-4', 'bg-white', 'py-2', 'px-0')}>
    {templates &&
      templates.length > 0 &&
      templates.map((item) => (
        <li
          key={item.id}
          onClick={() => {
            onFilter(item.id);
            closeImportTemplate();
          }}
          className={styles.listItem}
        >
          <div className={link}>{item.name}</div>
        </li>
      ))}
    {!templates ||
      (templates.length === 0 && (
        <li className={styles.listItem}>
          <div className={link}>No Templates yet</div>
        </li>
      ))}
  </ul>
);

Filter.propTypes = {
  onFilter: PropTypes.func.isRequired,
};

export default Filter;
