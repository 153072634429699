import React from 'react';

import { PHRTextInput } from '@palmhr/palmhr-ui-lib';
import PropTypes from 'prop-types';

const TextInput = ({
  name,
  placeholder,
  value,
  label,
  isRequired,
  isDisabled,
  onChange,
  onBlur,
  onKeyPress,
  autofocus,
  size,
  className,
  error,
  readOnly,
  prefix,
  suffix,
  direction,
  hidden,
  maxLength,
  isCustom,
  hideLabel,
  icon,
  type,
  iconPosition,
  isLoading,
}) => (
  <PHRTextInput
    name={name}
    placeholder={placeholder}
    value={value}
    label={label}
    isRequired={isRequired}
    isDisabled={isDisabled}
    onChange={onChange}
    onBlur={onBlur}
    onKeyPress={onKeyPress}
    autofocus={autofocus}
    size={size}
    className={className}
    error={error}
    readOnly={readOnly}
    prefix={prefix}
    sufix={suffix}
    direction={direction}
    hidden={hidden}
    maxLength={maxLength}
    isCustom={isCustom}
    hideLabel={hideLabel}
    icon={icon}
    type={type}
    iconPosition={iconPosition}
    isLoading={isLoading}
  />
);

export default TextInput;

TextInput.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  autofocus: PropTypes.bool,
  size: PropTypes.string,
  className: PropTypes.string,
  direction: PropTypes.string,
  error: PropTypes.string,
  readOnly: PropTypes.bool,
  maxLength: PropTypes.string,
  onKeyPress: PropTypes.func,
  prefix: PropTypes.string,
  type: PropTypes.string,
  hidden: PropTypes.bool,
  isCustom: PropTypes.bool,
  hideLabel: PropTypes.bool,
  iconPosition: PropTypes.string,
  isLoading: PropTypes.bool,
  icon: PropTypes.string,
  suffix: PropTypes.string,
};

TextInput.defaultProps = {
  value: '',
  placeholder: '',
  label: '',
  isRequired: false,
  isDisabled: false,
  onBlur: undefined,
  autofocus: undefined,
  size: '',
  className: '',
  type: 'text',
  direction: '',
  error: undefined,
  readOnly: false,
  maxLength: '255',
  onKeyPress: undefined,
  prefix: '',
  hidden: false,
  isCustom: false,
  hideLabel: false,
  iconPosition: '',
  icon: '',
  isLoading: false,
  suffix: '',
};
