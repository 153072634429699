import React, { useState, useEffect } from 'react';

import cx from 'classnames';
import _ from 'lodash';
import Scrollbars from 'react-custom-scrollbars-2';

import Modal from 'components/Modal/Modal';
import RoundedButton from 'components/RoundedButton/RoundedButton';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import AddTranslation from './AddTranslation';
import styles from './Table.module.scss';
import TranslationsTableRow from './TranslationsTableRow';
import TranslationsApi from '../api/TranslationsApi';
import { PHRPagination } from '@palmhr/palmhr-ui-lib';
import CustomCheckbox from '../components/CustomCheckbox';
import Search from '../components/Search';
import TableSkeleton from '../components/skeletons/TableSkeleton';
import TableHead from '../components/table/TableHead';
import useDebounce from '../Shared/useDebounce';

const Table = () => {
  const [translations, setTranslations] = useState({
    pagination: {
      currentPage: 1,
      pages: 0,
      perPage: 10,
      total: 0,
    },
    items: [],
  });
  const [pagination, setPagination] = useState({
    currentPage: 1,
    perPage: 10,
  });
  const [selectedRow, setSelectedRow] = useState(undefined);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [reviewChecked, setReviewChecked] = useState(false);
  const [mobileChecked, setMobileChecked] = useState(false);
  const [translationErrorMessage, setTranslationErrorMessage] = useState('');
  const [notTranslatedChecked, setNotTranslatedChecked] = useState(false);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);

  const debouncedSearch = useDebounce(search, 500);

  const headerCells = [
    { title: 'Key' },
    { title: 'English', textRight: false },
    { title: 'عربى', textRight: true },
    { title: '' },
  ];

  const onPaginationAction = (newPagination) => {
    setPagination({ currentPage: newPagination.currentPage, perPage: newPagination.perPage });
  };

  const getTranslations = (currentPage) => {
    setLoading(true);
    pagination.currentPage = currentPage || pagination.currentPage;
    const params = {};
    if (mobileChecked) {
      params.mobile = mobileChecked;
    }
    if (notTranslatedChecked) {
      params.notTranslated = notTranslatedChecked;
    }
    if (reviewChecked) {
      params.review = reviewChecked;
    }
    TranslationsApi.getTranslations(pagination, search, params)
      .then((res) => {
        setTranslations(res.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getTranslations();
  }, [pagination]);

  useEffect(() => {
    getTranslations(1);
  }, [reviewChecked, mobileChecked, notTranslatedChecked]);

  useEffect(() => {
    getTranslations(1);
  }, [debouncedSearch]);

  const selectRow = (item) => {
    setSelectedRow(item);
  };

  const approveTranslation = (id) => {
    TranslationsApi.approveTranslation(id)
      .then(() => {
        getTranslations();
      })
      .catch(() => {});
  };

  const deleteRow = () =>
    TranslationsApi.deleteTranslation(selectedRow.id)
      .then(() => {
        getTranslations();
      })
      .catch(() => {});

  const editRow = (id, data) =>
    TranslationsApi.editTranslation(id, data)
      .then(() => {
        setShowAddModal(false);
        getTranslations();
      })
      .catch((err) => {
        setTranslationErrorMessage(err.response.data.message);
      });

  const addNew = (values) => {
    TranslationsApi.createTranslation(values)
      .then(() => {
        setShowAddModal(false);
        getTranslations();
      })
      .catch((err) => {
        setTranslationErrorMessage(err.response.data.message);
      });
  };

  const handlerSelected = (e) => {
    e.preventDefault();
    setSelectedRow(undefined);
    setShowAddModal(true);
  };

  return (
    <div className={cx(styles.wrapper, 'panel p-0')}>
      <div className='px-5 py-4 border-btm d-flex align-items-center justify-content-between'>
        <div>
          <h2>Translations</h2>
          <p className='tab-subtitle'>List of multi language translations</p>
        </div>
        <RoundedButton icon='icon-plus' text='Add New' color='primary' size='sm' onClick={handlerSelected} />
      </div>
      <div className='d-flex mb-2 mt-2 flex-row align-items-center justify-content-between px-5'>
        <div className='col-4 p-2'>
          <Search
            className='p-0'
            onChange={(e) => {
              setSearch(e.target.value.replace(/^\s+/g, ''));
            }}
            placeholder='Search translations'
            value={search}
            onClear={() => {
              setSearch('');
            }}
          />
        </div>
        <div className='col-8 d-flex align-items-center justify-content-end p-2'>
          <div className='me-3'>
            <CustomCheckbox
              changeHandler={() => setNotTranslatedChecked(!notTranslatedChecked)}
              inputChecked={notTranslatedChecked}
              checkboxName='Not translated'
            />
          </div>
          <div className='me-3'>
            <CustomCheckbox
              changeHandler={() => setMobileChecked(!mobileChecked)}
              inputChecked={mobileChecked}
              checkboxName='Mobile'
            />
          </div>
          <div className='me-3'>
            <CustomCheckbox
              changeHandler={() => setReviewChecked(!reviewChecked)}
              inputChecked={reviewChecked}
              checkboxName='Review'
            />
          </div>
        </div>
      </div>
      <div className='d-flex justify-content-start flex-column gap-2 mx-5' style={{ height: 'calc(100vh - 190px)' }}>
        <Scrollbars
          className='scrollbarsWrapper'
          autoHide
          autoHideTimeout={1000}
          autoHideDuration={200}
          style={{ height: `100%` }}
        >
          <table className={cx(styles.table, 'w-100')}>
            <TableHead headerCells={headerCells} className={styles.fixedHeader} />
            <tbody>
              {!loading &&
                translations.items?.length > 0 &&
                translations.items.map((item) => (
                  <TranslationsTableRow
                    key={item.id}
                    item={item}
                    approveTranslation={approveTranslation}
                    setShowDeleteModal={setShowDeleteModal}
                    setShowEditModal={setShowAddModal}
                    selectRow={selectRow}
                  />
                ))}
              {loading && (
                <tr className='w-100 client-list'>
                  <td colSpan='5'>
                    <TableSkeleton lineHight={45} mb='mb-2' num={5} />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </Scrollbars>
        {translations?.pagination?.total > 0 && (
          <PHRPagination onPaginationAction={onPaginationAction} pagination={translations?.pagination} />
        )}
      </div>
      <ConfirmationModal
        isOpen={showDeleteModal}
        onAction={deleteRow}
        onClose={() => {
          setShowDeleteModal(false);
        }}
        description={'Are you sure to delete selected translation?'}
        title={'Delete Translation'}
        type={'danger'}
        actionText={'Delete'}
      />
      <Modal
        isOpen={showAddModal}
        shouldCloseOnOverlayClick
        title={selectedRow ? 'Edit Translation' : 'Add Translation'}
        styles={{
          width: '480px',
          padding: 0,
        }}
        onRequestClose={() => {
          setShowAddModal(false);
        }}
      >
        <AddTranslation
          isOpened={showAddModal}
          onClose={() => {
            setTranslationErrorMessage('');
            setShowAddModal(false);
          }}
          editRow={editRow}
          addNew={addNew}
          errorMessage={translationErrorMessage}
          translations={translations?.items}
          selectedRow={selectedRow}
        />
      </Modal>
    </div>
  );
};

export default Table;
