import React from 'react';

import crown from '../../assets/img/crown.svg';
import logo from '../../assets/img/logo-main.svg';
import logoWhite from '../../assets/img/logo-white.svg';

const AuthStatic = (props) => (
  <div className='login-div container-fluid'>
    <div className='logo-div'>
      <img className='logo-icon' src={logo} alt='logo' />
    </div>

    <div className='row'>
      <div className='col-12 height-100vh d-flex align-items-center justify-content-center'>
        <div className='login-form text-center'>
          <div className='form-logo-div'>
            <img className='crown-img' alt='logo' src={crown} />
            <img className='logo-icon' alt='logo' src={logoWhite} />
          </div>

          <h2 className='mt-6 mb-2 fw-bold'>PalmHR</h2>
          {props.children}
        </div>
      </div>
    </div>
  </div>
);

export default AuthStatic;
