import React from 'react';
import { PHRDirtyForm } from '@palmhr/palmhr-ui-lib';

const DirtyForm = ({ onClose, onConfirm, text, cancelText, confirmText, hideCancelButton }) => (
  <PHRDirtyForm
    onClose={onClose}
    onConfirm={onConfirm}
    text={text}
    cancelText={cancelText}
    confirmText={confirmText}
    hideCancelButton={hideCancelButton}
  />
);
export default DirtyForm;
