import http from './Api';

class AuthApi {
  static login(data) {
    return http.post('/users/login', data);
  }

  static resetPasword(data) {
    return http.post('users/reset-password', data);
  }

  static forceResetPasword(data) {
    return http.post('force-change-password', data);
  }

  static forgotPassword(data) {
    return http.post('users/forgot-password', data);
  }
}

export default AuthApi;
