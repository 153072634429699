import React from 'react';

import { PHRDraggableList } from '@palmhr/palmhr-ui-lib';
import PropTypes from 'prop-types';

const DraggableList = ({ items, onMoveItem, direction, DragComponentContainer, ItemComponent }) => (
  <PHRDraggableList
    items={items}
    onMoveItem={onMoveItem}
    direction={direction}
    DragComponentContainer={DragComponentContainer}
    ItemComponent={ItemComponent}
  />
);

export default DraggableList;

DraggableList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    })
  ).isRequired,
  onMoveItem: PropTypes.func.isRequired,
  direction: PropTypes.oneOf(['ltr', 'rtl']),
  ItemComponent: PropTypes.elementType,
};

DraggableList.defaultProps = {
  direction: 'ltr',
  ItemComponent: undefined,
};
