import React, { useState, useEffect } from 'react';

import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import cx from 'classnames';
import { Bar } from 'react-chartjs-2';

import styles from './CustomersChart.module.scss';
import DashboardApi from '../../api/DashboardApi';
import Line from '../../components/skeletons/Line';

Chart.register(...registerables);
Chart.register(ChartDataLabels);

const CustomersChart = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    DashboardApi.getCustomerStatus()
      .then((res) => {
        setLoading(false);
        setData(res.data);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const style = { height: 291, width: '100%', borderRadius: 8, opacity: 0.7 };

  const barData = {
    labels: ['In Trial', 'Active', 'Canceled'],
    datasets: [
      {
        label: 'Customers',
        data: [data.inTrial, data.active, data.cancelled],
        backgroundColor: ['#ffa150', '#62D26F', '#f45147'],
        borderColor: ['#ffa150', '#62D26F', '#f45147'],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    tooltips: {
      mode: 'index',
      intersect: false,
    },
    hover: {
      mode: 'index',
      intersect: false,
    },

    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 32,
        bottom: 0,
      },
    },
    plugins: {
      datalabels: {
        color: '#5D728C',
        anchor: 'end',
        align: 'top',
        clip: false,
      },
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        ticks: {
          display: false,
        },
        border: {
          display: false,
        },
        grid: {
          display: false,
          drawOnChartArea: false,
          drawTicks: false,
        },
      },
      x: {
        ticks: {
          color: '#93a5b1',
          font: {
            size: 12,
          },
        },
        border: {
          display: false,
        },
        grid: {
          display: false,
          drawOnChartArea: false,
          drawTicks: false,
        },
      },
    },
    // tooltips: {
    //   enabled: false,
    // },
    bezierCurve: false,
    elements: {
      point: {
        radius: 0,
      },
    },
  };

  if (loading) {
    return (
      <div className='col-4'>
        <Line style={style} gradient={styles.gradient} />
      </div>
    );
  }

  return (
    <div className='col-4'>
      <div className={cx(styles.wrapper, 'h-100')}>
        <h6 className={cx(styles.title, 'title border-btm pb-3 text-gray fw-bold')}>Customers</h6>
        <div className={cx(styles.graphHeight, 'd-flex align-items-end')}>
          <Bar data={barData} options={options} />
        </div>
      </div>
    </div>
  );
};

export default CustomersChart;
