import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './CustomerInfo.module.scss';

const CustomerInfo = ({ icon, name, type, showButton, onShowModal }) => (
  <div className={cx(styles.infoWrapper, 'd-flex', 'justify-content-between', 'align-items-center', 'mb-2a')}>
    <div className={cx(styles.info, 'd-flex', 'justify-content-start', 'align-items-center')}>
      <i className={cx(styles.icon, icon, 'me-2 text-gray')} />
      <div className='p-0 m-0 d-flex align-items-center'>
        <span className='label p-0 me-1'>{`${type}:`}</span>
        <div className='fs-14 text-nowrap'>{name}</div>
        {showButton ? (
          <span className={cx(styles.viewAll, 'ms-2 fw-bold text-nowrap')} onClick={onShowModal}>
            {showButton}
          </span>
        ) : null}
      </div>
    </div>
  </div>
);

CustomerInfo.propTypes = {
  icon: PropTypes.string.isRequired,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  type: PropTypes.string.isRequired,
};

export default CustomerInfo;
