import React, { useEffect, useState, useContext } from 'react';

import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';

import Options from './Options';
import stylesOption from './Options.module.scss';

const EmployeeSelectProfile = ({
  refSelect,
  customClass,
  onChange,
  onBlur,
  options,
  styles,
  name,
  value,
  onInputChange,
  error,
  label,
  isRequired,
  isMulti,
  hideErrorDiv,
  scrollMore,
  hideClear,
  isDisabled,
  size,
  selectAllOption,
}) => {
  const [selectOptions, setSelectOptions] = useState([]);
  const [isShown, setIsShown] = useState(false);

  const NoOptionsMessage = (data) => (
    <components.NoOptionsMessage {...data}>
      <span>NO OPTIONS</span>
    </components.NoOptionsMessage>
  );

  const Option = (data) => (
    <components.Option {...data}>
      <Options {...data} />
    </components.Option>
  );

  const SingleValue = (data) => (
    <components.SingleValue {...data}>
      <Options {...data} />
    </components.SingleValue>
  );

  const onBlurField = () => {
    if (onBlur) {
      onBlur(name);
    }
  };

  useEffect(() => {
    const result = [];

    if (isMulti && selectAllOption) {
      result.push({
        value: '0',
        label: 'SELECT ALL',
      });
    }

    options?.items?.forEach((item) => {
      result.push({
        value: item?.id ? `${item.id.toString()}` : '',
        label: `${item.fullName}`,
        user: item,
        isDisabled: !!item.isDisabled,
      });
    });
    setSelectOptions(result);
  }, [JSON.stringify(options)]);

  const changeHandler = (e, option) => {
    if (isMulti) {
      onChange(
        option?.option?.value === '0' ? selectOptions.filter((elem) => elem.value !== '0' && !elem.isDisabled) : e,
        option
      );
    } else if (e && e.value) {
      if (onChange) {
        onChange(e);
      }
    } else {
      onChange({ value: '', label: '' });
    }
  };

  const clearSerachHadler = () => {
    if (onInputChange) {
      /* This is added to remove text from searchValue on select */
      onInputChange('');
    }
  };

  const onSearch = (v, e) => {
    // set search value only on input change, to prevent options api request on select value,
    // 'cause select value clears search
    if (onInputChange && e.action === 'input-change') {
      onInputChange(v);
    }
  };

  const selectStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#1A938A' : 'white',
      '&:hover': {
        backgroundColor: state.isSelected ? '#1A938A' : '#e9edef',
      },
      '&:active': {
        backgroundColor: '#f4f6f7',
      },
    }),
    control: (provided, state) => ({
      ...provided,
      boxShadow: 'none',
      color: '#93a5b1',
      minHeight: size === 'sm' ? '32px' : '40px',
      backgroundColor: state.isDisabled ? '#F4F6F7' : 'white',
      borderColor: state.isFocused ? '#1A938A' : '#e9edef',
      '&:hover': {
        borderColor: state.isFocused ? '#1A938A' : '#d4dbe0',
      },
    }),
    menu: (provided) => ({
      ...provided,
      boxShadow: `0 4px 5px 0 rgba(93,114,140,0.14),
            0 1px 10px 0 rgba(93,114,140,0.12),
            0 2px 4px -1px rgba(93,114,140,0.20)`,
      minWidth: '100%',
      maxHeight: '215px',
      width: '100%',
      zIndex: 9999,
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: '215px',
      borderRadius: '4px',
      minWidth: '100%',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: state.isDisabled ? '#A7B5BF' : '#043344',
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      visibility: 'hidden',
      margin: 0,
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: size === 'sm' ? '0 8px' : '2px 8px',
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    multiValue: (base, state) => (state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base),
    multiValueLabel: (base, state) => (state.data.isFixed ? { ...base, paddingRight: 6 } : base),
    multiValueRemove: (base, state) => (state.data.isFixed ? { ...base, display: 'none' } : base),
    indicatorsContainer: (base) => (isMulti ? { ...base, height: size === 'sm' ? '32px' : '40px' } : base),
  };

  /* Styling clear and arrow icon in select */
  const controlStylesCustom = {
    cursor: 'pointer',
    color: '#bec9d0',
    padding: size === 'sm' ? '4px 8px' : '8px',
  };

  const DropdownIndicator = (props) => {
    const {
      children = <i className='icon-arrow-dropdown text-gray' />,
      innerProps: { ref, ...restInnerProps },
    } = props;

    return (
      <div {...restInnerProps} ref={ref} style={controlStylesCustom}>
        <div className='text-gray p-0 d-flex align-items-center'>{children}</div>
      </div>
    );
  };

  const ClearIndicator = (props) => {
    const {
      children = <i className='icon-cross-x' />,
      innerProps: { ref, ...restInnerProps },
    } = props;

    return (
      <div {...restInnerProps} ref={ref} style={controlStylesCustom}>
        <div className='p-0 d-flex align-items-center'>{children}</div>
      </div>
    );
  };

  return (
    <div
      className={cx(customClass, error ? 'is-invalid' : '')}
      onMouseEnter={() => setIsShown(true)}
      onMouseLeave={() => setIsShown(false)}
    >
      <div className='d-flex'>
        {label ? (
          <label className='label' htmlFor={name}>
            {label}
            {isRequired && <sup>*</sup>}
          </label>
        ) : null}
      </div>

      <Select
        ref={refSelect}
        name={name}
        className='employee-select'
        classNamePrefix='react-select'
        placeholder={
          <div className='d-flex align-items-center'>
            <i className={cx(stylesOption.icon, 'icon-user-circle text-gray')} />
            <span className={cx(stylesOption.value, 'flex-grow-2 text-gray')}>
              <p className={cx(stylesOption.name, 'mb-0', 'text-gray')}>SELECT</p>
            </span>
          </div>
        }
        components={{ Option, SingleValue, ClearIndicator, DropdownIndicator, NoOptionsMessage }}
        options={selectOptions}
        onChange={changeHandler}
        onBlur={onBlurField}
        value={isMulti ? value : _.find(selectOptions, ['value', value])}
        styles={{ ...selectStyles, ...styles }}
        onInputChange={onSearch}
        isClearable={(isMulti && !!value) || (isShown && !hideClear)}
        backspaceRemoves
        deleteRemoves
        isMulti={isMulti}
        isOptionDisabled={(option) => option.isDisabled}
        onMenuScrollToBottom={scrollMore}
        isDisabled={isDisabled}
        onMenuOpen={clearSerachHadler}
        maxMenuHeight={250}
        menuPortalTarget={document.body}
        menuShouldScrollIntoView={false}
        menuPosition='fixed'
        menuShouldBlockScroll
      />
      {!hideErrorDiv && <div className='error'>{error}</div>}
    </div>
  );
};

EmployeeSelectProfile.propTypes = {
  options: PropTypes.object.isRequired,
  onInputChange: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  styles: PropTypes.object,
  name: PropTypes.string,
  value: PropTypes.any,
  hideErrorDiv: PropTypes.bool,
  hideClear: PropTypes.bool,
  isDisabled: PropTypes.bool,
  customClass: PropTypes.string,
  size: PropTypes.string,
  error: PropTypes.string,
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  isMulti: PropTypes.bool,
  scrollMore: PropTypes.func,
  selectAllOption: PropTypes.bool,
};

EmployeeSelectProfile.defaultProps = {
  onChange: undefined,
  onBlur: undefined,
  styles: {},
  name: 'created_for',
  value: undefined,
  onInputChange: undefined,
  hideErrorDiv: false,
  hideClear: false,
  isDisabled: false,
  customClass: '',
  size: '',
  error: '',
  label: '',
  isRequired: false,
  isMulti: false,
  scrollMore: undefined,
  selectAllOption: false,
};

export default EmployeeSelectProfile;
