import React from 'react';

import cx from 'classnames';

import styles from './EmptyState.module.scss';

const EmptyState = ({ message }) => (
  <div className='pt-8 m-auto'>
    <div className={cx(styles.emptyList, 'm-auto')}>
      <div className={cx(styles.iconHistory, 'd-flex', 'justify-content-center', 'align-items-center', 'm-auto')}>
        <i className={cx(styles.icon, 'icon-housing')} />
      </div>
      <p className='mt-4 text-center text-capitalize'>{message}</p>
    </div>
  </div>
);

export default EmptyState;
