import React, { useState } from 'react';

import cx from 'classnames';
import { useFormik } from 'formik';
import * as yup from 'yup';

import TextInput from 'components/forms/TextInput/TextInput';
import IconButton from 'components/IconButton';
import RoundedButton from 'components/RoundedButton/RoundedButton';

import TasksList from '../../components/TasksList/TasksList';
import AddTasksModal from '../../modals/AddTasksModal/AddTasksModal';

const createValidationSchema = (formTasks) =>
  yup.object({
    name: yup.string().required('Name is required'),
    nameLocal: yup.string(),
    description: yup.string(),
    tasks: yup.array().test('empty', 'You should choose at least one task', () => formTasks.length > 0),
  });
const AddTemplateForm = ({ tasks, initialValues, onSubmit, isLoading }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formTasks, setFormTasks] = useState([...tasks]);

  const validationSchema = createValidationSchema(formTasks);
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      const submissionValues = {
        name: values.name,
        nameLocal: values.nameLocal,
        tasks: formTasks,
        description: values.description,
      };
      onSubmit(submissionValues).finally(() => setSubmitting(false));
    },
  });

  const updateFormTasks = (selectedTasks) => {
    setFormTasks(selectedTasks);
  };
  return (
    <>
      <form onSubmit={formik.handleSubmit} noValidate>
        <div className='d-flex flex-column pt-5'>
          <div className='d-flex align-items-center'>
            <TextInput
              name='name'
              suffix={'en'}
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.name && formik.errors.name ? formik.errors.name : undefined}
              label='name'
              isRequired
            />
          </div>

          <div className='d-flex align-items-center'>
            <TextInput
              name='nameLocal'
              suffix={'ar'}
              value={formik.values.nameLocal}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.nameLocal && formik.errors.nameLocal ? formik.errors.nameLocal : undefined}
              direction='rtl'
              isRequired
            />
          </div>
        </div>

        <>
          <div className='pt-2 px-0 pb-2 d-flex justify-content-between'>
            <h5 style={{ fontSize: '1rem' }} className='fw-bold'>
              Tasks
            </h5>
            <IconButton icon='icon-plus' size='lg' color='primary' onClick={() => setIsModalOpen(true)} />
          </div>
          {formik.errors.tasks && <p className='ms-3 mt-2 error'>{formik.errors.tasks}</p>}
          <TasksList tasks={tasks} updateFormTasks={updateFormTasks} />
        </>

        <div className={cx('d-flex', 'justify-content-end', 'py-4', 'pe-4', 'border-t')}>
          <RoundedButton loading={isLoading} type='submit' text='Save' btnStyle='contained' color='primary' size='md' />
        </div>
      </form>
      {isModalOpen && <AddTasksModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />}
    </>
  );
};

export default AddTemplateForm;
