import React, { useState, useEffect, useContext } from 'react';
import cx from 'classnames';
import _ from 'lodash';
import { useQueryClient } from '@tanstack/react-query';
import { PHRPagination } from '@palmhr/palmhr-ui-lib';

import Modal from 'components/Modal/Modal';
import RoundedButton from 'components/RoundedButton/RoundedButton';
import AddUser from './AddUser';
import styles from './Users.module.scss';
import { useQueryGetUsers, useMutationActivateUser, useMutationDeactivateUser, queryKeys } 
from './hooks/useUsers';
import { AuthContext } from '../Auth/AuthContext';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import Search from '../components/Search';
import useDebounce from '../Shared/useDebounce';
import UsersTable from './components/UsersTable/UsersTable';

const Users = () => {
  const { authState } = useContext(AuthContext);
  const queryClient = useQueryClient();
  const isAdmin = authState.user.roles.includes('ROLE_ADMIN');
  const [addUserModal, setAddUserModal] = useState(false);
  const [userModal, setUserModal] = useState(false);
  const [search, setSearch] = useState('');
  const debouncedSearchTerm = useDebounce(search, 500);
  const [selectedUser, setSelectedUser] = useState(undefined);
  const [params, setParams] = useState({
    status: '',
    currentPage: 1,
    perPage: 10,
    search: '',
    sort: '-started',
  });

  const { isLoading, data: users } = useQueryGetUsers(params);
  const { mutate: activateMutate } = useMutationActivateUser();
  const { mutate: deactivateMutate } = useMutationDeactivateUser();

  useEffect(() => {
    setParams((prevState) => ({ ...prevState, currentPage: 1, search: debouncedSearchTerm }));
  }, [debouncedSearchTerm]);

  const handleEditSuccess = async () => {
    await queryClient.invalidateQueries(queryKeys.users(params), { exact: true });
  };

  const updatePaginationParams = ({ currentPage, perPage }) => {
    setParams((prevState) => ({ ...prevState, currentPage, perPage }));
  };
  // >-- add/edit user ------------------- //
  const showEditUserModal = (user) => {
    setAddUserModal(true);
    setSelectedUser(user);
  };
  // close modal after adding user
  const closeModal = () => {
    setAddUserModal(false);
    setSelectedUser(undefined);
  };
  // <-- add/edit user -------------------//
  const showselectedUser = (user) => {
    setUserModal(true);
    setSelectedUser(user);
  };
  // <------ deactivate user -------------//
  const deactivateUser = () => {
    // initialize loading
    deactivateMutate(selectedUser.id, {
      onSuccess: async () => {
        handleEditSuccess();
        setUserModal(false);
      },
    });
  };
  const activateUser = () => {
    activateMutate(selectedUser.id, {
      onSuccess: async () => {
        handleEditSuccess();
        setUserModal(false);
      },
    });
  };
  // ---- render view -----//
  return (
    <div className='mt-3 mx-3'>
      <div className={cx(styles.wrapper, 'panel p-0')}>
        <div className='px-5 py-4 border-btm d-flex align-items-center justify-content-between'>
          <div>
            <h2>Users</h2>
            <p className='tab-subtitle'>List of users, their roles and details</p>
          </div>
          <RoundedButton
            icon='icon-plus'
            text='Add New'
            color='primary'
            size='sm'
            onClick={() => setAddUserModal(true)}
          />
        </div>
        <div className='d-flex mb-2 mt-2 flex-row align-items-center justify-content-between px-5'>
          <div className='col-4 p-2'>
            <Search
              className='p-0'
              onChange={(e) => {
                setSearch(e.target.value.replace(/^\s+/g, ''));
              }}
              placeholder='Search user'
              value={search}
              onClear={() => {
                setSearch('');
              }}
            />
          </div>
        </div>
        <div style={{ height: 'calc(100vh - 270px)' }} className='mx-5'>
          <UsersTable
            users={users?.data?.items}
            openEdit={showEditUserModal}
            openDeactivate={showselectedUser}
            openActivate={showselectedUser}
            isAdmin={isAdmin}
            isLoading={isLoading}
          />
          {users?.data?.pagination?.total > 0 && (
            <PHRPagination onPaginationAction={updatePaginationParams} pagination={users?.data?.pagination} />
          )}
        </div>
      </div>
      <Modal
        isOpen={addUserModal}
        shouldCloseOnOverlayClick
        title={selectedUser ? 'Edit user' : 'Add user'}
        styles={{
          width: '560px',
          padding: 0,
        }}
        onRequestClose={closeModal}
      >
        <AddUser isOpened={addUserModal} onClose={closeModal} selectedUser={selectedUser} params={params} />
      </Modal>
      <ConfirmationModal
        isOpen={userModal}
        onAction={selectedUser?.active ? deactivateUser : activateUser}
        onClose={() => {
          setUserModal(false);
        }}
        description={
          selectedUser?.active
            ? 'Are you sure you want to deactivate this user?'
            : 'Are you sure you want to activate this user?'
        }
        title={selectedUser?.active ? 'Deactivate user' : 'Activate user'}
        type={selectedUser?.active ? 'danger' : 'info'}
        actionText={selectedUser?.active ? 'Deactivate' : 'Activate'}
      />
    </div>
  );
};
export default Users;
