import React, { useState, useEffect } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';
import Select from 'react-select';

import translateOptionsCountries from '../utility/translateOptions';

const selectStyles = {
  control: (provided) => ({
    ...provided,
    minHeight: '40px',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#a7b5bf',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#284b64',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    visibility: 'hidden',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#1A938A' : 'white',
    color: state.isSelected ? 'white' : '#284b64',
    opacity: state.isDisabled ? '0.3' : '1',
  }),
  menuList: (provided) => ({
    ...provided,
    height: '150px',
  }),
};

const MultipleSelectField = ({
  className,
  name,
  label,
  options,
  isDisabled,
  isRequired,
  styles,
  change,
  error,
  blur,
  value,
  isFormik,
  onKeyPress,
  scrollMore,
  isSearchDesign,
}) => {
  const [inputValue, setInputValue] = useState([]);
  const translatedOptions = translateOptionsCountries(options);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const onChange = (val) => {
    if (isFormik) {
      if (change) {
        change(name)(val);
      }
    } else {
      change(name, val);
    }
  };

  return (
    <div className={cx(className, error ? 'is-invalid' : '', isSearchDesign ? 'search-select-design' : '')}>
      <div className='d-flex'>
        {label ? (
          <label className='label-title' htmlFor={name}>
            {label}
            {isRequired && <>*</>}
          </label>
        ) : null}
      </div>
      <Select
        isMulti
        hideSelectedOptions
        styles={{ ...selectStyles, ...styles }}
        name={name}
        className='styled-select'
        classNamePrefix='react-select'
        placeholder='Select'
        options={translatedOptions}
        value={inputValue}
        isDisabled={isDisabled}
        isClearable={!isRequired}
        backspaceRemoves={!isRequired}
        deleteRemoves={!isRequired}
        onChange={(val) => {
          onChange(val);
        }}
        onBlur={blur}
        onKeyDown={onKeyPress}
        onMenuScrollToBottom={scrollMore}
      />
      {!isSearchDesign && <div className='error'>{error}</div>}
    </div>
  );
};

MultipleSelectField.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object),
  value: PropTypes.array,
  label: PropTypes.string,
  change: PropTypes.func,
  blur: PropTypes.func,
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  className: PropTypes.string,
  styles: PropTypes.object,
  isFormik: PropTypes.bool,
  onKeyPress: PropTypes.func,
  isSearchDesign: PropTypes.bool,
};

MultipleSelectField.defaultProps = {
  value: [],
  label: '',
  change: undefined,
  blur: undefined,
  isDisabled: false,
  isRequired: false,
  className: '',
  styles: undefined,
  options: [],
  isFormik: true,
  onKeyPress: undefined,
  isSearchDesign: false,
};

export default MultipleSelectField;
