import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './SuccessCheckmark.module.scss';

const SuccessCheckmark = ({ positionFixed, text }) => (
  <div
    className={cx(
      styles.sendingOverlay,
      'd-flex',
      'justify-content-center',
      'align-items-center',
      positionFixed && 'position-fixed'
    )}
  >
    <div className={styles.content}>
      <div className={cx(styles.box, 'd-flex', 'justify-content-center', 'align-items-center')}>
        <div className={styles.smallCheck} />
        <div className={styles.bigCheck} />
        <div className={styles.circle} />
      </div>
      <p className={cx(styles.text, 'align-self-end', 'm-0')}>{text}</p>
    </div>
  </div>
);

SuccessCheckmark.propTypes = {
  positionFixed: PropTypes.bool,
  text: PropTypes.string,
};

SuccessCheckmark.defaultProps = {
  text: 'Changes saved successfully!',
  positionFixed: undefined,
};

export default SuccessCheckmark;
