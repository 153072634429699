import React from 'react';

import { useQueryClient } from '@tanstack/react-query';

import Modal from 'components/Modal/Modal';

import AddTaskForm from '../../forms/AddTaskForm/AddTaskForm';
import { queryKeys, useImplementationTaskEdit } from '../../hooks/useTemplates';

const EditTaskModal = ({ isOpen, onClose, task }) => {
  const initialValues = {
    name: task.name,
    nameLocal: task.nameLocal,
    periodAmount: task.periodAmount,
    periodUnit: task.periodUnit,
    description: task.description,
    order: task.order,
  };
  const { mutate, isLoading } = useImplementationTaskEdit();
  const queryClient = useQueryClient();

  const onHandleSubmit = (data) => {
    mutate(
      {
        id: task.id,
        task: data,
      },
      {
        onSuccess: async () => {
          onClose();
          await queryClient.invalidateQueries({ queryKey: queryKeys.ImplementationTaskDetails(task.id) });
          await queryClient.invalidateQueries({ queryKey: queryKeys.ImplementationTasks() });
        },
      }
    );
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} title={'Edit Task'} styles={{ width: '720px' }}>
      <AddTaskForm initialValues={initialValues} onSubmit={onHandleSubmit} isLoading={isLoading} />
    </Modal>
  );
};

export default EditTaskModal;
