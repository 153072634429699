import React from 'react';
import SelectField from 'components/SelectField';
import TextInput from 'components/forms/TextInput/TextInput';

const NewCustomerBasicInfoInputs = ({ formik, countries, edit }) => (
  <div className='row'>
    <div className='col-md-6 mb-3'>
      <TextInput
        name='firstName'
        label='First Name'
        value={formik.values.firstName}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        isRequired
        error={formik.touched.firstName && formik.errors.firstName}
      />
    </div>
    <div className='col-md-6 mb-3'>
      <TextInput
        name='lastName'
        label='Last Name'
        value={formik.values.lastName}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        isRequired
        error={formik.touched.lastName && formik.errors.lastName}
      />
    </div>
    <div className='col-md-6 mb-3'>
      <TextInput
        name='companyName'
        label='Company'
        value={formik.values.companyName}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        isRequired
        error={formik.touched.companyName && formik.errors.companyName}
      />
    </div>
    <div className='col-md-6 mb-3'>
      <SelectField
        isCustom
        label='Country'
        name='country'
        placeholder='Select'
        options={countries?.map((item) => ({
          id: item.alphaThreeCode,
          name: item.name,
          option: item.name,
          value: item.alphaThreeCode,
        }))}
        value={formik.values.country}
        onChange={formik.handleChange}
        error={formik.touched.country && formik.errors.country}
        isDisabled={!!edit}
        isRequired
      />
    </div>
    <div className='col-md-6 mb-3'>
      <TextInput
        type='email'
        name='workEmail'
        label='Work Email'
        value={formik.values.workEmail}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        isRequired
        error={formik.touched.workEmail && formik.errors.workEmail}
      />
    </div>
    <div className='col-md-6 mb-3'>
      <TextInput
        name='phoneNumber'
        label='Phone'
        value={formik.values.phoneNumber}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.phoneNumber && formik.errors.phoneNumber}
      />
    </div>
    <div className='col-md-6 mb-3'>
      <TextInput
        name='jobTitle'
        label='Job Title'
        value={formik.values.jobTitle}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        isRequired
        error={formik.touched.jobTitle && formik.errors.jobTitle}
      />
    </div>
    <div className='col-md-6 mb-3'>
      <TextInput
        name='department'
        label='Department'
        value={formik.values.department}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.department && formik.errors.department}
      />
    </div>
  </div>
);
export default NewCustomerBasicInfoInputs;
