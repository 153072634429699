import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './Tooltip.module.scss';

const Tooltip = ({ label, className, position }) => (
  <span className={cx(styles.tooltip, className, styles[position], 'tooltip-content', 'px-2', 'text-center')}>
    {label}
  </span>
);

Tooltip.propTypes = {
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  position: PropTypes.string,
};

Tooltip.defaultProps = {
  className: '',
  position: '',
};

export default Tooltip;
