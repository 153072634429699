import React, { useState, useContext } from 'react';

import { useFormik } from 'formik';
import jwtDecode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

import AuthApi from '../../api/AuthApi';
import AuthStatic from '../../Shared/Auth/AuthStatic';
import { AuthContext } from '../AuthContext';

const Login = () => {
  const { dispatch } = useContext(AuthContext);
  const [formHasErrors, setFormHasErrors] = useState(false);
  const [formIsValid, setFormIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loginText, setLoginText] = useState('Enter your login details');
  const navigate = useNavigate();

  const validate = (values) => {
    const errors = {};
    if (!values.username) {
      errors.username = 'Email is required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.username)) {
      errors.username = 'Invalid email address';
    }

    if (!values.password) {
      errors.password = 'Password is required';
    }

    if (Object.keys(errors).length === 0) {
      setFormHasErrors(false);

      if (Object.keys(values).length > 0) {
        setFormIsValid(true);

        Object.keys(values).forEach((key) => {
          if (values[key] === null || values[key] === undefined) {
            setFormIsValid(false);
          }
        });
      }
    } else {
      setFormHasErrors(true);
      setFormIsValid(false);
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
      rememberMe: false,
    },
    validate,
    onSubmit: (values) => {
      setIsLoading(true);
      AuthApi.login(values)
        .then((res) => {
          dispatch({
            type: 'set',
            payload: {
              user: jwtDecode(res.data.token),
              token: res.data.token,
              refreshToken: res.data.refreshToken,
            },
          });
          navigate('/dashboard');
        })
        .catch((error) => {
          const errorResponse = error.response;
          if (errorResponse?.status === 307) {
            navigate(`reset-password?token=${errorResponse.data.token}&username=${errorResponse.data.username}`);
          } else {
            setLoginText('Bad credentials');
            setIsLoading(false);
            setFormHasErrors(true);
            setFormIsValid(false);
          }
        });
    },
  });

  return (
    <AuthStatic>
      <p className={`lead mb-4 ${formHasErrors ? 'error-red' : formIsValid ? 'success-green' : null}`}>
        {formik.errors.username ? formik.errors.username : formik.errors.password ? formik.errors.password : loginText}
      </p>

      <form className='form-signin' onSubmit={formik.handleSubmit}>
        <div className='form-group phr-from-group'>
          <label className='sr-only' htmlFor='inputEmail'>
            email or username
          </label>

          <div className='input-group mb-3'>
            <div className='input-group-prepend'>
              <span className={`input-group-text phr-email-icon ${formik.errors.username ? 'error-red' : null}`} />
            </div>

            <input
              type='email'
              name='username'
              id='inputEmail'
              className='form-control'
              placeholder='email or username'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.username}
            />
          </div>
        </div>

        <div className='form-group phr-from-group'>
          <label className='sr-only' htmlFor='inputPassword'>
            password
          </label>

          <div className='input-group mb-3'>
            <div className='input-group-prepend'>
              <span className={`input-group-text phr-password-icon ${formik.errors.password ? 'error-red' : null}`} />
            </div>
            <input
              type={showPassword ? 'text' : 'password'}
              name='password'
              id='inputPassword'
              className='form-control'
              placeholder='password'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
            />
            <i
              className={showPassword ? 'icon-eye-no text-gray' : 'icon-eye text-gray'}
              style={{
                position: 'absolute',
                top: '11px',
                right: '15px',
                zIndex: '9999',
                cursor: 'pointer',
              }}
              onClick={() => {
                setShowPassword(!showPassword);
              }}
            />
          </div>
        </div>

        <div className='row m-0 my-4'>
          <div className='col ps-1'>
            <div className='form-group text-left'>
              <div className=''>
                <input
                  className='square-checkbox square'
                  type='checkbox'
                  name='rememberMe'
                  id='inputRememberMe'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.rememberMe}
                />

                <label className='label' htmlFor='inputRememberMe'>
                  Keep me Signed in
                </label>
              </div>
            </div>
          </div>
          <div className='col'>
            <a href='/forgot-password'>
              <p className='text text-right'>Forgot Password?</p>
            </a>
          </div>
        </div>

        {isLoading ? (
          <button
            className='btn btn-primary text-white btn btn-primary btn-block text-light loadingButton'
            type='button'
            disabled
          >
            <div className='d-flex align-items-center justify-content-center'>
              <span className='spinner-grow spinner-grow-sm' role='status' aria-hidden='true' />
              <span className='ms-2'>Login</span>
            </div>
          </button>
        ) : (
          <button
            className='btn btn-primary w-100 text-light'
            type='submit'
            disabled={!formik.values.username || !formik.values.password || formHasErrors}
          >
            Login
          </button>
        )}
      </form>
    </AuthStatic>
  );
};

export default Login;
