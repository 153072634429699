import React, { useContext, useEffect, useState } from 'react';

import cx from 'classnames';
import _ from 'lodash';
import Scrollbars from 'react-custom-scrollbars-2';
import Modal from 'react-modal';
import { PHRPagination } from '@palmhr/palmhr-ui-lib';

import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import InvoicePreview from './InvoicePreview';
import UnbilledChargeItem from './UnbilledChargeItem';
import styles from './UnbilledCharges.module.scss';
import CustomersApi from '../../../../api/CustomersApi';
import modalStyles from '../../../../components/modalStyles';
import TableSkeleton from '../../../../components/skeletons/TableSkeleton';
import TableHead from '../../../../components/table/TableHead';
import ClientContext from '../ClientContext';

const addModalStyle = _.cloneDeep(modalStyles);
addModalStyle.content.width = '640px';
addModalStyle.content.padding = '20px';

const UnbilledCharges = ({ goToInvoice }) => {
  const { selected } = useContext(ClientContext);
  const [unbilledChargesPagination, setUnbilledChargesPagination] = useState([]);
  const [data, setData] = useState([]);
  const [confirmModal, setConfirmModal] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [invoicePreview, setInvoicePreview] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [sent, setSent] = useState(false);
  const [error, setError] = useState('');
  const [params, setParams] = useState({
    currentPage: 1,
    perPage: 5,
  });

  const headerCells = [
    { title: 'From' },
    { title: 'To' },
    { title: 'Description' },
    { title: 'Unit Amount' },
    { title: 'Quantity' },
    { title: 'Amount', colSpan: 2 },
  ];

  useEffect(() => {
    if (selected) {
      setLoadingData(true);
      CustomersApi.getUnbilledCharges(selected.id, params)
        .then((res) => {
          setData(res.data || []);
          setUnbilledChargesPagination(res.data.pagination || []);
          setLoadingData(false);
        })
        .catch(() => {
          setLoadingData(false);
        });
    }
  }, [selected, params]);

  const updatePaginationParams = ({ currentPage, perPage }) =>
    setParams((prevState) => ({ ...prevState, currentPage, perPage }));

  const actionHandler = (id) => {
    setSelectedId(id);
    setConfirmModal(true);
  };

  const onDelete = () => {
    setIsSending(true);
    const param = selected.id;
    CustomersApi.deleteUnbilledCharge(param, selectedId)
      .then((res) => {
        let state = [...data];
        state = state.filter((el) => el.id !== selectedId);
        setData(state);
        setIsSending(false);
        setSent(true);
        setTimeout(() => {
          setSent(false);
          setConfirmModal(false);
        }, 1000);
      })
      .catch((res) => {
        setIsSending(false);
      });
  };

  const onGenerateInvoice = () => {
    setIsSending(true);
    const param = selected.id;
    CustomersApi.generateInvoice(param)
      .then(() => {
        setIsSending(false);
        setSent(true);
        setTimeout(() => {
          setSent(false);
          setInvoicePreview(false);
          goToInvoice();
        }, 1000);
      })
      .catch((err) => {
        setIsSending(false);
        if (err.response.status >= 300 && err.response.status < 500) {
          setError(err?.response?.data?.detail);
        }
      });
  };

  if (loadingData) {
    return (
      <div className='me-5'>
        <TableSkeleton num={5} lineHight={40} mb='mb-2' />
      </div>
    );
  }
  return (
    <div>
      {data.length > 0 ? (
        <div className='me-5 mt-3' style={{ height: 'calc(100vh - 265px)' }}>
          <div
            className={styles.invoiceBtn}
            onClick={() => {
              setInvoicePreview(true);
            }}
          >
            Invoice Now
          </div>
          <Scrollbars
            className='scrollbarsWrapper'
            autoHide
            autoHideTimeout={1000}
            autoHideDuration={200}
            style={{ height: `100%` }}
          >
            <table className='w-100'>
              <TableHead headerCells={headerCells} className='sticky-top' />
              <tbody>
                {data.map((item) => (
                  <UnbilledChargeItem
                    key={item.id}
                    data={item}
                    onDelete={() => {
                      actionHandler(item.id);
                    }}
                  />
                ))}
              </tbody>
            </table>
          </Scrollbars>
          {unbilledChargesPagination?.total > 0 && (
            <PHRPagination onPaginationAction={updatePaginationParams} pagination={unbilledChargesPagination} />
          )}
        </div>
      ) : (
        <div className='pt-8 me-5'>
          <div className={cx(styles.emptyList, 'm-auto')}>
            <div className={cx(styles.iconHistory, 'd-flex', 'justify-content-center', 'align-items-center', 'm-auto')}>
              <i className={cx(styles.icon, 'icon-file-text')} />
            </div>
            <p className='mt-4 text-center text-capitalize'>No unbilled charges</p>
          </div>
        </div>
      )}
      <ConfirmationModal
        isOpen={confirmModal}
        onAction={onDelete}
        onClose={() => {
          setConfirmModal(false);
        }}
        description={'Are you sure you want to delete this unbilled charge?'}
        title={'Delete Unbilled Charge'}
        type={'danger'}
        isLoading={isSending}
        actionText={'Delete'}
      />
      <Modal
        isOpen={invoicePreview}
        contentLabel='Preview'
        ariaHideApp={false}
        closeTimeoutMS={300}
        style={addModalStyle}
        onRequestClose={() => {
          setInvoicePreview(false);
        }}
      >
        <InvoicePreview
          saving={isSending}
          saved={sent}
          onGenerateInvoice={onGenerateInvoice}
          items={data}
          error={error}
          onClose={() => {
            setInvoicePreview(false);
          }}
        />
      </Modal>
    </div>
  );
};

export default UnbilledCharges;
