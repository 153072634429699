import http from './Api';

class CareersApi {
  static getCareers(params) {
    return http.get('/careers', { params });
  }

  static careersDetails(id) {
    return http.get(`/careers/${id}`);
  }

  static editCareersStatus(id, data) {
    return http.put(`/careers/${id}/change-status`, data);
  }

  static filePreview(id, action = 'preview') {
    return http.get(`/filestock/${id}/${action}`, {
      responseType: 'arraybuffer',
    });
  }

  static deleteCareers(id) {
    return http.put(`/careers/${id}/delete`);
  }
}

export default CareersApi;
