import React from 'react';

import { useFormik } from 'formik';
import * as yup from 'yup';

import RoundedButton from 'components/RoundedButton/RoundedButton';
import FormContext from 'components/FormContext';
import SpinnerRing from 'components/SpinnerRing';
import SuccessCheckmark from 'components/SuccessCheckmark';
import TextInput from 'components/forms/TextInput/TextInput';
import Textarea from 'components/forms/Textarea/Textarea';

const createValidationSchema = () =>
  yup.object({
    name: yup.string().required('Name is required'),
    nameLocal: yup.string(),
    description: yup.string(),
  });

const AddNote = ({ onSave, selectedNote, saved, isSending }) => {
  const validationSchema = createValidationSchema();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: selectedNote?.name || '',
      nameLocal: selectedNote?.nameLocal || '',
      description: selectedNote?.description || '',
    },
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      const submissionValues = { ...values };
      onSave(!!selectedNote, submissionValues).finally(() => setSubmitting(false));
    },
  });

  return (
    <>
      {isSending && <SpinnerRing />}
      {saved && <SuccessCheckmark />}
      <FormContext.Provider value={{ formik }}>
        <form noValidate onSubmit={formik.handleSubmit} className='h-100'>
          <div className='d-flex mx-5 mt-4'>
            <div className='row row-small'>
              <div className='col-12 mb-3'>
                <TextInput
                  name='name'
                  sufix={'en'}
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.name && formik.errors.name ? formik.errors.name : undefined}
                  label='name'
                  isRequired
                />
              </div>
              <div className='col-12 mb-3'>
                <TextInput
                  name='nameLocal'
                  sufix={'ar'}
                  value={formik.values.nameLocal}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.nameLocal && formik.errors.nameLocal ? formik.errors.nameLocal : undefined}
                  direction='rtl'
                />
              </div>
              <div className='w-100 mb-3'>
                <Textarea
                  label='Description'
                  rows='9'
                  id='content'
                  name='description'
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder='Type your description here'
                  className='form-control'
                />
              </div>
            </div>
          </div>

          <div className='d-flex justify-content-end  border-t py-4 mt-4'>
            <RoundedButton
              className='me-5'
              type='submit'
              text={selectedNote ? 'Save' : 'Add'}
              btnStyle='contained'
              color='primary'
              size='md'
            />
          </div>
        </form>
      </FormContext.Provider>
    </>
  );
};

export default AddNote;
