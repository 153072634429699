import React from 'react';

import cx from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import Scrollbars from 'react-custom-scrollbars-2';

import styles from './InvoicePreview.module.scss';
import IconButton from '../../../../components/IconButton';
import SpinnerRing from '../../../../components/SpinnerRing';
import SuccessCheckmark from '../../../../components/SuccessCheckmark';
import formatCurrency from '../../../../utility/formatCurrency';

const InvoicePreview = ({ onClose, items, onGenerateInvoice, saving, saved, error }) => {
  const subtotal = items.reduce((acc, val) => acc + parseFloat(val.amount), 0);
  const tax = 0.15 * subtotal;

  return (
    <div className='pt-4'>
      {saving && <SpinnerRing />}
      {saved && <SuccessCheckmark />}
      <div className='d-flex justify-content-between pb-3 border-btm mx-5'>
        <h5 className='fw-bold'>Invoice Preview</h5>
        <IconButton icon='icon-cross-x' onClick={onClose} />
      </div>
      <Scrollbars autoHide className='scroll-bar' style={{ height: '400px' }}>
        <div className={cx(styles.invoice, 'p-4 mt-5 p-5 mx-5')}>
          <ul className='pb-3 border-btm'>
            {items.length > 0 &&
              items.map((item) => (
                <li key={item.id} className='mb-3'>
                  <div className='d-flex align-items-center justify-content-between'>
                    <div>{item.description}</div>
                    <div>
                      <span className='text-gray me-1'>{item.currency}</span>
                      <span>{formatCurrency(item.amount)}</span>
                    </div>
                  </div>
                  <div className='text-gray'>
                    <span>{`(${item.currency} ${formatCurrency(item.unitAmount)} x ${item.quantity}) `}</span>
                    <span>
                      {`${moment(item.dateFrom).format('MMM DD, YYYY')} to ${moment(item.dateTo).format(
                        'MMM DD, YYYY'
                      )}`}
                    </span>
                  </div>
                </li>
              ))}
          </ul>
          <div className='border-btm pb-3'>
            <div className='d-flex align-items-center justify-content-between mb-3'>
              <div>Subtotal</div>
              <div>
                <span className='text-gray me-1'>{items[0].currency}</span>
                <span>{formatCurrency(subtotal)}</span>
              </div>
            </div>
            <div className='d-flex align-items-center justify-content-between'>
              <div>Standard rate Value Added Tax @ 15%</div>
              <div>
                <span className='text-gray me-1'>{items[0].currency}</span>
                <span>{formatCurrency(tax)}</span>
              </div>
            </div>
          </div>
          <div>
            <div className='d-flex align-items-center fw-bold justify-content-between mb-3 pt-3'>
              <div>Total</div>
              <div>
                <span className='text-gray me-1'>{items[0].currency}</span>
                <span>{formatCurrency(subtotal + tax)}</span>
              </div>
            </div>
          </div>
          <div className='error'>{error}</div>
        </div>
      </Scrollbars>
      <div className='d-flex justify-content-end py-0 mt-8 border-t py-4 mx-5'>
        <button type='button' className='btn btn-primary text-white' onClick={onGenerateInvoice}>
          Generate Invoice
        </button>
      </div>
    </div>
  );
};

InvoicePreview.propTypes = {
  onClose: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  onGenerateInvoice: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
  saved: PropTypes.bool.isRequired,
  error: PropTypes.string,
};

InvoicePreview.defaultProps = {
  error: '',
};

export default InvoicePreview;
