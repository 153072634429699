import { useQueryClient } from '@tanstack/react-query';
import AnnouncementsApi from '../../api/AnnouncementsApi';
import { useQueryHook, useMutationHook } from 'utility/reactQueryHooks';

export const queryKeys = {
  announcements: (params) => ['announcements', JSON.stringify(params)],
  publishAnnouncement: ['publishAnnouncement'],
  deleteAnnouncement: ['deleteAnnouncement'],
  updateAnnouncement: ['updateAnnouncement'],
  createAnnouncement: ['createAnnouncement'],
};

export const useQueryGetAnnouncements = (platform, params) =>
  useQueryHook(queryKeys.announcements(params), () => AnnouncementsApi.getAnnouncements(platform, params));

export const usePublishAnnouncement = () =>
  useMutationHook(queryKeys.publishAnnouncement, AnnouncementsApi.publishAnnouncement);

export const useDeleteAnnouncement = () => {
  const queryClient = useQueryClient();
  return useMutationHook(queryKeys.deleteAnnouncement, AnnouncementsApi.deleteAnnouncement, {
    onSuccess: async () => {
      await queryClient.refetchQueries(['announcements'], { exact: true });
    },
  });
};

export const useMutationUpdateAnnouncement = () =>
  useMutationHook(queryKeys.updateAnnouncement, AnnouncementsApi.updateAnnouncement);

export const useCreateAnnouncement = () =>
  useMutationHook(queryKeys.createAnnouncement, AnnouncementsApi.createAnnouncement);
