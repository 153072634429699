import React from 'react';

import DataTable from 'components/DataTable/DataTable';
import EmptyState from 'components/EmptyState/EmptyState';
import CustomerInsightRow from 'Insights/Subscriptions/pages/CustomerInsightRow';

const headerCells = [
  { title: 'Account', sortable: true, sortValue: 'account' },
  { title: 'Plan', sortable: true, sortValue: 'plan' },
  { title: 'Account status', sortable: true, sortValue: 'status' },
  { title: 'Renewal date', sortable: true, sortValue: 'renewal_date' },
  { title: 'Renewal status', sortable: true, sortValue: 'renewal_status' },
  { title: 'Payment confirmation', sortable: true, sortValue: 'payment_confirmation' },
  { title: 'Latest login', sortable: true, sortValue: 'last_login' },
  { title: '' },
];

const SubscriptionsTable = ({ subscriptions, isLoading, onSort, updateList, onSelectRow }) => (
  <DataTable
    isScroll
    data={subscriptions}
    isLoading={isLoading}
    columns={headerCells}
    headerAdditionalProps={{
      onSort,
    }}
    TableRowComponent={CustomerInsightRow}
    rowAdditionalProps={{ updateList, onSelectRow }}
    EmptySearchComponent={() => <EmptyState message='No Subscriptions' />}
  />
);

export default SubscriptionsTable;
