import ClientsApi from 'api/ClientsApi';
import { useQueryHook } from 'utility/reactQueryHooks';

export const queryKeys = {
  implementationOverview: (params) => ['implementationOverview', params],
  listPrimaryPlans: ['listPrimaryPlans'],
};

export const useQueryImplementationOverview = (params) =>
  useQueryHook(queryKeys.implementationOverview(params), () => ClientsApi.getImplementationOverview(params));

export const useQueryListPrimaryPlans = () =>
  useQueryHook(queryKeys.listPrimaryPlans, () => ClientsApi.getPrimaryPlans());
