import React, { useState, useEffect } from 'react';

import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars-2';

import ClientContext from './ClientContext';
import styles from './CustomersCompaniesModal.module.scss';
import HeadCountBarItem from './HeadCountBarItem';
import CustomersApi from '../api/CustomersApi';
import gradient from '../components/animations/gradient.module.scss';
import IconButton from '../components/IconButton';
import SelectMenu from '../components/SelectMenu';
import Line from '../components/skeletons/Line';

const CustomersModal = ({ onClose }) => {
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState({
    items: [],
    totalUsers: 0,
  });
  const [dropdown, setDropdown] = useState(false);
  const [selected] = useState(undefined);
  const [disabeDirBtn, setDisabeDirBtn] = useState({
    next: false,
    prev: false,
  });

  const style = { height: 400, width: '100%', borderRadius: 8, opacity: 0.7 };

  useEffect(() => {
    setLoading(true);
    CustomersApi.getCustomersHeadCount()
      .then((res) => {
        setCompanies(res.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const colorsMap = {
    'pro-40-yearly': '#62D26F',
    'pro-40-monthly': '#62D26F',
    'basic-10_24-yearly': '#4EB1F9',
    'basic-10_24-monthly': '#4EB1F9',
    'basic-25_39-yearly': '#7A6FF0',
    'basic-25_39-monthly': '#7A6FF0',
    'basic-1_9-yearly': '#ffa150',
    'basic-1_9-monthy': '#ffa150',
  };

  const closeHandler = () => {
    onClose();
  };

  const selectFileType = (type) => {
    setLoading(true);
    CustomersApi.customersFileDownload(type).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `top_five.${type}`);
      document.body.appendChild(link);
      link.click();
      setTimeout(() => {
        document.body.removeChild(link);
      }, 1000);
    });
    onClose();
  };

  useEffect(() => {
    if (selected) {
      const psIndex = _.findIndex(companies.items, ['company', selected.company]);
      const btnState = { ...disabeDirBtn };
      btnState.prev = psIndex <= 0;
      btnState.next = psIndex >= companies.items.length - 1;
      setDisabeDirBtn(btnState);
    }
  }, [selected]);

  return (
    <ClientContext.Provider value={{ selected }}>
      <div className='pb-4'>
        <div className='d-flex justify-content-end'>
          <IconButton icon='icon-cross-x' className='pt-3 pe-5' onClick={closeHandler} />
        </div>
        <header className='d-flex justify-content-between align-items-center border-btm mb-3 w-100'>
          <h5 className='pb-4 pt-4 ps-4 pe-4 fw-bold'>List of all companies</h5>
          <div className='timeoff-header position-relative d-flex align-items-center'>
            <div className='d-flex justify-content-end align-items-center pe-4'>
              <button
                type='button'
                onClick={() => setDropdown(true)}
                className={cx(styles.dwnlBtn, 'btn btn-primary text-white')}
              >
                <div className='d-flex align-items-center justify-content-between'>
                  <span>Download</span>
                  <i className='icon-chevron-bottom ms-2' />
                </div>
              </button>
            </div>
            {dropdown && (
              <SelectMenu
                onClose={() => setDropdown(false)}
                items={['pdf', 'csv', 'xls']}
                icons={['icon-file-pdf', 'icon-file-csv', 'icon-file-xls']}
                className={styles.selectTop}
                onSelect={selectFileType}
              />
            )}
          </div>
        </header>
        <Scrollbars autoHide className='scroll-bar' autoHeight autoHeightMin={200} autoHeightMax='calc(100vh - 200px)'>
          {loading ? (
            <Line style={style} gradient={gradient.gradient} />
          ) : (
            <div className='px-5'>
              {companies?.items.length > 0 &&
                companies.items.map((company) => (
                  <HeadCountBarItem
                    key={company.company}
                    title={company.company}
                    value={company.numberOfEmployees}
                    plan={company.subscriptionPlan}
                    url={company.customerUrl}
                    percent={(company.numberOfEmployees / companies.totalUsers) * 100}
                    total={companies.totalUsers}
                    color={colorsMap[company.subscriptionPlan]}
                  />
                ))}
            </div>
          )}
        </Scrollbars>
      </div>
    </ClientContext.Provider>
  );
};

CustomersModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default CustomersModal;
