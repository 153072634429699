import { useQueryHook } from 'utility/reactQueryHooks';

import ClientsApi from '../api/ClientsApi';

export const queryKeys = {
  insightCustomers: (params) => ['insightCustomers', JSON.stringify(params)],
};

export const useInsightCustomersQuery = (params) =>
  useQueryHook(queryKeys.insightCustomers(params), () => ClientsApi.getInsightCustomers(params));
