import React from 'react';

import DataTable from 'components/DataTable/DataTable';
import EmptyState from 'components/EmptyState/EmptyState';
import NotesTableRow from './NotesTableRow';

const headerCells = [
  { title: 'Note Name', className: 'w-70' },
  { title: 'Date Created', sortable: true, sortValue: 'createdAt', className: 'w-20' },
  { title: ' ', className: 'w-10' },
];

const NotesTable = ({ notes, isLoading, onSort, onEdit, isScroll }) => (
  <DataTable
    isScroll={isScroll}
    tableHeight={'80%'}
    className='me-5'
    data={notes}
    isLoading={isLoading}
    columns={headerCells}
    headerAdditionalProps={{
      onSort,
    }}
    TableRowComponent={NotesTableRow}
    rowAdditionalProps={{ onEdit }}
    EmptySearchComponent={() => <EmptyState message='No Notes Yet' />}
  />
);

export default NotesTable;
