import React from 'react';

import PropTypes from 'prop-types';

import GridItem from './GridItem';

const Grid = ({ items, onSelect }) => (
  <div className='row row-small mt-5'>
    {items.map((item) => (
      <GridItem key={item.title} item={item} onSelect={onSelect} />
    ))}
  </div>
);

Grid.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default Grid;
