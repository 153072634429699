import React from 'react';

import { PHRConfirmationModal } from '@palmhr/palmhr-ui-lib';
import PropTypes from 'prop-types';
/**
 * ConfirmationModal component to display a confirmation box within a modal.
 * @param {object} props - The props object.
 * @param {boolean} props.isOpen - Flag indicating whether the modal is open.
 * @param {function} props.onAction - Function to be called when action is performed.
 * @param {function} props.onClose - Function to be called when modal is closed.
 * @param {object} [props.responseStatus] - Response status object.
 * @param {boolean} [props.isLoading=false] - Flag indicating whether loading state is active.
 * @param {string} [props.description=''] - Description text to display.
 * @param {string} [props.title=''] - Title text to display.
 * @param {string} [props.type='confirm'] - Type of confirmation ('confirm', 'warning', etc.).
 * @param {function} [props.onRequestClose] - Function to be called when modal is requested to be closed.
 * @param {string} [props.actionText='Confirm'] - Text to display on the action button.
 * @param {object} [props.styles={}] - Additional styles for the modal.
 * @returns {JSX.Element} ConfirmationModal component.
 */
const ConfirmationModal = ({
  isOpen,
  onAction,
  onClose,
  responseStatus,
  isLoading,
  description,
  title,
  type,
  onRequestClose,
  actionText,
  styles,
  direction,
}) => (
  <PHRConfirmationModal
    onAction={onAction}
    onClose={onClose}
    isOpen={isOpen}
    responseStatus={responseStatus}
    isLoading={isLoading}
    description={description}
    title={title}
    type={type}
    onRequestClose={onRequestClose}
    actionText={actionText}
    styles={styles}
    direction={direction}
  />
);

ConfirmationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onAction: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  responseStatus: PropTypes.object,
  isLoading: PropTypes.bool,
  description: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  onRequestClose: PropTypes.func,
  actionText: PropTypes.string,
  styles: PropTypes.object,
  direction: PropTypes.oneOf(['ltr', 'rtl']),
};

ConfirmationModal.defaultProps = {
  isLoading: false,
  description: '',
  title: '',
  type: 'confirm',
  responseStatus: {},
  onRequestClose: null,
  actionText: 'Confirm',
  styles: {},
  direction: 'ltr',
};

export default ConfirmationModal;
