import React from 'react';

import { PHRGroupedList } from '@palmhr/palmhr-ui-lib';
import PropTypes from 'prop-types';

const GroupedList = ({ groups, className, onClickItem, isLoading, activeItemKey, height, direction }) => (
  <PHRGroupedList
    groups={groups}
    className={className}
    onClickItem={onClickItem}
    isLoading={isLoading}
    activeItemKey={activeItemKey}
    height={height}
    direction={direction}
  />
);

export default GroupedList;

PHRGroupedList.propTypes = {
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      showAddBtn: PropTypes.bool,
      addButtonTooltip: PropTypes.string,
      addButtonText: PropTypes.string,
      handleOnClickAdd: PropTypes.func,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          label: PropTypes.string,
          data: PropTypes.object,
        })
      ),
    })
  ).isRequired,
  className: PropTypes.string,
  onClickItem: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  activeItemKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  direction: PropTypes.oneOf(['rtl', 'ltr']),
};

PHRGroupedList.defaultProps = {
  className: '',
  isLoading: false,
  activeItemKey: '',
  height: '500px',
  direction: 'ltr',
};
