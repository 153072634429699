import React from 'react';

import Table from './Table';

const Translations = () => (
  <div className='mt-3 mx-3'>
    <Table />
  </div>
);

export default Translations;
