/* eslint-disable */

import PropTypes from 'prop-types';
import propTypesUtility from 'utility/propTypesUtility';
import cx from 'classnames';
import styles from './style.module.scss';
import _ from 'lodash';

export const propTypes = () =>
{
    return {
        id: propTypesUtility.idPropType(),
        className: propTypesUtility.classNamePropType(),
        contentClassName: propTypesUtility.classNamePropType(),
        style: propTypesUtility.stylePropType(),
        title: PropTypes.element,
        footer: PropTypes.element,
        onScroll: PropTypes.func,
    };
};

export const defaultProps = () =>
{
    return {
        id: 'phr-panel',
        className: '',
        contentClassName: '',
        style: null,
        title: null,
        footer: null,
        onScroll: null,
    };
};

export const containerClasses = (className) =>
{
    return cx(styles.container, `${className}`);
};

export const contentClasses = (contentClassName) =>
{
    return `flex-1 ${contentClassName} overflow-y-auto`;
};

export const onContentScroll = _.curry((onScroll, event) =>
{
    if(!onScroll) return;
    onScroll({event});
});