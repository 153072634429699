import React from 'react';

import PropTypes from 'prop-types';

import IconButton from '../IconButton';
import SpinnerRing from '../SpinnerRing';
import SuccessCheckmark from '../SuccessCheckmark';

const ConfirmModal = ({
  onClose,
  onSubmit,
  modalName,
  confirmName,
  confirmText,
  customLoader,
  isSending,
  sent,
  error,
}) => {
  const renderLoader = () => customLoader || <SpinnerRing />;
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {sent && <SuccessCheckmark />}
      {isSending && renderLoader()}
      <div>
        <div>
          <div className='d-flex justify-content-between border-btm pt-0 px-0 pb-3'>
            <h5 className='modal-title fw-bold'>{modalName}</h5>
            <IconButton icon='icon-cross-x' onClick={onClose} />
          </div>
          <div className='px-0 mt-3'>{confirmText}</div>
          <div className='error my-2'>{error}</div>
          <div className='d-flex justify-content-end pt-4 mt-3'>
            <button type='button' className='btn btn-secondary me-4' onClick={onClose}>
              Close
            </button>
            <button type='button' className='btn btn-primary text-white' onClick={onSubmit}>
              {confirmName}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

ConfirmModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  modalName: PropTypes.string.isRequired,
  confirmName: PropTypes.string.isRequired,
  confirmText: PropTypes.string.isRequired,
  customLoader: PropTypes.element,
  error: PropTypes.string,
};

ConfirmModal.defaultProps = {
  customLoader: undefined,
  error: '',
};

export default ConfirmModal;
