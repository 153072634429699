export const initialfilterMapArray = [
  {
    title: 'Plan',
    options: [],
  },
  {
    title: 'Task Status',
    options: [
      { id: 5, field: 'status', label: 'To Do', selected: false },
      { id: 6, field: 'status', label: 'In Progress', selected: false },
      { id: 7, field: 'status', label: 'Completed', selected: false },
    ],
  },
];
