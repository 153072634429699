import axios from 'axios';
import * as JWT from 'jwt-decode';

import { setLocalStorage, getLocalStorage } from '../Auth/AuthContext';

let data = {};

const apiUrl = process.env.REACT_APP_API_BASE_URL;
const http = axios.create({
  baseURL: apiUrl,
});

const isHandlerEnabled = (config = {}) => !(config.hasOwnProperty('handlerEnabled') && !config.handlerEnabled);

const requestHandler = (request) => {
  if (isHandlerEnabled(request)) {
    data = getLocalStorage('auth');

    // Modify request here
    if (!(request.url === 'users/login' || request.url === 'reset-password' || request.url === 'forgot-password')) {
      request.headers.Authorization = `Bearer ${data?.token}`;
    }

    request.headers['X-Palmhr-Client'] = 'WEB';
  }
  request.headers.tenant = 'palmhr';

  return request;
};

const errorHandler = (error) => {
  if (
    error.config &&
    !(
      error.config.url === 'login' ||
      error.config.url === 'reset-password' ||
      error.config.url === 'forgot-password'
    ) &&
    isHandlerEnabled(error.config)
  ) {
    /** Refresh Token Block * */
    const originalRequest = error.config;

    if (error?.response?.status === 401 && originalRequest.url.includes('token-refresh')) {
      window.location.href = '/login';
      return Promise.reject(error);
    }

    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const { refreshToken } = data;
      return http
        .post('users/token-refresh', { refreshToken })
        .then((res) => {
          if (res.status === 200) {
            const newData = res.data;
            newData.user = JWT(res.data.token);
            setLocalStorage('auth', newData);

            data = getLocalStorage('auth');
            originalRequest.headers.Authorization = `Bearer ${data.token}`;

            return axios(originalRequest);
          }
          window.location.href = '/login';
          return Promise.reject(error);
        })
        .catch(() => {
          window.location.href = '/login';
          return Promise.reject(error);
        });
    }
    /** ./ Refresh Token Block * */
  }

  return Promise.reject(error);
};

const successHandler = (response) => response;

http.interceptors.request.use((request) => requestHandler(request));

http.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);

export default http;
