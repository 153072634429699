import ClientsApi from 'api/ClientsApi';
import { useMutationHook, useQueryHook } from 'utility/reactQueryHooks';

export const queryKeys = {
  getDefaultFeatureFlags: ['getDefaultFeatureFlags'],
  getFeatureFlagsPerTenant: (tenant) => ['getFeatureFlagsPerTenant', tenant],
  postFeatureFlagsPerTenant: (tenant) => ['postFeatureFlagsPerTenant', tenant],
  patchFeatureFlagsPerTenant: (tenant) => ['patchFeatureFlagsPerTenant', tenant],
  postFeatureFlagConfigurationsModule: (tenant) => ['postFeatureFlagConfigurationsModule', tenant],
  deleteFeatureFlagConfigurations: (tenant) => ['deleteFeatureFlagConfigurations', tenant],
};

export const useQueryDefaultFeatureFlagConfigurations = () =>
  useQueryHook(queryKeys.getDefaultFeatureFlags, ClientsApi.getDefaultFeatureFlagConfigurations);

export const useQueryFeatureFlagsPerTenant = (tenant) =>
  useQueryHook(queryKeys.getFeatureFlagsPerTenant(tenant), () => ClientsApi.getFeatureFlagConfigurations(tenant));

export const useMutationPostFeatureFlagConfigPerTenant = (tenant) =>
  useMutationHook(queryKeys.postFeatureFlagsPerTenant(tenant), ClientsApi.postFeatureFlagsConfigPerTenant);

export const useMutationUpdateFeatureFlagPerTenant = (tenant) =>
  useMutationHook(queryKeys.patchFeatureFlagsPerTenant(tenant), ClientsApi.patchFeatureFlagConfigurations);

export const useMutationPostFeatureFlagModulePerTenant = (tenant) =>
  useMutationHook(
    queryKeys.postFeatureFlagConfigurationsModule(tenant),
    ClientsApi.postFeatureFlagConfigurationsModule
  );

export const useMutationDeleteFeatureFlagPerTenant = (tenant) =>
  useMutationHook(queryKeys.deleteFeatureFlagConfigurations(tenant), ClientsApi.deleteFeatureFlagConfigurations);
