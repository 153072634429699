import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { PHRPagination } from '@palmhr/palmhr-ui-lib';

import useDebounce from 'Shared/useDebounce';
import { PACKAGE_NAMES } from 'Customers/constants';
import Search from 'components/Search';
import SearchFilterMenu from 'components/searchFilterMenu/SearchFilterMenu';
import SideTransitionContainer from 'components/searchFilterMenu/SideTransitionContainer';
import IconButton from 'components/IconButton';
import { initialfilterMapArray } from 'Implementation/Overview/constants';
import OverviewTable from './components/OverviewTable/OverviewTable';
import { useQueryImplementationOverview, useQueryListPrimaryPlans } from './hooks/useOverview';

const Overview = () => {
  const isAsc = useRef(false);

  const [search, setSearch] = useState('');
  const [filterMenu, setFilterMenu] = useState(false);
  const [filterMapArray, setFilterMapArray] = useState(initialfilterMapArray);
  const [selectedFilter, setSelectedFilter] = useState([]);

  const debouncedSearch = useDebounce(search, 500);

  const [params, setParams] = useState({
    currentPage: 1,
    perPage: 10,
    search: '',
    sort: '',
  });

  const { isLoading, data } = useQueryImplementationOverview(params);
  const { isLoading: isLoadingPlans, data: plans } = useQueryListPrimaryPlans();

  useEffect(() => {
    if (!isLoadingPlans) {
      const mappedPlans = plans?.data.map((plan) => ({
        id: plan?.identifier,
        field: 'plan',
        label: PACKAGE_NAMES[plan?.name] || plan?.name,
        selected: false,
      }));

      setFilterMapArray((prevFilterMap) => {
        const newFilterMap = [...prevFilterMap];
        newFilterMap[0].options = mappedPlans;
        return newFilterMap;
      });
    }
  }, [plans]);

  useEffect(() => {
    setParams((prevState) => ({ ...prevState, currentPage: 1, search: debouncedSearch }));
  }, [debouncedSearch]);

  const onSort = (option) => {
    isAsc.current = !isAsc.current;
    const dir = isAsc.current ? '+' : '-';
    setParams((prevState) => ({ ...prevState, currentPage: 1, sort: `${dir}${option}` }));
  };

  const updatePaginationParams = ({ currentPage, perPage }) => {
    setParams((prevState) => ({ ...prevState, currentPage, perPage }));
  };

  const closeFilterMenu = () => {
    setFilterMenu(false);
  };

  const openFilterMenu = () => {
    setFilterMenu(true);
  };

  const onSelectField = (value, field, id, groupTitle, label) => {
    setSelectedFilter((prevFilters) => {
      const updatedFilters = _.cloneDeep(prevFilters);
      const filterIndex = updatedFilters.findIndex((f) => f.id === id);

      if (filterIndex >= 0) {
        updatedFilters[filterIndex].selected = value;
      } else {
        updatedFilters.push({ field, id, label, selected: value, value: [_.toLower(id)] });
      }

      return updatedFilters;
    });

    const stateArray = [...filterMapArray];
    stateArray.every((el) => {
      if (el.title === groupTitle) {
        el.options.every((elem) => {
          if (`${elem.id}` === `${id}`) {
            elem.selected = value;

            return false;
          }

          return true;
        });
      }

      return true;
    });
    setFilterMapArray(stateArray);
  };

  const onApplyFilter = (filters) => {
    setFilterMenu(false);
    const planFilters = filters.filter((f) => f.field === 'plan' && f.selected).map((f) => f.id);

    const statusFilters = filters
      .filter((f) => f.field === 'status' && f.selected)
      .map((f) => {
        if (f.label.toLowerCase() === 'to do') {
          return 'TODO';
        }
        return f.label.toUpperCase().replace(' ', '_');
      });

    setParams((prevParams) => {
      const newParams = { ...prevParams };

      if (planFilters.length > 0) {
        newParams.plan = planFilters.join(',');
      } else {
        delete newParams.plan;
      }

      if (statusFilters.length > 0) {
        newParams.status = statusFilters.join(',');
      } else {
        delete newParams.status;
      }

      return newParams;
    });
    // Clear ALL
    if (filters.length === 0) {
      const stateArray = [...filterMapArray];

      stateArray.forEach((el) => {
        el.options.forEach((elem) => {
          elem.selected = false;
        });
      });

      setFilterMapArray(stateArray);
      setSelectedFilter([]);
    }
  };

  return (
    <div className='mt-3 mx-5'>
      <div>
        <div className='d-flex mb-2 mt-2 flex-row align-items-center justify-content-between'>
          <div className='col-4 px-2 pb-2'>
            <Search
              className='p-0'
              onChange={(e) => {
                setSearch(e.target.value.replace(/^\s+/g, ''));
              }}
              placeholder='Search by name'
              value={search}
              onClear={() => {
                setSearch('');
              }}
            />
          </div>
          <div>
            {!isLoadingPlans && (
              <SideTransitionContainer isIn={filterMenu} zIndex={102} onClose={closeFilterMenu}>
                <SearchFilterMenu
                  filterMapArray={filterMapArray}
                  selectedFilter={selectedFilter}
                  onApplyFilter={onApplyFilter}
                  onSelectField={onSelectField}
                  onClose={closeFilterMenu}
                />
              </SideTransitionContainer>
            )}
            <IconButton icon='icon-filter' onClick={openFilterMenu} size='md' />
          </div>
        </div>
        <div style={{ height: 'calc(100vh - 190px)' }}>
          <div className='h-100 d-flex justify-content-start flex-column gap-2'>
            <OverviewTable onSort={onSort} isLoading={isLoading} data={data?.data?.items || []} />
            {data?.data?.pagination?.total > 0 && (
              <PHRPagination onPaginationAction={updatePaginationParams} pagination={data?.data?.pagination} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
