import React, { useContext, useEffect, useState } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';
import Scrollbars from 'react-custom-scrollbars-2';

import styles from './AsideContent.module.scss';
import AsideNavItem from './AsideNavItem';
import ClientsApi from '../../../../api/ClientsApi';
import logo from '../../../../assets/img/logo-primary.svg';
import ClientContext from '../ClientContext';
import { PACKAGE_NAMES } from '../../../constants';

const items = [
  { title: 'Details', value: 'details', icon: 'icon-custom-4' },
  { title: 'Implementation', value: 'implementation', icon: 'icon-settings' },
  { title: 'Subscriptions', value: 'subscriptions', icon: 'icon-file-text' },
  { title: 'Headcount', value: 'headcount', icon: 'icon-users' },
  { title: 'Invoices', value: 'invoices', icon: 'icon-file-text' },
  { title: 'Feature Flags', value: 'feature flags', icon: 'icon-flag' },
  { title: 'Last Login', value: 'last login', icon: 'icon-time-history' },
  {
    title: 'Unbilled  charges',
    value: 'unbilled charges',
    icon: 'icon-expense-claim',
  },
  {
    title: 'Biometric devices',
    value: 'biometric devices',
    icon: 'icon-pos',
  },
  { title: 'Event log', value: 'event-log', icon: 'icon-time-history' },
  { title: 'Notes', value: 'notes', icon: 'icon-note-text' },
];

const AsideContent = ({ selectView, active }) => {
  const { selected } = useContext(ClientContext);
  const [data, setData] = useState([]);
  const hasImplementation = data?.details?.addons?.some((addon) => {
    const addonName = addon?.name;
    return PACKAGE_NAMES[addonName] === 'Implementation' || addonName === 'Implementation';
  });
  useEffect(() => {
    if (selected) {
      const param = selected.id;
      ClientsApi.getTenant(param)
        .then((res) => {
          setData(res.data);
        })
        .catch(() => {});
    }
  }, []);

  return (
    <div className={cx(styles.sideContent, 'bg-light h-100 pt-6 position-relative ms-3')}>
      <div className={styles.logoWrrapper}>
        <img src={data?.companyLogo || logo} alt='logo' />
      </div>
      <div className='mt-3 mb-3 text-center'>
        <p className={styles.name}>{data?.companyName}</p>
        <a
          href={data?.fullDomain ? `https://${data.fullDomain.replace(/^https?:\/\//, '')}` : '#'}
          className={styles.link}
          target='_blank'
          rel='noopener noreferrer'
        >
          {data?.fullDomain || 'No domain available'}
        </a>
        {/* <p className={styles.job}>
            <i className='icon-users me-2' />
            <span>{data?.numberOfEmployees}</span>
          </p> */}
      </div>
      <Scrollbars
        className='scrollbarsWrapper'
        autoHide
        autoHideTimeout={1000}
        autoHideDuration={200}
        style={{ height: 'calc(90vh - 235px)' }}
      >
        <div>
          <ul className='px-4 mt-5'>
            {items
              .filter((item) => item.value !== 'implementation' || hasImplementation)
              .map((item) => (
                <AsideNavItem
                  key={item.value}
                  isActive={active === item.value}
                  text={item.title}
                  icon={item.icon}
                  onClick={() => {
                    selectView(item.value);
                  }}
                />
              ))}
          </ul>
        </div>
      </Scrollbars>
    </div>
  );
};

AsideContent.propTypes = {
  selectView: PropTypes.func.isRequired,
  active: PropTypes.string.isRequired,
};

export default AsideContent;
