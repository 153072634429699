import React, { useEffect, useState } from 'react';

import cx from 'classnames';
import _ from 'lodash';
import Select, { components } from 'react-select';

import Options from './Options';
import selectStyles from './selectStyles';
import styles from '../AssigneeList.module.scss';

const EmployeeSelectProfile = ({
  refSelect,
  onChange,
  onBlur,
  options,
  name,
  value,
  onInputChange,
  isMulti,
  scrollMore,
  hideClear,
  searchValue,
  selectAllOption,
}) => {
  const [selectOptions, setSelectOptions] = useState([]);
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    const result = [];

    if (isMulti && selectAllOption) {
      result.push({
        value: '0',
        label: 'SELECT_ALL',
      });
    }

    options?.forEach((item) => {
      result.push({
        value: item?.id ? `${item.id.toString()}` : '',
        label: item.fullName,
        user: item,
        isDisabled: !!item.isDisabled,
      });
    });
    setSelectOptions(result);
  }, [JSON.stringify(options)]);

  const changeHandler = (selectedOptions) => {
    onChange(selectedOptions);
  };

  const handleOptionClick = (option) => {
    const newValue = isMulti ? [...value, option] : [option];
    onChange(newValue);
  };

  const filteredItems = selectOptions.filter(
    (item) =>
      item.user.fullName.toLowerCase().includes(searchValue.toLowerCase()) && !value.some((a) => a.value === item.value)
  );

  const onSearch = (v, e) => {
    if (onInputChange && e.action === 'input-change') {
      onInputChange(v);
    }
  };

  const onKeyDown = (event) => {
    if (event.key === 'Enter') {
      const selectedOption = selectOptions.find((option) => option.value === value);
      onChange(selectedOption);
    }
  };

  const DropdownIndicator = (props) =>
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <i className='icon-search' />
      </components.DropdownIndicator>
    );

  return (
    <>
      <div
        className={cx(styles.cutomStyle)}
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={() => setIsShown(false)}
      >
        <Select
          ref={refSelect}
          name={name}
          className='employee-select'
          classNamePrefix='react-select'
          placeholder={<p className={cx('text-gray')}>Search</p>}
          components={{ DropdownIndicator }}
          options={selectOptions}
          onChange={changeHandler}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
          value={value}
          isClearable={(isMulti && !!value) || (isShown && !hideClear)}
          backspaceRemoves
          styles={{ ...selectStyles }}
          deleteRemoves
          isDisabled={false}
          isMulti={isMulti}
          isOptionDisabled={(option) => option.isDisabled}
          onMenuScrollToBottom={scrollMore}
          menuPortalTarget={document.body}
          menuShouldScrollIntoView={false}
          menuShouldBlockScroll
          isSearchable
          onInputChange={onSearch}
        />
      </div>
      <div className={cx(styles.options, 'd-flex flex-column')}>
        {filteredItems.map((option, index) => (
          <div
            className={styles.option}
            key={option.id}
            tabIndex={index.toString()}
            onClick={() => handleOptionClick(option)}
          >
            <Options data={option.user} />
          </div>
        ))}
      </div>
    </>
  );
};

export default EmployeeSelectProfile;
