import React from 'react';

import cx from 'classnames';
import { formatStatusClass, formatStatusDisplay } from 'utility/statusChipFormatting';

import styles from './StatusChip.module.scss';

const StatusChip = ({ status }) => (
  <div className={cx(styles.statusChip, styles[formatStatusClass(status)])}>{formatStatusDisplay(status)}</div>
);

export default StatusChip;
