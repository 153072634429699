import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import Tooltip from 'components/Tooltip/Tooltip';

import styles from './NavItem.module.scss';

const NavItem = ({ title, onClick, icon, active, isMinimized, tooltip }) => {
  const btnContent = (
    <button
      className={cx(
        styles.link,
        { [styles.active]: active },
        'nav-link',
        'w-100 py-2 px-1',
        'd-flex',
        'align-items-center'
      )}
      onClick={onClick}
      type='button'
    >
      <span className={cx(styles.iconWrapper, 'position-relative')}>
        <i className={icon} />
      </span>
      {!isMinimized && (
        <span className={cx(styles.text, 'd-flex', 'justify-content-between', 'ps-2', 'w-100')}>
          <span>{title}</span>
        </span>
      )}
    </button>
  );

  return (
    <>
      {isMinimized ? (
        <Tooltip className='ms-2' label={tooltip}>
          {btnContent}
        </Tooltip>
      ) : (
        btnContent
      )}
    </>
  );
};

NavItem.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  active: PropTypes.bool,
  isMinimized: PropTypes.bool,
  tooltip: PropTypes.string,
};

NavItem.defaultProps = {
  active: false,
  isMinimized: false,
  tooltip: '',
};

export default NavItem;
