import http from './Api';

class UsersApi {
  static getUsers(params) {
    return http.get('/users', { params });
  }

  static getPotentialSuperAdmins() {
    return http.get('/users/potential-super-admin?perPage=99999');
  }

  static getUserById(id) {
    return http.get(`/users/${id}`);
  }

  static createUser(data) {
    return http.post('/users', data);
  }

  static updateUser({ id, data }) {
    return http.put(`/users/${id}`, data);
  }

  static deactivateUser(id) {
    return http.put(`/users/${id}/deactivate`);
  }

  static activateUser(id) {
    return http.put(`/users/${id}/activate`);
  }
}

export default UsersApi;
