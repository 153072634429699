import React from 'react';

import cx from 'classnames';
import _ from 'lodash';
import CSSTransition from 'react-transition-group/CSSTransition';

import styles from './ShowRowContent.module.scss';
import fadeAnimation from 'components/animations/fadeAnimation.module.scss';
import IconButton from 'components/IconButton';

const ShowRowContent = ({ text, handleHideContent, isShowContent, colSpan, icon }) => (
  <CSSTransition
    mountOnEnter
    unmountOnExit
    in={isShowContent}
    timeout={200}
    classNames={{
      enter: fadeAnimation.enter,
      enterActive: fadeAnimation.enterActive,
      exit: fadeAnimation.exit,
      exitActive: fadeAnimation.exitActive,
    }}
  >
    <tr className={cx(styles.row, 't-row border-btm light')}>
      <td
        colSpan={colSpan}
        className={cx(styles.tableData)}
        onClick={() => {
          handleHideContent();
        }}
      >
        <div className={styles.fullWidthDescription}>
          <IconButton
            icon={icon}
            onClick={() => {
              handleHideContent();
            }}
          />
          <span style={{ whiteSpace: 'pre-line' }}>{text || 'No description to show'}</span>
        </div>
      </td>
    </tr>
  </CSSTransition>
);

export default ShowRowContent;
