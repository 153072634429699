const capitalize = ( sentence ) => sentence.toLowerCase().replace( /\b\w/g, ( l ) => l.toUpperCase() );

const toQueryString = ( params ) => Object.keys( params )
    .filter( key => params[key] !== undefined )
    .map( key => `${ key }=${ params[key] }` )
    .join( '&' );

export default {
    capitalize,
    toQueryString
};
