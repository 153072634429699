import React from 'react';

import { useFormik } from 'formik';
import * as yup from 'yup';

import SwitchInput from 'components/forms/SwitchInput/SwitchInput';
import TextInput from 'components/forms/TextInput/TextInput';
import RoundedButton from 'components/RoundedButton/RoundedButton';

const FeatureFlagForm = ({ onCancel, initialValues, onSubmit, className }) => {
  const formik = useFormik({
    validationSchema: yup
      .object()
      .shape({ name: yup.string().required('This Field is required'), enabled: yup.boolean() }),
    initialValues,
    onSubmit,
  });

  const handleEnableModule = (check, name) => {
    formik.setFieldValue(name, check);
  };

  return (
    <form className={className} noValidate onSubmit={formik.handleSubmit}>
      <h3 className='mb-3 font-size-16 font-weight-bold'>Add New Feature Flag Module</h3>
      <TextInput
        name='name'
        value={formik.values.name}
        onChange={formik.handleChange}
        error={formik.touched.name || formik.submitCount ? formik.errors.name : ''}
        label='Module Name'
        isRequired
      />

      <section className='d-flex justify-content-between'>
        <p className='font-size-14'>
          Is this module {formik.values.name && <span className='font-weight-bold'>({formik.values.name})</span>}{' '}
          enabled by Default ?
        </p>
        <SwitchInput name='enabled' onChange={handleEnableModule} checked={formik.values.enabled} />
      </section>

      <section className='d-flex justify-content-end gap-3 mt-5'>
        <RoundedButton text='Cancel' type='button' onClick={onCancel} btnStyle='outlined' />
        <RoundedButton text='Save' color='primary' type='submit' />
      </section>
    </form>
  );
};
export default FeatureFlagForm;
