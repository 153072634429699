import _ from 'lodash';

export const formatStatus = (status) => {
  if (status === 'TODO') return 'TO_DO';
  if (status === 'OPT_OUT') return 'INACTIVE';
  return status;
};

export const formatStatusClass = (status) => _.camelCase(formatStatus(status));

export const formatStatusDisplay = (status) => _.upperCase(formatStatus(status));
