function mapChartData(items) {
  const data = [];
  items.forEach((item) => {
    const dataItem = {};
    dataItem.x = item.date;
    dataItem.y = item.numberOfEmployees;
    data.push(dataItem);
  });
  return data;
}

export default mapChartData;
