import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';
import { useQueryGetTenantSubscriptions } from './hooks/useSubscriptions';
import Search from 'components/Search';
import useDebounce from 'Shared/useDebounce';
import ClientContext from 'Customers/components/detailsModal/ClientContext';
import TenantSubscriptionsTable from './components/TenantSubscriptionsTable/TenantSubscriptionsTable';

const Subscriptions = () => {
  const { selected } = useContext(ClientContext);
  const [search, setSearch] = useState('');
  const debouncedSearchTerm = useDebounce(search, 500);
  const [params, setParams] = useState({
    status: '',
    currentPage: 1,
    perPage: 9999,
    search: '',
    sort: '-started',
  });

  useEffect(() => {
    setParams((prevState) => ({ ...prevState, currentPage: 1, search: debouncedSearchTerm }));
  }, [debouncedSearchTerm]);

  const { isLoading, data: TenantSubscriptions } = useQueryGetTenantSubscriptions(selected.id, params);

  return (
    <div className='h-100 me-5'>
      <div className='d-flex mb-2 mt-2 flex-row align-items-center justify-content-between'>
        <div className='col-4 py-2'>
          <Search
            className='p-0'
            onChange={(e) => {
              setSearch(e.target.value.replace(/^\s+/g, ''));
            }}
            placeholder='Search by plan'
            value={search}
            onClear={() => {
              setSearch('');
            }}
          />
        </div>
      </div>
      <div style={{ height: 'calc(100vh - 300px)' }}>
        <TenantSubscriptionsTable subscriptions={TenantSubscriptions?.data?.items} isLoading={isLoading} />
      </div>
    </div>
  );
};

export default Subscriptions;
