import React, { useState } from 'react';

import cx from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import CSSTransition from 'react-transition-group/CSSTransition';

import InvoiceEditMenu from './InvoiceEditMenu';
import styles from './InvoiceItem.module.scss';
import fadeAnimation from '../../../../components/animations/fadeAnimation.module.scss';
import IconButton from '../../../../components/IconButton';
import OutsideClickHandler from '../../../../components/OutsideClickHandler';
import TableDataCell from '../../../../components/table/TableDataCell';
import { PACKAGE_NAMES } from 'Customers/constants';
import formatCurrency from '../../../../utility/formatCurrency';

const InvoiceItem = ({ data, onDownload, onPreview, onMarkAsPaid, onDelete }) => {
  const [editOpened, setEditOpened] = useState(false);

  return (
    <tr className={cx(styles.row, 't-row border-btm light')} onClick={onPreview}>
      <TableDataCell className='ps-3'>
        {data.created ? moment(data.created).format('MMM DD, YYYY') : '- -'}
      </TableDataCell>
      <TableDataCell>{PACKAGE_NAMES[data.plan] || data.plan}</TableDataCell>
      <TableDataCell>{data.period}</TableDataCell>
      <TableDataCell>
        <div className={cx(styles.status, styles[_.camelCase(data.status)], 'text-center')}>
          {_.lowerCase(data.status)}
        </div>
      </TableDataCell>
      <TableDataCell>{data.amount ? `${data.currency} ${formatCurrency(data.amount)}` : '- -'}</TableDataCell>
      <TableDataCell>
        <div
          className='d-flex justify-content-end'
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          <div className='d-flex'>
            <IconButton icon='icon-eye me-3' onClick={onPreview} />
            {data.status === 'paid' ? (
              <IconButton icon='icon-download' onClick={onDownload} />
            ) : (
              <div className='position-relative'>
                <IconButton
                  icon='icon-more-vertical'
                  onClick={() => {
                    setEditOpened(true);
                  }}
                />
                <CSSTransition
                  mountOnEnter
                  unmountOnExit
                  in={editOpened}
                  timeout={200}
                  classNames={{
                    enter: fadeAnimation.enter,
                    enterActive: fadeAnimation.enterActive,
                    exit: fadeAnimation.exit,
                    exitActive: fadeAnimation.exitActive,
                  }}
                >
                  <OutsideClickHandler
                    clickHandler={() => {
                      setEditOpened(false);
                    }}
                  >
                    <InvoiceEditMenu
                      onPaid={() => {
                        onMarkAsPaid();
                        setEditOpened();
                      }}
                      onDownload={() => {
                        onDownload();
                        setEditOpened();
                      }}
                      onDelete={() => {
                        onDelete();
                        setEditOpened();
                      }}
                    />
                  </OutsideClickHandler>
                </CSSTransition>
              </div>
            )}
          </div>
        </div>
      </TableDataCell>
    </tr>
  );
};

InvoiceItem.propTypes = {
  data: PropTypes.object.isRequired,
  onDownload: PropTypes.func.isRequired,
  onPreview: PropTypes.func.isRequired,
  onMarkAsPaid: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default InvoiceItem;
