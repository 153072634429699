import http from './Api';

class TranslationsApi {
  static getTranslations(pagination, search = undefined, data = undefined) {
    return http.get('translations', { params: { ...pagination, search, ...data } });
  }

  static createTranslation(data) {
    return http.post('translations', data);
  }

  static editTranslation(id, data) {
    return http.put(`translations/${id}`, data);
  }

  static deleteTranslation(id) {
    return http.delete(`translations/${id}`);
  }

  static approveTranslation(id) {
    return http.put(`translations/${id}/approve`);
  }
}

export default TranslationsApi;
