import React, { useState } from 'react';

import { useFormik } from 'formik';
import { useNavigate } from 'react-router';

import AuthApi from '../../api/AuthApi';
import AuthStatic from '../../Shared/Auth/AuthStatic';

const ForgotPassword = () => {
  const [formHasErrors, setFormHasErrors] = useState(false);
  const [formIsValid, setFormIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const validate = (values) => {
    const errors = {};

    if (!values.email) {
      errors.email = 'Email is required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Invalid email address';
    }

    if (Object.keys(errors).length === 0) {
      setFormHasErrors(false);

      if (Object.keys(values).length > 0) {
        setFormIsValid(true);
        Object.keys(values).forEach((key) => {
          if (values[key] === null || values[key] === undefined) {
            setFormIsValid(false);
          }
        });
      }
    } else {
      setFormHasErrors(true);
      setFormIsValid(false);
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validate,
    onSubmit: (values) => {
      setIsLoading(true);

      AuthApi.forgotPassword(values).then(
        () => {
          navigate('/login');
        },
        () => {
          setIsLoading(false);
          setFormHasErrors(true);
          setFormIsValid(false);
        }
      );
    },
  });

  return (
    <AuthStatic>
      <p className={`lead mb-4 ${formHasErrors ? 'error-red' : formIsValid ? 'success-green' : null}`}>
        {formik.errors.email ? formik.errors.email : 'Enter your email address'}
      </p>
      <form className='form-signin' onSubmit={formik.handleSubmit}>
        <div className='form-group phr-from-group'>
          <label className='sr-only' htmlFor='inputEmail'>
            email
          </label>

          <div className='input-group mb-3'>
            <div className='input-group-prepend'>
              <span className={`input-group-text phr-email-icon ${formik.errors.email ? 'error-red' : null}`} />
            </div>

            <input
              type='email'
              name='email'
              id='inputEmail'
              className='form-control'
              placeholder='email'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
          </div>
        </div>

        {isLoading ? (
          <button
            className='btn btn-primary text-white btn btn-md btn-primary btn-block text-light loadingButton'
            type='button'
            disabled
          >
            <div className='d-flex align-items-center justify-content-center'>
              <span className='spinner-grow spinner-grow-sm' role='status' aria-hidden='true' />
              <span className='ms-2'>Send</span>
            </div>
          </button>
        ) : (
          <button
            className='btn btn-md btn-primary btn-block text-light'
            type='submit'
            disabled={!formik.values.email || formHasErrors}
          >
            Send
          </button>
        )}

        <div className='text-center mt-2'>
          <a href='/login'>
            <p className='text'>Back to login</p>
          </a>
        </div>
      </form>
    </AuthStatic>
  );
};

export default ForgotPassword;
