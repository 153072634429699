import React, { useId } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './Radio.module.scss';

const Radio = ({ name, value, label, checked, onChange, className, blur }) => {
  const id = useId();

  const handleClick = () => {
    onChange(!checked);
  };
  return (
    <div className={cx(styles.wrapper, 'd-flex', 'align-items-center', className)} onClick={handleClick}>
      <input
        className={styles.inputField}
        id={id}
        type='radio'
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        onBlur={blur}
      />
      <label className={styles.label} htmlFor={id}>
        {label}
      </label>
    </div>
  );
};

Radio.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  blur: PropTypes.func,
  className: PropTypes.string,
};

Radio.defaultProps = {
  checked: undefined,
  onChange: undefined,
  blur: undefined,
  className: '',
  label: '',
};

export default Radio;
