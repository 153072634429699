import React, { useState, useEffect } from 'react';

import { Chart, registerables } from 'chart.js';
import cx from 'classnames';
import _ from 'lodash';
import { Doughnut } from 'react-chartjs-2';

import styles from './DemoChart.module.scss';
import DashboardApi from '../../api/DashboardApi';
import Line from '../../components/skeletons/Line';

Chart.register(...registerables);

const DemoChart = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  const style = { height: 291, width: '100%', borderRadius: 8, opacity: 0.7 };

  const isEmpty = (obj) => !Object.values(obj).some((element) => element !== 0);

  useEffect(() => {
    setLoading(true);
    DashboardApi.getDemoClients()
      .then((res) => {
        setLoading(false);
        setData(res.data);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <div className='col-4 h-100'>
        <Line style={style} gradient={styles.gradient} />
      </div>
    );
  }

  const pieData = {
    labels: ['Suspended', 'Active', 'Archived'],
    datasets: [
      {
        label: 'Demo clients statuses',
        data: !_.isEmpty(data) ? Object.values(data) : [],
        backgroundColor: ['#11BBD1', '#62D26F', '#f45147'],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: false,
      },
    },
    rotation: -0.5 * Math.PI - (120 / 180) * Math.PI,
    legend: {
      display: true,
      position: 'right',
      labels: {
        usePointStyle: true,
        boxWidth: 12,
        fontSize: 12,
        fontColor: '#a7b5bf',
      },
    },
  };

  return (
    <div className='col-4'>
      <div className={cx(styles.wrapper, 'h-100')}>
        <h6 className={cx(styles.title, 'title border-btm pb-3 text-gray fw-bold')}>Demo</h6>
        {!isEmpty(data) ? (
          <div className={cx(styles.doughnut, 'mt-5')}>
            <Doughnut data={pieData} options={options} />
          </div>
        ) : (
          <div className={cx(styles.iconBox, 'd-flex justify-content-center text-center pt-7')}>
            <div>
              <i className='icon-user-remove text-gray' />
              <p className={cx(styles.title)}>No data</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DemoChart;
