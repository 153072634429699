function formatCurrency(num) {
  const val = parseFloat(num);
  if (Number.isNaN(val)) {
    return '0';
  }
  return val.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

export default formatCurrency;
