import http from './Api';

class ImplementationApi {
  static getTemplates(params) {
    return http.get('/templates', { params });
  }

  static getTasks(params) {
    return http.get('/templates/tasks', { params });
  }

  static getTemplateById(id) {
    return http.get(`/templates/${id}`);
  }

  static getClientTasks(client, params) {
    return http.get(`/tenant-tasks/${client}`, { params });
  }

  static addClientTask({ client, data }) {
    return http.post(`/tenant-tasks/${client}`, data);
  }

  static createClientTasks({ client, data }) {
    return http.post(`tenant-tasks/${client}/from-template`, data);
  }

  static EditClientTasks(data) {
    return http.put(`/tenant-tasks/save`, data);
  }

  static updateTemplateFromClientTasks(client) {
    return http.put(`/tenant-tasks/${client}/update-template`);
  }

  static listImplementationTemplatesTasks(params) {
    return http.get('templates/tasks', { params: params || { perPage: 9999 } });
  }

  static deleteClientTask(id) {
    return http.delete(`/tenant-tasks/${id}`);
  }

  static saveAsNewTemplate({client,data}) {
    return http.post(`/tenant-tasks/${client}/save-as-template`,data);
  }
}

export default ImplementationApi;
