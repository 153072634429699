import React, { useState, useEffect } from 'react';

import cx from 'classnames';
import _ from 'lodash';
import Scrollbars from 'react-custom-scrollbars-2';

import CustomersList from './Customers/CustomersList';
import styles from './Headcount.module.scss';
import HeadcountChart from './HeadcountGraph/HeadcountGraph';
import HeadCountHeader from './HeadcountHeader';
import CustomersApi from '../../api/CustomersApi';
import Search from '../../components/Search';
import ClientContext from '../../Dashboard/ClientContext';

const Headcount = ({ onSelectType }) => {
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [companies, setCompanies] = useState({
    items: [],
    totalUsers: 0,
  });
  const [selected, setSelected] = useState(undefined);

  const [disabeDirBtn, setDisabeDirBtn] = useState({
    next: false,
    prev: false,
  });

  useEffect(() => {
    setLoading(true);
    CustomersApi.getCustomersHeadCount()
      .then((res) => {
        setCompanies(res.data);
        if (res?.data) {
          setSelected(res.data.items[0]);
        }

        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    let state = [...companies.items];
    if (searchValue) {
      state = state.filter((item) => item.company.toLowerCase().includes(searchValue.toLowerCase()));
    }
    setFilteredCustomers(state);
  }, [searchValue, companies]);

  useEffect(() => {
    if (selected) {
      const psIndex = _.findIndex(companies.items, ['company', selected.company]);
      const btnState = { ...disabeDirBtn };
      btnState.prev = psIndex <= 0;
      btnState.next = psIndex >= companies.items.length - 1;
      setDisabeDirBtn(btnState);
    }
  }, [selected]);

  const onSelect = (item) => {
    setSelected(item);
  };

  return (
    <ClientContext.Provider value={{ selected }}>
      <div className={cx(styles.content, 'm-3', 'position-relative', 'panel', 'radius-4', 'p-0', 'mb-0')}>
        <div className='mt-4 '>
          <HeadCountHeader title='Headcount' onSelectType={onSelectType} />

          <div className='px-5'>
            <div className={styles.searchDiv}>
              <Search
                onChange={(e) => {
                  setSearchValue(e.target.value.replace(/^\s+/g, ''));
                }}
                placeholder='Search customers'
                value={searchValue}
                onClear={() => {
                  setSearchValue('');
                }}
              />
            </div>
            <div className='row  m-0'>
              <div className='col-4 p-0'>
                <Scrollbars
                  className='scroll-bar'
                  autoHide
                  autoHideTimeout={1000}
                  autoHideDuration={200}
                  style={{ height: 'calc(100vh - 240px)' }}
                >
                  <CustomersList
                    filteredCustomers={filteredCustomers}
                    loading={loading}
                    searchValue={searchValue}
                    onSelect={onSelect}
                    selected={selected}
                  />
                </Scrollbars>
              </div>
              <div className='col-8 p-0 ps-5'>
                <div className='my-2' style={{ position: 'absolute', top: '0.5rem' }}>
                  <h5 className={cx(styles.company, 'ps-2 fw-bold')}>{selected?.company}</h5>
                </div>
                <HeadcountChart />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ClientContext.Provider>
  );
};

export default Headcount;
