import React, { useEffect, useRef, useState, useContext } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

const CountryFlag = ({ data, size }) => {
  const [src, setSrc] = useState(null);
  const [label, setLabel] = useState('');
  const mounted = useRef(false);

  const loadImage = () => {
    import(`assets/img/flags/${data?.alphaTwoCode}.png`)
      .then((image) => {
        if (mounted.current) {
          setSrc(image.default || '');
        }
      })
      .catch(() => {
        if (mounted.current) {
          setSrc(`https://countryflagsapi.com/png/${data?.alphaTwoCode}` || '');
        }
      });
  };

  useEffect(() => {
    if (data?.code) {
      setLabel(data?.code);
    } else {
      setLabel(data?.name);
    }
    loadImage();
  });

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  return (
    <img
      className={cx('rounded-circle')}
      style={{ width: `${size}px`, height: `${size}px`, minWidth: `${size}px`, minHeight: `${size}px` }}
      src={src}
      alt={label}
    />
  );
};

CountryFlag.propTypes = {
  data: PropTypes.object,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

CountryFlag.defaultProps = {
  data: {},
  size: '20',
};

export default CountryFlag;
