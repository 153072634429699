import React from 'react';

import cx from 'classnames';

import StatusChip from './StatusChip';
import styles from './StatusMenu.module.scss';

const link = cx(styles.link, 'd-flex', 'align-items-center', 'px-3', 'py-2');

function StatusMenu({ onSelect, items, label }) {
  return (
    <ul className={cx(styles.statusMenu, 'radius-4', 'bg-white', 'py-2')}>
      {items.map((item) => (
        <li key={item.id} className={styles.listItem} onClick={() => onSelect(label, item.value)}>
          <div className={link}>
            <StatusChip status={item.status} />
          </div>
        </li>
      ))}
    </ul>
  );
}

export default StatusMenu;
