import React, { useState } from 'react';
import EmployeeSelectProfile from './employeeSelect/EmployeeSelectProfile';
import cx from 'classnames';
import styles from './AssigneeList.module.scss';

const AssigneeList = ({ assignee, onAssigneeChange, users }) => {
  const [searchValue, setSearchValue] = useState('');

  const searchHandler = (e) => {
    setSearchValue(e);
  };
  const onSelectChange = (v) => {
    onAssigneeChange(v);
  };

  return (
    <div className={cx(styles.selectMenu, 'radius-4', 'bg-white')}>
      <EmployeeSelectProfile
        name='assignedTo'
        isMulti
        options={users}
        value={assignee}
        onChange={onSelectChange}
        onInputChange={searchHandler}
        searchValue={searchValue}
      />
    </div>
  );
};

export default AssigneeList;
