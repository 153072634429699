import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './EditMenu.module.scss';

const link = cx(styles.link, 'd-flex', 'align-items-center', 'px-3', 'py-2');

const EditMenu = ({
  onClick,
  canExtend,
  canUpgrade,
  canDelete,
  canAddAdmin,
  canConfirmBilling,
  canSuspend,
  canCancel,
  canReactivate,
  canResume,
  canRetry,
  canSyncSubscription,
  canArchive,
}) => (
  <ul
    className={cx(styles.editMenu, 'radius-4', 'bg-white', 'py-2', 'px-0')}
    onClick={(e) => {
      e.stopPropagation();
    }}
  >
    {canUpgrade && (
      <li
        className={styles.listItem}
        onClick={() => {
          onClick('upgrade');
        }}
      >
        <div className={link}>
          <i className='icon-circle-arrow-top me-2' />
          Upgrade
        </div>
      </li>
    )}
    {canExtend && (
      <li
        className={styles.listItem}
        onClick={() => {
          onClick('extend');
        }}
      >
        <div className={link}>
          <i className='icon-calendar-start me-2' />
          Extend trial
        </div>
      </li>
    )}
    {canSuspend && (
      <li
        className={styles.listItem}
        onClick={() => {
          onClick('suspend');
        }}
      >
        <div className={link}>
          <i className='icon-calendar-start me-2' />
          Suspend
        </div>
      </li>
    )}
    {canCancel && (
      <li
        className={styles.listItem}
        onClick={() => {
          onClick('cancel');
        }}
      >
        <div className={link}>
          <i className='icon-calendar-start me-2' />
          Cancel
        </div>
      </li>
    )}
    {canReactivate && (
      <li
        className={styles.listItem}
        onClick={() => {
          onClick('reactivate');
        }}
      >
        <div className={link}>
          <i className='icon-calendar-start me-2' />
          Reactivate
        </div>
      </li>
    )}
    {canResume && (
      <li
        className={styles.listItem}
        onClick={() => {
          onClick('resume');
        }}
      >
        <div className={link}>
          <i className='icon-calendar-start me-2' />
          Resume
        </div>
      </li>
    )}
    {canRetry && (
      <li
        className={styles.listItem}
        onClick={() => {
          onClick('retry');
        }}
      >
        <div className={link}>
          <i className='icon-calendar-start me-2' />
          Retry
        </div>
      </li>
    )}
    {canSyncSubscription && (
      <li
        className={styles.listItem}
        onClick={() => {
          onClick('sync');
        }}
      >
        <div className={link}>
          <i className='icon-calendar-start me-2' />
          Sync subscription
        </div>
      </li>
    )}
    {canDelete && (
      <li
        className={styles.listItem}
        onClick={() => {
          onClick('delete');
        }}
      >
        <div className={link}>
          <i className='icon-trash me-2' />
          Delete
        </div>
      </li>
    )}
    {canAddAdmin && (
      <li
        className={styles.listItem}
        onClick={() => {
          onClick('admin');
        }}
      >
        <div className={link}>
          <i className='icon-user-add me-2' />
          Add admin
        </div>
      </li>
    )}
    {canConfirmBilling && (
      <li
        className={styles.listItem}
        onClick={() => {
          onClick('confirm');
        }}
      >
        <div className={link}>
          <i className='icon-check-mark me-2' />
          Confirm billing
        </div>
      </li>
    )}
    {canArchive && (
      <li
        className={styles.listItem}
        onClick={() => {
          onClick('archive');
        }}
      >
        <div className={link}>
          <i className='icon-check-mark me-2' />
          Archive
        </div>
      </li>
    )}
    <li
      className={styles.listItem}
      onClick={() => {
        onClick('compute-status');
      }}
    >
      <div className={link}>
        <i className='icon-check-mark me-2' />
        Compute status
      </div>
    </li>
  </ul>
);

EditMenu.propTypes = {
  onClick: PropTypes.func.isRequired,
  canExtend: PropTypes.bool.isRequired,
  canUpgrade: PropTypes.bool.isRequired,
  canDelete: PropTypes.bool.isRequired,
  canAddAdmin: PropTypes.bool.isRequired,
  canSuspend: PropTypes.bool.isRequired,
  canCancel: PropTypes.bool.isRequired,
  canReactivate: PropTypes.bool.isRequired,
  canResume: PropTypes.bool.isRequired,
  canRetry: PropTypes.bool.isRequired,
  canArchive: PropTypes.bool.isRequired,
  canSyncSubscription: PropTypes.bool.isRequired,
};

export default EditMenu;
