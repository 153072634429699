import http from './Api';

class DashboardApi {
  static getTranslations() {
    return http.get('translations/count');
  }

  static getClientsCounts() {
    return http.get('dashboard/clients-count');
  }

  static getUsers() {
    return http.get('clients/dashboard/users-count');
  }

  static getAnnouncements() {
    return http.get('release-notes/count');
  }

  static getDemoClients() {
    return http.get('clients/dashboard/demo-clients-count');
  }

  static getCustomerStatus() {
    return http.get('clients/client/count-by-status');
  }

  static getSubscriptionPlan() {
    return http.get('clients/dashboard/subscription-plan-count');
  }

  static getInvoiceStatus() {
    return http.get('clients/dashboard/invoice-status-count');
  }
}

export default DashboardApi;
