import React from 'react';

import TemplatesList from './components/TemplatesList/TemplatesList';

const Templates = () => (
  <div className='m-3' style={{ height: 'calc(100vh - 180px)' }}>
    <TemplatesList />
  </div>
);

export default Templates;
