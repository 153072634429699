import React from 'react';

import cx from 'classnames';

import icon from 'assets/img/success.svg';
import RoundedButton from 'components/RoundedButton/RoundedButton';

import styles from './SuccessScreen.module.scss';

const SuccessScreen = ({ onClose, title, text, btnColor,textColor }) => (
  <div className={cx(styles.sendingOverlay, 'd-flex', 'justify-content-center', 'align-items-center')}>
    <div className='d-flex justify-content-center align-items-center'>
      <div className={styles.content}>
        <div className='m-auto' style={{ width: 124 }}>
          <img src={icon} alt='success' className={cx(styles.image, 'mb-3')} />
        </div>
        <p className={cx(styles.title, 'text-center fw-bold my-3',`text-${textColor || 'primary'}`)}>{title}</p>
        <p className={cx(styles.text, 'text-center', 'mb-4')}>{text}</p>
        <div className='d-flex justify-content-center'>
          <RoundedButton
            text='Got it'
            onClick={onClose}
            type='button'
            btnStyle='contained'
            color={btnColor || 'primary'}
            size='md'
          />
        </div>
      </div>
    </div>
  </div>
);

export default SuccessScreen;
