import React from 'react';

import { useFormik } from 'formik';
import * as yup from 'yup';

import TextInput from 'components/forms/TextInput/TextInput';
import RoundedButton from 'components/RoundedButton/RoundedButton';

const createValidationSchema = () =>
  yup.object({
    name: yup.string().required('Name is required'),
    nameLocal: yup.string(),
  });
const SaveTemplateForm = ({ onSubmit, isLoading }) => {
  const validationSchema = createValidationSchema();
  const formik = useFormik({
    initialValues : {
        name: '',
        nameLocal: '',
        description:''
      },
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      const submissionValues = {...values};
      onSubmit(submissionValues).finally(() => setSubmitting(false));
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit} noValidate>
        <div className='d-flex flex-column pt-5 mx-5'>
          <div className='d-flex align-items-center'>
            <TextInput
              name='name'
              sufix={'en'}
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.name && formik.errors.name ? formik.errors.name : undefined}
              label='name'
              isRequired
            />
          </div>
          <div className='d-flex align-items-center'>
            <TextInput
              name='nameLocal'
              sufix={'ar'}
              value={formik.values.nameLocal}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.nameLocal && formik.errors.nameLocal ? formik.errors.nameLocal : undefined}
              direction='rtl'
            />
          </div>
        </div>
        <div className='d-flex justify-content-end  border-t py-4 mt-4'>
          <RoundedButton
            loading={isLoading}
            type='submit'
            text='Save'
            btnStyle='contained'
            className='me-5'
            color='primary'
            size='md'
          />
        </div>
      </form>
    </>
  );
};

export default SaveTemplateForm;
