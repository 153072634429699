import React from 'react';

import cx from 'classnames';
import { useNavigate } from 'react-router-dom';

import { PrivateRoutes } from 'Router/routes';

import styles from './Insights.module.scss';
import TableOfContents from './TableOfContents';

const Insights = () => {
  const navigate = useNavigate();

  const onSetActiveInsights = (insight) => {
    if (insight === 'Subscriptions') {
      navigate(PrivateRoutes.insightsSubscription);
    }
    if (insight === 'Headcount') {
      navigate(PrivateRoutes.insightsHeadcount);
    }
  };

  const items = [
    {
      title: 'Customer headcount',
      selectValue: 'Headcount',
      desc: 'Number of employees and fluctuations per clients.',
      icon: 'icon-users text-primary',
      color: 'rgba(50, 198, 176, 0.1)',
    },
    {
      title: 'Renewals and Collections',
      selectValue: 'Subscriptions',
      desc: 'Overview of subscriptions.',
      icon: 'icon-users text-primary',
      color: 'rgba(50, 198, 176, 0.1)',
    },
  ];

  return (
    <div className={cx(styles.content, 'mt-3 mx-3', 'position-relative', 'panel', 'radius-4', 'p-0', 'mb-0')}>
      <TableOfContents items={items} onSelect={onSetActiveInsights} />
    </div>
  );
};

export default Insights;
