import React from 'react';

import { Scrollbars } from 'react-custom-scrollbars';

import IconButton from 'components/IconButton';
import List from 'components/List/List';
import ListLoader from 'components/Loader/Circular/ListLoader/ListLoader';
import PageHeader from 'components/PageHeader/PageHeader';
import { useVisibility } from 'Shared/useVisibility';

import { useImplementationTemplateDetailsQuery } from '../../hooks/useTemplates';
import EditTemplateModal from '../../modals/EditTemplateModal/EditTemplateModal';

const ImplementationTemplatesDetails = (props) => {
  const { templateId } = props;

  const { data: template, isLoading: isTemplateLoading } = useImplementationTemplateDetailsQuery(templateId);
  const { isOpen: isEditModalOpen, open: openEditModal, close: closeEditModal } = useVisibility();

  if (!template?.data) return null;

  return (
    <>
      {template?.data && (
        <div className='d-flex flex-column h-100'>
          <PageHeader
            title={template?.data.name}
            isLoading={isTemplateLoading}
            renderSideActionComponent={() => <IconButton size='lg' icon='icon-pencil-create' onClick={openEditModal} />}
          />
          <div className='flex-1 pb-3'>
            <Scrollbars className='scrollbarsWrapper' autoHide autoHideTimeout={1000} autoHideDuration={200}>
              <div className='mt-4 mx-5'>
                {isTemplateLoading && <ListLoader rowHeight={52} rowsCount={3} spaceBetween={8} />}
                {!isTemplateLoading && (
                  <List
                    items={template?.data.tasks}
                    renderItem={(task) => task.name}
                    ItemClassName={'border-btm pb-3 pt-3'}
                  />
                )}
              </div>
            </Scrollbars>
          </div>
          <EditTemplateModal
            isOpen={isEditModalOpen}
            onClose={closeEditModal}
            template={template.data}
            id={templateId}
          />
        </div>
      )}
    </>
  );
};

export default ImplementationTemplatesDetails;
