import React, { useContext, useState } from 'react';

import { useFormik } from 'formik';
import PropTypes from 'prop-types';

import RoundedButton from 'components/RoundedButton/RoundedButton';
import CustomersApi from '../../../../api/CustomersApi';
import SpinnerRing from '../../../../components/SpinnerRing';
import SuccessCheckmark from '../../../../components/SuccessCheckmark';
import ClientContext from '../ClientContext';

const GenerateProration = ({ onClose, units }) => {
  const { selected, openDetailsHandler } = useContext(ClientContext);
  const [isSending, setIsSending] = useState(false);
  const [sent, setSent] = useState(false);

  const onGenerateProration = (values, { resetForm }) => {
    setIsSending(true);
    const param = selected.id;
    CustomersApi.generateProration(param, values)
      .then(() => {
        setIsSending(false);
        setSent(true);
        setTimeout(() => {
          setSent(false);
          const state = { ...selected };
          state.numberOfEmployees = values.units;
          openDetailsHandler(state);
          onClose();
          resetForm();
        }, 1000);
      })
      .catch(() => {
        setIsSending(false);
      });
  };

  const validate = (values) => {
    const errors = {};
    if (!values.units) {
      errors.units = 'Field is required';
    }

    return errors;
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      units,
    },
    validate,
    onSubmit: (values, { resetForm }) => {
      onGenerateProration(values, { resetForm });
    },
  });

  return (
    <div>
      {isSending && <SpinnerRing />}
      {sent && <SuccessCheckmark />}
      <div className='m-5'>
        <form onSubmit={formik.handleSubmit}>
          <div className='text-steal pb-2'>
            The aligning effective and charged units will generate unbilled charge for this customer
          </div>
          <div className='form-group'>
            <label htmlFor='key-edit' className='label-title'>
              Effectve Units
              <sup>*</sup>
            </label>
            <input
              type='text'
              name='units'
              id='key-edit'
              value={formik.values.units}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`form-control ${formik.errors.units && formik.touched.units ? 'is-invalid' : ''}`}
            />
            <div className='invalid-feedback'>{formik.errors.units}</div>
          </div>
          <div className='d-flex justify-content-end border-t mt-5 pt-4 gap-2'>
            <RoundedButton text='cancel' btnStyle='contained' color='white' size='md' onClick={onClose} />
            <RoundedButton type='submit' text='Generate' btnStyle='contained' color='primary' size='md' />
          </div>
        </form>
      </div>
    </div>
  );
};

GenerateProration.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default GenerateProration;
