import React from 'react';

import SpinnerRing from 'components/SpinnerRing';
import SuccessCheckmark from 'components/SuccessCheckmark';

const ActionLoading = ({ responseStatus, isLoading }) => (
  <>
    {responseStatus?.resolved && <SuccessCheckmark text={responseStatus.msg} isFailed={responseStatus.isFailed} />}
    {isLoading && <SpinnerRing />}
  </>
);

export default ActionLoading;
