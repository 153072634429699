import React, { useEffect, useState } from 'react';

import cx from 'classnames';

import styles from './DeleteLoader.module.scss';

const DeleteLoader = () => {
  const [text, setText] = useState('Deleting palmhr workspace...');
  useEffect(() => {
    setTimeout(() => {
      setText('Deleting subscriptions...');
    }, 3000);
  }, []);

  return (
    <div className={cx(styles.sendingOverlay)}>
      <div className={cx(styles.content)}>
        <div className={styles.box}>
          <div className={styles.loader} />
        </div>
        <p className={styles.text}>{text}</p>
      </div>
    </div>
  );
};

export default DeleteLoader;
