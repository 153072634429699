import React, { useState } from 'react';

import { useFormik } from 'formik';
import PropTypes from 'prop-types';

import IconButton from '../components/IconButton';
import SpinnerRing from '../components/SpinnerRing';
import SuccessCheckmark from '../components/SuccessCheckmark';

const AnchorModal = ({ onClose, editAnchor, makeNewAnchor, selectedAnchor, anchors }) => {
  const [isSending, setIsSending] = useState(false);
  const [saved] = useState(false);
  const [loading] = useState(false);

  const validate = (values) => {
    const errors = {};
    if (!values.anchorKey) {
      errors.anchorKey = 'Anchor Key is required';
    } else if (
      selectedAnchor?.anchorKey !== values.anchorKey &&
      anchors.findIndex((item) => item.anchorKey === values.anchorKey) !== -1
    ) {
      errors.anchorKey = 'Anchor Key already exist';
    }

    return errors;
  };
  const closeHandler = () => {
    if (isSending) {
      return;
    }
    onClose();
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      anchorKey: selectedAnchor?.anchorKey || '',
      web: selectedAnchor?.web || '',
      ios: selectedAnchor?.ios || '',
      android: selectedAnchor?.android || '',
    },
    validate,
    onSubmit: (values) => {
      setIsSending(true);
      if (selectedAnchor?.id) {
        editAnchor(selectedAnchor.id, values).then(() => {
          setIsSending(false);
        });
      } else {
        makeNewAnchor(values);
        closeHandler();
      }
    },
  });

  return (
    <div>
      {(isSending || loading) && <SpinnerRing />}
      {saved && <SuccessCheckmark />}
      <div>
        <div className='position-relative'>
          <div className='border-btm d-flex justify-content-between pb-4 pb-3'>
            <h5 className='modal-title fw-bold'>{selectedAnchor?.id ? 'Edit anchor' : 'Add Anchor'}</h5>
            <IconButton icon='icon-cross-x' onClick={closeHandler} />
          </div>
          <div className='px-0 pt-4'>
            <form onSubmit={formik.handleSubmit}>
              <div className='form-group mb-3'>
                <label htmlFor='key-edit' className='label-title'>
                  Anchor Key
                  <sup>*</sup>
                </label>
                <input
                  type='text'
                  name='anchorKey'
                  id='key-edit'
                  placeholder='Anchor Key'
                  value={formik.values.anchorKey}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={`form-control ${formik.errors.anchorKey && formik.touched.anchorKey ? 'is-invalid' : ''}`}
                />
                <div className='invalid-feedback'>{formik.errors.anchorKey}</div>
              </div>
              <div className='form-group mb-3'>
                <label htmlFor='web-edit' className='label-title'>
                  Web
                </label>
                <textarea
                  id='web-edit'
                  rows='1'
                  name='web'
                  placeholder='Web'
                  value={formik.values.web}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className='form-control'
                />
              </div>
              <div className='form-group mb-3'>
                <label htmlFor='ios-edit' className='label-title'>
                  iOS
                </label>
                <textarea
                  id='ios-edit'
                  rows='1'
                  name='ios'
                  placeholder='iOS'
                  value={formik.values.ios}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className='form-control'
                />
              </div>
              <div className='form-group mb-3'>
                <label htmlFor='android-edit' className='label-title'>
                  Android
                </label>
                <textarea
                  id='android-edit'
                  rows='1'
                  name='android'
                  placeholder='Android'
                  value={formik.values.android}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className='form-control'
                />
              </div>
              <div className='py-0 mt-10 d-flex justify-content-end'>
                <button type='button' className='btn btn-secondary me-3' onClick={closeHandler} disabled={isSending}>
                  Close
                </button>
                {isSending ? (
                  <button className='btn btn-primary text-white' type='button' disabled>
                    <span className='spinner-border spinner-border-sm me-4 ms-1' role='status' aria-hidden='true' />
                    Saving...
                  </button>
                ) : (
                  <button type='submit' className='btn btn-primary text-white'>
                    Save changes
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

AnchorModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default AnchorModal;
