import ClientsApi from 'api/ClientsApi';
import { useQueryHook, useMutationHook } from 'utility/reactQueryHooks';

export const queryKeys = {
  customers: (params) => ['customers', JSON.stringify(params)],
  customerAccounts: (id, params) => ['customersAccounts', id, JSON.stringify(params)],
  getCustomerById: (id) => ['getCustomerById', id],
  getCountries: ['countries'],
  plansList: (id) => ['plansList', id],
  addCustomer: ['addCustomer'],
  suspendCustomer: ['suspendCustomer'],
  activateTenant: ['activateTenant'],
  archiveCustomer: ['archiveCustomer'],
  cancelTenantSubscription: ['cancelTenantSubscription'],
  createTenant: ['createTenant'],
  editCustomer: ['editCustomer'],
  syncSubscription: ['syncSubscription'],
  createSuperAdmin: ['createSuperAdmin'],
  retryCustomer: ['retryCustomer'],
  extendDemo: ['extendDemo'],
};

export const useQueryCustomers = (params) =>
  useQueryHook(queryKeys.customers(params), () => ClientsApi.getCustomers(params));

export const useQueryGetCustomerById = (id) =>
  useQueryHook(queryKeys.getCustomerById(id), () => ClientsApi.getCustomerById(id));

export const useGetCustomerAccounts = (id, params) =>
  useQueryHook(queryKeys.customerAccounts(id, params), () => ClientsApi.getCustomersAccounts(id, params));

export const useQueryGetCountries = () => useQueryHook(queryKeys.getCountries, () => ClientsApi.getCountries());

export const useQueryGetPlansList = (country) =>
  useQueryHook(queryKeys.plansList(country), () => ClientsApi.getPlansList(country));

export const useMutationAddCustomer = () => useMutationHook(queryKeys.addCustomer, ClientsApi.createCustomer);

export const useMutationUpdateCustomer = () => useMutationHook(queryKeys.editCustomer, ClientsApi.updateCustomer);

export const useMutationAddNewAccount = () => useMutationHook(queryKeys.createTenant, ClientsApi.createTenant);

export const useMutationSuspendCustomer = () => useMutationHook(queryKeys.suspendCustomer, ClientsApi.suspendCustomer);

export const useMutationActivateCustomer = () => useMutationHook(queryKeys.activateTenant, ClientsApi.activateTenant);

export const useMutationRetryFailedCustomer = () =>
  useMutationHook(queryKeys.retryCustomer, ClientsApi.retryFailedCustomer);

export const useMutationSyncCustomerSubscription = () =>
  useMutationHook(queryKeys.syncSubscription, ClientsApi.syncSubscription);

export const useMutationCreateSuperAdmin = () =>
  useMutationHook(queryKeys.createSuperAdmin, ClientsApi.createSuperAdmin);

export const useMutationArchiveCustomer = () => useMutationHook(queryKeys.archiveCustomer, ClientsApi.archiveCustomer);

export const useMutationCancelTenantSubscription = () =>
  useMutationHook(queryKeys.cancelTenantSubscription, ClientsApi.cancelSubscription);

export const useMutationExtendDemo = () => useMutationHook(queryKeys.extendDemo, ClientsApi.extendDemo);
