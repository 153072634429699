import React, { useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { Scrollbars } from 'react-custom-scrollbars';

import IconButton from 'components/IconButton';
import List from 'components/List/List';
import ListLoader from 'components/Loader/Circular/ListLoader/ListLoader';
import PageHeader from 'components/PageHeader/PageHeader';
import { useVisibility } from 'Shared/useVisibility';

import ConfirmationModal from '../../../../components/ConfirmationModal/ConfirmationModal';
import Menu from '../../../../components/Menu/Menu';
import { TaskOptions } from '../../constants';
import { queryKeys, useImplementationTaskDelete, useImplementationTasksList } from '../../hooks/useTemplates';
import AddTasksModal from '../../modals/AddTasksModal/AddTasksModal';
import EditTaskModal from '../../modals/EditTaskModal/EditTaskModal';

const ImplementationTasksDetails = () => {
  const { isOpen: isAddTaskModalOpen, open, close } = useVisibility();
  const { isOpen: isEditTaskModalOpen, open: EditTaskModalOpen, close: EditTaskModalClose } = useVisibility();
  const {
    isOpen: isDeleteConfirmationOpen,
    open: DeleteConfirmationOpen,
    close: DeleteConfirmationClose,
  } = useVisibility();
  const { data: tasks, isLoading: isTasksLoading } = useImplementationTasksList();
  const { mutate } = useImplementationTaskDelete();
  const queryClient = useQueryClient();
  const [selectedTask, setSelectedTask] = useState(null);

  const onDelete = () => {
    mutate(selectedTask.id, {
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: queryKeys.ImplementationTasks() });
        DeleteConfirmationClose();
      },
    });
  };

  const handleAction = (action, task) => {
    switch (action) {
      case 'Edit':
        EditTaskModalOpen();
        setSelectedTask(task);
        break;
      case 'Delete':
        DeleteConfirmationOpen();
        setSelectedTask(task);
        break;
      default:
        break;
    }
  };
  return (
    <>
      <div className='d-flex flex-column h-100'>
        <PageHeader
          title={'Implementation Tasks'}
          isLoading={false}
          renderSideActionComponent={() => <IconButton icon='icon-plus' color='primary' size='lg' onClick={open} />}
        />
        <div className='flex-1 pb-3'>
          <Scrollbars className='scrollbarsWrapper' autoHide autoHideTimeout={1000} autoHideDuration={200}>
            <div className='mt-4 mx-5'>
              {isTasksLoading ? (
                <ListLoader rowHeight={52} rowsCount={3} spaceBetween={8} />
              ) : (
                <List
                  items={tasks.data.items}
                  renderItem={(task) => (
                    <div className='d-flex justify-between'>
                      <div className='flex-grow-1'>{task.name}</div>
                      <Menu data={TaskOptions} onAction={(action) => handleAction(action, task)} />
                    </div>
                  )}
                  ItemClassName={'border-btm pb-3 pt-3'}
                />
              )}
            </div>
          </Scrollbars>
        </div>
      </div>
      <AddTasksModal isOpen={isAddTaskModalOpen} onClose={close} />
      {selectedTask && <EditTaskModal isOpen={isEditTaskModalOpen} onClose={EditTaskModalClose} task={selectedTask} />}
      <div className='p-5'>
        {selectedTask && (
          <ConfirmationModal
            isOpen={isDeleteConfirmationOpen}
            onAction={onDelete}
            onClose={DeleteConfirmationClose}
            description={`Do you really want to Delete '${selectedTask.name}' ? This process can not be undone.`}
            title={'Delete'}
            type='danger'
            actionText='Delete'
          />
        )}
      </div>
    </>
  );
};

export default ImplementationTasksDetails;
