import React from 'react';
import CustomerTypeRadio from './CustomerTypeRadio';

const CustomerAccountTypeInput = ({ formik }) => (
  <div className='row'>
    <CustomerTypeRadio
      formik={formik}
      title={'Live Site'}
      subtitle={'Start using the live application with your company.'}
      option={'LIVE_INSTANCE'}
    />
    <CustomerTypeRadio
      formik={formik}
      title={'Test Site'}
      subtitle={'Use a test site to see if the application fits your needs.'}
      option={'TEST_SITE'}
    />
    <CustomerTypeRadio
      formik={formik}
      title={'Demo'}
      subtitle={'Play around with dummy data for free.'}
      option={'DEMO'}
    />
  </div>
);

export default CustomerAccountTypeInput;
