import React, { useState, useContext } from 'react';

import cx from 'classnames';
import _ from 'lodash';
import { Scrollbars } from 'react-custom-scrollbars-2';
import Modal from 'react-modal';

import CustomersChart from './charts/CustomersChart';
import DemoChart from './charts/DemoChart';
import Invoices from './charts/Invoices';
import SubscriptionChart from './charts/SubscriptionChart';
import Announcements from './counts/Announcements';
import ClientsCount from './counts/ClientsCount';
import TranslationCount from './counts/TranslationCount';
import CustomersModal from './CustomersCompaniesModal';
import CustomersHeadcount from './CustomersHeadcount';
import styles from './Dashboard.module.scss';
import { AuthContext } from '../Auth/AuthContext';
import modalStyle from '../components/modalStyles';

const addModalStyle = _.cloneDeep(modalStyle);
addModalStyle.content.width = '540px';
addModalStyle.content.padding = '0px';
addModalStyle.content.marginTop = '50px';

const Dashboard = () => {
  const [showCompaniesModal, setShowCompaniesModal] = useState(false);
  const { authState } = useContext(AuthContext);

  const isAdmin = authState.user.roles.includes('ROLE_ADMIN');

  return (
    <div className='mt-3 mx-3'>
      <div className={cx(styles.wrapper, 'panel dashboard-page p-0')}>
        <div className={cx(styles.header, 'row px-5 py-5')}>
          <div className='col-4'>
            <h2>Dashboard</h2>
            <p className='tab-subtitle'>Details of our customers data</p>
          </div>
        </div>
        <div className='bg-white'>
          <div className='w-100 position-relative h-100'>
            <Scrollbars
              className='scroll-bar'
              autoHide
              renderView={({ style, ...props }) => <div {...props} style={{ ...style, overflowX: 'hidden' }} />}
              style={{ height: 'calc(100vh - 218px)' }}
            >
              <div className='p-5'>
                <div>
                  <div className='mb-5'>
                    <div className='row border-btm pb-5'>
                      <ClientsCount />
                      <TranslationCount />
                      <Announcements />
                    </div>
                  </div>
                </div>
                <div className='chart-section1 mt-5'>
                  <div className='row'>
                    <SubscriptionChart />
                    <DemoChart />

                    <CustomersChart />
                  </div>
                </div>
                {isAdmin && (
                  <div className='chart-section2 mt-5 pb-2'>
                    <div className='row'>
                      <CustomersHeadcount setShowCompaniesModal={setShowCompaniesModal} />

                      <Invoices />
                    </div>
                  </div>
                )}
              </div>
            </Scrollbars>
          </div>
        </div>
      </div>
      <Modal
        isOpen={showCompaniesModal}
        contentLabel='Upgrade Client'
        ariaHideApp={false}
        closeTimeoutMS={300}
        style={addModalStyle}
        onRequestClose={() => {
          setShowCompaniesModal(false);
        }}
      >
        <CustomersModal
          onClose={() => {
            setShowCompaniesModal(false);
          }}
          modalName='Companies List'
          confirmName='Heads'
          confirmText='List of all companies'
        />
      </Modal>
    </div>
  );
};

export default Dashboard;
