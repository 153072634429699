import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import palmhrLogoWhite from 'assets/img/logo-white.svg';
import IconButton from 'components/IconButton';

import styles from './NavHeader.module.scss';

const NavHeader = ({ open, close, minimized }) => (
  <div className={cx(styles.header, 'd-flex', 'justify-content-between', 'align-items-center')}>
    <img src={palmhrLogoWhite} className={styles.logo} alt='PalmHR logo' onClick={open} />
    {!minimized && <IconButton onClick={close} icon='icon-menu-minimize' size='md' color='white' />}
  </div>
);

NavHeader.propTypes = {
  close: PropTypes.func.isRequired,
  open: PropTypes.func.isRequired,
  minimized: PropTypes.string.isRequired,
};

export default NavHeader;
