import http from './Api';

class BiometricDveicesApi {
  // Devices Api

  static getDevices(tenant) {
    return http.get(`/biometric-devices/${tenant}/biometric-devices`);
  }

  static createDevice(tenant, data) {
    return http.post(`/biometric-devices/${tenant}/biometric-devices`, data);
  }

  static getDeviceById(id) {
    return http.get(`/biometric-devices/${id}`);
  }

  static updateDevice(id, data) {
    return http.put(`biometric-devices/${id}`, data);
  }

  static deleteDevice(id) {
    return http.delete(`/biometric-devices/${id}`);
  }

  static addToUnbilled(tenant) {
    return http.put(`/biometric-devices/${tenant}/biometric-devices/unbilled-charges/make`);
  }

  static timeZone() {
    return http.get('/biometric-devices/time-zones');
  }

  // Device notes
  static createNote(device, data) {
    return http.post(`biometric-devices/${device}/notes`, data);
  }

  static getNote(device, id) {
    return http.get(`customers/biometric-devices/${device}/notes/${id}`);
  }

  static editNote(device, id, data) {
    return http.put(`customers/biometric-devices/${device}/notes/${id}`, data);
  }

  static deleteNote(id) {
    return http.delete(`customers/biometric-devices/notes/${id}`);
  }

  static getDeviceNotes(device) {
    return http.get(`/biometric-devices/${device}/notes`);
  }
}

export default BiometricDveicesApi;
