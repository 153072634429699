import React from 'react';

import cx from 'classnames';

import Radio from 'components/Radio';

const FilterSection = ({ title, options, className, selectedOption, onSelect }) => (
  <section className={cx(className, 'mx-5')}>
    <h4 className='subtitle'>{title}</h4>
    <section className='d-flex flex-column gap-3'>
      {options.map((option) => (
        <Radio
          label={option.name}
          value={option.value}
          checked={selectedOption === option.value}
          name={title + option.name}
          onChange={onSelect}
        />
      ))}
    </section>
  </section>
);

export default FilterSection;
