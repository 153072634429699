import { startCase, toLower } from 'lodash';

export const FILTER_KEYS = {
  ACCOUNT_STATUS: 'account_status',
  RENEWAL_STATUS: 'renewal_status',
  PAYMENT_CONFIRMATION: 'payment_confirmation',
};

export const initialFilterValues = {
  [FILTER_KEYS.ACCOUNT_STATUS]: '',
  [FILTER_KEYS.RENEWAL_STATUS]: '',
  [FILTER_KEYS.PAYMENT_CONFIRMATION]: '',
};

export const ACCOUNT_STATUSES = {
  ACTIVE: 'ACTIVE',
  CANCELLED: 'CANCELLED',
  NON_RENEWING: 'NON_RENEWING',
  CONFIRMED: 'CONFIRMED',
  OVERDUE: 'OVERDUE',
  SUSPENDED: 'SUSPENDED',
  PENDING_RENEWAL: 'PENDING_RENEWAL',
};

export const accountStatusOptions = [
  {
    name: startCase(toLower(ACCOUNT_STATUSES.ACTIVE)),
    value: ACCOUNT_STATUSES.ACTIVE,
  },
  {
    name: startCase(toLower(ACCOUNT_STATUSES.CONFIRMED)),
    value: ACCOUNT_STATUSES.CONFIRMED,
  },
  {
    name: startCase(toLower(ACCOUNT_STATUSES.OVERDUE)),
    value: ACCOUNT_STATUSES.OVERDUE,
  },
  {
    name: startCase(toLower(ACCOUNT_STATUSES.SUSPENDED)),
    value: ACCOUNT_STATUSES.SUSPENDED,
  },
  {
    name: startCase(toLower(ACCOUNT_STATUSES.PENDING_RENEWAL)),
    value: ACCOUNT_STATUSES.PENDING_RENEWAL,
  },
  {
    name: startCase(toLower(ACCOUNT_STATUSES.NON_RENEWING)),
    value: ACCOUNT_STATUSES.NON_RENEWING,
  },
  {
    name: startCase(toLower(ACCOUNT_STATUSES.CANCELLED)),
    value: ACCOUNT_STATUSES.CANCELLED,
  },
];

export const RENEWAL_STATUSES = {
  CONFIRM_RENEWAL: 'CONFIRM_RENEWAL',
  CANCEL_SUBSCRIPTION: 'CANCEL_SUBSCRIPTION',
  NO_ACTION: 'NO_ACTION',
};

export const renewalStatusOptions = [
  {
    name: startCase(toLower(RENEWAL_STATUSES.CONFIRM_RENEWAL)),
    value: '1',
  },
  {
    name: startCase(toLower(RENEWAL_STATUSES.CANCEL_SUBSCRIPTION)),
    value: '0',
  },
  {
    name: startCase(toLower(RENEWAL_STATUSES.NO_ACTION)),
    value: RENEWAL_STATUSES.NO_ACTION,
  },
];

export const PAYMENT_CONFIRMATION = {
  CONFIRMED: 'CONFIRMED',
  NO_ACTION: 'NO_ACTION',
};

export const paymentConfirmationOptions = [
  {
    name: startCase(toLower(PAYMENT_CONFIRMATION.CONFIRMED)),
    value: '1',
  },
  {
    name: startCase(toLower(RENEWAL_STATUSES.NO_ACTION)),
    value: '0',
  },
];
