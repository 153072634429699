const reorderListItems = (sourceList, targetList) => {
  const itemMap = new Map(sourceList.map((item) => [item.id, item]));
  const reorderedItems = [];

  targetList.forEach((item) => {
    const correspondingItem = itemMap.get(item.originalTask);
    if (correspondingItem) {
      reorderedItems.push(correspondingItem);
    }
  });

  sourceList.forEach((localItem) => {
    if (!targetList.some((item) => item.originalTask === localItem.id)) {
      reorderedItems.push(localItem);
    }
  });

  return reorderedItems;
};

export default reorderListItems;
