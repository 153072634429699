import React from 'react';
import moment from 'moment';

import { PACKAGE_NAMES } from 'Customers/constants';
import StatusChip from '../StatusChip/StatusChip';

const OverviewTableRow = ({ mmr, tenantId, plan, customerName, currentTask, taskStatus, lastUpdated, currency }) => (
  <tr key={tenantId} className='border-bottom'>
    <td className='d-flex align-items-center py-3 px-3'>{customerName}</td>
    <td>{PACKAGE_NAMES[plan] || plan}</td>
    <td className='d-flex align-items-center justify-content-start gap-2'>
      <div>{currency}</div>
      <div>{mmr}</div>
    </td>
    <td>{currentTask}</td>
    <td>
      <StatusChip status={taskStatus} />
    </td>
    <td>{lastUpdated ? moment(lastUpdated).fromNow() : '- -'}</td>
  </tr>
);

export default OverviewTableRow;
