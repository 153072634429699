import React, { useState } from 'react';

import cx from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import CSSTransition from 'react-transition-group/CSSTransition';

import styles from './BiometricTableRow.module.scss';
import EditMenu from './EditMenu';
import fadeAnimation from '../../../../components/animations/fadeAnimation.module.scss';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import modalStyles from '../../../../components/modalStyles';
import OutsideClickHandler from '../../../../components/OutsideClickHandler';
import TableDataCell from '../../../../components/table/TableDataCell';
import Tooltip from '../../../../components/Tooltip';

const addModalStyle = _.cloneDeep(modalStyles);
addModalStyle.content.width = '640px';
addModalStyle.content.marginTop = '100px';

const BiometricTableRow = ({ data, onSelect, onEdit, onEditOpen, onMakeBillable, onDelete, onAddNote }) => {
  const [editOpened, setEditOpened] = useState(onEditOpen);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [action, setAction] = useState('');

  const onConfirm = () => {
    setOpenConfirm(false);
    switch (action) {
      case 'billable':
        onMakeBillable(data.id, data.isAddedToUnbilledCharges);
        break;
      default:
        onDelete(data.id);
        break;
    }
  };

  const onOpenConfirm = (ationType) => {
    setOpenConfirm(true);
    setEditOpened(false);
    setAction(ationType);
  };

  const modalContent = {
    billable: {
      modalName: 'Make device billable',
      confirmName: 'Make billable',
      confirmText: 'Are you sure you want to make this device billable?',
    },
    delete: {
      modalName: 'Remove Device',
      confirmName: 'Delete',
      confirmText: 'Are you sure you want to remove this device?',
    },
  };

  return (
    <tr key={data.id} className={cx(styles.row, 't-row border-btm light')} onClick={onSelect}>
      <TableDataCell className='ps-3'>
        <>
          <div className='d-flex justify-content-start align-items-center'>
            {data.name.length > 15 ? (
              <div className='tooltip-box'>
                {data.name.substring(0, 15)}...
                <Tooltip label={data.name} />
              </div>
            ) : (
              data.name
            )}
            {data.scheduledForDelete && (
              <div className='tooltip-box d-flex align-content-start ms-3'>
                <i className={cx(styles['icon-schedule-for-delete'], 'icon-alert-triangle')} />
                <Tooltip label='Scheduled for delete' />
              </div>
            )}
          </div>
        </>
      </TableDataCell>
      <TableDataCell>
        <>
          <div className='d-flex justify-content-start align-items-center'>
            {data.serialNumber.length > 15 ? (
              <div className='tooltip-box'>
                {data.serialNumber.substring(0, 15)}...
                <Tooltip label={data.serialNumber} />
              </div>
            ) : (
              data.serialNumber
            )}
          </div>
        </>
      </TableDataCell>
      <TableDataCell>{data.protocol}</TableDataCell>
      <TableDataCell>
        {data.lastImportDate ? moment(data.lastImportDate).format('MMM DD, YYYY  h:mm') : '- -'}
      </TableDataCell>
      <TableDataCell>{data.configuredAt ? moment(data.configuredAt).format('MMM DD, YYYY') : '- -'}</TableDataCell>

      <TableDataCell>
        <>
          <div className='d-flex justify-content-end align-items-center'>
            <div className='position-relative'>
              <div className='w-100 d-flex align-items-center justify-content-end'>
                <i
                  className={cx(styles.moreBtn, 'icon-more-vertical')}
                  onClick={(e) => {
                    e.stopPropagation();
                    setEditOpened(true);
                  }}
                />
              </div>
              <CSSTransition
                mountOnEnter
                unmountOnExit
                in={editOpened}
                timeout={200}
                classNames={{
                  enter: fadeAnimation.enter,
                  enterActive: fadeAnimation.enterActive,
                  exit: fadeAnimation.exit,
                  exitActive: fadeAnimation.exitActive,
                }}
              >
                <OutsideClickHandler
                  clickHandler={() => {
                    setEditOpened(false);
                  }}
                >
                  <EditMenu
                    deviceId={data.id}
                    isAddedToUnbilledCharges={data.isAddedToUnbilledCharges}
                    onEdit={() => {
                      onEdit(data.id);
                    }}
                    onPreview={() => {
                      onSelect(data);
                    }}
                    onDelete={() => {
                      onOpenConfirm('delete');
                    }}
                    onMakeBillable={() => {
                      onOpenConfirm('billable');
                    }}
                    onAddNote={() => {
                      onAddNote(data.id);
                    }}
                  />
                </OutsideClickHandler>
              </CSSTransition>
            </div>
          </div>
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <ConfirmationModal
              isOpen={openConfirm}
              onAction={onConfirm}
              onClose={() => {
                setOpenConfirm(false);
              }}
              description={`Are you sure you want to remove this device?`}
              title={'Remove Device'}
              type={'danger'}
              actionText={'Delete'}
            />

            {/* <Modal
              isOpen={tmpUnbilledModal}
              ariaHideApp={false}
              closeTimeoutMS={300}
              style={addModalStyle}
              onRequestClose={() => {
                setTmpUnbilledModal(false);
              }}
            >
              <TmpAddToUnbilled
                onClose={() => {
                  setTmpUnbilledModal(false);
                }}
              />
            </Modal> */}
          </div>
        </>
      </TableDataCell>
    </tr>
  );
};

BiometricTableRow.propTypes = {
  data: PropTypes.object.isRequired,
  onMakeBillable: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onAddNote: PropTypes.func.isRequired,
};

export default BiometricTableRow;
