import React from 'react';

import { PHRFullAreaContainer } from '@palmhr/palmhr-ui-lib';
import PropTypes from 'prop-types';

const FullAreaContainer = ({ isFullHeight, isFullWidth, children, className, ...rest }) => (
  <PHRFullAreaContainer isFullWidth={isFullWidth} isFullHeight={isFullHeight} className={className} {...rest}>
    {children}
  </PHRFullAreaContainer>
);

export default FullAreaContainer;

FullAreaContainer.propTypes = {
  isFullHeight: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  className: PropTypes.string,
};

FullAreaContainer.defaultProps = {
  isFullHeight: true,
  isFullWidth: true,
  className: '',
};
