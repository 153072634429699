import React, { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import Modal from 'components/Modal/Modal';
import EditSubscriptionForm from './EditSubscriptionForm';
import { useMutationUpdateSubscription } from '../../hooks/useSubscriptions';
import { useQueryGetUsers } from 'Users/hooks/useUsers';

const EditSubscriptionModal = ({ isOpen, onClose, selected }) => {
  const queryClient = useQueryClient();
  const { data: users } = useQueryGetUsers({ currentPage: 1, perPage: 9999 });
  const [sent, setSent] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const { mutateAsync: UpdateMutate } = useMutationUpdateSubscription();

  const initialValues = {
    price: selected?.price,
    numberOfUnits: selected?.numberOfUnits,
    implementationUser: selected?.implementationUser || '',
  };

  const handleSubmit = async (data) => {
    setIsSending(true);
    await UpdateMutate(
      { id: selected.id, data },
      {
        onSuccess: async () => {
          setSent(true);
          setIsSending(false);
          await queryClient.invalidateQueries({ queryKey: ['TenantSubscriptions'] }, { exact: true });
        },
      }
    );
  };
  return (
    <Modal
      isOpen={isOpen}
      shouldCloseOnOverlayClick
      title={'Edit Subscription'}
      styles={{
        width: '560px',
        padding: 0,
      }}
      onRequestClose={() => {
        onClose();
      }}
    >
      <EditSubscriptionForm
        onClose={onClose}
        onSubmit={handleSubmit}
        isSending={isSending}
        initialValues={initialValues}
        selected={selected}
        sent={sent}
        users={users?.data?.items || []}
      />
    </Modal>
  );
};

export default EditSubscriptionModal;
