import React, { useState } from 'react';

import { useFormik } from 'formik';
import PropTypes from 'prop-types';

import IconButton from '../../components/IconButton';
import SpinnerRing from '../../components/SpinnerRing';
import SuccessCheckmark from '../../components/SuccessCheckmark';

const EditStatus = ({ onClose, editData, onSave }) => {
  const [isSending, setIsSending] = useState(false);
  const [sent, setSent] = useState(false);

  const validate = (values) => {
    const errors = {};

    if (!values.status) {
      errors.status = 'Application status is required';
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      status: editData.status,
    },
    validate,
    onSubmit: (values) => {
      setIsSending(true);
      const data = {
        status: values.status,
      };
      onSave(editData.id, data)
        .then(() => {
          setIsSending(false);
          setSent(true);
          setTimeout(() => {
            setSent(false);
            onClose();
          }, 2000);
        })
        .catch(() => {
          setIsSending(false);
        });
    },
  });

  const closeHandler = () => {
    if (isSending) {
      return;
    }
    onClose();
  };

  return (
    <div>
      {isSending && <SpinnerRing />}
      {sent && <SuccessCheckmark />}
      <div>
        <div className='py-4 ps-5 position-relative'>
          <div className='border-btm d-flex justify-content-between pt-0 px-0 pb-3 me-5'>
            <h5 className='modal-title fw-bold'>Change Feedback Status</h5>
            <IconButton icon='icon-cross-x' onClick={closeHandler} />
          </div>
          <div className='px-0 pt-4 pe-5'>
            <form noValidate onSubmit={formik.handleSubmit}>
              <div className='row pb-7'>
                <div className='form-group input-hight-acm col-12 mb-5 p-0'>
                  <div className='form-group input-hight-acm col-12 mb-5'>
                    <label htmlFor='status' className='label-title'>
                      Status
                    </label>
                    <select
                      name='status'
                      onChange={formik.handleChange}
                      value={formik.values.status}
                      className={`form-control newClientInputs ${
                        formik.errors.status && formik.touched.status ? 'is-invalid' : ''
                      }`}
                    >
                      <option value='RECEIVED'>RECEIVED</option>
                      <option value='PROCESSED'>PROCESSED</option>
                      <option value='HIRED'>HIRED</option>
                      <option value='REJECTED'>REJECTED</option>
                    </select>
                    <div className='invalid-feedback'>{formik.errors.status}</div>
                  </div>
                </div>
              </div>
              <div className='pt-4 mt-1 d-flex justify-content-end border-t'>
                <button type='button' className='btn btn-secondary me-5' onClick={closeHandler} disabled={isSending}>
                  Cancel
                </button>
                {isSending ? (
                  <button className='btn btn-primary text-white' type='button' disabled>
                    <span className='spinner-border spinner-border-sm me-4 ms-1' role='status' aria-hidden='true' />
                    Sending...
                  </button>
                ) : (
                  <button type='submit' className='btn btn-primary text-white'>
                    Save
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

EditStatus.propTypes = {
  onClose: PropTypes.func.isRequired,
  editData: PropTypes.object.isRequired,
};

export default EditStatus;
