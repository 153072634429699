import React from 'react';

import { PHRSideTransitionContainer } from '@palmhr/palmhr-ui-lib';

const SideTransitionContainer = ({ children, isIn, zIndex, backdropClassName, onClose }) => (
  <PHRSideTransitionContainer isIn={isIn} zIndex={zIndex} backdropClassName={backdropClassName} onClose={onClose}>
    {children}
  </PHRSideTransitionContainer>
);

export default SideTransitionContainer;
