import React, { useEffect, useRef, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import cx from 'classnames';
import cloneDeep from 'lodash/cloneDeep';

import ClientContext from 'Customers/components/detailsModal/ClientContext';
import CalendarPicker from 'components/CalenderPicker/CalenderPicker';
import IconButton from 'components/IconButton';
import Search from 'components/Search';
import Modal from 'react-modal';
import styles from 'Insights/Headcount/Headcount.module.scss';
import SubscriptionFilter from 'Insights/Subscriptions/components/SubscriptionFilter/SubscriptionFilter';
import SubscriptionsTable from 'Insights/Subscriptions/components/SubscriptionsTable/SubscriptionsTable';

import SubscriptionsHeader from './SubscriptionsHeader';
import modalStyles from '../../components/modalStyles';
import useDebounce from '../../Shared/useDebounce';
import DetailsModal from 'Customers/components/detailsModal/DetailsModal';
import { queryKeys, useInsightCustomersQuery } from '../useInsights';
import { PHRPagination } from '@palmhr/palmhr-ui-lib';
import { useVisibility } from 'Shared/useVisibility';

const addModalStyle = cloneDeep(modalStyles);
addModalStyle.content.width = '640px';
addModalStyle.content.padding = '0';

const Subscriptions = () => {
  const queryClient = useQueryClient();
  const isAsc = useRef(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const { isOpen: isDetailsModalShown, open: handleShowDetailsModal, close: handleHideDetailsModal } = useVisibility();
  const [selected, setSelected] = useState(undefined);
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearch = useDebounce(searchValue, 500);

  const [filter, setFilterValue] = useState({});

  const [params, setParams] = useState({
    status: 'active',
    currentPage: 1,
    perPage: 10,
    search: '',
    sort: '-started',
    date: new Date(),
  });

  useEffect(() => {
    setParams((prevState) => ({ ...prevState, currentPage: 1, search: debouncedSearch }));
  }, [debouncedSearch]);

  const { isLoading, data } = useInsightCustomersQuery({
    ...params,
    ...filter,
    date: params.date.toISOString().substring(0, 10),
  });

  const subscriptions = data?.data.items || [];
  const subscriptionsPagination = data?.data?.pagination;

  const updateList = () => {
    queryClient.invalidateQueries({ queryKey: queryKeys.insightCustomers(params) });
  };

  const onSort = (option) => {
    isAsc.current = !isAsc.current;
    const dir = isAsc.current ? '+' : '-';
    setParams((prevState) => ({ ...prevState, currentPage: 1, sort: `${dir}${option}` }));
  };

  const updatePaginationParams = ({ currentPage, perPage }) =>
    setParams((prevState) => ({ ...prevState, currentPage, perPage }));

  const handleOnSelectDate = (date) => {
    setParams((prevState) => ({ ...prevState, currentPage: 1, date }));
  };

  const handleOnClickFilter = () => {
    setIsFilterOpen(true);
  };

  const handleOnCloseFilter = () => {
    setIsFilterOpen(false);
  };

  const handleFilter = (filterValues) => {
    setFilterValue(filterValues);
  };

  const openDetailsHandler = (item) => {
    setSelected(item);
  };

  return (
    <ClientContext.Provider value={{ selected, openDetailsHandler }}>
      <div className={cx(styles.content, 'm-3', 'position-relative', 'panel', 'radius-4', 'p-0', 'mb-0')}>
        <div className='d-flex flex-column h-100'>
          <div className='mt-2a '>
            <SubscriptionsHeader />
          </div>

          <section className='d-flex justify-content-between align-items-center mt-4 mb-3 mx-5'>
            <Search
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              onClear={() => setSearchValue('')}
            />
            <section className='d-flex gap-2 align-items-center'>
              <CalendarPicker value={params.date} onSelectDate={handleOnSelectDate} min={new Date()} />
              <IconButton color='primary' size='lg' icon='icon-filter' onClick={handleOnClickFilter} />
            </section>
          </section>

          <div className='mx-5' style={{ height: 'calc(100vh - 265px)' }}>
            <SubscriptionsTable
              onSort={onSort}
              isLoading={isLoading}
              subscriptions={subscriptions}
              updateList={updateList}
              onSelectRow={(item) => {
                setSelected(item);
                handleShowDetailsModal();
              }}
            />
            {subscriptionsPagination?.total > 0 && (
              <PHRPagination onPaginationAction={updatePaginationParams} pagination={subscriptionsPagination} />
            )}
          </div>
        </div>
      </div>

      <SubscriptionFilter onFilter={handleFilter} isOpen={isFilterOpen} onClose={handleOnCloseFilter} />
      <Modal
        isOpen={isDetailsModalShown}
        contentLabel='Add Client'
        ariaHideApp={false}
        closeTimeoutMS={300}
        style={addModalStyle}
        onRequestClose={() => {
          handleHideDetailsModal();
        }}
      >
        <DetailsModal
          customer={selected || {}}
          onClose={() => {
            handleHideDetailsModal();
          }}
        />
      </Modal>
    </ClientContext.Provider>
  );
};

export default Subscriptions;
