import React, { useState, useEffect } from 'react';

import cx from 'classnames';

import styles from './TranslationCount.module.scss';
import DashboardApi from '../../api/DashboardApi';
import Line from '../../components/skeletons/Line';

const TranslationCount = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  const style = { height: 228, width: '100%', borderRadius: 8, opacity: 0.7 };

  useEffect(() => {
    setLoading(true);
    DashboardApi.getTranslations()
      .then((res) => {
        setLoading(false);
        setData(res.data);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <div className='col-4'>
        <Line style={style} gradient={styles.gradient} />
      </div>
    );
  }
  return (
    <div className='col-4'>
      <div className={cx(styles.wrapper, 'p-3 radius-4 h-100')}>
        <h6 className='title border-btm pb-3 text-gray fw-bold'>Translations</h6>
        <div className='d-flex justify-content-between mt-4'>
          <span>For Review</span>
          <span className='fw-bold text-warning'>{data.review}</span>
        </div>
        <div className='d-flex justify-content-between'>
          <span>Translated</span>
          <span className='fw-bold text-success'>{data.translated}</span>
        </div>
        <div className='d-flex justify-content-between'>
          <span>Not Translated</span>
          <span className='fw-bold text-danger'>{data.notTranslated}</span>
        </div>
        <div className='d-flex justify-content-between mt-3 '>
          <span>Total</span>
          <span className='fw-bold'>{data.total}</span>
        </div>
      </div>
    </div>
  );
};

export default TranslationCount;
