import React, { useContext, useEffect, useState, useRef } from 'react';

import _ from 'lodash';
import snakeCase from 'lodash/snakeCase';
import { useQueryNotes } from './hooks/useNotes';

import NotesApi from 'api/NotesApi';
import Search from 'components/Search';

import ClientContext from '../ClientContext';
import useDebounce from 'Shared/useDebounce';
import NotesTable from './components/NotesTable';
import IconButton from 'components/IconButton';
import AddNoteModal from './modals/AddNoteModal';

const Notes = ({ addNoteModal, onEditOpen, onClose }) => {
  const isAsc = useRef(false);
  const { selected } = useContext(ClientContext);
  const [selectedNote, setSelectedNote] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [params, setParams] = useState({
    status: 'active',
    currentPage: 1,
    perPage: 9999,
    search: '',
    sort: '',
  });
  const debouncedSearchTerm = useDebounce(searchValue, 500);

  useEffect(() => {
    setParams((prevState) => ({ ...prevState, currentPage: 1, search: debouncedSearchTerm }));
  }, [debouncedSearchTerm]);

  const { isLoading: isLoadingNotes, data: notes } = useQueryNotes(selected.id, {
    ...params,
    status: snakeCase(params.status),
  });

  const closeModalHandler = () => {
    onClose(false);
    setSelectedNote(null);
  };

  const onSort = (option) => {
    isAsc.current = !isAsc.current;
    const dir = isAsc.current ? '+' : '-';
    setParams((prevState) => ({ ...prevState, sort: `${dir}${option}` }));
  };

  const onEdit = (id) => {
    NotesApi.getNoteById(selected.id, id).then((res) => {
      setSelectedNote(res.data);
      onEditOpen(true);
    });
  };

  return (
    <div className='d-flex flex-column justify-content-start h-100'>
      <div className='d-flex justify-content-between my-3 me-5'>
        <div className='d-flex flex-start'>
          {(searchValue || (notes && notes.data.items.length > 0)) && (
            <Search
              onChange={(e) => {
                setSearchValue(e.target.value.replace(/^\s+/g, ''));
              }}
              placeholder='Search by name'
              value={searchValue}
              onClear={() => {
                setSearchValue('');
              }}
            />
          )}
        </div>
        <div className='d-flex flex-end'>
          <IconButton
            tooltipText={'Add Note'}
            onClick={() => {
              onEditOpen(true);
            }}
            icon='icon-plus'
            size='md'
            color='primary'
          />
        </div>
      </div>
      <NotesTable
        onSort={onSort}
        isLoading={isLoadingNotes}
        notes={notes?.data?.items || []}
        onEdit={onEdit}
        isScroll
      />
      <AddNoteModal addNoteModal={addNoteModal} selectedNote={selectedNote} closeModalHandler={closeModalHandler} />
    </div>
  );
};

export default Notes;
