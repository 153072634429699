import React from 'react';
import _ from 'lodash';
import TemplateActions from './TemplateActions/TemplateActions';

const TemplateHeader = ({ name, isTemplatesActionsShown }) => (
  <div className='d-flex justify-content-between align-items-center'>
    <h6>
      <strong>Template name: </strong>
      {_.capitalize(name)}
    </h6>
    {isTemplatesActionsShown && <TemplateActions />}
  </div>
);

export default TemplateHeader;
