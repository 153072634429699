import React from 'react';
import { useQueryClient } from '@tanstack/react-query';

import Modal from 'components/Modal/Modal';
import { useMutationExtendDemo } from 'Customers/hooks/useCustomers';
import ExtenDemoForm from './ExtenDemoForm';

const ExtendDemoModal = ({ isOpen, onClose, id, trialEnd }) => {
  const queryClient = useQueryClient();
  const { isLoading, mutate: ExtendDemo } = useMutationExtendDemo();

  const initialValues = { expireDate: trialEnd };

  const handleSubmit = (data) => {
    ExtendDemo(
      { id, data },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries(['customersAccounts', id]);
          onClose();
        },
      }
    );
  };
  return (
    <Modal
      isOpen={isOpen}
      shouldCloseOnOverlayClick
      title={'Extend Demo'}
      styles={{
        width: '560px',
        padding: 0,
        height: '490px',
      }}
      onRequestClose={() => {
        onClose();
      }}
    >
      <ExtenDemoForm
        onClose={onClose}
        onSubmit={handleSubmit}
        isLoading={isLoading}
        initialValues={initialValues}
        trialEnd={trialEnd}
      />
    </Modal>
  );
};

export default ExtendDemoModal;
