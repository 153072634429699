export const getSubscriptionPricingModels = (
  isSingleCharge,
  pricingUnit,
  pricingModel,
  defaultBillingPeriod
) => {
  if (!isSingleCharge) {
    if (pricingModel === 'PER_UNIT') {
      return `${pricingUnit.toLowerCase()} ${defaultBillingPeriod ? `/ ${defaultBillingPeriod?.toLowerCase()}` : ''}`;
    }
    if (pricingModel === 'FLAT_FEE') {
      return `per ${defaultBillingPeriod?.toLowerCase()}`;
    }
  }
  if (isSingleCharge) {
    if (pricingModel === 'FLAT_FEE') {
      return 'once on activation';
    }
    return `once per ${pricingUnit.toLowerCase()}`;
  }
  return '';
};
