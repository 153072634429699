import http from './Api';

class AnchorApi {
  static getListOfAnchors(params) {
    return http.get('anchors', { params });
  }

  static createAnchor(data) {
    return http.post('anchors', data);
  }

  static getTheAnchor(id) {
    return http.get(`anchors/${id}`);
  }

  static updateAnchors(id, data) {
    return http.put(`anchors/${id}`, data);
  }

  static deleteAnchor(id) {
    return http.delete(`anchors/${id}`);
  }
}

export default AnchorApi;
