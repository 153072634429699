import React from 'react';

import { PHRTooltip } from '@palmhr/palmhr-ui-lib';

const Tooltip = ({ label, children, type, size, position, className, portalElementId }) => (
  <PHRTooltip
    label={label}
    type={type}
    size={size}
    position={position}
    className={className}
    portalElementId={portalElementId}
  >
    {children}
  </PHRTooltip>
);
export default Tooltip;
