import React, { useContext, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { queryKeys, useUpdateNote, useMutationAddNote } from '../hooks/useNotes';
import AddNote from '../forms/AddNote';
import ClientContext from '../../ClientContext';
import Modal from 'components/Modal/Modal';

const AddNoteModal = ({ addNoteModal, selectedNote, closeModalHandler, params }) => {
  const queryClient = useQueryClient();
  const { selected } = useContext(ClientContext);
  const [saved, setSaved] = useState(false);

  const { isLoading: isLoadingUpdating, mutate: updateMutate } = useUpdateNote();
  const { isLoading: isLoadingAdding, mutate: addMutate } = useMutationAddNote(selected.id);

  const handleEditSuccess = async () => {
    await queryClient.refetchQueries(queryKeys.notes(params), { exact: true });
  };

  const onSave = (isEdit, updatedData) => {
    if (isEdit) {
      updateMutate(
        { client: selected.id, id: selectedNote.id, data: updatedData },
        {
          onSuccess: async () => {
            setSaved(true);
            handleEditSuccess();
          },
        }
      );
    } else {
      addMutate(
        { client: selected.id, data: updatedData },
        {
          onSuccess: async () => {
            setSaved(true);
            handleEditSuccess();
          },
        }
      );
    }
    setTimeout(() => {
      closeModalHandler();
      setSaved(false);
    }, 1000);
  };
  return (
    <Modal
      isOpen={addNoteModal}
      showHeader
      shouldCloseOnOverlayClick
      contentLabel={selectedNote ? 'Edit Note' : 'Add Note'}
      title={selectedNote ? 'Edit Note' : 'Add Note'}
      ariaHideApp={false}
      styles={{ width: '560px', padding: '0' }}
      onRequestClose={() => {
        closeModalHandler();
      }}
    >
      <AddNote
        onClose={closeModalHandler}
        selectedNote={selectedNote}
        onSave={onSave}
        saved={saved}
        isSending={isLoadingAdding || isLoadingUpdating}
      />
    </Modal>
  );
};

export default AddNoteModal;
