import React from 'react';

import { PHRDataTable } from '@palmhr/palmhr-ui-lib';

const DataTable = ({
  isLoading,
  data,
  TableHeader,
  TableRowComponent,
  EmptySearchComponent,
  rowKey,
  onCheck,
  checkedRows,
  fetchNextPage,
  hasNextPage,
  columns,
  zeroIndexingPagination,
  rowAdditionalProps = {},
  headerAdditionalProps = {},
  isScroll,
  isPagination,
  pagination,
  onPaginationAction,
  tableHeight,
  direction,
  className,
  rowsPerPageText,
  isDraggable,
  onMoveItem,
}) => (
  <PHRDataTable
    isLoading={isLoading}
    data={data}
    TableHeader={TableHeader}
    TableRowComponent={TableRowComponent}
    EmptySearchComponent={EmptySearchComponent}
    rowKey={rowKey}
    onCheck={onCheck}
    checkedRows={checkedRows}
    fetchNextPage={fetchNextPage}
    hasNextPage={hasNextPage}
    columns={columns}
    zeroIndexingPagination={zeroIndexingPagination}
    rowAdditionalProps={rowAdditionalProps}
    headerAdditionalProps={headerAdditionalProps}
    isScroll={isScroll}
    isPagination={isPagination}
    pagination={pagination}
    onPaginationAction={onPaginationAction}
    tableHeight={tableHeight}
    direction={direction}
    className={className}
    rowsPerPageText={rowsPerPageText}
    isDraggable={isDraggable}
    onMoveItem={onMoveItem}
  />
);

export default DataTable;
