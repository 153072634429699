import React, { useState } from 'react';

import cx from 'classnames';
import { useFormik } from 'formik';

import RoundedButton from 'components/RoundedButton/RoundedButton';

const AddTranslation = ({ onClose, addNew, selectedRow, editRow, errorMessage }) => {
  const [isSending, setIsSending] = useState(false);

  const validate = (values) => {
    const errors = {};
    if (!values.translationKey) {
      errors.translationKey = 'Key is required';
    }
    if (!values.english) {
      errors.english = 'English is required';
    }

    return errors;
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      translationKey: selectedRow?.translationKey || '',
      english: selectedRow?.english || '',
      arabic: selectedRow?.arabic || '',
      mobile: selectedRow?.mobile || false,
      context: selectedRow?.context,
      review: false,
    },
    validate,
    onSubmit: (values) => {
      setIsSending(true);
      if (selectedRow?.id) {
        editRow(selectedRow.id, values).then(() => {
          setIsSending(false);
        });
      } else {
        addNew(values);
        setIsSending(false);
      }
    },
  });

  const closeHandler = () => {
    if (isSending) {
      return;
    }
    onClose();
  };

  return (
    <form noValidate onSubmit={formik.handleSubmit}>
      <div className='mx-5 my-3'>
        <div className='form-group pb-3'>
          <label htmlFor='key' className='label-title'>
            Key
            <sup>*</sup>
          </label>
          <input
            type='text'
            name='translationKey'
            id='key'
            placeholder='Key'
            required
            value={formik.values.translationKey}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={`form-control ${
              formik.errors.translationKey && formik.touched.translationKey ? 'is-invalid' : ''
            }`}
          />
          <div className='invalid-feedback'>{formik.errors.translationKey}</div>
        </div>
        <div className='form-group pb-3'>
          <label htmlFor='en' className='label-title'>
            English
            <sup>*</sup>
          </label>
          <textarea
            rows='4'
            id='en'
            name='english'
            value={formik.values.english}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            required
            placeholder='English'
            className={`form-control ${formik.errors.english && formik.touched.english ? 'is-invalid' : ''}`}
          />
          <div className='invalid-feedback'>{formik.errors.english}</div>
        </div>
        <div className='form-group pb-3'>
          <label htmlFor='ar' dir='rtl' className='label-title text-right w-100'>
            عربى
            <sup>*</sup>
          </label>
          <textarea
            rows='4'
            name='arabic'
            className='form-control'
            id='ar'
            value={formik.values.arabic}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder='عربى'
            dir='rtl'
          />
        </div>
        <div className='form-group pb-3'>
          <label htmlFor='en' className='label-title'>
            Context
          </label>
          <textarea
            rows='4'
            id='en'
            name='context'
            value={formik.values.context}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder='Context'
            className={`form-control ${formik.errors.context && formik.touched.context ? 'is-invalid' : ''}`}
          />
          <div className='invalid-feedback'>{formik.errors.english}</div>
        </div>
        <div className='form-group d-flex'>
          <div className='me-3'>
            <input
              className='custom-checkbox form-check-input'
              type='checkbox'
              checked={formik.values.mobile}
              name='mobile'
              id='mobile'
              onChange={formik.handleChange}
            />
            <label className='custom-label px-2' htmlFor='mobile'>
              Mobile
            </label>
          </div>
          <input
            className='custom-checkbox form-check-input'
            type='checkbox'
            name='review'
            id='review'
            onChange={formik.handleChange}
          />
          <label className='custom-label px-2' htmlFor='review'>
            Review
          </label>
        </div>

        <div className='d-block invalid-feedback'>{errorMessage}</div>
      </div>
      <div className={cx('d-flex', 'justify-content-end gap-2', 'p-3', 'border-t')}>
        <RoundedButton text='Cancel' btnStyle='outlined' size='md' onClick={closeHandler} disabled={isSending} />
        <RoundedButton loading={isSending} type='submit' text='Save' btnStyle='contained' color='primary' size='md' />
      </div>
    </form>
  );
};

export default AddTranslation;
