import React from 'react';

import { PHRTextarea } from '@palmhr/palmhr-ui-lib';
import PropTypes from 'prop-types';

const Textarea = ({
  showCounter,
  name,
  placeholder,
  value,
  label,
  lang,
  isRequired,
  onChange,
  onClick,
  onBlur,
  autofocus,
  rows,
  error,
  containerClassName,
  customClass,
  maxLength,
  showImportant,
  direction,
  id,
  onKeyPress,
  isDisabled,
  isTranslated,
}) => (
  <PHRTextarea
    showCounter={showCounter}
    name={name}
    placeholder={placeholder}
    value={value}
    label={label}
    lang={lang}
    isRequired={isRequired}
    onChange={onChange}
    onClick={onClick}
    onBlur={onBlur}
    autofocus={autofocus}
    rows={rows}
    error={error}
    containerClassName={containerClassName}
    customClass={customClass}
    maxLength={maxLength}
    showImportant={showImportant}
    direction={direction}
    id={id}
    onKeyPress={onKeyPress}
    isDisabled={isDisabled}
    isTranslated={isTranslated}
  />
);

export default Textarea;

Textarea.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  lang: PropTypes.string,
  isRequired: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  autofocus: PropTypes.bool,
  rows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // Adjusted to reflect the actual expected types
  error: PropTypes.string,
  customClass: PropTypes.string,
  containerClassName: PropTypes.string,
  maxLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showImportant: PropTypes.bool,
  direction: PropTypes.oneOf(['rtl', 'ltr']),
  id: PropTypes.string,
  onClick: PropTypes.func,
  onKeyPress: PropTypes.func,
  isDisabled: PropTypes.bool,
  isTranslated: PropTypes.bool,
  showCounter: PropTypes.bool,
};

Textarea.defaultProps = {
  value: '',
  placeholder: '',
  label: '',
  lang: '',
  isRequired: false,
  isTranslated: false,
  onChange: undefined,
  onClick: () => {},
  autofocus: false,
  onBlur: undefined,
  rows: '10',
  error: undefined,
  customClass: '',
  containerClassName: '',
  maxLength: '',
  showImportant: false,
  direction: 'ltr',
  id: '',
  showCounter: false,
  onKeyPress: undefined,
  isDisabled: false,
};
