/* eslint-disable indent */
/* eslint-disable react/jsx-indent-props */
import React, { useEffect, useState } from 'react';

import cx from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars-2';

import styles from './DetailsCustomer.module.scss';
import ClientsApi from 'api/ClientsApi';
import IconButton from 'components/IconButton';
import SpinnerRing from 'components/SpinnerRing';
import Tooltip from 'components/Tooltip';
import { PACKAGE_NAMES } from 'Customers/constants';
import TenantEventLog from 'Customers/EventLog/TenantEventLog';
import formatCurrency from 'utility/formatCurrency';

const DetailsCustomer = ({ onClose, customer }) => {
  const [details, setDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [clickedOnCopy, setClickedOnCopy] = useState(false);

  useEffect(() => {
    setLoading(true);
    ClientsApi.getTenant(customer.id)
      .then((res) => {
        setDetails(res.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const copyToClipboard = (str) => {
    const el = document.createElement('textarea');
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    setClickedOnCopy(true);

    setTimeout(() => {
      setClickedOnCopy(false);
    }, 1000);
  };

  return (
    <div>
      {loading && <SpinnerRing />}
      <div>
        <div className='py-4 ps-5 position-relative'>
          <div className='d-flex justify-content-between border-btm pt-0 px-0 pb-3 me-5 ms-1'>
            <a href={details?.subdomain} target='_blank' rel='noopener noreferrer' className={styles.link}>
              <h5 className='ps-0 pe-3 modal-title fw-bold'>{details?.companyName}</h5>
            </a>
            <div className='p-0 d-flex justify-content-end'>
              <IconButton icon='icon-cross-x' onClick={onClose} />
            </div>
          </div>
          <div className='px-0 pt-5'>
            <div>
              <Scrollbars
                className='scroll-bar'
                autoHide
                renderView={({ style, ...props }) => <div {...props} style={{ ...style, overflowX: 'hidden' }} />}
                style={{ height: '570px' }}
              >
                <div>
                  <div className='pe-5 pb-5'>
                    <p className='fw-bold mt-1 mb-3'>Company responsible</p>
                    <div className='row pb-3'>
                      <div className={cx(styles.inputHeight, 'form-group col-5')}>
                        <p className='label-title'>Name</p>
                        <p className='text-steal'>
                          {details?.firstName} {details?.lastName}
                        </p>
                      </div>

                      <div className={cx(styles.inputHeight, 'form-group col-7 mb-4')}>
                        <p className='label-title'>Work email</p>
                        <div className='text-steal d-flex align-items-start'>
                          <a href={`mailto:${details?.workEmail}`}>{details?.workEmail}</a>
                          {details?.workEmail && (
                            <div className={cx('ms-3', 'text-gray')}>
                              <div className='tooltip-box'>
                                <i
                                  className='icon-copy'
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    copyToClipboard(details?.workEmail);
                                  }}
                                />
                                <Tooltip label={clickedOnCopy ? 'Copied' : 'Click to copy'} />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className={cx(styles.inputHeight, 'form-group col-7 mb-4')}>
                        <p className='label-title'>Phone</p>
                        <div className='text-steal d-flex align-items-start'>
                          <span>{details?.phone || '- -'}</span>
                          {details?.phone && (
                            <div className={cx('ms-3', 'text-gray')}>
                              <div className='tooltip-box'>
                                <i
                                  className='icon-copy'
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    copyToClipboard(details?.phone);
                                  }}
                                />
                                <Tooltip label={clickedOnCopy ? 'Copied' : 'Click to copy'} />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className={styles.line} />
                  </div>
                  <div className='pe-5'>
                    <p className='fw-bold mb-3'>Subscription Details</p>
                    <div className='row pb-5'>
                      <div className={cx(styles.inputHeight, 'form-group col-5 mb-4')}>
                        <p className='label-title'>Plan Name</p>
                        <p className='text-steal'>
                          {PACKAGE_NAMES[details?.details?.planName] || details?.details?.planName}
                        </p>
                      </div>
                      <div className={cx(styles.inputHeight, 'form-group col-7 mb-4')}>
                        <p className='label-title'>Price</p>
                        <p className='text-steal d-flex align-items-center'>
                          <span className='ms-1 d-flex align-items-center'>
                            <span>{formatCurrency(details?.details?.price)}</span>
                            {/* {details.details?.price !== details.details?.pricePerUnit && (
                              <>
                                <span className={cx(styles.unitPrice, 'ms-2 text-gray')}>
                                  {`(${formatCurrency(details.details?.pricePerUnit)}`}
                                </span>
                                <span className={cx(styles.unitPrice, 'text-gray')}>
                                  {`${details.details.currency} / employee)`}
                                </span>
                              </>
                            )} */}
                          </span>
                        </p>
                      </div>
                      <div className={cx(styles.inputHeight, 'form-group col-5 mb-4')}>
                        <p htmlFor='planPrice' className='label-title'>
                          Created At
                        </p>
                        <p className='text-steal'>{moment(details?.details?.createdAt).format('MMMM DD, YYYY')}</p>
                      </div>
                      <div className={cx(styles.inputHeight, 'form-group col-5 mb-4')}>
                        <p htmlFor='planPrice' className='label-title'>
                          Created By
                        </p>
                        <p className='text-steal'>{details?.createdByEmail}</p>
                      </div>
                      <div className={cx(styles.inputHeight, 'form-group col-7 mb-4')}>
                        <p className='label-title'>Next Payment</p>
                        <p className='text-steal'>
                          {details?.details?.nextPayment
                            ? moment(details?.details?.nextPayment).format('MMMM DD, YYYY')
                            : ' - - '}
                        </p>
                      </div>
                      <div className={cx(styles.inputHeight, 'form-group col-5 mb-5')}>
                        <p className='label-title mb-2'>Status</p>
                        <span className={cx(styles.status, styles[_.camelCase(customer.status)])}>
                          {_.upperCase(customer.status)}
                        </span>
                      </div>
                      {details.subscription?.price !== details.subscription?.pricePerUnit && (
                        <div className={cx(styles.inputHeight, 'form-group col-7 mb-5')}>
                          <p className='label-title'>No. of Employees</p>
                          <p className='text-steal'>{details?.subscription?.numberOfEmployees}</p>
                        </div>
                      )}
                      <div className={cx(styles.line)} />
                    </div>
                  </div>

                  <div className='pe-5'>
                    <p className='fw-bold mt-1 mb-3'>Addons</p>

                    {details?.details?.addons && details?.details?.addons?.length > 0 ? (
                      details?.details?.addons.map((addon) => (
                        <div className='row row-small' key={addon.id}>
                          <div className={cx(styles.inputHeight, 'form-group col-5 mb-4')}>
                            <p className='label-title'>Addon Name</p>
                            <p className='text-steal'>{PACKAGE_NAMES[addon.name] || addon.name}</p>
                          </div>
                          <div className={cx(styles.inputHeight, 'form-group col-7 mb-4')}>
                            <p className='label-title'>Price</p>
                            <p className='text-steal'>{formatCurrency(addon.price)}</p>
                          </div>
                        </div>
                      ))
                    ) : (
                      <p className='text-gray'>
                        <i>No addons</i>
                      </p>
                    )}
                    <div className={cx(styles.line, 'pt-4 w-100')} />
                  </div>
                  <div className='pe-5 pt-4 pb-6'>
                    <p className='fw-bold mt-1 mb-3'>Coupons</p>
                    <div className='row row-small'>
                      {details?.details?.coupons && details?.details?.coupons?.length > 0 ? (
                        details.details.coupons.map((coupon) => (
                          <div key={coupon.coupon_id} className={cx(styles.inputHeight, 'form-group col-6 mb-4')}>
                            <p className='label-title'>Coupon Name</p>
                            <p className='text-steal'>{coupon.coupon_id}</p>
                          </div>
                        ))
                      ) : (
                        <p className='text-gray col-12'>
                          <i>No coupons</i>
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div>
                  <TenantEventLog data={details?.details?.eventLog ?? []} />
                </div>
              </Scrollbars>
            </div>
          </div>
          <div className='d-flex justify-content-end border-t pt-4 mt-1 me-5'>
            <button type='button' className='btn btn-primary text-white' onClick={onClose}>
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

DetailsCustomer.propTypes = {
  onClose: PropTypes.func.isRequired,
  customer: PropTypes.object.isRequired,
};

export default DetailsCustomer;
