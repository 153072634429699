import React, { useContext, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import RoundedButton from 'components/RoundedButton/RoundedButton';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import { queryKeys, useCreateClientTasks, useUpdateClientTasks } from '../hooks/useImplementation';
import ClientContext from '../../ClientContext';
import SaveTasksModal from '../modals/SaveTasksModal';

const SaveTasks = ({
  tasks,
  selectedTemplateId,
  setSelectedTemplateId,
  handleConfirmationOpen,
  handleCloseConfirmation,
  selectedTemplate,
  isConfirmationOpen,
  isNotSavedModalOpened,
  onClose,
  handleHideSaveModal,
}) => {
  const queryClient = useQueryClient();
  const { selected } = useContext(ClientContext);
  const [show, setShow] = useState(false);

  const { mutate: updateTasksMutation } = useUpdateClientTasks();
  const { mutate: createTasksMutation } = useCreateClientTasks();

  const handleSuccess = async () => {
    handleCloseConfirmation();
    setSelectedTemplateId(null);
    await queryClient.refetchQueries(queryKeys.clientTasks({ currentPage: 1, perPage: 10000 }), { exact: true });
    setShow(false);
  };

  const onSave = async () => {
    setShow(true);
    onClose();
    const taskData = { tasks: tasks.map((obj) => ({ ...obj, taskStatus: obj.status })) };

    if (selectedTemplateId !== null && tasks.length === 0) {
      createTasksMutation(
        { client: selected.id, data: { template: selectedTemplateId } },
        {
          onSuccess: handleSuccess,
        }
      );
    } else {
      updateTasksMutation(taskData, {
        onSuccess: handleSuccess,
      });
    }
  };

  const handleClickSave = () => {
    if (tasks.length === 0) {
      handleConfirmationOpen();
    } else {
      onSave();
    }
  };

  return (
    <>
      <div className='d-flex justify-content-end flex-end border-t py-4 mt-4 me-5'>
        <RoundedButton
          type='button'
          text={'Save'}
          btnStyle='contained'
          loading={show}
          isDisabled={show}
          color={show ? 'gray' : 'primary'}
          size='md'
          onClick={handleClickSave}
        />
      </div>
      <ConfirmationModal
        isOpen={isConfirmationOpen}
        onAction={onSave}
        onClose={handleCloseConfirmation}
        description={`Do you really want to import '${
          selectedTemplate?.data?.name || 'Default Template'
        }'? This process can not be undone.`}
        title={'Info'}
        type='info'
        actionText='Import'
      />
      {selectedTemplateId === null && tasks.length > 0 && (
        <SaveTasksModal
          isOpen={isNotSavedModalOpened}
          onConfirm={onSave}
          onClose={onClose}
          onHide={handleHideSaveModal}
        />
      )}
    </>
  );
};

export default SaveTasks;
